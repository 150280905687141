import { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useXplainableController } from "context";
import Swal from "sweetalert2";

// @mui material components
import { Card, Switch, Modal, Pagination, PaginationItem } from "@mui/material";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";

import Icon from "@mui/material/Icon";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";

// xplainable dashboard example components
import ReuseableCard from "shared/Cards/ReuseableCard";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import ProgressLineChart from "shared/Charts/ProgressLineChart";
import TableCellComponent from "./components/TableCellComponent";
import MiniStatisticsCard from "shared/Cards/MiniStatisticsCard";
import XButton from "components/XButton";
import Members from "shared/Avatars/Members";
import DataTable from "shared/Tables/DataTable";
import XInput from "components/XInput";
import XSelect from "components/XSelect";

import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCardsMutation, useModelsMutation } from "api/mutations";
import { useAllCollectionQuery, useCollectionsQuery, useXPanelQuery } from "api/query";
import { handleTokenExpired } from "utils";
import { useAuth0 } from "@auth0/auth0-react";
import { getTimeDifference } from "shared/Functions/Date";
import { apiHandlers } from "api/apiHandlers";
import { Select } from "shared/Timeline/ReuseableTimeline/components/Select";
import { XViewToggle } from "components/XViewToggle";
import { XImg } from "components/XImg";
import SearchIcon from "assets/images/search-icon.svg";
import PinkArrowIcon from "assets/images/icons/pink-arrow-right.svg";
import colors from "assets/theme/base/colors";

import { ReactComponent as DarkModelsIcon } from "assets/images/icons/dark-models-icon.svg";
import { ReactComponent as DarkArrowLeft } from "assets/images/icons/pagination/dark-arrow-left.svg";
import { ReactComponent as DarkArrowRight } from "assets/images/icons/pagination/dark-arrow-right.svg";
import DocsScreenshot from "assets/images/docs-screenshot.png";
import DocsScreenshotDark from "assets/images/docs-screenshot-dark.png";
import { PAGINATION } from "constants";
import SimulatedNotebook from "shared/SimulatedNotebook";
import XBadge from "components/XBadge";

export const useDropdownMenu = (openOnClick = false) => {
  const [menu, setMenu] = useState(null);
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  return { menu, openMenu, closeMenu, openOnClick };
};

function ModelOverview() {
  const { apiKey, viewState, activeWorkspace, workspaces } = useApiKey();
  const [controller] = useXplainableController();
  const { darkMode } = controller;
  const { editCardMutation } = useCardsMutation();
  const { logout } = useAuth0();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [dropdowns, setDropdowns] = useState([]);
  const [switchValue, setSwitchValue] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [availableTeams, setAvailableTeams] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [filteredModels, setFilteredModels] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isCardViewEnable, setIsCardViewEnable] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const { deleteModelMutation, archiveModelMutation, moveModelMutation } = useModelsMutation();
  const query = useXPanelQuery(
    {
      team_id: activeWorkspace?.team_id,
      event: ["viewed_model_profile"],
      users: "True",
    },
    apiKey,
    logout
  );
  const { data: userData, isLoading, isError } = query;
  const {
    data: fetchedProjects,
    isLoading: isLoadingModels,
    isError: isErrorModels,
    refetch: refetchModels,
  } = useQuery(
    [activeWorkspace?.organisation_id, activeWorkspace?.team_id, "projects"],
    () => {
      return apiHandlers.models.getModels(activeWorkspace, apiKey).then((data) => {
        if (!data) {
          throw new Error("No data returned from API");
        }
        return data;
      });
    },
    // { enabled: !!activeWorkspace?.organisation_id && !!activeWorkspace?.team_id },
    {
      onSuccess: (data) => {
        const toggleArray = data.data.map((item) => ({
          model_id: item.model_id,
          active: item.active,
        }));

        setSwitchValue(toggleArray);
      },
      onError: (err) => {
        handleTokenExpired(err, logout);
        console.error("Error fetching projects:", err);
      },
      // TODO bring this back into the logic after working it out
      // staleTime: 1000 * 60 * 1,  // Data is considered fresh for 1 minutes
      // cacheTime: 1000 * 60 * 1,  // Data is cached for 1 minutes
      cacheTime: 0,
      retry: 3, // Retry failed queries 3 times
    }
  );

  const { data: allCollectionsData } = useAllCollectionQuery(activeWorkspace, apiKey, logout);

  const handleSwitchChange = (foundSwitch) => {
    const action = foundSwitch.active ? "deactivate" : "activate";
    const endpointType = "models";
    const id = foundSwitch.model_id;

    editCardMutation([activeWorkspace, endpointType, id, action, apiKey], {
      onSuccess: () => {
        const updatedArray = switchValue.map((item) => {
          if (item.model_id === foundSwitch.model_id) {
            return { ...item, active: !foundSwitch.active };
          }
          return item;
        });
        setSwitchValue(updatedArray);

        console.log("The query client is ", queryClient);

        //TODO: get this to work
        //Update the cached data without refetching
        // queryClient.setQueryData(
        //   [activeWorkspace?.organisation_id, activeWorkspace?.team_id, "projects"],
        //   (oldData) => {
        //     console.log(oldData)
        //     const updatedArray = oldData.data.map((item) => {
        //       if (item.model_id === foundSwitch.model_id) {
        //         return { ...item, active: !foundSwitch.active };
        //       }
        //       return item;
        //     });
        //     return { ...oldData, data: updatedArray };
        //   }
        // );
      },
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    });
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const jsonCells = {
    cell1: [
      "import pandas as pd",
      "import xplainable as xp",
      "from xplainable.core.models import XClassifier",
      "from xplainable.core.optimisation.bayesian import XParamOptimiser",
      "from sklearn.model_selection import train_test_split",
    ],
    cell2: ["xp.initialise(", '  api_key="", #<- Add your own token here', ")"],
    cell3: [
      "X, y = df_transformed_cloud.drop(columns=['Label']), df['Label'])",
      "X_train, X_test, y_train, y_test = train_test_split(",
      "  X, y, test_size=0.2, random_state=42)",
    ],
    cell4: ["opt = XParamOptimiser()", "params = opt.optimise(X_train, y_train)"],
    cell5: ["model = XClassifier(**params)", "model.fit(X_train, y_train)"],
    cell6: [
      "model_id = xp.client.create_model_id(",
      "  model,",
      "  model_name='Add a descriptive model name.',",
      "  model_description='Add a description explaining the model.'",
      ")",
    ],
    cell7: [
      "version_id = xp.client.create_model_version(",
      "  model,",
      "  model_id,",
      "  X_train,",
      "  y_train",
      ")",
    ],
  };

  // Fetch projects from the /models endpoint

  useEffect(() => {
    const isCancelled = { current: false };

    // Cleanup
    return () => {
      isCancelled.current = true;
    };
  }, [activeWorkspace, dropdowns, isCardViewEnable]);

  useMemo(() => {
    viewState === "creator" ? setSelectedStatus("all") : setSelectedStatus(true);
  }, [viewState]);

  const handleDeleteProject = async (model_id, api_key) => {
    deleteModelMutation([activeWorkspace, model_id, api_key], {
      onSuccess: () => {
        const filteredProjects = fetchedProjects?.data?.filter(
          (project) => project.model_id !== model_id
        );
        // Update the query cache with the new data
        queryClient.setQueryData(
          [activeWorkspace?.organisation_id, activeWorkspace?.team_id, "projects"],
          (oldData) => {
            return { ...oldData, data: filteredProjects };
          }
        );
      },
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    });
  };

  const handleArchiveProject = async (model_id) => {
    archiveModelMutation([activeWorkspace, model_id, apiKey], {
      onSuccess: () => {
        console.log(`The model id ${model_id} was archived...`);
      },
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    });
  };

  //Handle Filter status
  const handleStatusFilterChange = (status) => {
    setSelectedStatus(status.value);
  };

  const handleRowClick = (e) => {
    const {
      model_id,
      model_name,
      model_description,
      model_type,
      target_name,
      created,
      archived,
      active,
      user,
      active_version,
      deployed,
      deployment_id,
      active_deployment,
      contributors,
      versions,
      number_of_versions,
    } = e.row.original;

    const params = {
      model_id,
      model_name,
      model_description,
      model_type,
      target_name,
      created,
      archived,
      active,
      user,
      active_version,
      deployed,
      deployment_id,
      active_deployment,
      contributors,
      versions,
      number_of_versions,
    };
    navigate(`/models/${model_id}`, {
      state: params,
    });
  };

  const cardActionStyles = ({ palette: { white }, typography: { size } }) => ({
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",

    "& .material-icons-round": {
      ml: 0.5,
      fontSize: size.md,
      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
    },

    "&:hover .material-icons-round": {
      transform: "translateX(5px)",
    },
  });

  // Function to pass to ProjectCard to update active count

  const updateActiveCount = (id, active) => {
    const updatedProjects = fetchedProjects?.data?.map((project) => {
      if (project.model_id === id) {
        return { ...project, active: active };
      }
      return project;
    });
    // setProjects(updatedProjects);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  // Sets the user metadata from the workspaces
  const setAvailableTeamsFromWorkspaces = () => {
    if (!activeWorkspace) return;

    const workspaceTeams = workspaces.find(
      (org) => org.organisation_id === activeWorkspace?.organisation_id
    )?.teams;

    const filteredTeams = workspaceTeams?.filter(
      (team) => team.role_name === "admin" && team.team_id !== activeWorkspace?.team_id
    );

    if (filteredTeams?.length > 0) {
      setAvailableTeams(filteredTeams);
    }
  };

  useEffect(() => {
    if (!fetchedProjects) return;

    const filteredTableRows = fetchedProjects?.data?.filter((item) => {
      const statusCondition =
        selectedStatus !== "Newest" ||
        selectedStatus !== "Oldest" ||
        selectedStatus === "all" ||
        item.active === selectedStatus;
      const searchQueryLower = searchQuery?.toLowerCase();

      const textConditions = item.model_name.toLowerCase().includes(searchQueryLower);

      return statusCondition && textConditions;
    });

    const sort = () => {
      if (selectedStatus === "Newest") {
        return filteredTableRows.toSorted((a, b) => new Date(b.created) - new Date(a.created));
      }

      if (selectedStatus === "Oldest") {
        return filteredTableRows.toSorted((a, b) => new Date(b.created) - new Date(a.created));
      }

      return filteredTableRows;
    };

    setFilteredModels(sort());
  }, [searchQuery, selectedStatus, fetchedProjects]);

  const handleTeamChange = (event) => {
    setSelectedTeam(event);
  };

  //Handle the move of the models
  const handleModelMove = async () => {
    moveModelMutation([activeWorkspace, selectedModel, selectedTeam.value, apiKey], {
      onSuccess: () => {
        setIsModalOpen(false); // Close the modal

        // Filter out project from fetchedProjects
        const filteredProjects = fetchedProjects?.data?.filter(
          (project) => project.model_id !== selectedModel
        );

        // Update the query cache with the new data
        queryClient.setQueryData(
          [activeWorkspace?.organisation_id, activeWorkspace?.team_id, "projects"],
          (oldData) => {
            return { ...oldData, data: filteredProjects };
          }
        );
      },
      onError: (err) => {
        console.log("Error moving model:", err);
        handleTokenExpired(err, logout);
      },
    });
  };

  // UseEffect to update available teams based on the active workspace
  useEffect(() => {
    setAvailableTeamsFromWorkspaces();
  }, [activeWorkspace]);

  const renderProjects = () => {
    const type = "model";

    if (isLoadingModels) {
      return (
        <Card sx={{ width: { xs: "100%", sm: "340px" }, height: "390px" }}>
          <LoadingSpinner size={40} animationType="pulse" />
        </Card>
      );
    }

    if (isLoadingModels) {
      return (
        <Card sx={{ width: "100%", height: "260px" }}>
          <LoadingSpinner size={40} />
        </Card>
      );
    }

    const columns = [
      {
        Header: "",
        width: "5%",
        id: "function",
        Cell: (props) => {
          return (
            <TableCellComponent
              {...props}
              setIsModalOpen={setIsModalOpen}
              setSelectedModel={setSelectedModel}
              project={props.row.original}
              isModel
              handleDeleteProject={handleDeleteProject}
              model_id={props.row.original.model_id}
              deployed={props.row.original.deployed}
            />
          );
        },
      },
      {
        Header: "Model name",
        accessor: "model_name",
        id: "model_name",

        Cell: (props) => {
          if (!props.row.original.model_name) {
            return null;
          }

          if (props.row.original.model_name) {
            return (
              <XBox display="flex" gap="0 10px" alignItems="center">
                <XImg>
                  <DarkModelsIcon />
                </XImg>

                <XTypography
                  variant="h6"
                  textTransform="capitalize"
                  fontWeight="medium"
                  // color={foundSwitch.active ? "light" : "dark"}
                  sx={{
                    maxHeight: "30px",
                    overflowY: "auto",
                    scrollbarWidth: "none",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                    "-msOverflowStyle": "none",
                  }}
                >
                  {props.row.original.model_name}
                </XTypography>
              </XBox>
            );
          }
        },
      },
      {
        Header: "Role",
        accessor: "number_of_versions",
        Cell: (props) => {
          if (!props.row.original.number_of_versions) {
            return null;
          }

          if (props.row.original.number_of_versions) {
            return (
              <>
                <XTypography variant="button" fontWeight="light">
                  {"Version " + props.row.original.number_of_versions}
                </XTypography>
              </>
            );
          }
        },
      },
      {
        Header: "Contributors",
        accessor: "contributors",
        Cell: ({ value }) => (value ? <Members members={value} /> : null),
      },

      {
        Header: "Created",
        accessor: "created",
        Cell: (props) => {
          return (
            <XTypography variant="button" fontWeight="light">
              {props.row.original.created
                ? getTimeDifference(props.row.original.created, true)
                : null}
            </XTypography>
          );
        },
      },
      {
        Header: "Activate",
        width: "5%",
        accessor: "model_id",
        id: "model_id",
        Cell: ({ value }) => {
          const foundSwitch = switchValue.find((item) => item.model_id === value);

          return (
            <Switch
              checked={foundSwitch.active}
              onChange={() => {
                handleSwitchChange(foundSwitch);
              }}
              sx={{
                width: "50px",
                height: "24px",
                "& .MuiSwitch-switchBase": {
                  padding: 0,
                  "&.Mui-checked": {
                    transform: "translate(28px,-10px)",
                  },
                },
                "& .MuiSwitch-thumb": {
                  width: 20,
                  height: 20,
                },

                "& .Mui-checked + .MuiSwitch-track": {
                  backgroundColor: `${colors["xppink"].main} !important`,
                },
              }}
            />
          );
        },
      },
      {
        Header: "",
        width: "5%",
        accessor: "",
        id: "model_transition",
        Cell: (props) => {
          return (
            <XBox
              display="flex"
              gap="10px"
              alignItems="center"
              onClick={() => handleRowClick(props)}
            >
              <XTypography
                variant="button"
                textTransform="capitalize"
                fontWeight="medium"
                color="xppink"
                sx={cardActionStyles}
              >
                Model Insights
              </XTypography>
              <XBox src={PinkArrowIcon} component="img" />
            </XBox>
          );
        },
      },
    ];

    const filteredTableRows = fetchedProjects?.data?.filter((item) => {
      const statusCondition =
        selectedStatus !== "Newest" ||
        selectedStatus !== "Oldest" ||
        selectedStatus === "all" ||
        item.active === selectedStatus;
      const searchQueryLower = searchQuery?.toLowerCase();

      const textConditions = item.model_name.toLowerCase().includes(searchQueryLower);

      return statusCondition && textConditions;
    });

    const sort = () => {
      if (selectedStatus === "Newest") {
        return filteredTableRows.toSorted((a, b) => new Date(b.created) - new Date(a.created));
      }

      if (selectedStatus === "Oldest") {
        return filteredTableRows.toSorted((a, b) => new Date(a.created) - new Date(b.created));
      }

      return filteredTableRows;
    };

    const startIndex = (currentPage - 1) * PAGINATION.MODELS_ITEMS_PER_PAGE;
    const endIndex = startIndex + PAGINATION.MODELS_ITEMS_PER_PAGE;
    const currentModels = sort()?.slice(startIndex, endIndex);

    return (
      <>
        {isCardViewEnable &&
          currentModels?.map((project, index) => (
            <ProjectCard
              key={index}
              index={index}
              project={project}
              handleDeleteProject={handleDeleteProject}
              handleArchiveProject={handleArchiveProject}
              selectedStatus={selectedStatus}
              updateActiveCount={updateActiveCount}
              searchQuery={searchQuery}
              setIsModalOpen={setIsModalOpen}
              setSelectedModel={setSelectedModel}
              allCollectionsData={allCollectionsData}
            />
          ))}

        {!isCardViewEnable && filteredTableRows.length > 0 && (
          <Card
            sx={{
              width: "100%",
              position: "relative",
              zIndex: 1,
              overflow: "visible",
            }}
          >
            <DataTable
              table={{ rows: filteredTableRows, columns: columns }}
              entriesPerPage={{
                defaultValue: 8,
                entries: [8, 15],
              }}
              type={type}
            />
          </Card>
        )}
      </>
    );
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="request-title"
        aria-describedby="request-description"
      >
        <XBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -60%)",
            width: 600,
            background: controller.darkMode ? colors.background.dark : colors.background.default,
            borderRadius: "16px",
            padding: 3,
          }}
        >
          <XBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <XTypography fontSize="16px" fontWeight="bold">
              Move the model to another team
            </XTypography>
            <Icon
              sx={({ typography: { size, fontWeightBold }, palette: { dark, white } }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                color: controller.darkMode ? white.main : dark.main,
                stroke: controller.darkMode ? white.main : dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
              })}
              onClick={handleClose}
            >
              close
            </Icon>
          </XBox>
          <XBox display="flex" flexDirection="column" gap={2}>
            <XSelect
              value={selectedTeam}
              onChange={handleTeamChange}
              fullWidth
              placeholder="Select Team"
              options={availableTeams?.map((team) => ({
                value: team.team_id,
                label: team.team_name,
              }))}
            />
            <XButton
              disabled={!selectedTeam}
              variant="gradient"
              color="button"
              sx={{ alignSelf: "end" }}
              onClick={handleModelMove}
            >
              Confirm model move
            </XButton>
          </XBox>
        </XBox>
      </Modal>
      <Grid container spacing={3} mt={3}>
        <Grid item xs={12} lg={9}>
          <XBox>
            <ProgressLineChart
              color={"xppink"}
              bgColor="dark"
              icon="groups"
              title="Total Model Views"
              progress={60}
              height={"214px"}
              event="viewed_model_profile"
              team_id={activeWorkspace?.team_id}
            />
          </XBox>
        </Grid>
        <Grid item xs={12} lg={3} display="flex">
          <XBox display="flex" flexDirection="column" gap={4} width="100%">
            <MiniStatisticsCard
              title={{ text: "Published Models", fontWeight: "medium" }}
              count={
                fetchedProjects
                  ? `${fetchedProjects?.data?.filter((x) => x.active === true).length}/${
                      fetchedProjects?.data?.length
                    }`
                  : 0
              }
              icon={{ component: "add_chart", color: "xppink" }}
              color={"xppink"}
            />
            <MiniStatisticsCard
              title={{ text: "All Viewers", fontWeight: "medium" }}
              count={userData?.data?.length || 0}
              color={"xppink"}
              users={userData?.data}
            />
          </XBox>
        </Grid>
      </Grid>
      <XBox pt={3} height={"100%"} pb={{ xs: 0, lg: 24 }}>
        <Grid container sx={{ width: "100%" }}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <XBox mb={2}>
              <XTypography variant="h5" fontSize="18px">
                All Models
              </XTypography>
            </XBox>
          </Grid>

          <XBox display="flex" justifyContent="space-between" width="100%">
            <XBox display="flex" gap={2} justifyContent="space-between" width="550px">
              <XInput
                sx={{ width: "100%" }}
                placeholder="Search"
                startAdornment={<XBox src={SearchIcon} component="img" />}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <XBox position="relative">
                <Select
                  color="ffffff"
                  darkColor="262525"
                  selectOptions={[
                    { value: "all", label: "All" },
                    { value: false, label: "Draft" },
                    { value: true, label: "Published" },
                    { value: "Newest", label: "Newest" },
                    { value: "Oldest", label: "Oldest" },
                  ]}
                  handleDropdownItemClick={handleStatusFilterChange}
                />
              </XBox>
            </XBox>

            <XBox>
              <XViewToggle
                color="xppink"
                isCardViewEnable={isCardViewEnable}
                setIsCardViewEnable={setIsCardViewEnable}
                updatePreferenceKey="models_view_table"
              />
            </XBox>
          </XBox>
        </Grid>

        <XBox mt={{ xs: 1, lg: 3 }} mb={1}>
          <XBox
            display="flex"
            flexWrap="wrap"
            sx={{ justifyContent: { xs: "center", lg: "flex-start" } }}
          >
            {fetchedProjects?.data.length === 0 && viewState !== "viewer" && (
              <XBox display="flex" height={"100%"} flexDirection="row" width="100%" gap={3}>
                <XBox display="flex" flexDirection="column" gap={2} width="50%">
                  <Card sx={{ width: "100%", p: 3 }}>
                    <XBox
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      <XTypography variant="h5" fontWeight="bold">
                        Quick Start
                      </XTypography>
                      <XBadge badgeContent="Xplainable v1.2.1" color="xpblue" variant="contained" />
                    </XBox>
                    <XTypography variant="body1" fontSize="14px" fontWeight="light" mb={2}>
                      Copy the following code into your Python IDE to get started with Xplainable.
                    </XTypography>
                    <SimulatedNotebook cells={jsonCells} />
                  </Card>
                </XBox>
                <XBox display="flex" flexDirection="column" gap={2} width="50%">
                  <Card
                    sx={{
                      width: "100%",
                      p: 3,
                      "&:hover": {
                        cursor: "pointer", // This will change the cursor to a pointer on hover
                      },
                    }}
                    onClick={() => {
                      window.open(
                        "https://docs.xplainable.io/docs/getting-started/about-xplainable",
                        "_blank"
                      );
                    }}
                  >
                    <XTypography variant="h5" fontWeight="bold" mb={2}>
                      Documentation
                    </XTypography>
                    <XTypography variant="body1" fontSize="14px" fontWeight="light" mb={2}>
                      Alternatively, explore our comprehensive documentation for advanced features,
                      such as saving preprocessing pipelines, utilizing regression modeling
                      functionality, and creating deployments directly from your IDE.
                    </XTypography>
                    <XBox
                      display="flex"
                      component="img"
                      src={darkMode ? DocsScreenshotDark : DocsScreenshot}
                      borderRadius="14px"
                    />
                  </Card>
                </XBox>
              </XBox>
            )}
            {renderProjects()}
          </XBox>
        </XBox>
        {filteredModels && filteredModels.length > 0 && (
          <Grid item xs={12}>
            <Pagination
              count={Math.ceil(filteredModels.length / PAGINATION.MODELS_ITEMS_PER_PAGE)}
              page={currentPage}
              onChange={handlePageChange}
              shape="rounded"
              sx={{
                alignSelf: "end",
                "& .Mui-selected": {
                  background: "linear-gradient(to right, #E14086, #0080EA)",
                  color: "white !important",
                },
              }}
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  components={{
                    next: () => (
                      <XBox display="flex" sx={{ borderRadius: "4px" }} p="4px" bgColor="#F7F7F8">
                        <XImg lightColor="black">
                          <DarkArrowRight />
                        </XImg>
                      </XBox>
                    ),
                    previous: () => (
                      <XBox display="flex" sx={{ borderRadius: "4px" }} p="4px" bgColor="#F7F7F8">
                        <XImg lightColor="black">
                          <DarkArrowLeft />
                        </XImg>
                      </XBox>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        )}
      </XBox>
    </>
  );
}

export default ModelOverview;

function ProjectCard({
  handleDeleteProject,
  index,
  updateActiveCount,
  setIsModalOpen,
  setSelectedModel,
  project,
  allCollectionsData
}) {
  const menuProps = useDropdownMenu(true);
  const theme = useTheme();

  const [controller] = useXplainableController();

  const showAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: `${project.model_name} has an active deployment.`,
        text: "Please delete the associated deployment before deleting this model.",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Okay, I confirm",
      })
      .then((result) => {
        if (result.isConfirmed) {
          return;
        }
      });
  };

  const showCollectionAlert = () => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: `${project.model_name} has collections.`,
        text: "Please delete collections before deleting this model.",
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Okay, I confirm",
      })
      .then((result) => {
        if (result.isConfirmed) {
          return;
        }
      });
  };

  //Render the menu and match it to current theme state
  const renderMenu = (state, close, model_id, active) => {
    const menuBackground = active ? theme.palette.dark.main : theme.palette.white;
    const menuTextColor = active ? theme.palette.white : theme.palette.text.primary;

    return (
      <Menu
        anchorEl={state}
        open={Boolean(state)}
        onClose={close}
        keepMounted
        PaperProps={{
          style: { backgroundColor: menuBackground },
        }}
      >
        <MenuItem
          onClick={() => {
            setSelectedModel(project?.model_id);
            setIsModalOpen(true);
            close();
          }}
          style={{
            color: controller.darkMode ? "white" : "dark",
          }}
        >
          Move Model
        </MenuItem>
        <MenuItem
          onClick={async () => {
            if (project.deployed) {
              showAlert();
              close();
              return;
            }

            const isCollection = allCollectionsData.data.find(
              (item) => item.model_id === project.model_id
            );

            if (isCollection) {
              showCollectionAlert();
              close();
              return;
            }

            handleDeleteProject(model_id);

            close();
          }}
          style={{
            color: "#EA2C4C",
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    );
  };

  return (
    <XBox
      m={1}
      sx={{
        width: { xs: "100%", sm: "340px" },
        opacity: 0,
        transform: "translateY(20px)",
        animation: "slide-up 0.5s forwards",
        animationDelay: `${index * 0.03}s`,
        "@keyframes slide-up": {
          "100%": {
            opacity: 1,
            transform: "translateY(0)",
          },
        },
      }}
    >
      <ReuseableCard
        type={"model"}
        color="xppink"
        dropdown={{
          action: () => menuProps.openMenu(),
          menu: renderMenu(
            menuProps.menu,
            () => menuProps.closeMenu(),
            project.model_id,
            project.active
          ),
        }}
        onClick={menuProps.openOnClick ? menuProps.openMenu : undefined}
        project={project}
        updateActiveCount={updateActiveCount}
      />
    </XBox>
  );
}

ProjectCard.propTypes = {
  project: PropTypes.object,
  selectedStatus: PropTypes.string,
  handleDeleteProject: PropTypes.func,
  handleArchiveProject: PropTypes.func,
  index: PropTypes.number,
  updateActiveCount: PropTypes.func,
  searchQuery: PropTypes.string,
  setIsModalOpen: PropTypes.func,
  setSelectedModel: PropTypes.func,
  allCollectionsData: PropTypes.array,
  active: PropTypes.bool
};

ModelOverview.propTypes = {
  value: PropTypes.number,
  row: PropTypes.number,
};
