import React from "react";
import PropTypes from "prop-types";

//xplainable Dashboard components
import XBox from "components/XBox";
import XAvatar from "components/XAvatar";

//xplainable dashboard base styles
import { Reply } from "../Reply";
import { useReplies } from "shared/Comments/hooks";
import { MentionInput } from "../MentionsInput";

export const Replies = ({
  activeWorkspace,
  type,
  apiKey,
  id,
  isReplyOpen,
  fetchedComments,
  setFetchedComments,
  comment,
  tabValue,
  tabMap,
  currentUser,
  handleLike,
}) => {
  const { replies } = comment;

  const { handleAddReply, replyInputValue, setReplyInputValue, user, fetchUsers } = useReplies({
    comment,
    tabValue,
    apiKey,
    activeWorkspace,
    tabMap,
    fetchedComments,
    setFetchedComments,
    id,
    type,
  });

  return (
    <XBox
      style={{
        marginLeft: "20px",
        fontSize: "0.9rem",
        maxHeight: isReplyOpen ? "1000px" : "0",
        transition: isReplyOpen ? "max-height 0.5s ease, opacity 0.5s ease" : "",
        opacity: isReplyOpen ? 1 : 0,
      }}
    >
      {isReplyOpen && (
        <XBox display="flex" alignItems="center" mt={3}>
          <XBox sx={{ position: "relative", flexGrow: "1", maxWidth: "80%" }}>
            <MentionInput
              mentionValue={replyInputValue}
              setMentionValue={setReplyInputValue}
              data={fetchUsers}
              handleSubmit={handleAddReply}
            />
          </XBox>
        </XBox>
      )}
      <XBox
        maxHeight="600px"
        overflow="scroll"
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
      >
        {replies.map((reply, index) => (
          <Reply
            key={index}
            replyKey={index}
            currentUser={currentUser}
            reply={reply}
            fetchedComments={fetchedComments}
            setFetchedComments={setFetchedComments}
            type={type}
            id={id}
            handleLike={handleLike}
          />
        ))}
      </XBox>
    </XBox>
  );
};

Replies.propTypes = {
  id: PropTypes.string,
  isReplyOpen: PropTypes.bool,
  setFetchedComments: PropTypes.func,
  fetchedComments: PropTypes.array,
  comment: PropTypes.object,
  key: PropTypes.number,
  type: PropTypes.string,
  activeWorkspace: PropTypes.object,
  apiKey: PropTypes.string,
  tabValue: PropTypes.number,
  tabMap: PropTypes.object,
  currentUser: PropTypes.string,
  handleLike: PropTypes.func,
};
