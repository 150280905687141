import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "api/apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useRegressionPerformanceQuery = (
  activeWorkspace,
  model_id,
  version_id,
  partition_id,
  logout
) => {
  return useQuery(
    [QUERY_CONSTANTS.REGRESSION_PERFORMANCE, activeWorkspace, model_id, version_id, partition_id],
    () =>
      apiHandlers.regressionPerformance.getData(
        activeWorkspace,
        model_id,
        version_id,
        partition_id
      ),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
