import React, { useMemo, useState } from "react";

import xplainableLogo from "assets/images/xplainable-logo.svg";
import XAvatar from "components/XAvatar";

import { ReactComponent as FileIcon } from "assets/images/icons/train/file-icon.svg";
import { ReactComponent as SavedFileIcon } from "assets/images/icons/train/saved-icon.svg";
import { ReactComponent as CodeIcon } from "assets/images/icons/train/code-icon.svg";
import { ReactComponent as ExplainationIcon } from "assets/images/icons/train/explaination-icon.svg";
import { ReactComponent as ExpandIcon } from "assets/images/icons/train/expand-icon.svg";
import { ReactComponent as RefineIcon } from "assets/images/icons/train/refine-icon.svg";
import { ReactComponent as RegenerateIcon } from "assets/images/icons/train/regenerate-icon.svg";
import { ReactComponent as ChevronLeftIcon } from "assets/images/icons/train/chevron-left-icon.svg";
import { ReactComponent as ChevronRightIcon } from "assets/images/icons/train/chevron-right-icon.svg";
import { ReactComponent as ChatErrorIcon } from "assets/images/icons/train/chat-error-icon.svg";
import { ReactComponent as ChatImageErrorIcon } from "assets/images/icons/train/chat-image-error-icon.svg";

import { AppBar, Tab, Tabs } from "@mui/material";
import { XDivider } from "components/XDivider";
import { useAutoTrain, useToast } from "hooks";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { Base64Image } from "../..";
import { CopyBlock, dracula, googlecode } from "react-code-blocks";
import { useTrainMutation } from "api/mutations";
import { useXplainableController } from "context";
import { v4 as uuidv4 } from "uuid";

import PropTypes from "prop-types";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XButton from "components/XButton";
import XInput from "components/XInput";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import { XImg } from "components/XImg";
import colors from "assets/theme/base/colors";

export const ChatMessage = ({
  item,
  setVisualisationModalData,
  visualisationModalData,
  setChatHistory,
  summarizeData,
  setIsRetry,
  isAnalystExpanded,
  isAnalyst,
  modelSettings
}) => {
  const { user, activeWorkspace } = useApiKey();
  const { 
    visualizeEditMutation, 
    visualizeRecommendMutation, 
    visualizeExplainMutation,
    visualizeRepairMutation
  } = useTrainMutation();
  const { showSuccessToast, showErrorToast } = useToast();
  const { savedPlots, setSavedPlots } = useAutoTrain();

  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const [isCodeOpen, setIsCodeOpen] = useState(false);
  const [isRefineOpen, setIsRefineOpen] = useState(false);
  const [isExplainationOpen, setIsExplainationOpen] = useState(false);

  const [isRefineLoading, setRefineLoading] = useState(false);
  const [isRecommendLoading, setIsRecommendLoading] = useState(false);
  const [isExplainLoading, setIsExplainLoading] = useState(false);
  const [isRepairLoading, setIsRepairLoading] = useState(false)

  const [inputValue, setInputValue] = useState("");
  const [activeChat, setActiveChat] = useState(1);
  const [codeTabValue, setCodeTabValue] = useState(0);

  const [chatRefineHistory, setChatRefineHistory] = useState([]);
  const [explainData, setExplainData] = useState([]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleRefine = async () => {
    setRefineLoading(true);
    setChatRefineHistory((prev) => [...prev, inputValue]);
    setInputValue("");

    try {
      const editObj = {
        summary: summarizeData.summary,
        code: item?.data[activeChat - 1]?.code,
        library: "plotly",
        instructions: [...chatRefineHistory, inputValue],
        textgen_config: modelSettings
      };
      const { data } = await visualizeEditMutation([activeWorkspace, editObj]);

      if (!data.status) {
        setRefineLoading(false);
        showErrorToast(data.message);
        return;
      }

      showSuccessToast("Chat was successfully updated");

      setChatHistory((prevState) => {
        return prevState.map((prevStateItem) =>
          prevStateItem.id === item.id
            ? {
                ...prevStateItem,
                data: [
                  ...prevStateItem.data,
                  { ...data?.result?.[0], refine: inputValue, refineId: uuidv4(), isRefined: true },
                ],
              }
            : prevStateItem
        );
      });

      setActiveChat((prevState) => prevState + 1);
      setRefineLoading(false);
    } catch (error) {
      setRefineLoading(false);
      showErrorToast(error?.response?.data?.message || "Error", 5000);
    }
  };

  const handleRecommend = async () => {
    if (isRecommendLoading) {
      return;
    }

    setIsRecommendLoading(true);

    try {
      const recommendData = {
        summary: summarizeData.summary,
        code: item?.data[activeChat - 1]?.code,
        library: "plotly",
        textgen_config: modelSettings
      };
      const { data } = await visualizeRecommendMutation([activeWorkspace, recommendData]);

      setChatHistory((prevState) => {
        const newChatItems = data.charts.map((item) => ({
          id: uuidv4(),
          sender: "ai",
          data: [item],
          goal: { question: "Recommendation" },
          type: "Recommendation",
        }));

        return [...prevState, ...newChatItems];
      });
      setIsRecommendLoading(false);
    } catch (error) {
      setIsRecommendLoading(false);
      showErrorToast(error?.response?.data?.message || "Error", 5000);
    }
  };

  const handleExplain = async () => {
    try {
      setIsExplainLoading(true);
      const explainData = {
        code: item?.data[activeChat - 1]?.code,
        library: "plotly",
        textgen_config: modelSettings
      };

      const { data } = await visualizeExplainMutation([activeWorkspace, explainData]);

      console.log("The explain data is", data)
      if (!data.status) {
        showErrorToast(data.message);
        setIsLoading(false);
        setIsExplainLoading(false);
        return;
      }
      setExplainData(data);
      setIsExplainLoading(false);
    } catch (error) {
      setIsExplainLoading(false);
      showErrorToast(error?.response?.data?.message || "Error", 5000);
    }
  };

  const handleRepair = async () => {
    try {
      setIsRepairLoading(true);
      const explainData = {
        code: item?.data[activeChat - 1]?.code,
        feedback: item?.data[activeChat - 1]?.error,
        goal: item?.goal?.question,
        summary:summarizeData.summary,
        library: "plotly",
        textgen_config: modelSettings
      };

      const { data } = await visualizeRepairMutation([activeWorkspace, explainData]);
      if (!data.status) {
        showErrorToast(data.message);
        setIsLoading(false);
        // setIsExplainLoading(false);
        return;
      }
      setExplainData(data);
      setIsRepairLoading(false);
    } catch (error) {
      setIsRepairLoading(false);
      showErrorToast(error?.response?.data?.message || "Error", 5000);
    }
  };

  const totalNumberOfChats = useMemo(() => {
    if (!item?.data) return;

    return item.data.length;
  }, [item]);

  const handlePrevChartButton = () => {
    setActiveChat((prevState) => (prevState !== 1 ? prevState - 1 : prevState));
  };

  const handleNextChartButton = () => {
    setActiveChat((prevState) => (prevState !== totalNumberOfChats ? prevState + 1 : prevState));
  };

  const handleRetry = () => {
    setChatHistory((prevState) => {
      const newHistory = prevState.slice(0, -2);
      return [...newHistory];
    });
    setIsRetry((prevState) => !prevState);
  };

  const handleCodeTabValue = (event, newValue) => {
    setCodeTabValue(newValue);
  };

  const handleSavePlot = (item) => {
    const obj = {
      title: item?.goal?.question,
      description: item?.goal?.rationale || item?.goal?.question,
      // refine: item?.data[activeChat - 1]?.refine,
      id: item?.data[activeChat - 1].isRefined ? item.data[activeChat - 1].refineId : item.id,
      raster: item?.data[activeChat - 1]?.raster,
    };

    if (savedPlots.some((el) => el.id === obj.id)) {
      setSavedPlots(savedPlots.filter((el) => el.id !== obj.id));
      return;
    }

    setSavedPlots((prev) => [...prev, obj]);
  };

  const savedItemId = useMemo(() => {
    return item?.data && item?.data[activeChat - 1]?.isRefined
      ? item.data[activeChat - 1]?.refineId
      : item.id;
  }, [activeChat]);

  return (
    // TODO: Jamie remove scroll-snap-align to remove this effect
    <XBox key={item.id} 
      // sx={{ "scroll-snap-align": "center" }}
      >
      {item.sender === "ai" && (
        <XBox sx={{ padding: "24px", display: "flex", flexDirection: "column", gap: "12px" }}>
          <XBox display="flex" gap="12px" alignItems="center" justifyContent="space-between">
            <XBox display="flex" gap="12px" alignItems="center">
              <img src={xplainableLogo} width={32} />
              <XTypography fontSize="16px" fontWeight="bold">
                Xplainable
              </XTypography>
            </XBox>

            {item.type !== "error" && (
              <XBox
                display="flex"
                gap="10px"
                padding="12px 10px"
                alignItems="center"
                borderRadius="12px"
                sx={{
                  backgroundColor: darkMode ? "#1D1B1B" : "#F7F7F8",
                }}
              >
                <XBox sx={{ cursor: "pointer", display: "flex" }} onClick={handlePrevChartButton}>
                  <XImg>
                    <ChevronLeftIcon />
                  </XImg>
                </XBox>
                <XBox>
                  <XTypography
                    fontSize="14px"
                    fontWeight="bold"
                  >{`${activeChat}/${totalNumberOfChats}`}</XTypography>
                </XBox>
                <XBox sx={{ cursor: "pointer", display: "flex" }} onClick={handleNextChartButton}>
                  <XImg>
                    <ChevronRightIcon />
                  </XImg>
                </XBox>
              </XBox>
            )}
          </XBox>

          {item.type === "error" ? (
            <XBox
              display="flex"
              flexDirection="column"
              gap={3}
              sx={{
                border: "1px solid #EAEAEA",
                borderRadius: "16px",
                padding: "24px",
                marginLeft: "44px",
                position: "relative",
                alignItems: "center",
                justifyContent: "center",
                gap: "28px",
              }}
            >
              <ChatErrorIcon />
              <XBox
                display="flex"
                flexDirection="column"
                gap="12px"
                alignItems="center"
                justifyContent="center"
              >
                <XTypography fontSize="24px" fontWeight="bold">
                  System Error
                </XTypography>
                <XTypography fontSize="16px" sx={{ color: "#7C7C7C", textAlign: "center" }}>
                  {"We're sorry, the system is experiencing an issue and cannot"} <br />
                  {"respond at the moment."}
                </XTypography>
              </XBox>

              <XButton color="button" onClick={handleRetry}>
                Retry
              </XButton>
            </XBox>
          ) : (
            <>
              <XBox
                display="flex"
                flexDirection="column"
                gap={3}
                sx={{
                  border: "1px solid #EAEAEA",
                  borderRadius: "16px",
                  padding: "24px",
                  marginLeft: "44px",
                  position: "relative",
                }}
              >
                {isRefineLoading && (
                  <XBox
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "rgba(255, 255, 255, 0.5)",
                      zIndex: "9999",
                    }}
                  >
                    <LoadingSpinner animationType="pulse" />
                  </XBox>
                )}

                <XBox display="flex" justifyContent="space-between" alignItems="center">
                  <XBox sx={{ maxWidth: "70%" }}>
                    <XTypography fontSize="16px" fontWeight="bold">
                      {item?.goal?.question}
                    </XTypography>
                    <XTypography fontSize="14px" color="secondary">
                      {item?.goal?.rationale || item?.goal?.question}
                    </XTypography>
                  </XBox>

                  <XBox display="flex" gap={1}>
                    <XBox
                      sx={{
                        display: "flex",
                        padding: "10px 12px",
                        backgroundColor: savedPlots.some((el) => el.id === savedItemId) ? "#FFEBF4" :
                        (darkMode ? "#1D1B1B" : "#F7F7F8"),
                        borderRadius: "12px",
                        alignItems: "center",
                        transition: ".3s all",
                        cursor: "pointer",
                        border: savedPlots.some((el) => el.id === savedItemId) && "1px solid #DE4188",
                        "&:hover": {
                          transform: "scale(105%)",
                        },
                      }}
                    >
                      <XImg onClick={() => handleSavePlot(item)}>
                        {savedPlots.some((el) => el.id === savedItemId) ? <SavedFileIcon /> : <FileIcon />}
                      </XImg>
                    </XBox>
                    <XBox
                      onClick={() => {
                        setIsExplainationOpen(false);
                        setIsCodeOpen((prevState) => !prevState);
                      }}
                      sx={{
                        display: "flex",
                        padding: "8px 12px",
                        backgroundColor: darkMode ? "#1D1B1B" : "#F7F7F8",
                        borderRadius: "12px",
                        alignItems: "center",
                        transition: ".3s all",
                        cursor: "pointer",
                        boxShadow: isCodeOpen ? "0px 0px 0px 1px black" : "none",
                        "&:hover": {
                          transform: "scale(105%)",
                        },
                      }}
                    >
                      <XImg>
                        <CodeIcon />
                      </XImg>
                    </XBox>
                    <XBox
                      onClick={() => {
                        handleExplain();
                        setIsCodeOpen(false);
                        setIsExplainationOpen((prevState) => !prevState);
                      }}
                      sx={{
                        display: "flex",
                        padding: "10px 12px",
                        backgroundColor: darkMode ? "#1D1B1B" : "#F7F7F8",
                        borderRadius: "12px",
                        alignItems: "center",
                        transition: ".3s all",
                        cursor: "pointer",
                        boxShadow: isExplainationOpen ? "0px 0px 0px 1px black" : "none",
                        "&:hover": {
                          transform: "scale(105%)",
                        },
                      }}
                    >
                      <XImg>
                        <ExplainationIcon />
                      </XImg>
                    </XBox>
                    <XBox
                      sx={{
                        display: "flex",
                        padding: "10px 12px",
                        backgroundColor: darkMode ? "#1D1B1B" : "#F7F7F8",
                        borderRadius: "12px",
                        alignItems: "center",
                        transition: ".3s all",
                        cursor: "pointer",
                        boxShadow: visualisationModalData ? "0px 0px 0px 1px black" : "none",
                        "&:hover": {
                          transform: "scale(105%)",
                        },
                      }}
                      onClick={() => {
                        setVisualisationModalData({ ...item, activeChat });
                      }}
                    >
                      <XImg>
                        <ExpandIcon />
                      </XImg>
                    </XBox>
                  </XBox>
                </XBox>

                <XDivider />

                {item?.data[activeChat - 1]?.refine && (
                  <XBox
                    sx={{
                      backgroundColor: "#EAEAEA",
                      borderRadius: "100px",
                      padding: "8px 12px",
                      display: "flex",
                      width: "fit-content",
                      gap: "4px",
                    }}
                  >
                    <XTypography sx={{ color: "#7C7C7C", fontStyle: "italic", fontSize: "14px" }}>
                      Refine:
                    </XTypography>
                    <XTypography fontSize="14px">{item?.data[activeChat - 1]?.refine}</XTypography>
                  </XBox>
                )}

                <XBox
                  sx={{
                    width: "100%",
                    display: "flex",
                    gap: "8px",
                    flexWrap: isAnalystExpanded || isAnalyst ? "nowrap" : "wrap",
                  }}
                >
                  <XBox
                    width={isAnalystExpanded || isAnalyst ? "50%" : "100%"}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {item?.data[activeChat - 1]?.raster ? (
                      <Base64Image base64={item?.data[activeChat - 1]?.raster} />
                    ) : (
                      <ChatImageErrorIcon style={{ width: "300px", height: "300px" }} />
                    )}
                  </XBox>
                  {isCodeOpen && (
                    <XBox
                      sx={{
                        overflow: "auto",
                        overflowY: "scroll",
                        borderRadius: "5px",
                        border: "1px solid grey",
                        fontSize: "14px",
                        minHeight: "300px",
                      }}
                      width={isAnalystExpanded || isAnalyst ? "50%" : "100%"}
                    >
                      <AppBar position="static">
                        <Tabs
                          value={codeTabValue}
                          onChange={handleCodeTabValue}
                          sx={{
                            maxWidth: "700px",

                            "& .Mui-selected": {
                              color: `${colors.xppink.main} !important`,
                              fontWeight: "600",
                            },
                            "& .MuiTabs-indicator": {
                              backgroundColor: colors.xppink.main,
                            },
                          }}
                        >
                          <Tab label="Python Code" sx={{ minWidth: 0, maxWidth: "max-content" }} />
                          {item?.data[activeChat - 1]?.error && (
                            <Tab label="Error" sx={{ minWidth: 0, maxWidth: "max-content" }} />
                          )}
                        </Tabs>
                      </AppBar>
                      {/* TODO: Jamie, uncomment this line to see how it looks with wrapped lines */}
                      {codeTabValue === 0 && (
                        <CopyBlock
                          text={item?.data[activeChat - 1]?.code}
                          language="python"
                          theme={darkMode ? dracula : googlecode}
                          showLineNumbers={true}
                          customStyle={{
                            height: "10px",
                          }}
                          // wrapLongLines
                        />
                      )}

                      {codeTabValue === 1 && (
                        <XBox display="flex" flexDirection="column" gap={1} p={1}>
                          <XTypography fontSize="14px">Error Message</XTypography>

                          <XBox sx={{ code: { background: "#EDF2F7", borderRadius: "5px" } }}>
                            <CopyBlock
                              text={item?.data[activeChat - 1]?.error?.message}
                              language="python"
                              theme={darkMode ? dracula : googlecode}
                              showLineNumbers={false}
                              codeBlock
                            />
                          </XBox>

                          <XTypography fontSize="14px">Traceback</XTypography>
                          <XBox sx={{ code: { background: "#EDF2F7", borderRadius: "5px" } }}>
                            <CopyBlock
                              text={item?.data[activeChat - 1]?.error?.traceback}
                              language="python"
                              theme={darkMode ? dracula : googlecode}
                              showLineNumbers={false}
                              codeBlock
                            />
                          </XBox>
                        </XBox>
                      )}
                    </XBox>
                  )}

                  {isExplainationOpen && (
                    <XBox
                      sx={{
                        overflow: "auto",
                        overflowY: "scroll",
                        borderRadius: "5px",
                        border: "1px solid grey",
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      width={isAnalystExpanded || isAnalyst ? "50%" : "100%"}
                    >
                      {isExplainLoading ? (
                        <LoadingSpinner animationType="pulse" size={50} />
                      ) : (
                        <XBox>
                          <XTypography fontSize="16px" fontWeight="bold" ml={2}>
                            Explanation
                          </XTypography>
                          <XBox
                          display="flex"
                          flexDirection="column"
                          p={2}
                          sx={{
                            borderRadius: "12px",
                            overflowY: "scroll",
                            height: "320px",
                            paddingTop: 0, // Removes top padding
                            paddingBottom: 0 // Removes bottom padding
                          }}
                        >
                          {
                            explainData?.explanations && explainData?.explanations.length > 0 ? (
                              explainData?.explanations?.map((item, index) => (
                                <XBox key={index} mt={1}>
                                  {/* Loop through each explanation object and display both keys and values */}
                                  {Object.keys(item).map((sectionKey) => (
                                    <XBox key={sectionKey} mb={2}>
                                      {/* Display the header */}
                                      <XTypography
                                        fontWeight="medium"
                                        fontSize="14px"
                                        sx={{ color: "#DE4188" }}
                                      >
                                        {sectionKey}
                                      </XTypography>

                                      {/* Display the corresponding text (value) */}
                                      <XTypography
                                        fontSize="14px"
                                        sx={{ color: "#7C7C7C" }}
                                        whiteSpace="pre-line" // To handle line breaks in text
                                      >
                                        {item[sectionKey] || "No data available"}
                                      </XTypography>
                                    </XBox>
                                  ))}
                                </XBox>
                              ))
                            ) : (
                              <XTypography fontSize="14px" sx={{ color: "#7C7C7C" }}>
                                No data available
                              </XTypography>
                            )
                          }
                        </XBox>
                      </XBox>
                      )}
                    </XBox>
                  )}
                </XBox>
                <XBox display="flex" gap={1}>
                  <XBox
                    sx={{
                      display: "flex",
                      padding: "10px 12px",
                      backgroundColor: darkMode ? "#1D1B1B" : "#F7F7F8",
                      borderRadius: "12px",
                      alignItems: "center",
                      transition: ".3s all",
                      fontSize: "14px",
                      gap: "10px",
                      cursor: "pointer",
                      "&:hover": {
                        transform: "scale(105%)",
                      },
                    }}
                    onClick={() => setIsRefineOpen((prevState) => !prevState)}
                  >
                    <XImg>
                      <RefineIcon />
                    </XImg>
                    <XTypography fontSize="14px">Refine</XTypography>
                  </XBox>

                  {item?.type !== "Recommendation" && (
                    <XBox
                      sx={{
                        display: "flex",
                        padding: "10px 12px",
                        backgroundColor: darkMode ? "#1D1B1B" : "#F7F7F8",
                        borderRadius: "12px",
                        alignItems: "center",
                        transition: ".3s all",
                        fontSize: "14px",
                        gap: "10px",
                        cursor: isRecommendLoading ? "default" : "pointer",
                        "&:hover": {
                          transform: isRecommendLoading ? "normal" : "scale(105%)",
                        },
                        opacity: isRecommendLoading ? "50%" : "100%",
                      }}
                      onClick={handleRecommend}
                    >
                      <XImg>
                        <RegenerateIcon />
                      </XImg>
                      <XTypography fontSize="14px">Recommend</XTypography>
                    </XBox>
                  )}
                </XBox>

                {item?.data[activeChat - 1]?.error && (
                  <XBox
                    sx={{
                      display: "flex",
                      padding: "10px 12px",
                      backgroundColor: darkMode ? "#1D1B1B" : "#F7F7F8",
                      borderRadius: "12px",
                      alignItems: "center",
                      transition: ".3s all",
                      fontSize: "14px",
                      gap: "10px",
                      cursor: isRepairLoading ? "default" : "pointer",
                      "&:hover": {
                        transform: isRepairLoading ? "normal" : "scale(105%)",
                      },
                      opacity: isRepairLoading ? "50%" : "100%",
                    }}
                    onClick={handleRepair}
                    >
                    <XImg>
                      <RegenerateIcon />
                    </XImg>
                    <XTypography fontSize="14px">Repair</XTypography>
                  </XBox>
                )}

                {isRecommendLoading && <LoadingSpinner animationType="pulse" size={50} />}
              </XBox>

              {isRefineOpen && (
                <XBox display="flex" width="100%" gap="12px" alignItems="center" pl="44px">
                  <XInput
                    sx={{ width: "100%", padding: "16px" }}
                    placeholder="What would you like to change?"
                    size="large"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        handleRefine();
                      }
                    }}
                  />
                  <XBox sx={{ height: "40px", width: "2px", backgroundColor: "#AFAFAF" }} />
                  <XButton
                    variant="gradient"
                    color="button"
                    sx={{ whiteSpace: "nowrap" }}
                    onClick={handleRefine}
                    disabled={isRefineLoading}
                  >
                    Refine
                  </XButton>
                </XBox>
              )}
            </>
          )}
        </XBox>
      )}

      {item.sender === "user" && (
        <XBox pl={3} pt={3} display="flex" gap="12px">
          <XImg>
            <XAvatar
              src={!user ? "" : user.image}
              alt="chat picture"
              size="sm"
              variant="full"
              aria-controls="logout-menu"
              aria-haspopup="true"
            />
          </XImg>
          <XBox>
            <XTypography fontSize="16px" fontWeight="bold">
              User
            </XTypography>
            <XTypography fontSize="16px">{item.text}</XTypography>
          </XBox>
        </XBox>
      )}
    </XBox>
  );
};

ChatMessage.propTypes = {
  item: PropTypes.object,
  setVisualisationModalData: PropTypes.func,
  setChatHistory: PropTypes.func,
  setIsAnalystOpen: PropTypes.func,
  visualisationModalData: PropTypes.object,
  summarizeData: PropTypes.array,
  setIsRetry: PropTypes.func,
  isAnalystExpanded: PropTypes.bool,
  isAnalyst: PropTypes.bool,
  modelSettings: PropTypes.object
};
