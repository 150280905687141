import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useToast } from "hooks";
import UserTable from "../admin/components/UserTable";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XButton from "components/XButton";
import { Autocomplete, Grid, Icon, Modal, TextField, Tooltip } from "@mui/material";
import { useAdmin } from "hooks";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useParams } from "react-router-dom";
import { debounce } from "lodash";
import { useXplainableController } from "context";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { MdCheck } from "react-icons/md";

import colors from "assets/theme/base/colors";
import RoleSelect from "./components/RoleSelect";

import {
  useSearchUserQuery,
  useSubscriptionSeatsQuery,
  useTeamUsersQuery,
  useUsersQuery,
} from "api/query";
import { useInviteMutation } from "api/mutations/useInvitationsMutation";

const hostUrl = process.env.REACT_APP_HOST_URL;

export const Users = () => {
  const { organisationId } = useParams();
  const { setUserData, userData, setUsers, users, team, teamData, organisation, access } =
    useAdmin();
  const { logout } = useAuth0();
  const { apiKey, activeWorkspace } = useApiKey();
  const [controller] = useXplainableController();

  const { data } = useSubscriptionSeatsQuery(activeWorkspace, apiKey, logout);
  const { data: teamUsersData, refetch } = useTeamUsersQuery({ organisationId, logout });

  console.log(users, "users");

  const { showSuccessToast } = useToast();
  const { inviteUserMutation } = useInviteMutation();

  const [isAdmin, setIsAdmin] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [roleValue, setRoleValue] = useState(2);
  const [role, setRole] = useState("Viewer");
  const [tableRows, setTableRows] = useState(null);
  const [buttonText, setButtonText] = useState("Invite");
  const [searchInput, setSearchInput] = useState("");
  const [messageContent, setMessageContent] = useState("");
  const [show, setShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const roleTabs = ["Team Admin", "Creator", "Viewer"];

  const { data: searchedUser, refetch: searchUserRefetch } = useSearchUserQuery(
    organisationId,
    searchInput
  );

  const handleRoleSelect = (selected) => {
    setRole(selected);
  };

  const toggleSnackbar = () => setShow(!show);

  const emailRef = useRef(null);
  const imageRef = useRef(null);

  const filter = createFilterOptions();

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  // Function to toggle checkbox state
  const handleToggleCheckbox = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (!searchedUser) return;

    setSearchResults(searchedUser.data);
  }, [searchedUser]);

  const debouncedSearch = useCallback(
    debounce((input) => {
      searchUserRefetch({ organisationId, input });
    }, 200),
    []
  );

  useEffect(() => {
    refetch();
  }, [team, teamData]);

  useEffect(() => {
    if (searchInput) {
      debouncedSearch(searchInput);
      return;
    }

    setSearchResults([]);
  }, [searchInput, debouncedSearch]);

  useEffect(() => {
    if (searchResults.length === 0) {
      setButtonText("Invite");
      return;
    }

    setButtonText("Add");
  }, [searchResults]);

  useEffect(() => {
    if (!teamUsersData) {
      setUsers([]);
      return;
    }

    const userData = teamUsersData.data;

    setUserData(userData || []);

    const usersInTeam = userData
      .filter((user) => user.team_ids.includes(team?.team_id))
      .map((user) => {
        return { ...user, team_name: [team?.team_name] };
      });

    const teamMap = new Map(teamData.map((team) => [team.team_id, team]));

    // Update each user with team_name based on team_ids
    const updatedUsers = userData.map((user) => ({
      ...user,
      team_name: user.team_ids.map((teamId) => teamMap.get(teamId)?.team_name || null),
    }));

    const users = usersInTeam.length > 0 ? usersInTeam : updatedUsers;

    //Add logic to filter on specific users
    setUsers(users || []);
  }, [teamUsersData]);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const onAdd = useCallback(() => {
    let emailToSend;
    if (selectedUser) {
      emailToSend = selectedUser.email;
    } else if (searchInput) {
      emailToSend = searchInput;
    }

    if (selectedUser && team.team_id && organisation) {
      const payload = {
        email: emailToSend,
        team_id: team.team_id,
        organisation_id: organisation.organisation_id,
        role: roleTabs.indexOf(role) + 1,
      };

      inviteUserMutation([JSON.stringify(payload), apiKey], {
        onSuccess: (data) => {
          console.log("Invite API response:", data.data);
          setSelectedUser(null); // Reset the selected user

          showSuccessToast(`Successfully invited ${emailToSend} to join the team.`);
          //Add snackbar confirmation on response
          // setMessageContent(`${selectedUser.email} has been invited to join the team.`);
          // toggleSnackbar();
          handleClose();
        },
        onError: (error) => {
          handleClose();
          console.log(error);
        },
      });
    } else {
      // Create new line item from response
      const newState = [...tableRows];

      //Set the role
      setRole(role);

      //Add an ascending ID and the organisation name and expiry days
      const item = {
        id:
          1 +
          (newState.length > 0
            ? newState.reduce((max, item) => {
                return item.id > max ? item.id : max;
              }, newState[0].id)
            : 0),
        user: { image: imageRef.current.value, email: emailRef.current.value },
        username: "",
        role_name: role,
        status: "offline",
        created: new Date().toLocaleDateString("en-GB").slice(0, -2).replace(/\//g, "/"),
        invite_id: data.invite_id,
      };

      //Add new table rows on response
      newState.push(item);
      setTableRows(newState);
      handleClose();
    }
  }, [tableRows, roleValue, selectedUser]);

  useEffect(() => {
    setTableRows(users);
  }, [users]);

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="request-title"
        aria-describedby="request-description"
      >
        <XBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -60%)",
            width: 800,
            background: controller.darkMode ? colors.background.dark : colors.background.default,
            borderRadius: "16px",
            padding: 3,
          }}
        >
          <XBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <XTypography fontSize="16px" fontWeight="bold">
              Add New User
            </XTypography>
            <Icon
              sx={({ typography: { size, fontWeightBold }, palette: { dark, white } }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                color: controller.darkMode ? white.main : dark.main,
                stroke: controller.darkMode ? white.main : dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
              })}
              onClick={handleClose}
            >
              close
            </Icon>
          </XBox>

          <Grid item xs={12} lg={4}>
            <XBox>
              <Grid container>
                <Grid item xs={12}>
                  <Autocomplete
                    freeSolo
                    value={selectedUser}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setSelectedUser({ email: newValue });
                      } else if (newValue && newValue.inputValue) {
                        setSelectedUser({ email: newValue.inputValue });
                      } else {
                        setSelectedUser(newValue);
                      }
                    }}
                    placeholder="Enter user's email"
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      const isExisting = options.some((option) => inputValue === option.email);
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          email: `Add "${inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="user-autocomplete"
                    options={searchResults}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") return option;
                      if (option.inputValue) return option.inputValue;
                      return option.email;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.email}</li>}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" onChange={handleSearchChange} />
                    )}
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <XBox display="flex" flexDirection="column">
                    <XTypography fontSize="16px" fontWeight="bold" pb={2}>
                      User Subscription
                    </XTypography>
                    <RoleSelect onSelection={handleRoleSelect} />
                  </XBox>
                </Grid>
                <Grid item xs={12} mt={3}>
                  <XTypography fontSize="16px" fontWeight="bold" pb={2}>
                    Team Admin
                  </XTypography>
                  <XBox
                    sx={{
                      backgroundColor: "rgba(colors.secondary.main, 0.1)",
                      padding: 0.5,
                      borderColor: isChecked ? colors.xpblue.main : "grey.300",
                      border: 1,
                      borderStyle: "solid",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <XBox px={2}>
                      <XTypography fontSize="16px" fontWeight="light" color="secondary">
                        {`Set this user as the Team Administrator, allowing them to update user roles.`}
                      </XTypography>
                    </XBox>
                    <Tooltip title="Set as Team Admin" placement="top">
                      <XBox
                        sx={{
                          p: "8px", // Adjust padding to give more space for the icon. Original was 1.5.
                          borderColor: isChecked ? colors.xpblue.main : "grey.300",
                          border: 1,
                          height: "48px", // Increased from 40px to give more space.
                          width: "48px", // Increased from 40px to give more space.
                          borderStyle: "solid",
                          borderRadius: "8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={handleToggleCheckbox}
                      >
                        {isChecked && <MdCheck size="24" color="#0080EA" />}
                      </XBox>
                    </Tooltip>
                  </XBox>
                </Grid>
                <Grid item xs={12} mt={3}>
                  <XBox display="flex" justifyContent="flex-end">
                    <Tooltip title={team ? "" : "Select a team to add a user"}>
                      <span>
                        <XButton
                          type="submit"
                          variant="gradient"
                          color="button"
                          disabled={team ? false : true}
                          size="medium"
                          onClick={onAdd}
                        >
                          {buttonText}
                        </XButton>
                      </span>
                    </Tooltip>
                  </XBox>
                </Grid>
              </Grid>
            </XBox>
          </Grid>
        </XBox>
      </Modal>

      {(access === "globaladmin" || access === "orgadmin" || access === "teamadmin") && (
        <>
          <XBox my={3}>
            <Grid container>
              <Grid item xs={12}>
                <XBox display="flex" justifyContent="space-between" alignItems="center">
                  <XTypography textTransform="capitalize" variant="h5">
                    Users
                  </XTypography>

                  <XBox display="flex" gap={2} alignItems="center">
                    <Tooltip
                      title={
                        userData?.length >= data?.data?.viewers.limit + data?.data?.creators.limit
                          ? "Add more seats to invite users"
                          : ""
                      }
                    >
                      <XButton
                        disabled={
                          userData?.length >= data?.data?.viewers.limit + data?.data?.creators.limit
                        }
                        variant="gradient"
                        color="button"
                        onClick={() => setIsModalOpen(true)}
                      >
                        Add New User
                      </XButton>
                    </Tooltip>
                  </XBox>
                </XBox>
              </Grid>
              <Grid item xs={12}>
                <XBox pt={3}>
                  {tableRows && (
                    <UserTable
                      data={users}
                      teamData={team}
                      organisationId={organisation}
                      handleRowClick={(value) => {
                        console.log(value, "here");
                      }}
                      isAdmin={isAdmin}
                      setIsAdmin={setIsAdmin}
                      tableRows={tableRows}
                      setTableRows={setTableRows}
                      messageContent={messageContent}
                      toggleSnackbar={toggleSnackbar}
                      show={show}
                    />
                  )}
                </XBox>
              </Grid>
            </Grid>
          </XBox>
        </>
      )}
    </>
  );
};
