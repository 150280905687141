import React, { useState, useEffect, useContext } from "react";

import { useApiKey } from "components/Authorisation/ApiKeyContext";
import XSelect from "components/XSelect";

import { Slider, Grid, Card } from "@mui/material";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import ScrollReveal from "scrollreveal";

// import RegressionChart from './components/RegressionChart';
import RegressionChart from "./components/RegressionChart2";
import ResidualChart from "./components/ResidualChart2";
import Parameters from "../shared/Parameters";
import { useModel } from "hooks";
import { useRegressionPerformanceQuery } from "api/query";
import { useAuth0 } from "@auth0/auth0-react";
const hostUrl = process.env.REACT_APP_HOST_URL;

const RegressionPerformance = () => {
  const { logout } = useAuth0();
  const { apiKey, activeWorkspace } = useApiKey();
  const { selectedPartition, trainingMetadata, regressionBins, selectedVersion, model_id } =
    useModel();

  const { data } = useRegressionPerformanceQuery(
    activeWorkspace,
    model_id,
    selectedVersion?.value,
    selectedPartition?.value,
    logout
  );

  const [isLoading, setIsLoading] = useState(true);
  const [performanceData, setPerformanceData] = useState([]);
  const [regressionData, setRegressionData] = useState([]);
  const [errorData, setErrorData] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState({ value: "train", label: "Train" });
  const [sliderValue, setSliderValue] = useState(26);
  const [filteredData, setFilteredData] = useState([]);

  //Handle Filter status
  const handleDatasetChange = (dataset) => {
    setSelectedDataset(dataset);
  };

  useEffect(() => {
    if (!data) return;
    setPerformanceData(data.data[0]);
  }, [data]);

  const filterAndSetData = (data) => {
    if (data) {
      setFilteredData(data);
      setRegressionData(data.charts);

      const { observed_min, observed_max, prediction_bins } = data;

      const filterBinsByClass = (bins, className) =>
        bins?.filter((bin) => bin.class === className)[0].values;

      const formattedData = {
        min: observed_min,
        max: observed_max,
        bins: {
          true: filterBinsByClass(prediction_bins, "true"),
          pred: filterBinsByClass(prediction_bins, "pred"),
        },
      };

      setErrorData(formattedData);
    }
  };

  // Fetch the performance data from the model endpoint
  useEffect(() => {
    // A local function to handle the logic
    const handleData = (data) => {
      if (selectedDataset.value === "validation") {
        filterAndSetData(data.evaluation?.validation);
      } else if (selectedDataset.value === "train") {
        filterAndSetData(data.evaluation?.train);
      }
    };

    if (selectedVersion && data) {
      handleData(data);
    } else {
      handleData(performanceData);
    }
  }, [model_id, selectedVersion, selectedPartition, selectedDataset]); // Make sure you add selectedDataset here.

  useEffect(() => {
    if (selectedDataset.value === "validation") {
      filterAndSetData(performanceData.evaluation?.validation);
    }

    if (selectedDataset.value === "train") {
      filterAndSetData(performanceData.evaluation?.train);
    }
  }, [selectedDataset, performanceData]);

  useEffect(() => {
    ScrollReveal().reveal(".scrollreveal", {
      delay: 0,
      distance: "100px",
      duration: 700,
      easing: "cubic-bezier(0.5, 0, 0, 1)",
      origin: "bottom",
      interval: 100,
    });
  }, []);

  return (
    <Grid container spacing={2} mt={1} className="scrollreveal">
      <Grid item xs={12} mb={3}>
        <Card
          sx={{
            px: 3,
            py: 1,
            position: "relative",
            zIndex: 100,
            overflow: "visible",
          }}
        >
          <XBox display="flex" justifyContent="space-between">
            <XBox>
              <XTypography variant="h5" py={1}>
                Regression Perfomance
              </XTypography>
              <XTypography variant="h6" color="secondary" fontWeight="light">
                Visualise adjusting the granularity of the plots
              </XTypography>
            </XBox>
            <XBox display="flex">
              <XBox>
                <XTypography variant="overline">Selected Dataset</XTypography>
                <XSelect
                  placeholder="Filter on Dataset"
                  options={[
                    { value: "validation", label: "Validation" },
                    { value: "train", label: "Train" },
                  ]}
                  selected={selectedDataset}
                  onChange={handleDatasetChange}
                />
              </XBox>
              <XBox ml={2}>
                <XTypography variant="overline">Adjust granularity</XTypography>
                <Slider
                  color={"primary"}
                  step={0.1}
                  min={5}
                  max={100}
                  onChange={(sliderValue) => {
                    setSliderValue(sliderValue.target.value);
                  }}
                />
              </XBox>
            </XBox>
          </XBox>
        </Card>
      </Grid>
      <Grid item xs={12} lg={6} mb={3}>
        <Card sx={{ px: 2, minHeight: "510px" }}>
          <XBox pt={2} px={2} lineHeight={1}>
            <XTypography variant="h6" fontSize="18px">
              Predicted vs Actual (Residuals)
            </XTypography>
          </XBox>
          {filteredData.charts && (
            <RegressionChart
              id={"regression--error--plot"}
              data={filteredData.charts}
              pixels={sliderValue}
              title={"Regression Error Plot"}
              xLabel={"Actual x"}
              yLabel={"Predicted x"}
              xName={"error-x"}
              yName={"error-y"}
            />
          )}
        </Card>
      </Grid>
      <Grid item xs={12} lg={6} mb={3}>
        <Card sx={{ px: 2, minHeight: "510px" }}>
          <XBox pt={2} px={2} lineHeight={1}>
            <XTypography variant="h6" fontSize="18px">
              Predicted vs Actual (Histogram)
            </XTypography>
          </XBox>
          {errorData && <ResidualChart regressionBins={errorData} numBins={sliderValue} />}
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Parameters />
      </Grid>
    </Grid>
  );
};

export default RegressionPerformance;
