import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  LinearProgress,
  Typography,
  Grid,
  IconButton,
  Tooltip,
  Slider
} from "@mui/material";
import { css } from "@emotion/react";
import { useXplainableController } from "context";
import { useAutoTrain } from "hooks";
import { TableCellCustom } from "shared/models";
import { ReactComponent as DarkEyeIcon } from "assets/images/icons/deployments/dark-eye-icon.svg";
import { ReactComponent as DownloadIcon } from "assets/images/icons/batch/download-icon.svg";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XProgress from "components/XProgress";
import { XImg } from "components/XImg";
import rgba from "assets/theme/functions/rgba";
import colors from "assets/theme/base/colors";
import { useTrainMutation } from "api/mutations";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useLocation } from "react-router-dom";
import Histogram from "./components/histogram";
import XBadge from "components/XBadge";

const flashAnimation = css`
  @keyframes flash {
    0% {
      background-color: transparent;
    }
    50% {
      background-color: pink;
    }
    100% {
      background-color: transparent;
    }
  }
`;

export const DataTable = ({ fileMetadata }) => {
  const [controller] = useXplainableController();
  const { darkMode } = controller;
  const { activeWorkspace } = useApiKey();
  const { downloadDataprepMutation } = useTrainMutation();
  const [showTable, setShowTable] = useState(false);
  const [step, setStep] = useState(0);
  const location = useLocation();
  const path = location.pathname;

  const {
    preprocessor,
    pipeLineData: pipelineDataFromReq,
    setFinalTableData,
    setColumnKeys,
    summaryMetrics,
  } = useAutoTrain();

  const headerWidth = "20px"; // Adjust this value as needed
  const [pipelineData, setPipelineData] = useState(null);
  const [mainDelta, setMainDelta] = useState(null);

  useEffect(() => {
    if (!pipelineDataFromReq) return;
    setPipelineData(pipelineDataFromReq.data);
    setMainDelta(pipelineDataFromReq.data.deltas[pipelineDataFromReq.data.deltas.length - 1]);
    setStep(pipelineDataFromReq.data.deltas.length - 1);

    const uniqueKeys = new Set();

    // Iterate over each object in the array
    pipelineDataFromReq.data.deltas[pipelineDataFromReq.data.deltas.length - 1].forEach((item) => {
      Object.keys(item).forEach((key) => uniqueKeys.add(key));
    });

    // Convert the Set to an array
    const keysArray = Array.from(uniqueKeys);

    setColumnKeys(keysArray);
  }, [pipelineDataFromReq]);

  useEffect(() => {
    if (!pipelineDataFromReq) return;

    if (step === 0) {
      setMainDelta(pipelineDataFromReq.data.deltas[step]);
      return;
    }

    const deltasToMerge = pipelineDataFromReq.data.deltas.slice(0, step + 1);

    const newData = _.mergeWith([], ...deltasToMerge);

    setMainDelta(newData);
  }, [step]);

  useEffect(() => {
    console.log("Current step is:", step);
  }, [step]);

  const isDroppedOrChangedColumn = (rowIndex, columnIndex, deltas, currentStep) => {
    if (deltas.length === 0 || deltas[0].length === 0) return { dropped: false, changed: false };
    const keys = Object.keys(deltas[0][0]);
    for (let i = 1; i < deltas.length; i++) {
      if (
        (!deltas[i][rowIndex][keys[columnIndex]] &&
          deltas[i - 1][rowIndex][keys[columnIndex]] &&
          currentStep >= i) ||
        (!deltas[i][rowIndex][keys[columnIndex]] &&
          !deltas[i - 1][rowIndex][keys[columnIndex]] &&
          currentStep >= i) ||
        (deltas[i][rowIndex][keys[columnIndex]] &&
          !deltas[i - 1][rowIndex][keys[columnIndex]] &&
          currentStep >= i)
      ) {
        return { dropped: true, step: i };
      }

      if (
        deltas[i][rowIndex][keys[columnIndex]] !== deltas[i - 1][rowIndex][keys[columnIndex]] &&
        currentStep >= i
      ) {
        return { changed: true, step: i };
      }
    }

    return { dropped: false, changed: false };
  };

  // Function to check if a column is added or removed
  const isAddedOrRemovedColumn = (columnName, deltas) => {
    for (let i = 1; i < deltas.length; i++) {
      const previousKeys = Object.keys(deltas[i - 1][0]);
      const currentKeys = Object.keys(deltas[i][0]);

      if (currentKeys.includes(columnName) && !previousKeys.includes(columnName)) {
        return { added: true, step: i };
      }

      if (!currentKeys.includes(columnName) && previousKeys.includes(columnName)) {
        return { removed: true, step: i };
      }
    }
    return { added: false, removed: false };
  };

  const computeFinalTableData = (step) => {
    if (!pipelineData || !fileMetadata || fileMetadata.length === 0) return;

    // Create a copy of the original data
    let finalData = [...fileMetadata[0].data].map((row) => ({ ...row }));

    // Iterate through each delta stage up to the current step
    for (let i = 0; i <= step; i++) {
      // Iterate through each delta entry
      pipelineData.deltas[i].forEach((delta) => {
        Object.keys(delta).forEach((column) => {
          if (delta[column] === null) {
            // Column removed
            finalData.forEach((row) => {
              delete row[column];
            });
          } else {
            // Column added or updated
            finalData.forEach((row) => {
              if (!row.hasOwnProperty(column)) {
                row[column] = null;
              }
              row[column] = delta[column] || row[column];
            });
          }
        });
      });
    }

    setFinalTableData(finalData);
  };

  const onDownloadClick = async () => {
    if (!preprocessor.id && !preprocessor.version_id) return;

    const data = {
      preprocessor_id: preprocessor.id,
      version_id: preprocessor.version,
    };

    const downloadData = await downloadDataprepMutation([activeWorkspace, data]);

    const url = window.URL.createObjectURL(new Blob([downloadData.data]));

    const link = document.createElement("a");
    link.href = url;
    link.download = downloadData.data.type;

    document.body.appendChild(link);

    link.click();

    link.parentNode.removeChild(link);
  };


  const renderSummaryChart = (column) => {

    // Determine the current step
    const currentStep = step;

    // Check if summaryMetrics is an empty object
    const isEmptyObject = (obj) => {
      return Object.keys(obj).length === 0 && obj.constructor === Object || currentStep === 0;
    };
  
    // Determine which summaryMetrics to use
    const columnSummary = !isEmptyObject(summaryMetrics)
      ? summaryMetrics[currentStep]?.[column]
      : fileMetadata[0].summaryMetrics[column];
  
    // Return null if columnSummary is not available
    if (!columnSummary) return null;
  
    //Check if it's first pass then use fileMetadata summary metrics
    if (columnSummary.type === "numeric") {
      const dataToUse = !isEmptyObject(summaryMetrics)
        ? { summaryMetrics: summaryMetrics[currentStep] }
        : { summaryMetrics: fileMetadata[0]?.summaryMetrics };
  
      return <Histogram data={dataToUse} column={column} />;
    } else if (columnSummary.type === "category") {
      const valueCounts = columnSummary.values;
      const labels = Object.keys(valueCounts);
      const values = Object.values(valueCounts);
  
      const total = values.reduce((acc, value) => acc + value, 0);
      const percentages = values.map(value => (value / total) * 100);
  
      return (
        <div>
          {labels.map((label, index) => (
            <XBox key={label} display="flex" flexDirection="column" style={{ marginBottom: '4px' }}>
              <XBox display="flex" justifyContent="space-between">
                <XTypography variant="body2" fontSize="12px">{label}</XTypography>
                <XTypography variant="body2" fontSize="12px">{percentages[index].toFixed(1)}%</XTypography>
              </XBox>
              <XProgress
                style={{ height: "8px" }}
                value={percentages[index]}
                color="primary"
              />
            </XBox>
          ))}
        </div>
      );
    }
  };
  

  return (
    <XBox>
      <Card sx={{ padding: "24px", display: "flex", flexDirection: "column", gap: 1 }}>
          {path !== "/analyst" && (
          <>
          <XBox display="flex" justifyContent="space-between" alignItems="center">
            <XBox>
              <XTypography sx={{ fontSize: "18px", fontWeight: 700 }}>Preprocessing Pipeline</XTypography>
              <XTypography sx={{ fontSize: "14px"}} color="secondary">
                Move the slider to view the preprocessing stages.
              </XTypography>
            </XBox>
            <XBox display="flex" gap="8px" alignItems="center">
              <Tooltip title={showTable ? "Show Preprocessed Table" : "Show Preprocessor Steps"}>
                <IconButton
                  size="medium"
                  color={"dark"}
                  onClick={() => {
                    if (!mainDelta) return;
                    if (showTable) {
                      computeFinalTableData(step);
                    }
                    setShowTable((prev) => !prev);
                  }}
                >
                  {showTable ? (
                    <XImg>
                      <DarkEyeIcon style={{ stroke: "#7C7C7C" }} />
                    </XImg>
                  ) : (
                    <XImg>
                      <DarkEyeIcon style={{ stroke: !mainDelta ? "grey" : "black" }} />
                    </XImg>
                  )}
                </IconButton>
              </Tooltip>
              <XImg>
                <Tooltip title={"Download Preprocessed Data"}>
                  <DownloadIcon style={{ cursor: "pointer" }} onClick={onDownloadClick} />
                </Tooltip>
              </XImg>
            </XBox>
          </XBox>
          <XBox display="flex" alignItems="center">
          <XBox p={2} width="100%">
            <Slider
              color={"primary"}
              step={1}
              min={0}
              disabled={ pipelineData ? false: true }
              max={pipelineData ? pipelineData.deltas.length - 1 : 1}
              value={step}
              onChange={(e, sliderValue) => {
                setStep(sliderValue);
              }}
            />
          </XBox>
          <XTypography
            fontSize="14px"
            variant="body1"
            fontWeight="regular"
            sx={{ whiteSpace: "nowrap" }}
          >
            Stage {step}
          </XTypography>
        </XBox>
        </>
        )}

        {mainDelta ? (
          <TableContainer
            sx={{
              background: darkMode ? "#1D1B1B" : "white",
              boxShadow: "none",
              border: "1px solid #EAEAEA",
              height: "auto",
            }}
          >
            <Table>
              <TableRow sx={{width: "100px"}}>
                {mainDelta.length > 0 &&
                  Object.keys(mainDelta[0]).map((header, index) => {
                    const droppedOrChangedInfo = mainDelta.some((row, rowIndex) =>
                      isDroppedOrChangedColumn(rowIndex, index, pipelineData.deltas, step)
                    );

                    const addedOrRemovedInfo = isAddedOrRemovedColumn(header, pipelineData.deltas);

                    const bgColor = addedOrRemovedInfo.added
                      ? rgba(colors.xpblue.main, 0.02)
                      : addedOrRemovedInfo.removed && addedOrRemovedInfo.step <= step
                      ? rgba(colors.xppink.main, 0.02)
                      : droppedOrChangedInfo
                      ? "fff"
                      : "transparent";

                    const textColor = addedOrRemovedInfo.added
                      ? rgba(colors.xpblue.main, 0.7)
                      : addedOrRemovedInfo.removed && addedOrRemovedInfo.step <= step
                      ? rgba(colors.xppink.main, 1)
                      : droppedOrChangedInfo.changed && addedOrRemovedInfo.step <= step
                      ? rgba(colors.xpblue.main, 0.7)
                      : droppedOrChangedInfo.dropped && addedOrRemovedInfo.step <= step
                      ? rgba(colors.xppink.main, 1)
                      : "transparent";

                    return (
                      <TableCellCustom
                        key={header}
                        headerWidth={headerWidth}
                        sx={{
                          pb:0,
                          borderBottom: 'none',
                          maxWidth:"180px",
                          backgroundColor: bgColor,
                          color: textColor,
                          display: showTable && addedOrRemovedInfo.removed ? "none" : "table-cell",
                          animation:
                            droppedOrChangedInfo.updated && step === droppedOrChangedInfo.step
                              ? `${flashAnimation} 2s`
                              : "",
                        }}
                      >
                      <XBox display="flex" flexDirection="column">
                          <XTypography 
                            fontWeight="bold" 
                            fontSize="18px" 
                            mr={2} 
                            sx={{ color: droppedOrChangedInfo ? textColor : "black" }}
                          >
                            {header}
                          </XTypography>
                          {step === 0 ?
                            fileMetadata[0]?.summaryMetrics[header]?.type ? (
                              <XBadge 
                                color={fileMetadata[0]?.summaryMetrics[header]?.type === "numeric" ? "xpblue" : "xppink"} 
                                badgeContent={fileMetadata[0]?.summaryMetrics[header]?.type} 
                                variant="contained"
                              />
                            ) : (
                              <div style={{ height: '18px' }} /> 
                            )
                            :
                            summaryMetrics[step][header]?.type ? (
                              <XBadge 
                                color={summaryMetrics[step][header]?.type === "numeric" ? "xpblue" : "xppink"} 
                                badgeContent={summaryMetrics[step][header]?.type} 
                                variant="contained"
                              />
                            ) : (
                              <div style={{ height: '18px' }} />  
                            )
                          }
                        </XBox>
                      </TableCellCustom>
                    );
                  })}
              </TableRow>
              <TableRow>
                {mainDelta.length > 0 &&
                  Object.keys(mainDelta[0]).map((header, index) => {
                    const droppedOrChangedInfo = mainDelta.some((row, rowIndex) =>
                      isDroppedOrChangedColumn(rowIndex, index, pipelineData.deltas, step)
                    );

                    const addedOrRemovedInfo = isAddedOrRemovedColumn(header, pipelineData.deltas);

                    const bgColor = addedOrRemovedInfo.added
                      ? rgba(colors.xpblue.main, 0.02)
                      : addedOrRemovedInfo.removed && addedOrRemovedInfo.step <= step
                      ? rgba(colors.xppink.main, 0.02)
                      : droppedOrChangedInfo
                      ? "fff"
                      : "transparent";

                    const textColor = addedOrRemovedInfo.added
                      ? rgba(colors.xpblue.main, 0.7)
                      : addedOrRemovedInfo.removed && addedOrRemovedInfo.step <= step
                      ? rgba(colors.xppink.main, 1)
                      : droppedOrChangedInfo.changed && addedOrRemovedInfo.step <= step
                      ? rgba(colors.xpblue.main, 0.7)
                      : droppedOrChangedInfo.dropped && addedOrRemovedInfo.step <= step
                      ? rgba(colors.xppink.main, 1)
                      : "transparent";

                    return (
                      <TableCellCustom
                        key={header}
                        headerWidth={headerWidth}
                        sx={{
                          py:0,
                          backgroundColor: bgColor,
                          color: textColor,
                          display: showTable && addedOrRemovedInfo.removed ? "none" : "table-cell",
                          animation:
                            droppedOrChangedInfo.updated && step === droppedOrChangedInfo.step
                              ? `${flashAnimation} 1s`
                              : "",
                        }}
                      >
                        <XBox width={"180px"}>
                          {renderSummaryChart(header)}
                        </XBox>
                      </TableCellCustom>
                    );
                  })}
              </TableRow>
              <TableBody>
                {mainDelta &&
                  mainDelta.length > 0 &&
                  mainDelta.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {Object.keys(row).map((key, columnIndex) => {
                        const value = row[key];
                        const droppedOrChangedInfo = isDroppedOrChangedColumn(
                          rowIndex,
                          columnIndex,
                          pipelineData.deltas,
                          step
                        );

                        const addedOrRemovedInfo = isAddedOrRemovedColumn(key, pipelineData.deltas);

                        const bgColor = addedOrRemovedInfo.added
                          ? rgba(colors.xpblue.main, 0.02)
                          : addedOrRemovedInfo.removed && addedOrRemovedInfo.step <= step
                          ? rgba(colors.xppink.main, 0.02)
                          : droppedOrChangedInfo.changed && addedOrRemovedInfo.step <= step
                          ? rgba(colors.xpblue.main, 0.02)
                          : droppedOrChangedInfo.dropped && addedOrRemovedInfo.step <= step
                          ? rgba(colors.xppink.main, 0.02)
                          : "transparent";

                        const textColor = addedOrRemovedInfo.added
                          ? rgba(colors.xpblue.main, 0.7)
                          : addedOrRemovedInfo.removed && addedOrRemovedInfo.step <= step
                          ? rgba(colors.xppink.main, 1)
                          : droppedOrChangedInfo.changed && addedOrRemovedInfo.step <= step
                          ? rgba(colors.xpblue.main, 0.7)
                          : droppedOrChangedInfo.dropped && addedOrRemovedInfo.step <= step
                          ? rgba(colors.xppink.main, 1)
                          : "transparent";

                        return (
                          <TableCellCustom
                            key={columnIndex}
                            sx={{
                              backgroundColor: bgColor,
                              color: darkMode ? "#fff" : textColor,
                              display:
                                showTable && addedOrRemovedInfo.removed ? "none" : "table-cell",

                              animation:
                                droppedOrChangedInfo.updated && step === droppedOrChangedInfo.step
                                  ? `${flashAnimation} 1s`
                                  : "",
                            }}
                          >
                            {value !== null ? value.toString() : "null"}
                          </TableCellCustom>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer
            component={Paper}
            sx={{
              background: darkMode ? "#1D1B1B" : "white",
              boxShadow: "none",
              border: "1px solid #EAEAEA",
              height: "auto",
            }}
          >
            <Table aria-label="mui table">
              <TableBody>
                <TableRow>
                  {fileMetadata[0]?.headerColumns.map((columnName) => (
                    <TableCellCustom key={columnName} sx={{ borderBottom: 'none', pb:0, maxWidth:"180px" }}>
                      <XBox display="flex" flexDirection="column">
                        <XTypography fontWeight="bold" fontSize="18px" mr={2}>
                          {columnName}
                        </XTypography>
                        {fileMetadata[0]?.summaryMetrics[columnName]?.type && 
                        <XBadge 
                          color={fileMetadata[0]?.summaryMetrics[columnName]?.type == "numeric" ? "xpblue" : "xppink"} 
                          badgeContent={fileMetadata[0]?.summaryMetrics[columnName]?.type} 
                          variant="contained"
                        />
                        }
                      </XBox>
                    </TableCellCustom>
                  ))}
                </TableRow>
                <TableRow>
                {fileMetadata[0]?.headerColumns.map((columnName) => (
                  <TableCellCustom sx={{py:0}} key={columnName}>
                    <XBox width={"180px"}>
                      {renderSummaryChart(columnName)}
                    </XBox>
                  </TableCellCustom>
                ))}
                </TableRow>
                {fileMetadata && fileMetadata[0] && fileMetadata[0].data && fileMetadata[0].data.slice(1).map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                  >
                    {Object.keys(row).map((key, cellIndex) => {
                      return (
                        <TableCellCustom
                          key={cellIndex}
                        >
                          {row[key]}
                        </TableCellCustom>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Card>
    </XBox>
  );
};

DataTable.propTypes = {
  fileMetadata: PropTypes.array,
};
