import React from "react";
import PropTypes from "prop-types";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XAvatar from "components/XAvatar";
import XBadge from "components/XBadge";
import XProgress from "components/XProgress";

function LogoCell({ image, name }) {
  return (
    <XBox display="flex" alignItems="center" pr={2}>
      <XBox mr={2}>
        <XAvatar src={image} alt={name} variant="full" />
      </XBox>
      <XBox display="flex" flexDirection="column">
        <XTypography variant="button" fontWeight="medium">
          {name}
        </XTypography>
      </XBox>
    </XBox>
  );
}

function AddressCell({ addressDetails }) {
  const { address, state, country, postcode } = addressDetails;

  return (
    <XBox>
      <XTypography fontSize="14px">{address}</XTypography>
      <XTypography fontSize="14px">{`${state}, ${country}, ${postcode}`}</XTypography>
    </XBox>
  );
}

function Completion({ value }) {
  let color;
  if (value <= 30) {
    color = "error";
  } else if (value > 30 && value <= 70) {
    color = "info";
  } else {
    color = "success";
  }

  return (
    <XBox display="flex" alignItems="center">
      <XTypography variant="caption" color="text">
        {value}%
      </XTypography>
      <XBox width="8rem" ml={1}>
        <XProgress value={value} color={color} label={false} />
      </XBox>
    </XBox>
  );
}

// Badges
const archived = (
  <XBadge variant="contained" color="warning" size="xs" badgeContent="Archived" container />
);
const active = (
  <XBadge variant="contained" color="success" size="xs" badgeContent="Active" container />
);

Completion.propTypes = {
  value: PropTypes.number,
  color: PropTypes.string,
};

LogoCell.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  orders: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

AddressCell.propTypes = {
  addressDetails: PropTypes.object,
};

export { AddressCell, LogoCell, Completion };
