import React, { useState, useMemo, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import {
  Grid,
  Icon,
  Pagination,
  Card,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  PaginationItem,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useTheme } from "@mui/material/styles";

import XTypography from "components/XTypography";
import XInput from "components/XInput";
import XBox from "components/XBox";
import XButton from "components/XButton";
import XSelect from "components/XSelect";
import XBadge from "components/XBadge";

import PricingModal from "shared/Modals/PricingModal";
import ProgressLineChart from "shared/Charts/ProgressLineChart";
import MiniStatisticsCard from "shared/Cards/MiniStatisticsCard";
import Members from "shared/Avatars/Members";
import DataTable from "shared/Tables/DataTable";
import CollectionCard from "shared/Cards/CollectionCard";

import { getTimeDifference } from "shared/Functions/Date";
import { useNavigate } from "react-router-dom";
import { useAllCollectionQuery, useModelsQuery, useXPanelQuery } from "api/query";

import TableCellComponent from "layouts/models/all-models/components/overview/components/TableCellComponent";
import { useCollectionMutation } from "api/mutations";
import { useAuth0 } from "@auth0/auth0-react";
import { XViewToggle } from "components/XViewToggle";
import { useXplainableController } from "context";

import SearchIcon from "assets/images/search-icon.svg";
import LightPlusIcon from "assets/images/icons/deployments/light-plus-icon.svg";
import ArrowRightIcon from "assets/images/icons/collections/arrow-right-icon.svg";
import DeleteIcon from "assets/images/icons/collections/delete-icon.svg";

import { ReactComponent as ChevronDownIcon } from "assets/images/icons/collections/chevron-down-icon.svg";
import { ReactComponent as ChevronUpIcon } from "assets/images/icons/collections/chevron-up-icon.svg";
import { ReactComponent as DarkArrowLeft } from "assets/images/icons/pagination/dark-arrow-left.svg";
import { ReactComponent as DarkArrowRight } from "assets/images/icons/pagination/dark-arrow-right.svg";
import { ReactComponent as DarkCollectionIcon } from "assets/images/icons/collections/dark-collections-icon.svg";
import { ReactComponent as DarkEditIcon } from "assets/images/icons/collections/dark-edit-icon.svg";

import DocsScreenshot from "assets/images/docs-screenshot.png";
import DocsScreenshotDark from "assets/images/docs-screenshot-dark.png";

import colors from "assets/theme/base/colors";
import { XImg } from "components/XImg";

export const useDropdownMenu = (openOnClick = false) => {
  const [menu, setMenu] = useState(null);
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  return { menu, openMenu, closeMenu, openOnClick };
};

const CollectionsOverview = () => {
  const { apiKey, viewState, activeWorkspace } = useApiKey();
  const { logout } = useAuth0();
  const navigate = useNavigate();

  const titleRef = useRef(null);
  const descRef = useRef(null);
  let formattedDate = new Date().toISOString().replace("Z", "") + "000";

  const { createCollection } = useCollectionMutation();

  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const [loading, setLoading] = useState(false);
  const [collections, setCollections] = useState([]);
  const dropdownMenu = useDropdownMenu();
  const [searchQuery, setSearchQuery] = useState("");
  const [addingCollection, setAddingCollection] = useState({});
  const [page, setPage] = useState({});
  const [showPricingModal, setShowPricingModal] = useState(false);

  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);

  //Add the editing state
  const [editMode, setEditMode] = useState({});
  const [isCardViewEnable, setIsCardViewEnable] = useState(true);
  const [tableRows, setTableRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const query = useXPanelQuery(
    {
      team_id: activeWorkspace?.team_id,
      event: ["viewed_collection"],
      users: "True",
    },
    apiKey,
    logout
  );

  const { data: userData } = query;
  const { data, isLoading } = useAllCollectionQuery(activeWorkspace, apiKey, logout);
  const { data: modelData } = useModelsQuery(activeWorkspace, apiKey, logout);

  const { deleteCollectionMutation, deleteModelCollectionsMutation } = useCollectionMutation();

  const [expandedItems, setExpandedItems] = useState([]);

  const handleToggle = (index) => {
    setExpandedItems((prev) =>
      prev.includes(index) ? prev.filter((item) => item !== index) : [...prev, index]
    );
  };

  const handleModelChange = (event) => {
    setSelectedModel(event);
  };

  useEffect(() => {
    if (!modelData) return;

    setModels(modelData.data);
  }, [modelData]);

  useEffect(() => {
    if (!data) return;

    data.data.sort((a, b) => {
      return new Date(b.created) - new Date(a.created);
    });

    console.log("the fetched collections are", data.data);

    // setCollections(fetchedCollections)
    //Add the dropdown menu to each card component
    setCollections(
      data.data.map((collection) => {
        return { ...collection, dropdown: dropdownMenu };
      })
    );
  }, [data]);

  useEffect(() => {
    setTableRows(collections);
  }, [collections]);

  const addNewCollection = () => {
    const newCollection = {
      model_id: selectedModel.value,
      model_name: selectedModel.label,
      name: titleRef.current.value,
      description: descRef.current.value,
      created: formattedDate,
      scenario_count: 0,
    };

    createCollection([activeWorkspace, selectedModel.value, JSON.stringify(newCollection)], {
      onSuccess: () => {
        onCreateCollection(newCollection);
        setAddingCollection(false);
        setIsModalOpen(false);
      },
      onError: (err) => {
        setIsModalOpen(false);
        setShowPricingModal(true);
        setAddingCollection(false);
      },
    });
  };

  const onCreateCollection = (newCollection) => {
    setCollections((prev) => [...prev, newCollection]);
  };

  const handlePageChange = (modelId, event, value) => {
    setPage((prevState) => ({
      ...prevState,
      [modelId]: value,
    }));
  };

  const filteredCollections = collections.filter(
    (collection) =>
      collection.model_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      collection.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      collection.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  let initialPageState = {};
  const collectionGroups = {};

  filteredCollections.forEach((collection) => {
    if (!collectionGroups[collection.model_id]) {
      collectionGroups[collection.model_id] = [];
      // Instead of calling setPage, we add an entry to initialPageState
      initialPageState[collection.model_id] = 1;
    }
    collectionGroups[collection.model_id].push(collection);
  });

  useEffect(() => {
    if (Object.keys(page).length === 0) {
      // Only set initial pages if they haven't been set before
      setPage(initialPageState);
    }
  }, [collections]); // Adding collections as a dependency

  const handleDeleteProject = async (model_id, collection_id) => {
    deleteCollectionMutation([activeWorkspace, model_id, collection_id], {
      onSuccess: () => {
        const filteredProjects = collections.filter(
          (collection) => collection.collection_id !== collection_id
        );
        setCollections(filteredProjects);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const handleDeleteModelCollection = async (model_id) => {
    deleteModelCollectionsMutation([activeWorkspace, model_id], {
      onSuccess: (response) => {
        console.log(response);
        const filteredProjects = collections.filter(
          (collection) => collection.model_id !== model_id
        );
        setCollections(filteredProjects);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const handleEditProject = (collection_id) => {
    setEditMode((prevMode) => ({ ...prevMode, [collection_id]: true }));
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleRowClick = (item) => {
    if (!item) {
      return;
    }

    const { collection_id, model_id, model_name, created, contributors } = item;

    const params = {
      collection_id,
      model_id,
      model_name,
      created,
      contributors,
    };

    navigate(`/collections/${model_id}/${collection_id}`, {
      state: params,
    });
  };

  const cardActionStyles = ({ palette: { white }, typography: { size } }) => ({
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#BE22F5",

    "& .material-icons-round": {
      ml: 0.5,
      fontSize: size.md,
      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
    },

    "&:hover .material-icons-round": {
      transform: "translateX(5px)",
    },
  });

  const renderProjects = (item, key) => {
    const type = "collections";

    if (loading && isCardViewEnable) {
      return (
        <Card sx={{ width: { xs: "100%", sm: "340px" }, height: "390px" }}>
          <LoadingSpinner size={40} animationType="pulse" />
        </Card>
      );
    }

    if (loading && !isCardViewEnable) {
      return (
        <Card sx={{ width: "100%", height: "270px", margin: " 0 16px" }}>
          <LoadingSpinner size={40} animationType="pulse" />
        </Card>
      );
    }

    const columns = [
      {
        Header: "",
        width: "5%",
        id: "function",
        Cell: (props) => (
          <TableCellComponent
            {...props}
            handleDeleteProject={handleDeleteProject}
            collection_id={props.row.original.collection_id}
            deployed={props.row.original.deployed}
            model_id={props.row.original.model_id}
          />
        ),
      },
      {
        Header: "Model name",
        accessor: "model_name",
        id: "model_name",

        Cell: (props) => {
          if (!props.row.original["model_name"]) {
            return null;
          }

          return (
            <XTypography
              variant="button"
              fontWeight="regular"
              sx={{
                maxHeight: "30px",
                overflowY: "auto",
                scrollbarWidth: "none",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                msOverflowStyle: "none",
              }}
            >
              {props.row.original["model_name"]}
            </XTypography>
          );
        },
      },
      {
        Header: "Collection name",
        accessor: "collection_name",
        id: "collection_name",

        Cell: (props) => {
          if (!props.row.original.name) {
            return null;
          }

          return (
            <XBox display="flex" gap="0 10px" alignItems="center">
              <XImg>
                <DarkCollectionIcon />
              </XImg>
              <XTypography
                variant="button"
                sx={{
                  maxHeight: "30px",
                  overflowY: "auto",
                  scrollbarWidth: "none",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  msOverflowStyle: "none",
                }}
              >
                {props.row.original.name}
              </XTypography>
            </XBox>
          );
        },
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: (props) => {
          return (
            <XTypography variant="button" fontWeight="light">
              {props.row.original["description"]}
            </XTypography>
          );
        },
      },
      {
        Header: "Items count",
        accessor: "items_count",
        Cell: (props) => {
          return (
            <XTypography variant="button" fontWeight="medium">
              {props.row.original["scenario_count"]}
            </XTypography>
          );
        },
      },
      {
        Header: "Contributors",
        accessor: "contributors",
        Cell: ({ value }) => (value ? <Members members={value} /> : null),
      },
      {
        Header: "Created",
        accessor: "created",
        Cell: ({ value }) => {
          return (
            <XTypography variant="button" fontWeight="light">
              {value ? getTimeDifference(value, true) : null}
            </XTypography>
          );
        },
      },
      {
        Header: "",
        width: "5%",
        accessor: "",
        id: "model_transition",
        Cell: (props) => {
          return (
            <XBox sx={{ cursor: "pointer" }} onClick={() => handleRowClick(props.row.original)}>
              <XTypography
                variant="button"
                textTransform="capitalize"
                fontWeight="medium"
                sx={cardActionStyles}
              >
                View Collection
                <XBox src={ArrowRightIcon} component="img" />
              </XTypography>
            </XBox>
          );
        },
      },
    ];

    const filteredTableRows =
      tableRows &&
      tableRows.filter(
        (collection) =>
          collection.model_name.toLowerCase().includes(searchQuery?.toLowerCase()) ||
          collection.name.toLowerCase().includes(searchQuery?.toLowerCase()) ||
          collection.description.toLowerCase().includes(searchQuery?.toLowerCase())
      );

    return (
      <>
        {isCardViewEnable && (
          <ProjectCard
            project={item}
            handleDeleteProject={handleDeleteProject}
            handleEditProject={handleEditProject}
            editMode={editMode[item.collection_id] || false}
            setEditMode={setEditMode}
            setCollections={setCollections}
            index={key}
          />
        )}

        {!isCardViewEnable && (
          <Card
            sx={{
              width: "100%",
              position: "relative",
              zIndex: 1,
            }}
          >
            <DataTable
              table={{ rows: filteredTableRows, columns: columns }}
              entriesPerPage={{
                defaultValue: 5,
                entries: [5, 7, 10, 15, 20, 25],
              }}
              type={type}
            />
          </Card>
        )}
      </>
    );
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="request-title"
        aria-describedby="request-description"
      >
        <XBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -60%)",
            width: 600,
            background: controller.darkMode ? "#1D1B1B" : colors.background.default,
            borderRadius: "16px",
            padding: 3,
          }}
        >
          <XBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <XTypography fontSize="16px" fontWeight="bold">
              Add collection
            </XTypography>
            <Icon
              sx={({ typography: { size, fontWeightBold }, palette: { dark, white } }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                color: controller.darkMode ? white.main : dark.main,
                stroke: controller.darkMode ? white.main : dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
              })}
              onClick={handleClose}
            >
              close
            </Icon>
          </XBox>
          <XBox display="flex" flexDirection="column" gap={1}>
            <XTypography variant="h6" fontWeight="light">
              Model
            </XTypography>
            <XSelect
              value={selectedModel}
              onChange={handleModelChange}
              fullWidth
              placeholder="Select Model"
              options={models.map((model) => ({
                value: model.model_id,
                label: model.model_name,
              }))}
            />
            <XTypography variant="h6" fontWeight="light">
              Collection Title
            </XTypography>
            <XInput placeholder={"This is a title"} inputRef={titleRef} />
            <XTypography variant="h6" fontWeight="light">
              Collection Description
            </XTypography>
            <XInput placeholder={"This is a description"} inputRef={descRef} />
            <XBox sx={{ alignSelf: "end" }} mt={1}>
              <XButton variant="gradient" color="button" onClick={addNewCollection}>
                Create Collection
              </XButton>
            </XBox>
          </XBox>
        </XBox>
      </Modal>

      <Grid container spacing={3} mt={3} mb={3}>
        <Grid item xs={12} lg={9}>
          <XBox>
            <ProgressLineChart
              color={"xppurple"}
              bgColor="dark"
              icon="groups"
              title="Total Collection Views"
              height={"160px"}
              event="viewed_collection"
              team_id={activeWorkspace?.team_id}
            />
          </XBox>
        </Grid>
        <Grid item xs={12} lg={3} display="flex">
          <XBox display="flex" flexDirection="column" gap={4} width="100%">
            <MiniStatisticsCard
              title={{ text: "Total Collections", fontWeight: "medium" }}
              count={`${collections.length}`}
              color="xppurple"
            />

            <MiniStatisticsCard
              title={{ text: "All Viewers", fontWeight: "medium" }}
              count={userData?.data?.length || 0}
              color="xppurple"
              users={userData?.data}
            />
          </XBox>
        </Grid>

        <Grid item xs={12}>
          <Grid container sx={{ width: "100%" }}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <XBox mb={2}>
                <XTypography variant="h5" fontSize="18px">
                  All Collections
                </XTypography>
              </XBox>
            </Grid>

            <XBox display="flex" justifyContent="space-between" width="100%">
              <XBox display="flex" gap={2} justifyContent="space-between" width="550px">
                <XInput
                  sx={{ width: "100%" }}
                  placeholder="Search Collections..."
                  startAdornment={<XBox src={SearchIcon} component="img" />}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </XBox>
              <XBox display="flex" gap={2}>
                {viewState === "creator" && (
                  <XButton variant="gradient" color="button" onClick={() => setIsModalOpen(true)}>
                    {/* <XBox component="img" src={LightPlusIcon} /> */}
                    New Collection
                  </XButton>
                )}
                <XViewToggle
                  color="xppurple"
                  isCardViewEnable={isCardViewEnable}
                  setIsCardViewEnable={setIsCardViewEnable}
                />
              </XBox>
            </XBox>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <XBox
          width={"100%"}
          display={!loading && tableRows.length > 0 ? "flex" : "none"}
          flexDirection="column"
          flexGrow={1}
        >
          {!isCardViewEnable && renderProjects()}
        </XBox>

        <XBox display="flex" flexDirection="column" gap={3} mt={3} width="100%">
          {isCardViewEnable &&
            Object.entries(collectionGroups).map(([modelId, group], index) => (
              <Card
                key={modelId + index}
                sx={{ padding: "24px" }}
                onClick={() => handleToggle(index)}
              >
                <XBox display="flex" justifyContent="space-between" alignItems="center">
                  <XTypography
                    variant="button"
                    fontSize="18px"
                    mb={expandedItems.includes(index) ? 3 : 0}
                    mx={1}
                  >
                    {`${group[0].model_name} (${group.length})`}
                  </XTypography>
                  <XBox display="flex" alignItems="center">
                    <XBox mr={1}>
                      <Tooltip title="Delete All Collections">
                        <XBox
                          src={DeleteIcon}
                          component="img"
                          bgColor="#f5dadf"
                          borderRadius="10px"
                          mt={1}
                          p={0.5}
                          onClick={() => {
                            handleDeleteModelCollection(modelId);
                          }}
                        />
                      </Tooltip>
                    </XBox>
                    <XImg>
                      {expandedItems.includes(index) ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    </XImg>
                  </XBox>
                </XBox>
                <XBox display="flex" flexWrap="wrap" onClick={(e) => e.stopPropagation()} gap={3}>
                  {expandedItems.includes(index) &&
                    group.slice((page[modelId] - 1) * 7, page[modelId] * 7).map((item, key) => {
                      if (isCardViewEnable) {
                        return <XBox display="flex">{renderProjects(item, key)}</XBox>;
                      }
                    })}

                  {isCardViewEnable && expandedItems.includes(index) && (
                    <Grid item xs={12}>
                      <Pagination
                        count={Math.ceil(group.length / 7)}
                        page={page[modelId] || 1}
                        onChange={(event, value) => handlePageChange(modelId, event, value)}
                        shape="rounded"
                        sx={{
                          alignSelf: "end",
                          "& .Mui-selected": {
                            background: "linear-gradient(to right, #E14086, #0080EA)",
                            color: "white !important",
                          },
                        }}
                        renderItem={(item) => (
                          <PaginationItem
                            {...item}
                            components={{
                              next: () => (
                                <XBox
                                  display="flex"
                                  sx={{ borderRadius: "4px" }}
                                  p="4px"
                                  bgColor="#F7F7F8"
                                >
                                  <XImg lightColor="black">
                                    <DarkArrowRight />
                                  </XImg>
                                </XBox>
                              ),
                              previous: () => (
                                <XBox
                                  display="flex"
                                  sx={{ borderRadius: "4px" }}
                                  p="4px"
                                  bgColor="#F7F7F8"
                                >
                                  <XImg lightColor="black">
                                    <DarkArrowLeft />
                                  </XImg>
                                </XBox>
                              ),
                            }}
                          />
                        )}
                      />
                    </Grid>
                  )}
                </XBox>
              </Card>
            ))}
        </XBox>

        {!collections.length && viewState !== "viewer" && !isLoading && (
          <XBox display="flex" height={"100%"} flexDirection="row" width="100%" gap={3}>
            <XBox display="flex" flexDirection="column" gap={2} width="50%">
              <Card sx={{ width: "100%", p: 3 }}>
                <XBox
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <XTypography variant="h5" fontWeight="bold">
                    Quick Start
                  </XTypography>
                  <XBadge badgeContent="Xplainable v1.2.1" color="xpblue" variant="contained" />
                </XBox>
                <XTypography variant="body1" fontSize="14px" fontWeight="light" mb={2}>
                  Collections serves as a place to upload a CSV dataset and save the results to the
                  Xplainable application.
                </XTypography>
                <XBox className="video-container">
                  <video width="100%" height="450" autoPlay muted loop>
                    <source
                      src="https://xplainablepublic.blob.core.windows.net/asset-repository/video_content/collections_recording_480p_compressed.mp4"
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                </XBox>
              </Card>
            </XBox>
            <XBox display="flex" flexDirection="column" gap={2} width="50%">
              <Card
                sx={{
                  width: "100%",
                  p: 3,
                  "&:hover": {
                    cursor: "pointer", // This will change the cursor to a pointer on hover
                  },
                }}
                onClick={() => {
                  window.open(
                    "https://docs.xplainable.io/docs/getting-started/about-xplainable",
                    "_blank"
                  );
                }}
              >
                <XTypography variant="h5" fontWeight="bold" mb={2}>
                  Documentation
                </XTypography>
                <XTypography variant="body1" fontSize="14px" fontWeight="light" mb={2}>
                  Alternatively, explore our comprehensive documentation for advanced features, such
                  as saving preprocessing pipelines, utilizing regression modeling functionality,
                  and creating deployments directly from your IDE.
                </XTypography>
                <XBox
                  display="flex"
                  component="img"
                  src={darkMode ? DocsScreenshotDark : DocsScreenshot}
                  borderRadius="14px"
                />
              </Card>
            </XBox>
          </XBox>
        )}
      </Grid>
      {showPricingModal && (
        <PricingModal open={showPricingModal} onClose={() => setShowPricingModal(false)} />
      )}
    </>
  );
};

export default CollectionsOverview;

function ProjectCard({
  project,
  handleDeleteProject,
  handleEditProject,
  index,
  editMode,
  setEditMode,
  setCollections,
}) {
  const { activeWorkspace } = useApiKey();
  const [editableTitle, setEditableTitle] = useState(project.name);
  const [editableDescription, setEditableDescription] = useState(project.description);
  const menuProps = useDropdownMenu(true);
  const theme = useTheme();

  const { updateCollectionTitleMutation, updateCollectionDescriptionMutation } =
    useCollectionMutation();

  // Method to handle title/description change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "title") setEditableTitle(value);
    if (name === "description") setEditableDescription(value);
  };

  const handleUpdate = async (model_id, collection_id, e, title, description) => {
    try {
      // Update title if it has changed

      if (editableTitle !== project.name) {
        updateCollectionTitleMutation(
          [
            activeWorkspace,
            model_id,
            collection_id,
            JSON.stringify({
              name: editableTitle,
            }),
          ],
          {
            onSuccess: () => {
              console.log(`Successfully updated the title of collection ${collection_id}.`);
            },
            onError: () => {
              console.error(`Failed to update the title of collection ${collection_id}.`);
            },
          }
        );
      }

      // Update description if it has changed
      if (editableDescription !== project.description) {
        updateCollectionDescriptionMutation(
          [
            activeWorkspace,
            model_id,
            collection_id,
            JSON.stringify({
              description: editableDescription,
            }),
          ],
          {
            onSuccess: () => {
              console.log(`Successfully updated the description of collection ${collection_id}.`);
            },
            onError: () => {
              console.error(`Failed to update the description of collection ${collection_id}.`);
            },
          }
        );
      }

      // Here is where we update the collections state
      setCollections((prevCollections) => {
        return prevCollections.map((collection) => {
          if (collection.collection_id === collection_id) {
            // This is the one we need to update
            return {
              ...collection,
              name: editableTitle,
              description: editableDescription,
            };
          } else {
            // This is not the one we need to update, return it as is
            return collection;
          }
        });
      });

      // Switch off the edit mode
      setEditMode((prevMode) => {
        const newMode = { ...prevMode };
        delete newMode[collection_id];
        return newMode;
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Render the menu and match it to current theme state
  const renderMenu = (state, close, model_id, active) => {
    const menuBackground = active ? theme.palette.dark.main : theme.palette.white;
    const menuTextColor = active ? theme.palette.white : theme.palette.text.primary;

    return (
      <Menu
        anchorEl={state}
        open={Boolean(state)}
        onClose={close}
        keepMounted
        PaperProps={{
          style: { backgroundColor: menuBackground },
        }}
      >
        <MenuItem
          onClick={() => {
            handleEditProject(project.collection_id);
            close();
          }}
          style={{
            color: menuTextColor,
            display: "flex",
            gap: 8,
            padding: 8,
          }}
        >
          <XImg>
            <DarkEditIcon />
          </XImg>
          <XTypography fontSize="14px">Edit</XTypography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDeleteProject(project.model_id, project.collection_id);
            close();
          }}
          style={{
            color: "#EA2C4C",
            display: "flex",
            gap: 8,
            padding: 8,
          }}
        >
          <XBox src={DeleteIcon} component="img" />
          <XTypography fontSize="14px" sx={{ color: "#EA2C4C" }}>
            Delete
          </XTypography>
        </MenuItem>
      </Menu>
    );
  };

  return (
    <XBox
      m={1}
      display="flex"
      sx={{
        opacity: 0,
        transform: "translateY(20px)",
        animation: "slide-up 0.5s forwards",
        animationDelay: `${index * 0.03}s`,
        "@keyframes slide-up": {
          "100%": {
            opacity: 1,
            transform: "translateY(0)",
          },
        },
      }}
    >
      <CollectionCard
        collection={project}
        dropdown={{
          action: () => menuProps.openMenu(),
          menu: renderMenu(
            menuProps.menu,
            () => menuProps.closeMenu(),
            project.model_id,
            project.active
          ),
        }}
        icon={{ color: "white", component: "library_books" }}
        onClick={menuProps.openOnClick ? menuProps.openMenu : undefined}
        handleUpdate={(e) => handleUpdate(project.model_id, project.collection_id, e)}
        editableTitle={editableTitle}
        editableDescription={editableDescription}
        handleInputChange={handleInputChange}
        editMode={editMode}
      />
    </XBox>
  );
}

const NewCollection = ({
  onCreateCollection,
  setAddingCollection,
  activeWorkspace,
  modelId,
  modelName,
  setShowPricingModal,
}) => {
  const titleRef = useRef(null);
  const descRef = useRef(null);
  let formattedDate = new Date().toISOString().replace("Z", "") + "000";

  const { createCollection } = useCollectionMutation();

  const addNewCollection = () => {
    const newCollection = {
      model_id: modelId,
      model_name: modelName,
      name: titleRef.current.value,
      description: descRef.current.value,
      created: formattedDate,
      scenario_count: 0,
    };

    createCollection([activeWorkspace, modelId, JSON.stringify(newCollection)], {
      onSuccess: () => {
        onCreateCollection(newCollection);
        setAddingCollection(false);
      },
      onError: (err) => {
        setShowPricingModal(true);
        setAddingCollection(false);
      },
    });
  };

  return (
    <Card
      sx={{
        position: "relative",
        zIndex: 1,
        overflow: "visible",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        height: "100%",
        width: "100%",
        py: 2,
        px: 5,
      }}
    >
      <IconButton
        sx={{
          position: "absolute",
          top: 7,
          right: 7,
        }}
        onClick={() => {
          setAddingCollection(false);
        }}
      >
        <CloseIcon />
      </IconButton>
      <XTypography variant="h6">Collection Title</XTypography>
      <XInput placeholder={"This is a title"} inputRef={titleRef} />
      <XTypography variant="h6">Collection Description</XTypography>
      <XInput placeholder={"This is a description"} inputRef={descRef} />
      <XBox width={"100%"} mt={1}>
        <XButton fullWidth variant="contained" color="dark" onClick={addNewCollection}>
          {/* <Icon>add</Icon> */}
          New Collection
        </XButton>
      </XBox>
    </Card>
  );
};

// Typechecking props for the Feature Chart
NewCollection.propTypes = {
  onCreateCollection: PropTypes.func,
  setAddingCollection: PropTypes.func,
  apiKey: PropTypes.string,
  modelId: PropTypes.number,
  modelName: PropTypes.string,
  activeWorkspace: PropTypes.object,
  setShowPricingModal: PropTypes.func,
  isCardViewEnable: PropTypes.bool,
  loading: PropTypes.bool,
};

ProjectCard.propTypes = {
  project: PropTypes.object.isRequired,
  handleDeleteProject: PropTypes.func.isRequired,
  handleEditProject: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  editMode: PropTypes.bool,
  setEditMode: PropTypes.func,
  setCollections: PropTypes.func,
};

CollectionsOverview.propTypes = {
  value: PropTypes.number,
  row: PropTypes.number,
};
