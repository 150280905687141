import axios from "axios";

const APIVersion = "v1";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_HOST_URL}/${APIVersion}`,
});

export const setQueryAccessToken = (token) => {
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const setQueryApiKeyConfig = (key) => {
  axiosInstance.defaults.headers.common["api_key"] = key;
};

export const httpClient = axiosInstance;

export async function makeHttpRequest(config) {
  const data = { ...config };

  const headersToSend = {
    "Content-Type": data?.header ? "" : "application/json",
  };

  return httpClient.request({
    headers: {
      ...headersToSend,
    },
    ...config,
  });
}
