import React, { useState, useMemo, useEffect, useRef } from "react";
import XBox from "components/XBox";
import Chart from "./components/chart";
import PropTypes from "prop-types";
import { AppBar, Tabs, Tab, Grid, Tooltip, Card } from "@mui/material";

import XTypography from "components/XTypography";
import breakpoints from "assets/theme/base/breakpoints";
import XInput from "components/XInput";

import { useXplainableController } from "context";
import { useCollectionMutation } from "api/mutations/useCollectionMutation";

import EditIcon from "assets/images/icons/configuration/edit-icon.svg";
import SaveIcon from "assets/images/icons/configuration/save-icon.svg";
import { BrushChart } from "./components/brush";
function ExpandableInfo({
  collapsed,
  data,
  notes,
  activeWorkspace,
  modelId,
  collectionId,
  scenarioId,
}) {
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const selectionWaterfallRef = useRef([0, 0]);

  const [chartType, setChartType] = useState("bar");
  const [highlightedFeature, setHighlightedFeature] = useState(null);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [sortOrder, setSortOrder] = useState("asc");
  const [editing, setEditing] = useState(false);
  const [editableNotes, setEditableNotes] = useState(notes); // To manage editable notes

  const { updateScenarioNotesMutation } = useCollectionMutation();

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    if (newValue === 1) {
      setChartType("waterfall");
      setTabValue(newValue);
      return;
    }

    setChartType("bar");
    setTabValue(newValue);
  };

  function filterNonZeroValues(arr) {
    return arr.filter((item) => item.score !== 0);
  }

  const handleMouseEnter = (feature) => {
    setHighlightedFeature(feature);
  };

  const handleMouseLeave = () => {
    setHighlightedFeature(null);
  };

  const filteredData = useMemo(() => filterNonZeroValues(data), [data]);

  const sortedData = useMemo(() => {
    const baseValue = filteredData.find((d) => d.key === "Base Value");
    const filteredWithoutBaseValue = filteredData.filter((d) => d.key !== "Base Value");

    let sortedData;

    if (sortOrder === "desc") {
      sortedData = [...filteredWithoutBaseValue].sort((a, b) => b.score - a.score);
    } else {
      sortedData = [...filteredWithoutBaseValue].sort((a, b) => a.score - b.score);
    }

    // Return sorted data with "Base Value" always first.
    return baseValue ? [baseValue, ...sortedData] : sortedData;
  }, [filteredData, sortOrder]);

  useEffect(() => {
    selectionWaterfallRef.current = [data?.length, 0];
  }, [data]);

  const toggleEdit = () => {
    setEditing(!editing);
    if (editing) {
      // Save logic goes here
      handleSaveClick();
    }
  };

  const handleNotesChange = (event) => {
    setEditableNotes(event.target.value);
  };

  // Function to use the mutation to save the update notes
  const handleSaveClick = () => {
    updateScenarioNotesMutation(
      [
        activeWorkspace,
        modelId,
        collectionId,
        scenarioId,
        JSON.stringify({
          notes: editableNotes,
        }),
      ],
      {
        onSuccess: () => {
          setEditing(false);
        },
        onError: () => {
          console.error(`Failed to update the notes of ${collectionId}.`);
        },
      }
    );
  };

  return (
    <Grid container sx={{ background: darkMode ? "#1D1B1B" : "#F7F7F8", padding: 2, gap: "25px" }}>
      <Grid item xs={12} lg={5}>
        <XBox display="flex" mb={2}>
          <XTypography variant="h6" fontSize="18px">
            Score Breakdown
          </XTypography>
        </XBox>
        <XBox>
          <XTypography variant="button">Top Contributors</XTypography>
          <XBox
            maxHeight={180}
            mt={1}
            overflow="scroll"
            width="100%"
            sx={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            {[...sortedData]
              .filter((item) => item.feature !== "Base Value" && item.feature !== "Total")
              .sort((a, b) => Math.abs(b.score) - Math.abs(a.score))
              .slice(0, 3)
              .map((item) => (
                <XBox
                  py={0.5}
                  key={item.feature}
                  display="flex"
                  justifyContent="space-between"
                  borderBottom={0.5}
                  borderColor="grey.300"
                  onMouseEnter={() => handleMouseEnter(item.feature)}
                  onMouseLeave={handleMouseLeave}
                >
                  <XBox display="flex" alignItems="center">
                    <XBox
                      bgColor={Number(item.score) < 0 ? "xppink" : "xpblue"}
                      width="12px"
                      height="12px"
                      mt={0.25}
                      mr={2}
                      mb={0.25}
                      borderRadius="lg"
                    />
                    <XBox display="flex" flexDirection="column">
                      <XTypography variant="h5" fontSize="14px" fontWeight="light">
                        {item.feature}
                      </XTypography>
                      <XTypography
                        variant="body2"
                        fontWeight="light"
                        fontSize="12px"
                        color="secondary"
                      >
                        {item.value}
                      </XTypography>
                    </XBox>
                  </XBox>
                  <XBox display="flex" alignItems="center" justifyContent="center">
                    <XTypography variant="body1" fontSize="14px">
                      {Number(item.score * 100).toFixed(1) + "%"}
                    </XTypography>
                  </XBox>
                </XBox>
              ))}
          </XBox>
        </XBox>
        <XBox mt={3}>
          <XBox display="flex" justifyContent="space-between">
            <XTypography variant="button">Notes</XTypography>
            <XBox ml={1} src={editing ? SaveIcon : EditIcon} component="img" onClick={toggleEdit} />
          </XBox>
          <XBox maxHeight={180} mt={1} sx={{ position: "relative", height: "100%" }}>
            {!editing ? (
              <XTypography variant="button" fontWeight="light">
                {editableNotes}
              </XTypography>
            ) : (
              <>
                <XInput
                  value={editableNotes}
                  onChange={handleNotesChange}
                  placeholder="Type here"
                  multiline
                  rows={6}
                />
                <Tooltip title="Auto generate text coming soon" placement="top">
                  <XBox
                    display="flex"
                    sx={{
                      position: "absolute",
                      bottom: 8,
                      right: 8,
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <XBox
                      component="i"
                      color="secondary"
                      fontSize="12px"
                      className="ni ni-atom"
                      mr={0.5}
                    />
                    <XTypography variant="body1" fontSize="14px" color="secondary">
                      Generate
                    </XTypography>
                  </XBox>
                </Tooltip>
              </>
            )}
          </XBox>
        </XBox>
      </Grid>
      <Grid item xs={12} lg={6.5}>
        <Card sx={{ p: "14px", display: "flex", flexDirection: "column", gap: "10px" }}>
          <AppBar position="static">
            <Tabs
              orientation={tabsOrientation}
              value={tabValue}
              onChange={handleSetTabValue}
              sx={{
                backgroundColor: `${darkMode ? "#1D1B1B" : "#F7F7F8"} !important`,

                transition: "all 500ms ease",
                color: "#AFAFAF",

                "& .Mui-selected": {
                  fontWeight: "600",
                  color: `${darkMode ? "white" : "black"} !important`,
                  backgroundColor: `${darkMode ? "#262525" : "white"} !important`,
                },
                "& .MuiTabs-indicator": {
                  display: "none",
                },
              }}
            >
              <Tab label="Bar Chart" sx={{ minWidth: 0 }} />
              <Tab label="Waterfall Chart" sx={{ minWidth: 0 }} />
            </Tabs>
          </AppBar>

          <BrushChart
            data={sortedData}
            selectionRef={selectionWaterfallRef}
            isCollapsed={collapsed}
            chartType={chartType}
          >
            {(selection) => (
              <Chart
                data={sortedData}
                collapsed={collapsed}
                highlightedFeature={highlightedFeature}
                chartType={chartType}
                selection={selection}
              />
            )}
          </BrushChart>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ExpandableInfo;

ExpandableInfo.propTypes = {
  collapsed: PropTypes.bool,
  data: PropTypes.array,
  notes: PropTypes.string,
  activeWorkspace: PropTypes.string,
  modelId: PropTypes.string,
  collectionId: PropTypes.string,
  scenarioId: PropTypes.string,
};
