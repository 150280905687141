import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";
import { getRoleName, useApiKey } from "components/Authorisation/ApiKeyContext";
import { usePusher } from "context/Pusher";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Badge, Tooltip } from "@mui/material";

// xplainable Dashboard components
import XBox from "components/XBox";
import XInput from "components/XInput";
import XTypography from "components/XTypography";
import UserButton from "components/Authorisation/Logout";

// xplainable dashboard example components
import Breadcrumbs from "examples/Breadcrumbs";

import { useNotificationsMutation } from "api/mutations/useNotificationsMutation";
import {
  CommentNotification,
  InvitationNotification,
  ModelNotification,
} from "./notifications/types";

// Custom styles for DashboardNavbar
import { navbar, navbarContainer, navbarRow } from "examples/Navbars/DashboardNavbar/styles";

// xplainable dashboard context
import {
  useXplainableController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

// Images
import { useApp } from "hooks";

import SearchIcon from "assets/images/search-icon.svg";
import LightNotificationIcon from "assets/images/light-notification-icon.svg";
import LightSettingsIcon from "assets/images/light-settings-icon.svg";
import DarkNotificationIcon from "assets/images/dark-notification-icon.svg";
import DarkSettingsIcon from "assets/images/dark-settings-icon.svg";
import DarkBellIcon from "assets/images/icons/dark-bell-icon.svg";
import DarkXCloseIcon from "assets/images/dark-x-close-icon.svg";

import XSelect from "components/XSelect";
import { XDivider } from "components/XDivider";

function DashboardNavbar({ absolute, light, isMini, color }) {
  const { apiKey, viewState, setViewState, workspaces, activeWorkspace } = useApiKey();
  const { activeStep, activeTitle } = useApp();
  const [controller, dispatch] = useXplainableController();
  const { notifications, setNotifications } = usePusher();
  const { markAsReadMutation, markAllAsReadMutation } = useNotificationsMutation();
  const { transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;

  const [navbarType, setNavbarType] = useState();
  const [openMenu, setOpenMenu] = useState(false);
  const [roleName, setRoleName] = useState(null);

  const route = useLocation().pathname.split("/").slice(1);
  const location = useLocation();

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleClearAllNotifications = () => {
    markAllAsReadMutation(apiKey, {
      onSuccess: () => {
        setNotifications([]);
        handleCloseMenu();
      },
      onError: (error) => {
        console.error("Failed to clear notifications:", error);
      },
    });
  };

  const handleMarkNotificationAsRead = (notificationId) => {
    markAsReadMutation([notificationId, apiKey], {
      onSuccess: (data) => {
        setNotifications((prevNotifications) =>
          prevNotifications.filter((notification) => notification._id !== notificationId)
        );
      },
      onError: (error) => {
        console.error("Failed to mark notification as read:", error);
      },
    });
  };

  useEffect(() => {
    setOpenConfigurator(dispatch, false);
  }, [location.pathname]);

  const handleTitle = () => {
    if (activeStep === 1) return "";

    if (route[route.length - 1].length === 0) {
      return "Home";
    }

    if (route[route.length - 1] === "admin" && route.length === 2) {
      return activeTitle;
    }
    if (route[route.length - 1] === "batch-optimisation" && route.length === 2) {
      return activeTitle;
    }

    if (
      route[route.length - 1] === "models" ||
      route[route.length - 1] === "auto-train" ||
      route[route.length - 1] === "deployments" ||
      route[route.length - 1] === "collections" ||
      route[route.length - 1] === "reports" ||
      route[route.length - 1] === "batch-optimisation" ||
      route[route.length - 1] === "api-keys" ||
      route[route.length - 1] === "admin" ||
      route[route.length - 1] === "subscriptions" ||
      route[route.length - 1] === "train" ||
      route[route.length - 1] === "datasets" ||
      route[route.length - 1] === "analyst" ||
      route[route.length - 3] === "collections"
    ) {
      return route[route.length - 1];
    }

    return activeTitle;
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      PaperProps={{
        style: {
          maxWidth: "600px",
          minWidth: "400px",
          padding: "16px",
        },
      }}
    >
      <XBox display="flex" justifyContent="space-between" mb={2}>
        <XTypography variant="button" fontSize="18px">
          Notification Centre
        </XTypography>

        <XBox display="flex" gap={2}>
          <XBox sx={{ cursor: "pointer" }} onClick={handleClearAllNotifications}>
            <XTypography
              variant="button"
              sx={{
                backgroundImage: "linear-gradient(to right, #E14086, #0080EA)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                whiteSpace: "nowrap",
              }}
            >
              Clear all
            </XTypography>
          </XBox>
          <XBox component="img" src={DarkBellIcon} />
          <XBox component="img" src={DarkXCloseIcon} onClick={handleCloseMenu} />
        </XBox>
      </XBox>
      <XDivider />
      <XBox sx={{ maxHeight: "300px", overflowY: "auto" }}>
        {" "}
        {/* This is the scrollable container */}
        {Array.isArray(notifications) && notifications.length > 0 ? (
          [...notifications].reverse().map((notification, index) => {
            switch (notification.type) {
              case "comments":
                return (
                  <CommentNotification
                    key={index}
                    notification={notification}
                    handleCloseMenu={handleCloseMenu}
                    handleMarkAsRead={handleMarkNotificationAsRead}
                  />
                );
              case "comment_tags":
                return (
                  <CommentNotification
                    key={index}
                    notification={notification}
                    handleCloseMenu={handleCloseMenu}
                    handleMarkAsRead={handleMarkNotificationAsRead}
                  />
                );
              case "invitations":
                return (
                  <InvitationNotification
                    key={index}
                    notification={notification}
                    handleCloseMenu={handleCloseMenu}
                    handleMarkAsRead={handleMarkNotificationAsRead}
                  />
                );
              case "new_models":
                return (
                  <ModelNotification
                    key={index}
                    notification={notification}
                    handleCloseMenu={handleCloseMenu}
                    handleMarkAsRead={handleMarkNotificationAsRead}
                  />
                );
              case "new_versions":
                return (
                  <ModelNotification
                    key={index}
                    notification={notification}
                    handleCloseMenu={handleCloseMenu}
                    handleMarkAsRead={handleMarkNotificationAsRead}
                  />
                );
              // ... Add cases for other notification types
              default:
                return null;
            }
          })
        ) : (
          <MenuItem>You currently have no notifications</MenuItem>
        )}
      </XBox>
    </Menu>
  );

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
   The event listener that's calling the handleTransparentNavbar function when 
   scrolling the window.
  */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar, apiKey]);

  //TODO: Alex can you check this logic?
  useEffect(() => {
    const role = getRoleName(workspaces, activeWorkspace);

    if (role) {
      setRoleName(role);
      //This will also return admin but will update render logic

      // setViewState(role === "viewer" ? "viewer" : "creator");
    }
  }, [activeWorkspace?.organisation_id]);

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme, { navbarType })}>
        <XBox
          color={light && transparentNavbar ? "white" : "dark"}
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <Breadcrumbs
            icon="home"
            title={handleTitle()}
            route={route}
            light={transparentNavbar ? light : false}
            color={color}
          />
        </XBox>
        {isMini ? null : (
          <XBox sx={(theme) => navbarRow(theme, { isMini })}>
            {activeStep !== 1 && (
              <XBox mr={3}>
                <XInput
                  placeholder="Type here..."
                  startAdornment={<XBox src={SearchIcon} component="img" />}
                />
              </XBox>
            )}

            <XBox display="flex" gap={3} alignItems="center" color={light ? "white" : "inherit"}>
              {roleName !== "viewer" && activeStep !== 1 && (
                <XBox maxWidth="136px">
                  <XSelect
                    options={[
                      { value: "viewer", label: "Viewer" },
                      { value: "creator", label: "Creator" },
                    ]}
                    defaultValue={{
                      value: viewState,
                      label: viewState.charAt(0).toUpperCase() + viewState.slice(1),
                    }}
                    value={{
                      value: viewState,
                      label: viewState.charAt(0).toUpperCase() + viewState.slice(1),
                    }}
                    onChange={(e) => setViewState(e.value)}
                  />
                </XBox>
              )}

              {activeStep !== 1 && (
                <>
                  <Badge badgeContent={notifications ? notifications.length : null} color="error">
                    <XBox
                      p="8px"
                      bgColor={darkMode ? "#262525" : "white"}
                      display="flex"
                      borderRadius="12px"
                      onClick={handleOpenMenu}
                    >
                      {darkMode ? (
                        <XBox
                          component="img"
                          sx={{ cursor: "pointer" }}
                          src={LightNotificationIcon}
                        />
                      ) : (
                        <XBox
                          component="img"
                          sx={{ cursor: "pointer" }}
                          src={DarkNotificationIcon}
                        />
                      )}
                    </XBox>
                  </Badge>
                  <XBox
                    p="8px"
                    bgColor={darkMode ? "#262525" : "white"}
                    display="flex"
                    borderRadius="12px"
                    onClick={handleConfiguratorOpen}
                  >
                    {darkMode ? (
                      <XBox component="img" sx={{ cursor: "pointer" }} src={LightSettingsIcon} />
                    ) : (
                      <XBox component="img" sx={{ cursor: "pointer" }} src={DarkSettingsIcon} />
                    )}
                  </XBox>

                  {renderMenu()}
                </>
              )}
              <UserButton />
            </XBox>
          </XBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: true,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  color: PropTypes.string,
};

export default DashboardNavbar;
