import React, { useState, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import XSelect from "components/XSelect/index.js";
import XButton from "components/XButton/index.js";

// @mui material components
import Grid from "@mui/material/Grid";
import { Card, Icon, Tooltip } from "@mui/material";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";

import LoadingSpinner from "shared/Animations/LoadingAnimation";
import ScenarioAnalysis from "../../../../../shared/models/ScenarioAnalysis/index.js";
import ScrollReveal from "scrollreveal";
import CollectionModal from "./components/CollectionModal/index.js";
import XBadge from "components/XBadge/index.js";

import { useScenarioMutation } from "api/mutations/useScenarioMutation.js";
import { useXplainableController } from "context/index.js";
import { useToast } from "hooks/useToast.js";
import { useModel } from "hooks/useModel.js";
import { useApiKey } from "components/Authorisation/ApiKeyContext/index.js";

import DarkCreateNewCollectionIcon from "assets/images/icons/scenario/dark-create-new-collection-icon.svg";
import LightCreateNewCollectionIcon from "assets/images/icons/scenario/light-create-new-collection-icon.svg";
import { useScenarioQuery } from "api/query/useScenarioQuery.js";
import { useAuth0 } from "@auth0/auth0-react";
import { useCollectionsQuery } from "api/query/useColletionsQuery.js";
import { GradientText } from "components/GradientTypography/index.js";


function Scenario() {
  const { apiKey, activeWorkspace } = useApiKey();
  const { addScenarioMutation } = useScenarioMutation();
  const { showSuccessToast } = useToast();
  const { logout } = useAuth0();

  const { profileData, model_id, selectedVersion, selectedPartition, model_type } = useModel();
  const [controller] = useXplainableController();

  const [fieldValues, setFieldValues] = useState({});
  const [score, setScore] = useState(profileData.base_value);
  const [probability, setProbability] = useState(0);
  const [multiplier, setMultiplier] = useState(0);
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [scenarioCount, setScenarioCount] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationStatus, setConfirmationStatus] = useState(null);
  const [showCollectionModal, setShowCollectionModal] = useState(false);

  const { data: collectionData } = useScenarioQuery(
    activeWorkspace,
    model_id,
    selectedCollection?.collection_id,
    apiKey,
    logout
  );

  const { data: collectionsData } = useCollectionsQuery(activeWorkspace, model_id, logout);

  let firstKey;
  if (profileData && (profileData.categorical || profileData.numeric)) {
    firstKey =
      profileData.categorical && Object.keys(profileData.categorical).length > 0
        ? Object.keys(profileData.categorical)[0]
        : Object.keys(profileData.numeric)[0];
  }

  const handleSelectCollection = (data) => {
    //Set the selected collection for the endpoint
    setSelectedCollection(data);

    //Set the scenario count
    setScenarioCount(data.scenario_count);

    //Close Modal on click
    setShowCollectionModal(false);
  };

  const addScenario = async (model_id, collection_id, scenario, probability, score, multiplier) => {
    //Add the score to the list of scenarios
    const scenarioData = {
      version_id: selectedVersion.value,
      partition_id: selectedPartition.value,
      scenario: scenario,
      proba: probability,
      score: score,
      multiplier: multiplier,
      support: 0,
      notes: "",
    };

    console.log("the scenario data is", scenarioData);

    addScenarioMutation(
      [activeWorkspace, model_id, collection_id, apiKey, JSON.stringify([scenarioData])],
      {
        onSuccess: (data) => {
          showSuccessToast(`Scenario added successfully to ${selectedCollection.name}`);
          setScenarioCount((prev) => prev + 1);
        },
        onError: (error) => {
          console.log(error, "here");
        },
      }
    );
  };

  const addToCollection = () => {
    // Make sure a collection is selected
    if (selectedCollection?.collection_id) {
      // Find the selected collection
      const collectionToUpdate = collections.find(
        (collection) => collection.collection_id === selectedCollection.collection_id
      );

      console.log("The collection to update:", collectionToUpdate)

      // Check if fieldValues already exists in the collection's scenarios
      const scenarios = collectionData;

      // Check whether scenario already exists
      //const scenarioExists = !areArraysEqual(scenarios, fieldValues);
        //TODO:handle add logic here
      const scenarioExists = false

      if (!scenarioExists) {
        //Add the scenario to the
        addScenario(
          model_id,
          selectedCollection.collection_id,
          fieldValues,
          probability,
          score,
          multiplier
        );

        // Increment the count
        collectionToUpdate.scenario_count += 1;
      } else {
        setShowConfirmation(true);
        setConfirmationStatus("error");
      }
    }
  };

  useEffect(() => {
    if (!collectionsData) return;

    const data = collectionsData.data;

    // Sort the collections from newest to oldest
    data.sort((a, b) => {
      return new Date(b.created) - new Date(a.created);
    });

    //Set the Performance data
    setCollections(data);
  }, [collectionsData]);

  useEffect(() => {
    ScrollReveal().reveal(".scrollreveal", {
      delay: 0,
      distance: "100px",
      duration: 700,
      easing: "cubic-bezier(0.5, 0, 0, 1)",
      origin: "bottom",
      interval: 100,
    });
  }, [profileData, firstKey]);

  // Set up an effect that hides the confirmation after 5 seconds
  useEffect(() => {
    let timer;
    if (showConfirmation) {
      timer = setTimeout(() => {
        setShowConfirmation(false);
      }, 2000);
    }
    // Clear timeout if the component is unmounted while the confirmation is showing
    return () => clearTimeout(timer);
  }, [showConfirmation]);

  // console.log(fieldValues, "fieldValues");

  return (
    <>
      {profileData && Object.keys(profileData).length > 0 && firstKey ? (
        <>
          <XBox py={2}>
            <Grid container spacing={1} className="scrollreveal">
              <Grid item xs={12} mb={2}>
                <Card
                  sx={{
                    px: 2,
                    py: 2,
                    position: "relative",
                    zIndex: 100,
                    overflow: "visible",
                    borderRadius: "16px",
                  }}
                >
                  <XBox display="flex" flexDirection="column" justifyContent="space-between">
                    <XBox display="flex" justifyContent="space-between">
                      <XBox display="flex" flexDirection="column">
                        <XTypography variant="h5" fontSize="24px">
                          Scenario Analysis
                        </XTypography>
                        <XTypography
                          variant="h6"
                          style={{ color: "#7C7C7C" }}
                          fontWeight="light"
                          fontSize="14px"
                        >
                          Adjust features to optimise specific outcomes
                        </XTypography>
                      </XBox>
                      <XBox display="flex">
                        {selectedCollection?.collection_id && (
                          <XBox
                            display="flex"
                            bgColor="#F7F7F8"
                            py={0.5}
                            px={2}
                            sx={{ alignItems: "center", borderRadius: "16px" }}
                          >
                            <XBox
                              display="flex"
                              justifyContent="space-between"
                              flexDirection="column"
                              minWidth={"120px"}
                            >
                              <XBox>
                                <XBadge
                                  variant="contained"
                                  color="xpblue"
                                  badgeContent={scenarioCount + " Scenarios"}
                                />
                              </XBox>
                              <XTypography
                                variant="button"
                                sx={{
                                  minWidth: "160px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {selectedCollection.name}
                              </XTypography>
                            </XBox>
                            <XBox>
                              <Tooltip title="Add to Collection">
                                <XButton
                                  color="button"
                                  variant="gradient"
                                  onClick={addToCollection}
                                >
                                  Add
                                </XButton>
                              </Tooltip>
                            </XBox>
                          </XBox>
                        )}
                       <XBox 
                          sx={{ 
                            cursor: "pointer",
                            display: "flex", 
                            alignItems: "center"  // Centers the text vertically
                          }}
                          onClick={() => setShowCollectionModal(true)}
                        >
                          <GradientText>
                            + Create New Collection
                          </GradientText>
                        </XBox>
                      </XBox>
                    </XBox>
                  </XBox>
                </Card>
                <CollectionModal
                  open={showCollectionModal}
                  onClose={() => setShowCollectionModal(false)}
                  handleSelectCollection={handleSelectCollection}
                />
              </Grid>
              <XBox
                display="flex"
                ml="8px"
                width="100%"
                gap={2}
                // mb={2}
                style={{ transition: "width 0.5s ease" }}
              >
                <Card sx={{ padding: "16px", width: "100%", borderRadius: "16px" }}>
                  <XBox display="flex" flexDirection="column" gap={3}>
                    <XTypography fontSize="18px" fontWeight="medium">
                      Score
                    </XTypography>
                    <XTypography
                      fontSize="24px"
                      fontWeight="medium"
                      color="xpblue"
                      lineHeight="normal"
                    >
                      {score?.toFixed(2)}
                    </XTypography>
                  </XBox>
                </Card>
                {model_type === "binary_classification" && (
                  <Card sx={{ padding: "16px", width: "100%", borderRadius: "16px" }}>
                    <XBox display="flex" flexDirection="column" gap={3}>
                      <XTypography fontSize="18px" fontWeight="medium">
                        Probability
                      </XTypography>
                      <XTypography
                        fontSize="24px"
                        fontWeight="medium"
                        color="xpblue"
                        lineHeight="normal"
                      >
                        {probability?.toFixed(2)}
                      </XTypography>
                    </XBox>
                  </Card>
                )}
                <Card sx={{ padding: "16px", width: "100%", borderRadius: "16px" }}>
                  <XBox display="flex" flexDirection="column" gap={3}>
                    <XTypography fontSize="18px" fontWeight="medium">
                      Multiplier
                    </XTypography>
                    <XTypography
                      fontSize="24px"
                      fontWeight="medium"
                      color="xpblue"
                      lineHeight="normal"
                    >
                      {multiplier?.toFixed(1)}x
                    </XTypography>
                  </XBox>
                </Card>
              </XBox>
              <Grid item xs={12}>
                <ScenarioAnalysis
                  fieldValues={fieldValues}
                  setFieldValues={setFieldValues}
                  setProbability={setProbability}
                  setScore={setScore}
                  setMultiplier={setMultiplier}
                  probability={probability}
                  score={score}
                  multiplier={multiplier}
                />
              </Grid>
            </Grid>
          </XBox>
        </>
      ) : (
        <XBox height={"400px"}>
          <LoadingSpinner size={50} animationType="pulse" />
        </XBox>
      )}
    </>
  );
}

export default Scenario;

Scenario.propTypes = {
  allowAddCollection: PropTypes.bool,
};
