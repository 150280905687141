import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "api/apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useLoginQuery = (token, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.LOGIN],
    () => apiHandlers.login.login(token),
    {
      enabled: !!token,
    },
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};

export const useLimitsQuery = (activeWorkspace, token, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.LIMITS, activeWorkspace?.organisation_id],
    () => apiHandlers.login.limits(activeWorkspace),
    {
      enabled: !!token && !!activeWorkspace,
      cacheTime: 60000, // Refetch data every 60,000 milliseconds (1 minute)
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};