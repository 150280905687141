import { apiPaths } from "./apiPath";
import { getData, putData, deleteData, patchData, postData } from "./crudHandlers";

export const apiHandlers = {
  comments: {
    getCommentsModels: (activeWorkspace, modelId, pageNumber, itemsPerPage) => {
      return getData(
        apiPaths.comments.getComments.models(activeWorkspace, modelId, pageNumber, itemsPerPage)
      );
    },

    getCommentsDeployments: (activeWorkspace, modelId, pageNumber, itemsPerPage) => {
      return getData(
        apiPaths.comments.getComments.deployments(
          activeWorkspace,
          modelId,
          pageNumber,
          itemsPerPage
        )
      );
    },

    getCommentsCollections: (activeWorkspace, modelId, pageNumber, itemsPerPage) => {
      return getData(
        apiPaths.comments.getComments.collections(
          activeWorkspace,
          modelId,
          pageNumber,
          itemsPerPage
        )
      );
    },

    getUsers: (activeWorkspace) => {
      return getData(apiPaths.comments.getUsers(activeWorkspace));
    },

    getFilteredUsers: (activeWorkspace, input) => {
      return getData(apiPaths.comments.getFilteredUsers(activeWorkspace, input));
    },

    addComment: (activeWorkspace, type, id, api_key, data) => {
      return putData(apiPaths.comments.addComment(activeWorkspace, type, id), {
        data,
        api_key: api_key,
      });
    },

    deleteComment: (activeWorkspace, type, id, comment_id, api_key) => {
      return deleteData(apiPaths.comments.deleteComment(activeWorkspace, type, id, comment_id), {
        api_key: api_key,
      });
    },

    editComment: (activeWorkspace, type, id, comment_id, api_key, data) => {
      return patchData(apiPaths.comments.editComment(activeWorkspace, type, id, comment_id), {
        data,
        api_key: api_key,
      });
    },

    addLike: (activeWorkspace, type, id, comment_id, api_key, interactionType) => {
      return putData(
        apiPaths.comments.addLike(activeWorkspace, type, id, comment_id, interactionType),
        {
          api_key: api_key,
        }
      );
    },

    removeLike: (activeWorkspace, type, id, comment_id, api_key, interactionType) => {
      return deleteData(
        apiPaths.comments.removeLike(activeWorkspace, type, id, comment_id, interactionType),
        {
          api_key: api_key,
        }
      );
    },
  },
  cards: {
    editCard: (activeWorkspace, endpointType, id, action, api_key) => {
      return patchData(apiPaths.cards.toggleCard(activeWorkspace, endpointType, id, action), {
        api_key: api_key,
      });
    },
  },
  xPanel: {
    getXPanel: (queryParams, api_key) => {
      const queryStringParts = [];

      for (const key in queryParams) {
        if (Array.isArray(queryParams[key])) {
          // For array values, add each item as a separate key-value pair
          queryParams[key].forEach((val) => {
            queryStringParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
          });
        } else {
          // For non-array values, add the key-value pair as usual
          queryStringParts.push(
            `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
          );
        }
      }

      const queryString = queryStringParts.join("&");

      return getData(`${apiPaths.xPanel.getXPanel()}?${queryString}`, {
        api_key: api_key,
      });
    },
  },
  userManagement: {
    getUser: (api_key) => {
      return getData(apiPaths.user.getUser(), {
        api_key: api_key,
      });
    },
    createUser: (data, jwt) => {
      return putData(apiPaths.user.createUser(), {
        data: data,
        headers: {
          Authorization: `Bearer ${jwt}`,
          "Access-Control-Allow-Origin": "*",
        },
      });
    },
    deleteUser: (api_key) => {
      return deleteData(apiPaths.user.deleteUser(), {
        api_key: api_key,
      });
    },
    getUserNotification: () => {
      return getData(apiPaths.user.userNotification());
    },
    patchUserNotification: (data) => {
      return patchData(apiPaths.user.userNotification(), {
        data,
      });
    },
  },
  models: {
    getModels: (activeWorkspace, api_key) => {
      if (api_key) {
        return getData(apiPaths.models.getModels(activeWorkspace), {
          api_key: api_key,
        });
      }
    },
    getModel: (activeWorkspace, model_id, api_key) => {
      if (api_key) {
        return getData(apiPaths.models.getModel(activeWorkspace, model_id), {
          api_key: api_key,
        });
      }
    },

    deleteModel: (activeWorkspace, model_id, api_key) => {
      return deleteData(apiPaths.models.deleteModel(activeWorkspace, model_id), {
        api_key: api_key,
      });
    },
    archiveModel: (activeWorkspace, model_id, api_key) => {
      return patchData(apiPaths.models.archiveModel(activeWorkspace, model_id), {
        api_key: api_key,
      });
    },
    moveModel: (activeWorkspace, model_id, new_team_id, api_key) => {
      return patchData(apiPaths.models.moveModel(activeWorkspace, model_id), {
        api_key: api_key,
        data: {
          new_team_id: new_team_id,
        },
      });
    },
    deleteModelVersion: (activeWorkspace, model_id, selectedVersion, api_key) => {
      return deleteData(
        apiPaths.models.deleteModelVersion(activeWorkspace, model_id, selectedVersion),
        {
          api_key: api_key,
        }
      );
    },
    handleActiveVersion: (activeWorkspace, model_id, selectedVersion, api_key) => {
      return patchData(
        apiPaths.models.handleActiveVersion(activeWorkspace, model_id, selectedVersion),
        {
          api_key: api_key,
        }
      );
    },

    createModel: async (activeWorkspace, modelData, api_key) => {
      if (api_key) {
        return postData(apiPaths.models.createModel(activeWorkspace), modelData, {
          api_key: api_key,
        });
      }
    },

    addVersion: async (activeWorkspace, model_id, versionData, api_key) => {
      if (api_key) {
        return postData(apiPaths.models.addVersion(activeWorkspace, model_id), versionData, {
          api_key: api_key,
        });
      }
    },
  },
  deployments: {
    getDeployments: (activeWorkspace, api_key) => {
      return getData(apiPaths.deployments.getDeployments(activeWorkspace), {
        api_key: api_key,
      });
    },
    getDeployment: (activeWorkspace, model_id, api_key) => {
      if (api_key) {
        return getData(apiPaths.models.getModel(activeWorkspace, model_id), {
          api_key: api_key,
        });
      }
    },
    handleArchiveDeployment: (activeWorkspace, model_id) => {
      return patchData(apiPaths.deployments.handleArchiveDeployment(activeWorkspace, model_id));
    },

    handleDeployDeployment: (activeWorkspace, model_id, version_id, data) => {
      return putData(
        apiPaths.deployments.handleDeployDeployment(activeWorkspace, model_id, version_id),
        {
          data,
        }
      );
    },
    deleteDeployments: (activeWorkspace, deployment_id) => {
      return deleteData(apiPaths.deployments.deleteDeployments(activeWorkspace, deployment_id));
    },
    getKey: (activeWorkspace, deployment_id, api_key) => {
      return getData(apiPaths.deployments.getKey(activeWorkspace, deployment_id), {
        api_key: api_key,
      });
    },

    getPayload: (activeWorkspace, deployment_id, api_key) => {
      return getData(apiPaths.deployments.getPayload(activeWorkspace, deployment_id), {
        api_key: api_key,
      });
    },
    getPrediction: (deployment_key, payload) => {
      return postData(apiPaths.deployments.getPrediction(), {
        body: payload,
        headers: {
          "Content-Type": "application/json",
          api_key: deployment_key,
        },
      });
    },
  },
  toggle: {
    toggleDeployment: (activeWorkspace, deployment_id, action, api_key) => {
      return patchData(apiPaths.toggle.toggleDeployment(activeWorkspace, deployment_id, action), {
        api_key: api_key,
      });
    },
    toggleModel: (activeWorkspace, model_id, action, api_key) => {
      return patchData(apiPaths.toggle.toggleModel(activeWorkspace, model_id, action), {
        api_key: api_key,
      });
    },
  },
  invitations: {
    inviteUser: (data, apiKey) => {
      return putData(apiPaths.invitations.inviteUser(), {
        api_key: apiKey,
        data,
      });
    },
    getInvitations: (api_key) => {
      return getData(apiPaths.invitations.getInvitations(), {
        api_key: api_key,
      });
    },
    getRequests: (api_key) => {
      return getData(apiPaths.invitations.getRequests(), {
        api_key: api_key,
      });
    },
    deleteInvitation: (invitationId, api_key) => {
      return deleteData(apiPaths.invitations.deleteInvitation(invitationId), {
        api_key: api_key,
      });
    },
    handleInvitations: (type, requestId, action, api_key) => {
      return patchData(apiPaths.invitations.handleInvitations(type, requestId, action), {
        api_key: api_key,
      });
    },
  },
  notifications: {
    getNotifications: (api_key) => {
      return getData(apiPaths.notifications.getNotifications(), {
        api_key: api_key,
      });
    },
    markAsRead: (notificationId, api_key) => {
      return deleteData(apiPaths.notifications.markAsRead(notificationId), {
        api_key: api_key,
      });
    },
    markAllAsRead: (api_key) => {
      return deleteData(apiPaths.notifications.markAllAsRead(), {
        api_key: api_key,
      });
    },
  },
  predictions: {
    getPrediction: (deployment_key) => {
      return getData(apiPaths.predictions.getPrediction(), {
        api_key: deployment_key,
      });
    },
    getPayload: (activeWorkspace, deployment_id, api_key) => {
      return getData(apiPaths.predictions.getPayload(activeWorkspace, deployment_id), {
        api_key: api_key,
      });
    },
  },
  preprocessors: {
    getAllPreprocessors: (activeWorkspace, apiKey) => {
      return getData(apiPaths.preprocessors.getAllPreprocessors(activeWorkspace), {
        headers: {
          api_key: apiKey,
        },
      });
    },
    getPreprocessorVersion: (activeWorkspace, preprocessor_id) => {
      return getData(
        apiPaths.preprocessors.getPreprocessorVersion(activeWorkspace, preprocessor_id)
      );
    },
    getPreprocessors: (activeWorkspace, model_id, version_id, api_key) => {
      return getData(
        apiPaths.preprocessors.getPreprocessors(activeWorkspace, model_id, version_id),
        {
          api_key: api_key,
        }
      );
    },
    getPreprocessorSample: (activeWorkspace, preprocessor_id, version_id) => {
      return getData(
        apiPaths.preprocessors.getPreprocessorSample(activeWorkspace, preprocessor_id, version_id)
      );
    },
    checkSignature: (
      activeWorkspace,
      model_id,
      version_id,
      preprocessor_id,
      preprocessor_version,
      api_key
    ) => {
      return postData(
        apiPaths.preprocessors.checkSignature(
          activeWorkspace,
          preprocessor_id,
          preprocessor_version
        ),
        {
          api_key: api_key,
          data: {
            model_id: model_id,
            version_id: version_id,
          },
        }
      );
    },
    linkPreprocessor: (
      activeWorkspace,
      model_id,
      version_id,
      preprocessor_id,
      preprocessor_version_id,
      api_key
    ) => {
      return putData(
        apiPaths.preprocessors.linkPreprocessor(activeWorkspace, model_id, version_id),
        {
          api_key: api_key,
          data: {
            preprocessor_id: preprocessor_id,
            preprocessor_version_id: preprocessor_version_id,
          },
        }
      );
    },
  },
  profileData: {
    getProfileData: (activeWorkspace, model_id, version, api_key) => {
      return getData(apiPaths.profileData.getProfileData(activeWorkspace, model_id, version), {
        api_key: api_key,
      });
    },
  },
  scenario: {
    addScenario: (activeWorkspace, model_id, collection_id, api_key, data) => {
      return putData(apiPaths.scenario.addScenario(activeWorkspace, model_id, collection_id), {
        api_key: api_key,
        data: data,
      });
    },
    getScenario: (activeWorkspace, model_id, collection_id, api_key) => {
      return getData(apiPaths.scenario.getScenario(activeWorkspace, model_id, collection_id), {
        api_key: api_key,
      });
    },
    deleteScenario: (activeWorkspace, modelId, collectionId, scenarioId) => {
      return deleteData(
        apiPaths.scenario.deleteScenario(activeWorkspace, modelId, collectionId, scenarioId)
      );
    },
  },
  collections: {
    getAllCollections: (activeWorkspace, apiKey) => {
      return getData(apiPaths.collections.getAllCollections(activeWorkspace), {
        api_key: apiKey,
      });
    },
    getCollections: (activeWorkspace, model_id) => {
      return getData(apiPaths.collections.getCollections(activeWorkspace, model_id));
    },
    getCollection: (activeWorkspace, model_id, collection_id) => {
      return getData(apiPaths.collections.getCollection(activeWorkspace, model_id, collection_id));
    },
    createCollection: (activeWorkspace, modelId, data) => {
      return putData(apiPaths.collections.createCollection(activeWorkspace, modelId), {
        data,
      });
    },
    deleteCollection: (activeWorkspace, model_id, collection_id) => {
      return deleteData(
        apiPaths.collections.deleteCollection(activeWorkspace, model_id, collection_id)
      );
    },
    updateTitle: (activeWorkspace, model_id, collection_id, data) => {
      return patchData(apiPaths.collections.updateTitle(activeWorkspace, model_id, collection_id), {
        data,
      });
    },
    updateDescription: (activeWorkspace, model_id, collection_id, data) => {
      return patchData(
        apiPaths.collections.updateDescription(activeWorkspace, model_id, collection_id),
        {
          data,
        }
      );
    },
    deleteModelCollections: (activeWorkspace, model_id) => {
      return deleteData(apiPaths.collections.deleteModelCollections(activeWorkspace, model_id));
    },
    updateScenarioNotes: (activeWorkspace, model_id, collection_id, scenario_id, data) => {
      return patchData(
        apiPaths.collections.updateScenarioNotes(
          activeWorkspace,
          model_id,
          collection_id,
          scenario_id
        ),
        {
          data,
        }
      );
    },
  },
  reports: {
    getReports: (activeWorkspace) => {
      return getData(apiPaths.reports.getReports(activeWorkspace));
    },
    getReportsVersion: (activeWorkspace, reportId) => {
      return getData(apiPaths.reports.getReportsVersion(activeWorkspace, reportId));
    },
    createReport: (activeWorkspace, api_key, data) => {
      return putData(apiPaths.reports.createReport(activeWorkspace), {
        api_key,
        data,
      });
    },
    createReportVersion: (activeWorkspace, api_key, reportId, data) => {
      return putData(apiPaths.reports.createReportVersion(activeWorkspace, reportId), {
        api_key,
        data,
      });
    },
    deleteReport: (activeWorkspace, api_key, reportId) => {
      return deleteData(apiPaths.reports.deleteReport(activeWorkspace, reportId), {
        api_key,
      });
    },
    copyReport: (activeWorkspace, api_key, reportId, data) => {
      return postData(apiPaths.reports.copyReport(activeWorkspace, reportId, data), {
        api_key,
        data,
      });
    },
  },
  health: {
    getHealthData: (activeWorkspace, apiKey, modelId, selectedVersion) => {
      return getData(apiPaths.health.getHealthData(activeWorkspace, modelId, selectedVersion), {
        apiKey,
      });
    },
  },
  predict: {
    postPredict: (apiKey, data) => {
      return postData(apiPaths.predict.postPredict(), {
        apiKey,
        data,
        header: `multipart/form-data`,
      });
    },
  },
  subscriptions: {
    getSubscription: (activeWorkspace, api_key) => {
      return getData(apiPaths.subscription.getSubscription(activeWorkspace));
    },
    getSubscriptionSeats: (activeWorkspace) => {
      return getData(apiPaths.subscription.getSubscriptionSeats(activeWorkspace));
    },
    updateSubscriptionSeats: (activeWorkspace, data) => {
      return putData(apiPaths.subscription.updateSubscriptionSeats(activeWorkspace), {
        data,
      });
    },
    createSubscription: (activeWorkspace) => {
      return postData(apiPaths.subscription.createSubscription(activeWorkspace));
    },
    cancelSubscription: (activeWorkspace) => {
      return postData(apiPaths.subscription.cancelSubscription(activeWorkspace));
    },
    resumeSubscription: (activeWorkspace) => {
      return postData(apiPaths.subscription.resumeSubscription(activeWorkspace));
    },
  },
  commentary: {
    getCommentary: (activeWorkspace, id, version) => {
      return getData(apiPaths.commentary.getCommentary(activeWorkspace, id, version));
    },

    autoGenerateReport: (activeWorkspace, id, version, data) => {
      return putData(apiPaths.commentary.autoGenerateReport(activeWorkspace, id, version), {
        data,
      });
    },

    changeCommentary: (activeWorkspace, id, version, data) => {
      return patchData(apiPaths.commentary.changeCommentary(activeWorkspace, id, version), {
        data,
      });
    },

    handlePublishCommentary: (activeWorkspace, id, version, action) => {
      return patchData(
        apiPaths.commentary.handlePublishCommentary(activeWorkspace, id, version, action)
      );
    },
  },
  users: {
    getUsers: (activeWorkspace) => {
      return getData(apiPaths.users.getUsers(activeWorkspace));
    },
    getTeamUsers: (organisationId) => {
      return getData(apiPaths.users.getTeamUsers(organisationId));
    },
    searchUser: (organisationId, input) => {
      return getData(apiPaths.users.searchUser(organisationId, input));
    },
  },
  binaryPerformance: {
    getData: (activeWorkspace, model_id, version_id, partition_id) => {
      return getData(
        apiPaths.binaryPerformance.getData(activeWorkspace, model_id, version_id, partition_id)
      );
    },
  },
  pipeLine: {
    getData: (activeWorkspace, preprocessor_id, preprocessor_version, signal) => {
      return getData(
        apiPaths.pipeLine.getData(activeWorkspace, preprocessor_id, preprocessor_version),
        {
          signal,
        }
      );
    },
  },
  regressionPerformance: {
    getData: (activeWorkspace, model_id, version_id, partition_id) => {
      return getData(
        apiPaths.regressionPerformance.getData(activeWorkspace, model_id, version_id, partition_id)
      );
    },
  },
  organisations: {
    getData: () => {
      return getData(apiPaths.organisation.getData());
    },
    createOrganisation: (data) => {
      return postData(apiPaths.organisation.createOrganisation(), {
        data,
      });
    },

    deleteOrganisation: (organisation_id) => {
      return deleteData(apiPaths.organisation.deleteOrganisation(organisation_id));
    },
    getOrganisationMetadata: () => {
      return getData(apiPaths.organisation.getOrganisationMetadata());
    },
    manageOrganisation: () => {
      return getData(apiPaths.organisation.manageOrganisation());
    },
  },
  teams: {
    getTeams: (organisationId, apiKey) => {
      return getData(apiPaths.teams.getTeams(organisationId), {
        api_key: apiKey,
      });
    },
    createTeam: (organisationData, data) => {
      return putData(apiPaths.teams.createTeam(organisationData), {
        data,
      });
    },
    deleteTeam: (organisationData, team_id) => {
      return deleteData(apiPaths.teams.deleteTeam(organisationData, team_id));
    },
  },
  apiKeys: {
    getData: (activeWorkspace) => {
      return getData(apiPaths.apiKeys.getData(activeWorkspace));
    },
    createKey: (activeWorkspace, data) => {
      return putData(apiPaths.apiKeys.createKey(activeWorkspace), {
        data,
      });
    },
    revokeAllKeys: () => {
      return postData(apiPaths.apiKeys.revokeAllKeys());
    },
  },
  batchOptimisation: {
    getData: (activeWorkspace) => {
      return getData(apiPaths.batchOptimisation.getData(activeWorkspace));
    },
    getOptimisersVersions: (activeWorkspace, optimiser_id) => {
      return getData(
        apiPaths.batchOptimisation.getOptimisersVersions(activeWorkspace, optimiser_id)
      );
    },
    getOptimiserVersion: (activeWorkspace, optimiser_id, version_id) => {
      return getData(
        apiPaths.batchOptimisation.getOptimiserVersion(activeWorkspace, optimiser_id, version_id)
      );
    },
    createOptimiserVersion: (activeWorkspace, optimiser_id, data) => {
      console.log(optimiser_id);
      return putData(
        apiPaths.batchOptimisation.createOptimiserVersion(activeWorkspace, optimiser_id),
        {
          data,
        }
      );
    },
    deleteOptimiser: (activeWorkspace, optimiser_id) => {
      return deleteData(apiPaths.batchOptimisation.deleteOptimiser(activeWorkspace, optimiser_id));
    },
    createOptimiser: (activeWorkspace, data) => {
      return putData(apiPaths.batchOptimisation.createOptimiser(activeWorkspace), {
        data,
      });
    },
    deleteOptimiserVersion: (activeWorkspace, optimiser_id, version_id) => {
      return deleteData(
        apiPaths.batchOptimisation.deleteOptimiserVersion(activeWorkspace, optimiser_id, version_id)
      );
    },
    resetOptimiserVersion: (activeWorkspace, optimiser_id, version_id) => {
      return patchData(
        apiPaths.batchOptimisation.resetOptimiserVersion(activeWorkspace, optimiser_id, version_id)
      );
    },
    updateOptimiserVersion: (activeWorkspace, optimiser_id, version_id, data) => {
      return patchData(
        apiPaths.batchOptimisation.updateOptimiserVersion(
          activeWorkspace,
          optimiser_id,
          version_id
        ),
        {
          data,
        }
      );
    },
    createBatches: (activeWorkspace, optimiser_id, version_id, data) => {
      return putData(
        apiPaths.batchOptimisation.createBatches(activeWorkspace, optimiser_id, version_id),
        {
          data,
        }
      );
    },
    getBatch: (activeWorkspace, optimiser_id, version_id, batch_id) => {
      return getData(
        apiPaths.batchOptimisation.getBatch(activeWorkspace, optimiser_id, version_id, batch_id)
      );
    },
    downloadBatch: (activeWorkspace, optimiser_id, version_id, batch_id) => {
      return getData(
        apiPaths.batchOptimisation.downloadBatch(
          activeWorkspace,
          optimiser_id,
          version_id,
          batch_id
        ),
        {
          responseType: "blob",
        }
      );
    },
    getRunBatches: (activeWorkspace, optimiser_id, version_id, run_id) => {
      return getData(
        apiPaths.batchOptimisation.getRunBatches(activeWorkspace, optimiser_id, version_id, run_id)
      );
    },
  },
  dataset: {
    getData: (activeWorkspace) => {
      return getData(apiPaths.dataset.getData(activeWorkspace));
    },
    getItemData: (activeWorkspace, dataset_id) => {
      return getData(apiPaths.dataset.getItemData(activeWorkspace, dataset_id));
    },
    getPreview: (activeWorkspace, dataset_id) => {
      return getData(apiPaths.dataset.getPreview(activeWorkspace, dataset_id));
    },
    createDataset: (activeWorkspace, data) => {
      return putData(apiPaths.dataset.createDataset(activeWorkspace), {
        data,
        header: `multipart/form-data`,
      });
    },
    deleteDataset: (activeWorkspace, dataset_id) => {
      return deleteData(apiPaths.dataset.deleteDataset(activeWorkspace, dataset_id));
    },
    updateDataSet: (activeWorkspace, dataset_id, data) => {
      return patchData(apiPaths.dataset.updateDataSet(activeWorkspace, dataset_id), {
        data,
        header: `multipart/form-data`,
      });
    },
    downloadDataset: (activeWorkspace, dataset_id) => {
      return getData(apiPaths.dataset.downloadDataset(activeWorkspace, dataset_id));
    },
  },
  runs: {
    createRun: (activeWorkspace, optimiser_id, version_id, data) => {
      return putData(apiPaths.runs.createRun(activeWorkspace, optimiser_id, version_id), {
        data,
      });
    },
    getData: (activeWorkspace, optimiser_id, version_id) => {
      return getData(apiPaths.runs.getData(activeWorkspace, optimiser_id, version_id));
    },
    getRunData: (activeWorkspace, optimiser_id, version_id, run_id) => {
      return getData(apiPaths.runs.getRunData(activeWorkspace, optimiser_id, version_id, run_id));
    },
  },
  output: {
    enableExplain: (activeWorkspace, deployment_id) => {
      return patchData(apiPaths.output.enableExplain(activeWorkspace, deployment_id));
    },
    disableExplain: (activeWorkspace, deployment_id) => {
      return patchData(apiPaths.output.disableExplain(activeWorkspace, deployment_id));
    },
  },
  partitions: {
    getPartitions: (activeWorkspace, model_id, version_id) => {
      return getData(apiPaths.partitions.getPartitions(activeWorkspace, model_id, version_id));
    },
  },
  preferences: {
    updatePreferences: (data) => {
      return patchData(apiPaths.preferences.updatePreferences(), {
        data,
      });
    },
  },
  deployKeys: {
    getDeployKeys: (activeWorkspace, deploymentId, apiKey) => {
      return getData(apiPaths.deployKeys.getDeployKeys(activeWorkspace, deploymentId), {
        api_key: apiKey,
      });
    },
    createDeployKey: (activeWorkspace, deploymentId, apiKey, data) => {
      return putData(apiPaths.deployKeys.createDeployKey(activeWorkspace, deploymentId), {
        api_key: apiKey,
        data,
      });
    },
    deleteDeployKey: (activeWorkspace, deploymentId, apiKey, keyId) => {
      return deleteData(apiPaths.deployKeys.deleteDeployKey(activeWorkspace, deploymentId, keyId), {
        api_key: apiKey,
      });
    },
    revokeAllDeployKeys: (activeWorkspace, deploymentId) => {
      return patchData(apiPaths.deployKeys.revokeAllDeployKeys(activeWorkspace, deploymentId));
    },
  },
  network: {
    createIpAddress: (activeWorkspace, deploymentId, data) => {
      return putData(apiPaths.network.createIpAddress(activeWorkspace, deploymentId), {
        data,
      });
    },
    handleToggleChange: (activeWorkspace, deployment_id, action) => {
      return patchData(apiPaths.network.handleToggleChange(activeWorkspace, deployment_id, action));
    },
    getFirewalls: (activeWorkspace, deploymentId) => {
      return getData(apiPaths.network.getFirewalls(activeWorkspace, deploymentId));
    },
    removeIpAddress: (activeWorkspace, deploymentId, sourceToRemove) => {
      return deleteData(
        apiPaths.network.removeIpAddress(activeWorkspace, deploymentId, sourceToRemove)
      );
    },
  },
  calendar: {
    getCalendarData: (apiKey) => {
      return getData(apiPaths.calendar.getCalendarData(), {
        api_key: apiKey,
      });
    },
  },
  recent: {
    getRecentData: (activeWorkspace, apiKey) => {
      return getData(apiPaths.recent.getRecentData(activeWorkspace), {
        api_key: apiKey,
      });
    },
  },
  accountSettings: {
    updateProfileImage: (data, apiKey) => {
      return patchData(apiPaths.accountSettings.updateProfileImage(), {
        data,
        headers: {
          api_key: apiKey,
        },
      });
    },
    updateDetails: (data, apiKey) => {
      return patchData(apiPaths.accountSettings.updateDetails(), {
        data,
        headers: {
          api_key: apiKey,
          "Content-Type": "application/json",
        },
      });
    },
  },
  configuration: {
    saveConfiguration: (activeWorkspace, model_id, selectedVersion, data) => {
      return patchData(
        apiPaths.configuration.saveConfiguration(activeWorkspace, model_id, selectedVersion),
        {
          data,
        }
      );
    },
  },
  login: {
    login: (token) => {
      return getData(apiPaths.login.login(), {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
    },
    limits: (activeWorkspace) => {
      return getData(apiPaths.login.limits(activeWorkspace));
    },
  },
  train: {
    dataPrep: (activeWorkspace, data) => {
      return postData(apiPaths.train.dataPrep(activeWorkspace), {
        data,
      });
    },
    summarize: (activeWorkspace, data) => {
      return postData(apiPaths.train.summarize(activeWorkspace), {
        data,
        header: `multipart/form-data`,
      });
    },
    summarizeUrl: (activeWorkspace, data) => {
      return postData(apiPaths.train.summarizeUrl(activeWorkspace), {
        data,
      });
    },
    goal: (activeWorkspace, data) => {
      return postData(apiPaths.train.goal(activeWorkspace), {
        data,
      });
    },
    autoTrain: (activeWorkspace, data) => {
      return postData(apiPaths.train.autoTrain(activeWorkspace), {
        data,
      });
    },
    applyStep: (activeWorkspace, data) => {
      return postData(apiPaths.train.applyStep(activeWorkspace), {
        data,
      });
    },
    downloadDataprep: (activeWorkspace, data) => {
      return postData(apiPaths.train.downloadDataprep(activeWorkspace), {
        data,
      });
    },
    dropDataprep: (activeWorkspace, data) => {
      return postData(apiPaths.train.dropDataprep(activeWorkspace), {
        data,
      });
    },
    train: (activeWorkspace, data) => {
      return postData(apiPaths.train.train(activeWorkspace), {
        data,
      });
    },
    featureEngineering: (activeWorkspace, data) => {
      return postData(apiPaths.train.featureEngineering(activeWorkspace), {
        data,
      });
    },
    insights: (activeWorkspace, data) => {
      return postData(apiPaths.train.insights(activeWorkspace), {
        data,
      });
    },
    visualize: (activeWorkspace, data) => {
      return postData(apiPaths.train.visualize(activeWorkspace), {
        data,
      });
    },
    visualizeEdit: (activeWorkspace, data) => {
      return postData(apiPaths.train.visualizeEdit(activeWorkspace), {
        data,
      });
    },
    visualizeRepair: (activeWorkspace, data) => {
      return postData(apiPaths.train.visualizeRepair(activeWorkspace), {
        data,
      });
    },
    visualizeExplain: (activeWorkspace, data) => {
      return postData(apiPaths.train.visualizeExplain(activeWorkspace), {
        data,
      });
    },
    visualizeEvaluate: (activeWorkspace, data) => {
      return postData(apiPaths.train.visualizeEvaluate(activeWorkspace), {
        data,
      });
    },
    visualizeRecommend: (activeWorkspace, data) => {
      return postData(apiPaths.train.visualizeRecommend(activeWorkspace), {
        data,
      });
    },
    textGenerate: (activeWorkspace, data) => {
      return postData(apiPaths.train.textGenerate(activeWorkspace), {
        data,
      });
    },
    infographer: (activeWorkspace, data) => {
      return postData(apiPaths.train.infographer(activeWorkspace), {
        data,
      });
    },
    getModels: () => {
      return getData(apiPaths.train.getModels());
    },
    getApiKey: (modelProvider) => {
      return getData(apiPaths.train.getApiKey(modelProvider));
    },
    setApiKey: (data) => {
      return patchData(apiPaths.train.setApiKey(),{
        data,
      });;
    },
  },
};
