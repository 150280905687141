import React, { useState, useRef } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import { useApiKey } from "components/Authorisation/ApiKeyContext";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XButton from "components/XButton";
import XAvatar from "components/XAvatar";
import XInput from "components/XInput";
import { Icon } from "@mui/material";

// Update user components information
import FormField from "shared/Forms/FormField";
import { useAccountSettingMutation } from "api/mutations/useAccountSettingMutation";

const hostUrl = process.env.REACT_APP_HOST_URL;

function UserInfo({ formData }) {
  const { apiKey, user, setUser } = useApiKey();
  const { updateProfileImageMutation, updateDetailsMutation } = useAccountSettingMutation();

  const [avatar, setAvatar] = useState(null);
  const [editingField, setEditingField] = useState(null);
  const [allFieldsEditing, setAllFieldsEditing] = useState(false);
  const { formField, values, errors, touched } = formData;
  const { userName, firstName, lastName, city, country, position, email, bio } = formField;
  const {
    userName: userNameV,
    firstName: firstNameV,
    lastName: lastNameV,
    city: cityV,
    country: countryV,
    position: positionV,
    email: emailV,
    bio: bioV,
  } = values;

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2097152) {
        alert("The selected file is too large. Please select a file less than 2MB.");
        return;
      }

      const formData = new FormData();
      formData.append("image", file);

      updateProfileImageMutation([formData, apiKey], {
        onSuccess: (data) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            setAvatar(reader.result);
          };
          reader.readAsDataURL(file);
        },
        onError: (error) => {
          alert(`Failed to update profile image: ${error.message}`);
        },
      });
    }
  };

  // Create a ref for the file input
  const fileInputRef = useRef(null);

  const handleEditClick = (fieldName) => {
    console.log("The field name is: ", fieldName);
    if (fieldName === "profilePicture") {
      // Trigger file input click for profile picture
      fileInputRef.current.click();
      return;
    }

    if (editingField === fieldName) {
      setEditingField(null);
    } else {
      setEditingField(fieldName);
    }
  };

  const handleEditFieldsClick = () => {
    if (allFieldsEditing) {
      handleSubmit();
    }
    setAllFieldsEditing(!allFieldsEditing); // Toggle allFieldsEditing state
  };

  // Separate function to handle the form submission.
  const handleSubmit = () => {
    const updatedFields = {};
    for (let key in values) {
      if (values[key] !== formField[key].value) {
        updatedFields[key] = values[key];
      }
    }

    handleFieldUpdate(updatedFields);
  };

  const handleFieldUpdate = async (updatedFields) => {
    console.log("The updated fields are: ", updatedFields);

    // Mapping between source fields and target fields
    const fieldMapping = {
      userName: "username",
      firstName: "given_name",
      lastName: "family_name",
      position: "position",
      city: "city",
      country: "country",
      bio: "bio",
    };

    // Create new object with desired structure
    const mappedFields = {};
    const updatedUser = user;
    for (let key in updatedFields) {
      if (fieldMapping[key]) {
        mappedFields[fieldMapping[key]] = updatedFields[key];
        updatedUser[fieldMapping[key]] = updatedFields[key];
      }
    }

    updateDetailsMutation([JSON.stringify(mappedFields), apiKey], {
      onSuccess: () => {
        setEditingField(null);
        setAllFieldsEditing(false);
        setUser(updatedUser);
      },
      onError: (error) => {
        alert(`Failed to update user details: ${error.message}`);
      },
    });
  };

  return (
    <XBox p={5}>
      <XBox
        width={"100%"}
        lineHeight={0}
        display="flex"
        height={"100%"}
        flexDirection="column"
        p={2}
        justifyContent="start"
        alignItems="start"
      >
        <XTypography variant="button" fontSize="18px">
          About me
        </XTypography>
        <XTypography variant="button" fontWeight="regular" color="text">
          {"Update your user information"}
        </XTypography>
      </XBox>
      <XBox display="flex" gap={4}>
        <XBox
          display="flex"
          height="100%"
          flexDirection="column"
          justifyContent="start"
          alignItems="center"
        >
          <XBox position="relative" height="max-content" mx="auto">
            <XAvatar
              src={avatar || user.image}
              alt="profile picture"
              size="xxl"
              variant="rounded"
            />
            <XBox position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
              <input
                type="file"
                id="avatarInput"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleFileChange}
              />
              <label htmlFor="avatarInput">
                {allFieldsEditing && (
                  <XButton
                    variant="gradient"
                    color="light"
                    size="small"
                    iconOnly
                    component="span"
                    sx={{
                      animation: "shake 2s linear infinite",
                    }}
                    onClick={() => handleEditClick("profilePicture")}
                  >
                    <Icon>edit</Icon>
                  </XButton>
                )}
              </label>
            </XBox>
          </XBox>
        </XBox>
        <XBox display="flex" flexDirection="column" width="100%">
          <XBox display="flex" width="100%" gap="40px">
            <XBox position="relative" height="max-content" width="100%">
              <FormField
                as={"input"}
                type={userName.type}
                label={userName.label}
                name={userName.name}
                value={userNameV}
                placeholder={userName.placeholder}
                error={errors.userName && touched.userName}
                success={userNameV.length > 0 && !errors.userName && editingField === userName.name}
                // onChange={e => handleFieldUpdate(userName.name, e.target.value)} // Update the field when its value changes
                disabled={editingField !== userName.name}
              />

              <XBox position="absolute" right={0} top={0} mr={-1} mt={2}>
                {allFieldsEditing && (
                  <XButton
                    variant="gradient"
                    color="light"
                    size="small"
                    iconOnly
                    component="span"
                    onClick={() => handleEditClick(userName.name)}
                    sx={{
                      animation: "shake 2s linear infinite",
                    }}
                  >
                    <Icon>edit</Icon>
                  </XButton>
                )}
              </XBox>
            </XBox>
            <XBox position="relative" height="max-content" width="100%">
              <FormField
                as={"input"}
                type={firstName.type}
                label="Full Name"
                name={firstName.name}
                value={firstNameV}
                placeholder={firstName.placeholder}
                error={errors.firstName && touched.firstName}
                success={
                  firstNameV.length > 0 && !errors.firstName && editingField === firstName.name
                }
                disabled={editingField !== firstName.name}
              />
              <XBox position="absolute" right={0} top={0} mr={-1} mt={2}>
                {allFieldsEditing && (
                  <XButton
                    variant="gradient"
                    color="light"
                    size="small"
                    iconOnly
                    component="span"
                    onClick={() => handleEditClick(firstName.name)}
                    sx={{
                      animation: "shake 2s linear infinite",
                    }}
                  >
                    <Icon>edit</Icon>
                  </XButton>
                )}
              </XBox>
            </XBox>
            {/* <XBox position="relative" height="max-content" mx="auto">
              <FormField
                as={"input"}
                type={lastName.type}
                label={lastName.label}
                name={lastName.name}
                value={lastNameV}
                placeholder={lastName.placeholder}
                error={errors.lastName && touched.lastName}
                success={lastNameV.length > 0 && !errors.lastName && editingField === lastName.name}
                disabled={editingField !== lastName.name}
              />
              <XBox position="absolute" right={0} top={0} mr={-1} mt={2}>
                {allFieldsEditing && (
                  <XButton
                    variant="gradient"
                    color="light"
                    size="small"
                    iconOnly
                    component="span"
                    onClick={() => handleEditClick(lastName.name)}
                    sx={{
                      animation: "shake 2s linear infinite",
                    }}
                  >
                    <Icon>edit</Icon>
                  </XButton>
                )}
              </XBox>
            </XBox> */}
          </XBox>
          <XBox display="flex" width="100%" gap="40px">
            <XBox position="relative" height="max-content" width="100%">
              <FormField
                as={"input"}
                type={city.type}
                label={city.label}
                name={city.name}
                value={cityV}
                placeholder={city.placeholder}
                error={errors.city && touched.city}
                success={cityV.length > 0 && !errors.city && editingField === city.name}
                disabled={editingField !== city.name}
              />
              <XBox position="absolute" right={0} top={0} mr={-1} mt={2}>
                {allFieldsEditing && (
                  <XButton
                    variant="gradient"
                    color="light"
                    size="small"
                    iconOnly
                    component="span"
                    onClick={() => handleEditClick(city.name)}
                    sx={{
                      animation: "shake 2s linear infinite",
                    }}
                  >
                    <Icon>edit</Icon>
                  </XButton>
                )}
              </XBox>
            </XBox>
            <XBox position="relative" height="max-content" width="100%">
              <FormField
                as={"input"}
                type={country.type}
                label={country.label}
                name={country.name}
                value={countryV}
                placeholder={country.placeholder}
                error={errors.country && touched.country}
                success={countryV.length > 0 && !errors.country && editingField === country.name}
                disabled={editingField !== country.name}
              />
              <XBox position="absolute" right={0} top={0} mr={-1} mt={2}>
                {allFieldsEditing && (
                  <XButton
                    variant="gradient"
                    color="light"
                    size="small"
                    iconOnly
                    component="span"
                    onClick={() => handleEditClick(country.name)}
                    sx={{
                      animation: "shake 2s linear infinite",
                    }}
                  >
                    <Icon>edit</Icon>
                  </XButton>
                )}
              </XBox>
            </XBox>
          </XBox>
          <XBox display="flex" width="100%" gap="40px">
            <XBox width="100%">
              <XBox position="relative" height="max-content" width="100%">
                <FormField
                  as={"input"}
                  type={position.type}
                  label={position.label}
                  name={position.name}
                  value={positionV}
                  placeholder={position.placeholder}
                  error={errors.position && touched.position}
                  success={
                    positionV.length > 0 && !errors.position && editingField === position.name
                  }
                  disabled={editingField !== position.name}
                />
                <XBox position="absolute" right={0} top={0} mr={-1} mt={2}>
                  {allFieldsEditing && (
                    <XButton
                      variant="gradient"
                      color="light"
                      size="small"
                      iconOnly
                      component="span"
                      onClick={() => handleEditClick(position.name)}
                      sx={{
                        animation: "shake 2s linear infinite",
                      }}
                    >
                      <Icon>edit</Icon>
                    </XButton>
                  )}
                </XBox>
              </XBox>
            </XBox>
            <XBox width="100%">
              <XBox mb={1.5} width="100%">
                <XBox mb={1} ml={0.5} lineHeight={0} display="inline-block" width="100%">
                  <XTypography
                    component="label"
                    variant="caption"
                    fontWeight="regular"
                    textTransform="capitalize"
                  >
                    {email.name}
                  </XTypography>
                </XBox>
                <XInput value={emailV} disabled={true} width="100%" />
              </XBox>
            </XBox>
          </XBox>
          <XBox width="100%">
            <XBox width="100%">
              <XBox mb={1.5} width="100%">
                <XBox mb={1} ml={0.5} lineHeight={0} display="inline-block" width="100%">
                  <XTypography
                    component="label"
                    variant="caption"
                    fontWeight="regular"
                    textTransform="capitalize"
                  >
                    Bio
                  </XTypography>
                </XBox>
                <XBox position="relative" height="max-content" mx="auto">
                  {/* TODO update this component so formfield accepts props */}
                  <XInput
                    placeholder="Please enter a Bio..."
                    multiline
                    rows={5}
                    disabled={editingField !== bio.name}
                  />
                  <XBox position="absolute" right={0} top={0} mr={-1} mt={-2}>
                    {allFieldsEditing && (
                      <XButton
                        variant="gradient"
                        color="light"
                        size="small"
                        iconOnly
                        component="span"
                        onClick={() => handleEditClick(bio.name)}
                        sx={{
                          animation: "shake 2s linear infinite",
                        }}
                      >
                        <Icon>edit</Icon>
                      </XButton>
                    )}
                  </XBox>
                </XBox>
              </XBox>
            </XBox>
          </XBox>
        </XBox>
      </XBox>
      <XBox sx={{ mt: 1, display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <XButton color="button" variant="gradient" px={4} onClick={handleEditFieldsClick}>
          {allFieldsEditing ? "Update All" : "Edit Fields"}
        </XButton>
      </XBox>
    </XBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;
