import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useReportsVersionQuery = (activeWorkspace, reportId, logout, activeStep) => {
  return useQuery(
    [QUERY_CONSTANTS.REPORT_VERSION, activeWorkspace, reportId],
    () => apiHandlers.reports.getReportsVersion(activeWorkspace, reportId),
    {
      enabled: !!activeStep,
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
