import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useDatasetQuery = (activeWorkspace, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.DATASET, activeWorkspace],
    () => apiHandlers.dataset.getData(activeWorkspace),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};

export const useDatasetItemQuery = (activeWorkspace, logout, dataset_id) => {
  return useQuery(
    [QUERY_CONSTANTS.DATASET, activeWorkspace, dataset_id],
    () => apiHandlers.dataset.getItemData(activeWorkspace, dataset_id),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
