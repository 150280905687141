// usePreprocessorQuery.js
import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const usePreprocessorQuery = (
  activeWorkspace,
  model_id,
  version_id,
  api_key,
  logout,
  updatePreprocessorData,
  setPreprocessorData,
  setAddingPreprocessor
) => {
  return useQuery(
    [activeWorkspace?.organisation_id, activeWorkspace?.team_id, QUERY_CONSTANTS.PREPROCESSORS],
    () =>
      apiHandlers.preprocessors.getPreprocessors(activeWorkspace, model_id, version_id, api_key),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
      onSuccess: (data) => {
        if (data.data) {
          updatePreprocessorData({
            id: data.data.preprocessor_id,
            version: data.data.preprocessor_version_id,
          });
          console.log("The fetched Preprocessor is", data);

          // Add your new lines here
          setPreprocessorData({
            preprocessor_name: data.data.preprocessor_name,
            selected_version: data.data.version_number,
            given_name: data.data.created_by.given_name,
            family_name: data.data.created_by.family_name,
            user: {
              image: data.data.created_by.image,
              given_name: data.data.created_by.given_name,
              family_name: data.data.created_by.family_name,
            },
            created: data.data.created,
            preprocessor_description: data.data.preprocessor_description,
            position: data.data.created_by.position,
          }); // Assuming linkPreprocessor contains the latest data
          setAddingPreprocessor(false);
        }
      },
    }
  );
};
