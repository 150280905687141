import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useApiKey } from 'components/Authorisation/ApiKeyContext';
import { useNavigate } from 'react-router-dom';
import { useAdmin, useApp } from 'hooks';
import XBox from 'components/XBox';
import XTypography from 'components/XTypography';
import XButton from 'components/XButton';
import ProgressLineChart from 'shared/Charts/ProgressLineChart';
import MiniStatisticsCard from 'shared/Cards/MiniStatisticsCard';
import OrganisationCreationModal from 'shared/Modals/OrganisationModal';
import { useOrganisationCreation } from 'hooks/useOrganisationCreation';
import { OrganisationTable } from './components/OrgTable';

function Admin() {
  const { activeWorkspace, viewState, workspaces } = useApiKey();
  const { setOrganisation, access } = useAdmin();
  const { setActiveTitle } = useApp();
  const { isModalOpen, handleOpen, handleClose, handleSubmit } = useOrganisationCreation();
  const navigate = useNavigate();
  const [tableRows, setTableRows] = useState([]);

  const handleOrgClick = async (rowData) => {
    const orgData = rowData.original;
    const organisation_id = orgData.organisation_id;

    setOrganisation(orgData);
    setActiveTitle(orgData.organisation_name);
    navigate(`/admin/${organisation_id}`);
  };

  return (
    <>
      <OrganisationCreationModal isOpen={isModalOpen} onClose={handleClose} onSubmit={handleSubmit} />
      <Grid container spacing={3} mt={3} mb={3}>
        <Grid item xs={12} lg={9}>
          <XBox>
            <ProgressLineChart
              color={"xpblue"}
              bgColor="dark"
              icon="groups"
              title="Total Users"
              height={"200px"}
              event={["new_login"]}
              organisation_id={activeWorkspace?.organisation_id}
            />
          </XBox>
        </Grid>
        <Grid item xs={12} lg={3} display="flex">
          <XBox display="flex" flexDirection="column" gap={4} width="100%">
            <MiniStatisticsCard
              title={{ text: "Organisations", fontWeight: "medium" }}
              count={workspaces?.length}
              color={"xpblue"}
            />
            <MiniStatisticsCard
              title={{ text: "Teams", fontWeight: "medium" }}
              count={workspaces?.reduce((total, org) => total + org.teams.length, 0)}
              color={"xpblue"}
            />
          </XBox>
        </Grid>
      </Grid>
      <>
        <XBox my={5}>
          <Grid container>
            <Grid item xs={12}>
              <XBox display="flex" justifyContent="space-between" alignItems="center">
                <XTypography variant="button" fontSize="18px">
                  Organisations
                </XTypography>
                {viewState === "creator" && (
                  <XButton variant="gradient" color="button" onClick={handleOpen}>
                    Create New Organisation
                  </XButton>
                )}
              </XBox>
            </Grid>
            <Grid item xs={12}>
              <XBox pt={3}>
                <OrganisationTable
                  handleRowClick={handleOrgClick}
                  setTableRows={setTableRows}
                  tableRows={tableRows}
                />
              </XBox>
            </Grid>
          </Grid>
        </XBox>
      </>
    </>
  );
}

export default Admin;
