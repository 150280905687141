import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "api/apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useCollectionsQuery = (activeWorkspace, model_id, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.COLLECTIONS, activeWorkspace, model_id],
    () => apiHandlers.collections.getCollections(activeWorkspace, model_id),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
