import React, { useEffect, useState, useRef } from "react";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useXplainableController } from "context";
import PropTypes from "prop-types";

import Tooltip from "@mui/material/Tooltip";
import { Card } from "@mui/material";
import colors from "assets/theme/base/colors";

import LoadingSpinner from "shared/Animations/LoadingAnimation";
import XBox from "components/XBox";
import XTypography from "components/XTypography";

import * as d3 from "d3";
import { XDivider } from "components/XDivider";
import { useCalendarQuery } from "api/query";
import { useAuth0 } from "@auth0/auth0-react";

import "./style.css";

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const daysOfWeek = ["M", "T", "W", "T", "F", "S", "S"];

const LoginCalendar = () => {
  const { apiKey } = useApiKey();
  const { logout } = useAuth0();

  const [controller, dispatch] = useXplainableController();
  const { darkMode } = controller;
  const { data: calendarData } = useCalendarQuery(apiKey, logout);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const maxCount = Math.max(...data.map((item) => item.count));
  const startingColor = darkMode ? "#ba8d98" : "#ffd4de";

  const colorScale = d3.scaleLinear().domain([0, maxCount]).range([startingColor, "#DE4188"]);

  const step = maxCount / 4;

  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  const displayYear = oneYearAgo.getFullYear() + 1;

  const getColor = (count) => {
    if (count === 0) return darkMode ? colors.dark.main : "#ebedf0"; // Return black if darkMode is true and count is 0
    if (count <= step) return colorScale(count / 4);
    if (count <= 2 * step) return colorScale(count / 3);
    if (count <= 3 * step) return colorScale(count / 2);
    return "#DE4188"; // For everything greater than 3*step
  };

  const adjustDayOfWeek = (date) => {
    const day = new Date(date).getDay();
    return day === 0 ? 6 : day - 1; // Adjust Sunday to end, and shift other days back
  };

  const getStructuredData = (data) => {
    if (!data.length)
      return Array(7)
        .fill([])
        .map(() => []);

    let structuredData = Array(7)
      .fill([])
      .map(() => []);
    const startDay = adjustDayOfWeek(data[0].date);

    // Populate the starting empty days.
    for (let i = 0; i < startDay; i++) {
      structuredData[i].push(null);
    }

    data.forEach((dayData) => {
      const dayOfWeek = adjustDayOfWeek(dayData.date);
      structuredData[dayOfWeek].push(dayData);
    });

    return structuredData;
  };

  const getLast12Months = () => {
    const currentMonth = new Date().getMonth();
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const last12Months = [];

    for (let i = 0; i < 12; i++) {
      last12Months.unshift(months[(currentMonth - i + 12) % 12]); // unshift adds the element to the start of the array
    }

    last12Months.unshift(months[currentMonth]);

    return last12Months;
  };

  const getColorBox = (stepIndex) => {
    return getColor(step * stepIndex);
  };

  function splitByMonth(data) {
    const result = {};
    data.forEach((item) => {
      const year = new Date(item.date).getFullYear().toString();
      const month = new Date(item.date).toLocaleString("default", { month: "long" });
      if (!result[year]) {
        result[year] = {};
      }
      if (!result[year][month]) {
        result[year][month] = [item];
      } else {
        result[year][month].push(item);
      }
    });

    const sortedYears = Object.keys(result).sort((a, b) => parseInt(a) - parseInt(b));
    const sortedResult = {};
    sortedYears.forEach((year) => {
      sortedResult[year] = result[year];
    });

    return sortedResult;
  }

  const splitData = splitByMonth(data);

  const structuredData = getStructuredData(data);
  const monthHeaders = getLast12Months();
  const totalLogins = data.reduce((sum, day) => sum + day.count, 0);

  const renderMonth = (monthData, monthName, year) => {
    const currentDate = new Date();
    const monthIndex = currentDate.getMonth();
    const monthNameNow = currentDate.toLocaleString("default", { month: "long" });
    const daysInMonth = new Date(currentDate.getFullYear(), monthIndex + 1, 0).getDate();

    const currentYear = currentDate.getFullYear();

    const newMonthData = [...monthData];

    const monthRows = [];
    const weeks =
      monthNameNow === monthName && currentYear === +year
        ? Math.ceil(daysInMonth / 7)
        : Math.ceil(monthData.length / 5);

    if (monthNameNow === monthName && currentYear === +year) {
      const currentDateNow = new Date();
      const lastDay = new Date(
        currentDateNow.getFullYear(),
        currentDateNow.getMonth() + 1,
        0
      ).getDate();

      const lastDate = new Date(monthData[monthData.length - 1].date);
      let currentDate = new Date(lastDate);

      for (let i = 0; i < lastDay - monthData.length; i++) {
        currentDate.setDate(currentDate.getDate() + 1);
        newMonthData.push({ date: currentDate.toISOString(), count: 0 });
      }
    }

    let currentColumnDays = [];

    for (let i = 0; i < weeks; i++) {
      const weekStartIndex = i * 7;
      const weekEndIndex = Math.min((i + 1) * 7, newMonthData.length);
      const weekDays = newMonthData.slice(weekStartIndex, weekEndIndex);

      if (i === 0) {
        const firstDayOfMonth = new Date(weekDays[0].date);
        const emptyCellsCount = (firstDayOfMonth.getDay() + 6) % 7;
        for (let j = 0; j < emptyCellsCount; j++) {
          currentColumnDays.push(
            <div key={`empty_${j}`} style={{ margin: "0.09rem" }}>
              <div
                style={{
                  width: "15px",
                  height: "15px",
                  borderRadius: "1px",
                  backgroundColor: "transparent",
                }}
              ></div>
            </div>
          );
        }
      }

      weekDays.forEach((day, dayIndex) => {
        const dateObject = new Date(day.date);
        const formattedDate = `${dateObject.getDate()}${ordinalSuffixOf(dateObject.getDate())} ${
          months[dateObject.getMonth()]
        }`;

        currentColumnDays.push(
          <div key={dayIndex} style={{ margin: "0.09rem" }}>
            <Tooltip title={`${formattedDate}: ${day.count} ${day.count === 1 ? "Login" : "Logins"}`}>
              <div
                style={{
                  width: "15px",
                  height: "15px",
                  borderRadius: "1px",
                  backgroundColor: getColor(day.count),
                }}
              ></div>
            </Tooltip>
          </div>
        );
      });

      if (currentColumnDays.length >= 7 || i === weeks - 1) {
        monthRows.push(
          <div key={`column_${i}`} style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
            {currentColumnDays.slice(0, 7)}
          </div>
        );

        currentColumnDays = currentColumnDays.slice(7);
      }
    }

    return (
      <XBox key={monthName} display="flex" flexDirection="column" alignItems="center">
        {monthName === "January" ? (
          <XBox height={26}>
            <XTypography variant="h6" fontWeight="bold">
              {displayYear}
            </XTypography>
          </XBox>
        ) : (
          <XBox height={26} />
        )}
        <XBox>
          <XTypography variant="h6" fontWeight="bold">
            {monthName.slice(0, 3)}
          </XTypography>
        </XBox>
        <div style={{ display: "flex", gap: "2px" }}>{monthRows}</div>
      </XBox>
    );
  };

  useEffect(() => {
    if (!calendarData) return;

    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    const filteredData = calendarData.data.filter((item) => new Date(item.date) >= oneYearAgo);
    //   console.log("The filtered data is ", filteredData)
    setData(filteredData);
    setLoading(false);
  }, [calendarData]);

  if (loading) {
    return (
      <Card sx={{ height: "260px", width: "100%" }}>
        <LoadingSpinner size={40} animationType="pulse" />
      </Card>
    );
  }

  return (
    <>
      <XBox sx={{ display: "flex", flexDirection: "row", px: 2, pt: 2, mb: 2 }}>
        <XBox
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            mr: 1,
            mt: 6.2,
            height: "69px",
          }}
        >
          {daysOfWeek.map((day, index) => (
            <XTypography key={day + index} variant="h6" fontSize="12px">
              {day}
            </XTypography>
          ))}
        </XBox>
        <ScrolledBox>
          <XBox sx={{ display: "flex", alignItems: "flex-start" }}>
            <XBox
              sx={{
                display: "flex",
                gap: "20px",
                width: "calc(100% - 50px)",
              }}
            >
              {Object.keys(splitData).map((year) => (
                <div key={year}>
                  <div style={{ display: "flex", gap: "20px" }}>
                    {Object.keys(splitData[year]).map((monthName) =>
                      renderMonth(splitData[year][monthName], monthName, year)
                    )}
                  </div>
                </div>
              ))}
            </XBox>
          </XBox>
        </ScrolledBox>
      </XBox>
      <XBox sx={{ px: 2 }}>
        <XDivider />
      </XBox>
      <XBox display="flex" justifyContent="space-between" px={3} pb={2}>
        <XTypography variant="body1" fontSize="14px" style={{ color: "#7C7C7C" }} mt={1}>
          {totalLogins} {totalLogins === 1 ? "login" : "logins"} in the last year
        </XTypography>
        <XBox sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
          <XTypography variant="body1" fontSize="14px" mr={1} style={{ color: "#7C7C7C" }}>
            Less
          </XTypography>
          {[0, 1, 2, 3, 4].map((stepIndex) => (
            <XBox
              key={stepIndex}
              sx={{
                width: 15,
                height: 15,
                margin: 0.1,
                borderRadius: 0.8,
                backgroundColor: getColorBox(stepIndex),
              }}
            ></XBox>
          ))}
          <XTypography variant="body1" ml={1} fontSize="14px" style={{ color: "#7C7C7C" }}>
            More
          </XTypography>
        </XBox>
      </XBox>
    </>
  );
};

export default LoginCalendar;

function ordinalSuffixOf(i) {
  const j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return "st";
  }
  if (j === 2 && k !== 12) {
    return "nd";
  }
  if (j === 3 && k !== 13) {
    return "rd";
  }
  return "th";
}

// Scroll component all the way to the right on load
function ScrolledBox(props) {
  const boxRef = useRef(null);

  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.scrollLeft = boxRef.current.scrollWidth;
    }
  }, []);
  return (
    <XBox
      ref={boxRef}
      sx={{ overflowX: "auto", width: "100%", ...props.sx }}
      id="calendar-scrollbar"
    >
      {props.children}
    </XBox>
  );
}

ScrolledBox.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node,
};
