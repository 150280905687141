import { useState, useContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import { Card, Icon, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XSelect from "components/XSelect";
import XButton from "components/XButton";
import XBadge from "components/XBadge";

import PlaceholderCard from "shared/Cards/PlaceholderCard";
import colors from "assets/theme/base/colors";
import { getTimeDifference } from "shared/Functions/Date";

import PreprocessorInfoCard from "shared/Cards/PreprocessorCard";
import { ReactComponent as DarkXCloseIcon } from "assets/images/dark-x-close-icon.svg";

import { usePreprocessorQuery } from "api/query/usePreprocessorQuery";
import { usePreprocessorMutations } from "api/mutations/usePreprocessorMutation";
import { useModel } from "hooks";
import { XImg } from "components/XImg";
import { useAllPreprocessorsQuery } from "api/query/useAllPreprocessorsQuery";
import { usePreprocessorVersionQuery } from "api/query";

const hostUrl = process.env.REACT_APP_HOST_URL;

function LinkPreprocessor({ updatePreprocessorData, isPredict = false }) {
  const { apiKey, activeWorkspace } = useApiKey();
  const { model_id, selectedVersion, modelState } = useModel();
  const [preprocessorData, setPreprocessorData] = useState(null);
  const [addingPreprocessor, setAddingPreprocessor] = useState(false);
  const { logout } = useAuth0();
  const { user, created, model_name, model_description } = modelState;

  // Integrate the usePreprocessorQuery to check if there's an existing preprocessor.
  const {
    data: fetchedPreprocessor,
    isLoading: isLoadingPreprocessor,
    isError: isErrorFetchingPreprocessor,
    error: errorFetchingPreprocessor,
  } = usePreprocessorQuery(
    activeWorkspace,
    model_id,
    selectedVersion.value,
    apiKey,
    logout,
    updatePreprocessorData,
    setPreprocessorData,
    setAddingPreprocessor
  );

  const { linkPreprocessorMutation } = usePreprocessorMutations();

  const addPreprocessor = async (linkPreprocessor) => {
    //Link the preprocessor
    linkPreprocessorMutation(
      [
        activeWorkspace,
        model_id,
        selectedVersion.value,
        linkPreprocessor.preprocessor_id,
        linkPreprocessor.selected_version,
        apiKey,
      ],
      {
        onSuccess: (response) => {
          console.log("Successfully Linked Preprocessor", response);
          updatePreprocessorData({
            id: linkPreprocessor.preprocessor_id,
            version: linkPreprocessor.selected_version,
          });
          setPreprocessorData(linkPreprocessor);
          setAddingPreprocessor(false);
        },
        onError: (response) => {
          console.log("Function failed: Link Preprocessor", response);
        },
      }
    );
  };

  const handleCreatePreprocessor = (linkPreprocessor) => {
    addPreprocessor(linkPreprocessor);
  };

  return (
    <>
      <Grid container mb={2}>
        <Grid item xs={12}>
          <XTypography variant="h5" py={1} fontSize="18px">
            {isPredict ? "Batch Predict" : "Linked Preprocessor"}
          </XTypography>
          <XTypography variant="h6" color="secondary" fontWeight="light">
            {isPredict
              ? "Upload a dataset to predict on with the associated preprocessor."
              : "Link a preprocessor to the model. This preprocessor will be used for all predict processes for models and deployments"}
          </XTypography>
        </Grid>
        <Grid item xs={12}>
          <XBox
            sx={{
              mt: 3,
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container item xs={12} spacing={1} alignItems="start">
              <Grid item xs={12} lg={6}>
                <XBox>
                  {addingPreprocessor ? (
                    <NewDeploymentForm
                      onCreatePreprocessor={handleCreatePreprocessor}
                      setAddingPreprocessor={setAddingPreprocessor}
                      model_id={model_id}
                      version_id={selectedVersion.value}
                    />
                  ) : preprocessorData ? (
                    <Tooltip title="Click to link new preprocessor" placement="top">
                      <XBox onClick={() => setAddingPreprocessor(true)}>
                        <PreprocessorInfoCard
                          title={preprocessorData?.preprocessor_name}
                          version={preprocessorData?.selected_version}
                          author={
                            preprocessorData?.user.given_name +
                            " " +
                            preprocessorData?.user.family_name
                          }
                          imgUrl={preprocessorData?.user.image}
                          description={preprocessorData.preprocessor_description}
                          role={preprocessorData?.position}
                          date={preprocessorData?.created}
                          bgColor="white"
                        />
                      </XBox>
                    </Tooltip>
                  ) : (
                    <XBox
                      sx={{
                        zIndex: "0",
                        width: { xs: "100%" },
                        justifyContent: { xs: "center", lg: "flex-start" },
                      }}
                    >
                      <PlaceholderCard
                        title={{ variant: "h6", text: "Click to link preprocessor" }}
                        height="230px"
                        outlined
                        onClick={() => {
                          setAddingPreprocessor(!addingPreprocessor);
                        }}
                      />
                    </XBox>
                  )}
                </XBox>
              </Grid>
              <Grid item xs={12} lg={6}>
                <XBox>
                  <PreprocessorInfoCard
                    title={model_name}
                    version={selectedVersion.value}
                    author={user?.given_name + " " + user?.family_name}
                    imgUrl={user?.image}
                    description={model_description}
                    role={user?.position}
                    date={created}
                    bgColor="white"
                  />
                </XBox>
              </Grid>
            </Grid>
          </XBox>
        </Grid>
      </Grid>
    </>
  );
}

export default LinkPreprocessor;

const NewDeploymentForm = ({
  onCreatePreprocessor,
  setAddingPreprocessor,
  model_id,
  version_id,
}) => {
  const { apiKey, activeWorkspace } = useApiKey();
  const { logout } = useAuth0();

  const [models, setPreprocessors] = useState([]);
  const [selectedPreprocessor, setSelectedPreprocessor] = useState(null);
  const [selectedPreprocessorMetadata, setSelectedPreprocessorMetadata] = useState(null);
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [signatureMatch, setSignatureMatch] = useState(false);
  const [partialMatch, setPartialMatch] = useState(false);

  const { data: preprocessorVersion } = usePreprocessorVersionQuery(
    activeWorkspace,
    selectedPreprocessor?.value,
    logout
  );
  const { data: allPreprocessorsData } = useAllPreprocessorsQuery(activeWorkspace, apiKey, logout);
  const { checkSignatureMutation } = usePreprocessorMutations();

  const handleModelChange = (event) => {
    //Reset signature match & version
    setSignatureMatch(false);
    setPartialMatch(false);
    // setVersions([]);
    // setSelectedVersion(null);

    console.log(event)

    //Set the selected Model
    setSelectedPreprocessor(event);

    //Get the model id from the selection
    const modelId = event.value;
    const selectedPreprocessor = models.find((model) => model.preprocessor_id === modelId);
    console.log("The selected preprocessor is", selectedPreprocessor)
    setSelectedPreprocessorMetadata(selectedPreprocessor);
  };

  const handleVersionChange = async (event) => {
    //Update the selected version
    setSelectedVersion(event);
    console.log("The version change is", event)

    checkSignatureMutation(
      [
        activeWorkspace,
        model_id,
        version_id,
        selectedPreprocessorMetadata.preprocessor_id,
        event.value,
        apiKey,
      ],
      {
        onSuccess: (response) => {

          const model_columns = response.data.model_columns;
          const pipeline_columns = response.data.pipeline_columns;
          const allExist = model_columns.every((col) => pipeline_columns.includes(col));

          if (response.data.signatures_match) {
            setSignatureMatch(true);
          } else {
            if (allExist) {
              setPartialMatch(true);
              setSignatureMatch(false);
            } else {
              setSignatureMatch(false);
            }
          }
        },
        onError: () => {
          setSignatureMatch(false);
        },
      }
    );
  };

  // Fetch projects from the /models endpoint
  useEffect(() => {
    if (!preprocessorVersion) return;
    
    setVersions(preprocessorVersion.data);
  }, [preprocessorVersion]);

  // Fetch projects from the /models endpoint
  useEffect(() => {
    if (!allPreprocessorsData) return;

    setPreprocessors(allPreprocessorsData.data);
  }, [allPreprocessorsData]);

  return (
    <Card
      sx={{
        position: "relative",
        zIndex: 1,
        overflow: "visible",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        p: 1,
      }}
    >
      <XBox
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
        }}
        onClick={() => {
          setAddingPreprocessor(false);
        }}
      >
        <XImg>
          <DarkXCloseIcon />
        </XImg>
      </XBox>

      <XTypography variant="h6" align="start">
        Link Preprocessor
      </XTypography>
      <XTypography variant="overline">Preprocessor</XTypography>
      <XSelect
        value={selectedPreprocessor}
        onChange={handleModelChange}
        fullWidth
        placeholder="Select Preprocessor"
        options={models.map((model) => ({
          value: model.preprocessor_id,
          label: model.preprocessor_name,
        }))}
      />
      {selectedPreprocessor ? (
        <XBox py={1}>
          <PreprocessorMetadata preprocessor={selectedPreprocessorMetadata} />
        </XBox>
      ) : (
        <></>
      )}
      <XBox mb={2}>
        <XBox display="flex" justifyContent="space-between">
          <XTypography variant="overline">Version</XTypography>
        </XBox>
        <XSelect
          value={selectedVersion}
          onChange={handleVersionChange}
          fullWidth
          placeholder="Select Version"
          options={versions.map((version) => ({
            value: version.version_id,
            label: version.version_number,
          }))}
        />
      </XBox>

      <XBox display="flex" gap={3} justifyContent="space-between">
        <XBox>
          <XBadge
            badgeContent={signatureMatch ? "Signatures match" : "Signatures don't match"}
            color={partialMatch ? "warning" : signatureMatch ? "success" : "secondary"}
            variant="contained"
            size="sm"
          />
        </XBox>
        <XButton
          variant="contained"
          color={partialMatch ? "warning" : signatureMatch ? "primary" : "secondary"}
          disabled={!signatureMatch && !partialMatch}
          onClick={async () => {
            const foundPreprocessor = models.find(
              (preprocessor) => preprocessor.preprocessor_id === selectedPreprocessor.value
            );
            console.log(foundPreprocessor);
            console.log(selectedVersion);
            if (foundPreprocessor && selectedVersion) {
              const linkPreprocessor = {
                created: foundPreprocessor.created,
                preprocessor_description: foundPreprocessor.preprocessor_description,
                preprocessor_id: foundPreprocessor.preprocessor_id,
                preprocessor_name: foundPreprocessor.preprocessor_name,
                user: foundPreprocessor.user,
                position: foundPreprocessor.user.position,
                selected_version: selectedVersion.value,
              };
              onCreatePreprocessor(linkPreprocessor);
            }
          }}
        >
          {partialMatch ? "Link Anyway" : "Link"}
        </XButton>
      </XBox>
    </Card>
  );
};

const PreprocessorMetadata = ({ preprocessor }) => {
  if (!preprocessor) {
    return null;
  }

  return (
    <XBox
      display="flex"
      flexDirection="column"
      gap={1}
      sx={{
        borderRadius: "10px",
        backgroundColor: colors.light.main,
        py: 1,
        px: 1.5,
      }}
    >
      <XTypography variant="button" fontWeight="light">
        {preprocessor.preprocessor_description}
      </XTypography>
      <XTypography variant="caption">
        Created {getTimeDifference(preprocessor.created, true)}
      </XTypography>
    </XBox>
  );
};

// Typechecking props for the Feature Chart
LinkPreprocessor.propTypes = {
  updatePreprocessorData: PropTypes.func,
  isPredict: PropTypes.bool,
};

// Typechecking props for the Feature Chart
NewDeploymentForm.propTypes = {
  onCreatePreprocessor: PropTypes.func,
  setAddingPreprocessor: PropTypes.func,
  projects: PropTypes.object,
  model_id: PropTypes.string,
  version_id: PropTypes.string,
};

PreprocessorMetadata.propTypes = {
  preprocessor: PropTypes.object,
};
