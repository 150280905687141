import React, { useCallback, useEffect, useRef, useState } from "react";

import ReuseableHeader from "shared/Headers/ReuseableHeader";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import XTypography from "components/XTypography";
import XBox from "components/XBox";
import XInput from "components/XInput";
import XButton from "components/XButton";

import { useXplainableController } from "context";
import { animateGradient } from "assets/theme/base/animations";
import { useNavigate, useParams } from "react-router-dom";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { Grid, Icon, MenuItem, Modal, Select, Tooltip } from "@mui/material";
import { TeamTable } from "../admin/components/TeamTable";
import { useAdmin, useApp } from "hooks";
import { useTeamsMutation } from "api/mutations";
import { ReactComponent as ButtonArrowLeft } from "assets/images/icons/button-arrow-left.svg";

import colors from "assets/theme/base/colors";
import { XImg } from "components/XImg";
import { Users } from "../users";
import { useTeamsQuery } from "api/query";
import { useAuth0 } from "@auth0/auth0-react";

export const Teams = () => {
  const { organisation, access, team, setTeam, setTeamData, teamData } = useAdmin();
  const { apiKey, workspaces } = useApiKey();
  const { organisationId } = useParams();
  const { logout } = useAuth0();

  const { data } = useTeamsQuery({ organisationId, apiKey, logout });

  const inputRef = useRef(null);
  const descRef = useRef(null);

  const [controller] = useXplainableController();
  const { createTeamMutation } = useTeamsMutation();

  const navigate = useNavigate();

  const [isAdmin, setIsAdmin] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState("person");
  const [tableRows, setTableRows] = useState(null);

  useEffect(() => {
    setTeamData(data?.data || []);
  }, [data]);

  useEffect(() => {
    // console.log("The team data is", data)
    setTableRows(teamData);
  }, [teamData]);

  useEffect(() => {
    if (organisation && workspaces) {
      const currentOrganisation = workspaces.find(
        (item) => item?.organisation_id === organisation?.organisation_id
      );
      console.log("The active workspaces are", workspaces);
      console.log("The current organisation is", currentOrganisation);
      setIsAdmin(currentOrganisation?.is_admin);
    }
  }, [organisation, workspaces]);

  const handleTeamClick = (rowData) => {
    //Get the row data from the teams table
    const teamData = rowData.original;

    //Set the selected team
    setTeam(teamData);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const onAdd = useCallback(() => {
    //Add an ascending ID and the organisation name and expiry days
    const item = {
      team_name: inputRef.current.value,
      team_description: descRef.current.value,
      display_icon: selectedIcon,
    };

    createTeamMutation([organisation, JSON.stringify(item)], {
      onSuccess: (data) => {
        //Add the id to render the table
        item["team_id"] = data.data.team_id;

        //Update the state of the table
        const newState = [...tableRows];
        newState.push(item);
        setTableRows(newState);
        // handleAdd(item);

        //Reset the values
        inputRef.current.value = null;
        descRef.current.value = null;
        handleClose();
        window.location.reload();
      },
      onError: (err) => {
        console.log(err);
        handleClose();
      },
    });
  }, [tableRows]);

  const icons = [
    "schedule",
    "timeline",
    "person",
    "group",
    "person_outline",
    "assessment",
    "scatter_plot",
    "insights",
    "build",
    "tune",
    "engineering",
    "chat",
    "contact_mail",
    "message",
    "description",
    "cloud_queue",
    "palette",
    "create",
    "brush",
    "style",
  ];

  return (
    <DashboardLayout
      sx={{
        backgroundImage: ({ palette: { gradients } }) =>
          globalImage
            ? `linear-gradient(to bottom right, rgba(225,64,103,0.9), rgba(0,128,234,0.9)), url(${globalImage})`
            : `linear-gradient(to bottom right, ${globalColor}, ${globalColor})`,
        backgroundSize: "100% 100%, cover",
        backgroundPosition: "0% 50%, 80% 50%",
        animation: `${animateGradient} 15s ease infinite`,
      }}
    >
      <XBox
        // onClick={(e) => {
        //   const table = document.getElementById("table");
        //   if (table && !table.contains(event.target)) {
        //     setTeam(null);
        //   }
        // }}
      >
        <ReuseableHeader />

        <Modal
          open={isModalOpen}
          onClose={handleClose}
          aria-labelledby="request-title"
          aria-describedby="request-description"
        >
          <XBox
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -60%)",
              width: 600,
              background: controller.darkMode ? colors.background.dark : colors.background.default,
              borderRadius: "16px",
              padding: 3,
            }}
          >
            <XBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <XTypography fontSize="16px" fontWeight="bold">
                Add New Team
              </XTypography>
              <Icon
                sx={({ typography: { size, fontWeightBold }, palette: { dark, white } }) => ({
                  fontSize: `${size.md} !important`,
                  fontWeight: `${fontWeightBold} !important`,
                  color: controller.darkMode ? white.main : dark.main,
                  stroke: controller.darkMode ? white.main : dark.main,
                  strokeWidth: "2px",
                  cursor: "pointer",
                })}
                onClick={handleClose}
              >
                close
              </Icon>
            </XBox>
            <Grid item xs={12} lg={4}>
              <XBox>
                <Grid container>
                  <Grid item xs={12} md={12} mt={2}>
                    <XInput
                      placeholder="Team name..."
                      id="team-name"
                      name="team-name"
                      label="team-name"
                      inputRef={inputRef}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} mt={2}>
                    <XBox display="flex" flexDirection="column">
                      <XBox display="flex">
                        <XBox width="100%">
                          <Select
                            value={selectedIcon}
                            onChange={(event) => setSelectedIcon(event.target.value)}
                            sx={{ height: 200, overflow: "auto" }}
                          >
                            {icons.map((name) => (
                              <MenuItem key={name} value={name}>
                                <XBox display="flex" sx={{ alignItems: "center" }}>
                                  <Icon>{name}</Icon>
                                  <XBox ml={1}>{name}</XBox>
                                </XBox>
                              </MenuItem>
                            ))}
                          </Select>
                        </XBox>
                      </XBox>
                    </XBox>
                  </Grid>
                  <Grid item xs={12} md={12} mt={2}>
                    <XInput
                      type="description"
                      id="description"
                      label="logo"
                      name="description"
                      placeholder={"This is a team description..."}
                      inputRef={descRef}
                      bgColor="dark"
                    />
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <XBox display="flex" width="100%" justifyContent="flex-end">
                      <Tooltip title={organisation ? "" : "Select an organisation to add a team"}>
                        <span>
                          <XButton
                            type="submit"
                            variant="gradient"
                            color="button"
                            disabled={organisation ? false : true}
                            size="medium"
                            onClick={onAdd}
                          >
                            Add
                          </XButton>
                        </span>
                      </Tooltip>
                    </XBox>
                  </Grid>
                </Grid>
              </XBox>
            </Grid>
          </XBox>
        </Modal>

        {(access === "globaladmin" || access === "orgadmin") && (
          <>
            <XBox my={3}>
              <Grid container>
                <Grid item xs={12}>
                  <XBox display="flex" justifyContent="space-between" alignItems="center">
                    <XTypography textTransform="capitalize" variant="h5">
                      Teams
                    </XTypography>
                    <XBox display="flex" gap={2} alignItems="center">
                      <XButton
                        sx={{
                          background: "transparent",
                        }}
                        onClick={() => {
                          navigate(`/admin`);
                        }}
                      >
                        <XImg>
                          <ButtonArrowLeft />
                        </XImg>
                      </XButton>
                      {isAdmin && (
                        <XButton
                          variant="gradient"
                          color="button"
                          onClick={() => setIsModalOpen(true)}
                        >
                          {/* <XBox component="img" src={LightPlusIcon} /> */}
                          Add New Team
                        </XButton>
                      )}
                    </XBox>
                  </XBox>
                </Grid>
                <Grid item xs={12}>
                  <XBox pt={3}>
                    {tableRows && (
                      <TeamTable
                        data={teamData || []}
                        organisationData={organisation}
                        handleRowClick={handleTeamClick}
                        isAdmin={isAdmin}
                        tableRows={tableRows}
                        setTableRows={setTableRows}
                      />
                    )}
                  </XBox>
                </Grid>
              </Grid>
            </XBox>
          </>
        )}

        {/* TODO: Pass org admin and remove button if not available */}
        <Users />
      </XBox>
    </DashboardLayout>
  );
};
