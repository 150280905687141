import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useApiKey } from "components/Authorisation/ApiKeyContext";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";

// custom styles for the ListItem
import { Card, Icon } from "@mui/material";
import { menuImage } from "shared/Items/ListItem/styles";
import { useXplainableController } from "context";

import DarkSelectOpenIcon from "assets/images/dark-select-open-icon.svg";
import LightSelectOpenIcon from "assets/images/light-select-open-icon.svg";

import XBadge from "components/XBadge";

export const SidenavMenuSelect = ({ color, image, title, description, onClick, anchorEl }) => {
  const [controller] = useXplainableController();
  const { workspaces, activeWorkspace, setActiveSubscription } = useApiKey();
  const [userMetadata, setUserMetadata] = useState(null);
  const { miniSidenav, darkSidenav } = controller;

  // Sets the user metadata from the workspaces
  const setUserMetadataFromWorkspaces = () => {
    if (!activeWorkspace) return;

    let filteredOrganisations = workspaces.filter(
      (org) => org.organisation_id === activeWorkspace?.organisation_id
    );

    let filteredTeam = filteredOrganisations[0]?.teams.filter(
      (team) => team.team_id === activeWorkspace?.team_id
    );

    console.log("The filtered team is", filteredTeam)

    if (filteredOrganisations.length > 0) {
      //Set the user metadata associated with the organisastion
      setUserMetadata({
        is_admin: filteredOrganisations[0]?.is_admin,
        start_date: filteredOrganisations[0]?.subscription?.start_date,
        role_name: filteredTeam[0]?.role_name,
      });

      //Set active subscription
      setActiveSubscription(filteredOrganisations[0]?.subscription?.active)
    }
  };

  //Call UseEffect to update current tenure and role
  useEffect(() => {
    if (!activeWorkspace) {
      return;
    }

    setUserMetadataFromWorkspaces();
  }, [activeWorkspace]);

  return (
    <Card
      onClick={onClick}
      sx={{
        background: darkSidenav ? "#262525" : "white",
        border: "1px solid #EAEAEA",
        borderRadius: "8px",
        padding: "8px",
        width: "100%",
        marginBottom: "24px",
      }}
    >
      <XBox display="flex" flexDirection="column" gap="8px">
        <XBox
          display="flex"
          alignItems="center"
          justifyContent={`${miniSidenav ? "center" : "space-between"}`}
        >
          <XBox display="flex" alignItems="center">
            {image ? (
              <XBox
                width="2.5rem"
                height="2.5rem"
                borderRadius="lg"
                sx={(theme) => menuImage(theme, { color })}
              >
                {image}
              </XBox>
            ) : (
              <XBox
                width="2rem"
                height="2rem"
                display="grid"
                justifyContent="center"
                alignItems="center"
                borderRadius="md"
                shadow="md"
                color="xppink"
                bgColor={rgba(colors.xppink.main, 0.1)}
                variant="gradient"
              >
                <Icon fontSize="default">{displayIcon}</Icon>
              </XBox>
            )}
            {!miniSidenav && (
              <XBox ml={1} display="flex" flexDirection="column">
                <XTypography
                  variant="button"
                  textTransform="capitalize"
                  fontWeight="regular"
                  style={{ color: darkSidenav ? "white" : "black" }}
                >
                  <strong>{title[0]}</strong> {title[1]}
                </XTypography>
                <XTypography variant="caption" color="secondary">
                  <XTypography
                    variant="button"
                    color="secondary"
                    style={{ color: darkSidenav ? "white" : "black" }}
                  ></XTypography>
                  {description}
                </XTypography>
              </XBox>
            )}
          </XBox>
          {!miniSidenav && !darkSidenav && (
            <XBox src={DarkSelectOpenIcon} component="img" padding="9px 6px" />
          )}
          {!miniSidenav && darkSidenav && (
            <XBox src={LightSelectOpenIcon} component="img" padding="9px 6px" />
          )}
        </XBox>
        <XBox height="1px" width="100%" bgColor="#EAEAEA" />
        <XBox
          display="flex"
          flexDirection="column"
          alignItems={`${miniSidenav ? "center" : "space-between"}`}
        >
          <XBox>
            {!miniSidenav && (
              <XTypography
                fontWeight="bold"
                variant="button"
                style={{ color: darkSidenav ? "white" : "black" }}
              >
                Tenure -{" "}
              </XTypography>
            )}
            <XTypography
              variant="caption"
              sx={{ fontSize: "14px" }}
              style={{ color: darkSidenav ? "white" : "black" }}
            >
              {Math.floor(
                (new Date() - new Date(userMetadata?.start_date)) / (1000 * 60 * 60 * 24)
              )}{" "}
              days
            </XTypography>
          </XBox>
          <XBox display="flex" gap="20px">
            {!miniSidenav && (
              <XTypography
                fontWeight="bold"
                variant="button"
                style={{ color: darkSidenav ? "white" : "black" }}
              >
                Role
              </XTypography>
            )}
            <XBadge
              badgeContent={userMetadata?.role_name}
              variant="contained"
              color={
                userMetadata?.role_name !== "admin"
                  ? userMetadata?.role_name === "creator"
                    ? "xppink"
                    : "xpblue"
                  : "success"
              }
            />
          </XBox>
        </XBox>
      </XBox>
    </Card>
  );
};

SidenavMenuSelect.defaultProps = {
  color: "dark",
};

SidenavMenuSelect.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  image: PropTypes.node.isRequired,
  displayIcon: PropTypes.node,
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  anchorEl: PropTypes.element,
};
