import React, { useState, useCallback, useEffect } from "react";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import Papa from "papaparse";
import XInput from "components/XInput";
import XButton from "components/XButton";
import Pagination from "@mui/material/Pagination";
import SearchIcon from "assets/images/search-icon.svg";
import PlusIcon from "assets/images/icons/plus-icon.svg";
import EditIcon from "assets/images/icons/configuration/edit-icon.svg";
import SaveIcon from "assets/images/icons/configuration/save-icon.svg";
import XCloseIcon from "assets/images/icons/configuration/x-close-icon.svg";
import NumericIcon from "assets/images/icons/configuration/numeric-icon.svg";
import CategoryIcon from "assets/images/icons/configuration/category-icon.svg";
import { ReactComponent as DarkArrowLeft } from "assets/images/icons/pagination/dark-arrow-left.svg";
import { ReactComponent as DarkArrowRight } from "assets/images/icons/pagination/dark-arrow-right.svg";

import { Divider, Icon, PaginationItem, Switch, Tooltip } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useModel } from "hooks";
import { XImg } from "components/XImg";
import { useConfigurationMutation } from "api/mutations";

const hostUrl = process.env.REACT_APP_HOST_URL;

const ModelGeneralSettings = () => {
  const { model_id, featureDescriptionData, setFeatureDescriptionData, selectedVersion } =
    useModel();
  const { saveConfigurationMutation } = useConfigurationMutation();
  const { apiKey, activeWorkspace } = useApiKey();

  const [editing, setEditing] = useState({});
  const [tempDescription, setTempDescription] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [uploadOpen, setUploadOpen] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const handleEditClick = (feature) => {
    const description = featureDescriptionData.find(
      (data) => data.feature === feature
    )?.description;
    setEditing({ [feature]: true });
    setTempDescription({ [feature]: description });
  };

  const handleInputChange = (feature, value) => {
    setTempDescription({ [feature]: value });
  };

  const handleSaveClick = async (feature) => {
    const featureData = featureDescriptionData.find((data) => data.feature === feature);
    const description = tempDescription[feature];

    saveConfigurationMutation(
      [
        activeWorkspace,
        model_id,
        selectedVersion,
        JSON.stringify([
          {
            feature_id: featureData.feature_id,
            description: description,
            is_lever: featureData.is_lever,
          },
        ]),
      ],
      {
        onSuccess: (data) => {
          console.log("Success:", data.data);
        },
        onError: (error) => {
          console.error("Error:", error);
        },
        onSettled: () => {
          featureData.description = description;
          setEditing({ [feature]: false });
        },
      }
    );
  };

  const batchUpload = async (payload) => {
    saveConfigurationMutation(
      [
        activeWorkspace,
        model_id,
        selectedVersion,
        JSON.stringify([
          {
            feature_id: featureData.feature_id,
            description: description,
            is_lever: featureData.is_lever,
          },
        ]),
      ],
      {
        onSuccess: (data) => {
          console.log(data.data);
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  };

  const handleCancelClick = (feature) => {
    setEditing({ [feature]: false });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleUploadClick = () => {
    setUploadOpen(!uploadOpen);
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        complete: function (results) {
          setUploadedData(results.data);
        },
      });
    });
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    const updatedFeatureDescriptionData = [...featureDescriptionData];
    const payload = uploadedData.reduce((arr, row) => {
      const featureData = updatedFeatureDescriptionData.find(
        (data) => data.feature === row.feature
      );
      if (featureData && row.description) {
        featureData.description = row.description;
        arr.push({
          feature_id: featureData.feature_id,
          description: row.description,
          is_lever: featureData.is_lever,
        });
      }
      return arr;
    }, []);

    if (payload.length > 0) {
      batchUpload(payload);
      setFeatureDescriptionData(updatedFeatureDescriptionData);
    }
  }, [uploadedData]);

  const filteredData = featureDescriptionData.filter(
    (data) =>
      data.feature.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (data.description && data.description.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <XBox display="flex" flexDirection="column">
      <XTypography variant="button" fontWeight="light" color="secondary">
        Set Feature Information
      </XTypography>

      <XBox px={{ xs: 2, lg: 0 }} mt={3} minHeight={"600px"} mb={2}>
        <XBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <XBox width={{ xs: "100%", lg: "50%" }}>
            <XInput
              placeholder="Search Features..."
              startAdornment={<XBox src={SearchIcon} component="img" />}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </XBox>
          <Tooltip title="Upload a CSV file with feature information. Columns must be feature, description.">
            <XBox
              {...getRootProps()}
              onClick={open}
              display="flex"
              gap="10px"
              alignItems="center"
              sx={{
                cursor: "pointer",
              }}
            >
              <XBox src={PlusIcon} component="img" />
              <XTypography
                fontSize="18px"
                sx={{
                  backgroundImage: "linear-gradient(to right, #E14086, #0080EA)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontWeight: "bold",
                }}
              >
                Upload CSV file
              </XTypography>
              <input
                {...getInputProps()}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </XBox>
          </Tooltip>
        </XBox>
        <XBox display="flex" justifyContent="space-between" mb={3} mt={2}>
          <XTypography fontWeight="medium" variant="h5" fontSize="14px" sx={{ color: "#AFAFAF" }}>
            Feature
          </XTypography>
          <XTypography fontWeight="medium" variant="h5" fontSize="14px" sx={{ color: "#AFAFAF" }}>
            Description
          </XTypography>
          <XTypography fontWeight="medium" variant="h5" fontSize="14px" sx={{ color: "#AFAFAF" }}>
            {" "}
          </XTypography>
        </XBox>
        {paginatedData.map((data, index) => (
          <>
            <XBox
              key={index + data.feature}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              py={2}
            >
              <XBox width={"30%"} display="flex" justifyContent="left " alignItems="center">
                <XBox mr={2}>
                  <XButton
                    variant="gradient"
                    color={data.type === "numeric" ? "xppink" : "xpblue"}
                    size="small"
                    circular
                    iconOnly
                  >
                    <XBox
                      component="img"
                      src={data.type === "numeric" ? CategoryIcon : NumericIcon}
                    />
                  </XButton>
                </XBox>
                <XTypography
                  fontWeight="medium"
                  variant="h6"
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {data.feature}
                </XTypography>
              </XBox>
              {editing[data.feature] ? (
                <XBox
                  width={"65%"}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <XBox width={"80%"} px={2}>
                    <XInput
                      value={tempDescription[data.feature]}
                      onChange={(e) => handleInputChange(data.feature, e.target.value)}
                      sx={{ backgroundColor: "#F7F7F8" }}
                    />
                  </XBox>
                  <XBox width={"20%"} display="flex" gap={1}>
                    <Tooltip title="Save">
                      <XBox
                        src={SaveIcon}
                        component="img"
                        onClick={() => handleSaveClick(data.feature)}
                      />
                    </Tooltip>
                    <Tooltip title="Cancel">
                      <XBox
                        src={XCloseIcon}
                        component="img"
                        onClick={() => handleCancelClick(data.feature)}
                      />
                    </Tooltip>
                  </XBox>
                </XBox>
              ) : (
                <XBox
                  width={"70%"}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <XBox width={"80%"} pl={3}>
                    <XTypography variant="body1" fontSize={"16px"}>
                      {data.description}
                    </XTypography>
                  </XBox>
                  <XBox
                    width={"20%"}
                    display="flex"
                    alignItems="center"
                    justifyContent="center" // Changed to center
                  >
                    <Tooltip title="Edit">
                      <XBox
                        src={EditIcon}
                        component="img"
                        onClick={() => handleEditClick(data.feature, data.description)}
                      />
                    </Tooltip>
                  </XBox>
                </XBox>
              )}
              {/* <XBox width={"5%"} display="flex" justifyContent="center" alignItems="center">
                <Switch
                  checked={data.is_lever}
                  sx={{
                    width: "50px",
                    height: "24px",
                    "& .MuiSwitch-switchBase": {
                      padding: 0,
                      "&.Mui-checked": {
                        transform: "translate(28px,-10px)",
                      },
                    },
                    "& .MuiSwitch-thumb": {
                      width: 20,
                      height: 20,
                    },
                  }}
                />
              </XBox> */}
            </XBox>
          </>
        ))}
      </XBox>
      <Pagination
        count={Math.ceil(filteredData.length / itemsPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        shape="rounded"
        sx={{
          alignSelf: "end",
          "& .MuiPaginationItem-root": {
            color: "white !important",
          },
          "& .Mui-selected": {
            background: "linear-gradient(to right, #E14086, #0080EA)",
            color: "white !important",
          },
        }}
        renderItem={(item) => (
          <PaginationItem
            {...item}
            components={{
              next: () => (
                <XBox display="flex" sx={{ borderRadius: "4px" }} p="4px" bgColor="#F7F7F8">
                  <XImg lightColor="black">
                    <DarkArrowRight />
                  </XImg>
                </XBox>
              ),
              previous: () => (
                <XBox display="flex" sx={{ borderRadius: "4px" }} p="4px" bgColor="red">
                  <XImg lightColor="black">
                    <DarkArrowLeft />
                  </XImg>
                </XBox>
              ),
            }}
          />
        )}
      />
    </XBox>
  );
};

export default ModelGeneralSettings;
