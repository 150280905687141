import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "api/apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useRequestsQuery = (apiKey, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.REQUESTS, apiKey],
    () => apiHandlers.invitations.getRequests(apiKey),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
