import { useState } from "react";
import { useXplainableController } from "context";
import { useAuth0 } from "@auth0/auth0-react";
import { useToast } from "hooks";

import PropTypes from "prop-types";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// xplainable Dashboard components
import XBox from "components/XBox";
import XButton from "components/XButton";
import XSnackbar from "components/XSnackbar";

// NewUser page components
import UserInfo from "./components/UserInfo";
import Survey from "./components/Survey";
import Verification from "./components/Verification";

// NewUser layout schemas for form and form fields
import validations from "./schemas/validations";
import form from "./schemas/form";

import createInitialValues from "./schemas/initialValues";
import { makeStyles } from "@mui/styles";
import { Subscription } from "./components/Subscription";
import { Modal } from "@mui/material";
import colors from "assets/theme/base/colors";
import { useUserMutation } from "api/mutations";

const hostUrl = process.env.REACT_APP_HOST_URL;

function getSteps() {
  return ["User Info", "Verification"];
}
function getStepContent(stepIndex, formData, setFieldValue, setAvatar) {
  switch (stepIndex) {
    case 0:
      return <UserInfo formData={formData} setAvatar={setAvatar} />;
    // case 1:
    //   return <Subscription />;
    case 1:
      return <Survey formData={formData} setFieldValue={setFieldValue} />;
    default:
      return null;
  }
}

function NewUser() {
  const { getAccessTokenSilently, user, logout } = useAuth0();
  const { createUserMutation } = useUserMutation();
  const { showErrorToast } = useToast();
  const [controller] = useXplainableController();
  const { globalImage, globalColor, darkMode } = controller;
  const { formId, formField } = form;

  const [avatar, setAvatar] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [show, setShow] = useState(false);
  const [messageContent, setMessageContent] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleSnackbar = () => setShow(!show);
  const steps = getSteps();
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const preFilledInitialValues = createInitialValues(user);

  const [completedSteps, setCompletedSteps] = useState([true, false]);

  const handleNextClick = () => {
    setCompletedSteps((prevSteps) =>
      prevSteps.map((step, index) => (index === prevSteps.indexOf(false) ? true : step))
    );
  };

  const handleBackClick = () => {
    if (activeStep === 0) {
      logout();
      return;
    }

    setCompletedSteps((prevSteps) =>
      prevSteps.map((step, index) => (index === prevSteps.lastIndexOf(true) ? false : step))
    );
    setActiveStep(activeStep - 1);
  };

  const submitForm = async (values, actions) => {
    console.log("The values are", values);
    setIsModalOpen(true);

    const jwt = await getAccessTokenSilently();

    const payload = {
      username: values.userName,
      email: values.email,
      given_name: values.firstName,
      family_name: values.lastName,
      city: values.city,
      country: values.country,
      position: values.position,
      bio: "",
      survey_response: values.survey,
    };

    // If avatar is available, append it to the payload
    if (avatar) {
      payload.profile_image = avatar;
    }

    // Convert payload to FormData
    const formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }

    createUserMutation([formData, jwt], {
      onSuccess: (data) => {
        if (data.data.user_id) {
          //Go to final step
          actions.setTouched({});
          actions.setSubmitting(false);

          // Wait for 3 seconds, then navigate to "/" and refresh
          setTimeout(() => {
            setIsModalOpen(false);

            //Clear the local storage and route to endpoint
            localStorage.clear();
            window.location.href = "/";
          }, 3000); // 3000 milliseconds = 3 seconds
        } else {
          setIsModalOpen(false);
          showErrorToast(
            `Unable to create a new user. Please contact support if the problem persists.`
          );
          // alert(responseData.message || "Failed to create user.");
          // setMessageContent("Failed to create user.");
          // toggleSnackbar();
          //Go back the initial step
          setCompletedSteps([true, false]);
          setActiveStep(0);
          actions.setTouched({});
          actions.setSubmitting(false);
        }
      },
      onError: (error) => {
        setIsModalOpen(false);
        showErrorToast(error.response.data.detail);
        //Set the error message
        // setMessageContent(error.message);
        // toggleSnackbar();
        //Go back the initial step
        setCompletedSteps([true, false]);
        setActiveStep(0);
        actions.setTouched({});
        actions.setSubmitting(false);
      },
    });
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      handleNextClick();
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {" "}
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="request-title"
        aria-describedby="request-description"
      >
        <XBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -60%)",
            width: 800,
            background: darkMode ? colors.background.dark : colors.background.default,
            borderRadius: "16px",
            padding: 3,
          }}
        >
          <Verification />
        </XBox>
      </Modal>
      <XBox
        sx={{ minHeight: "100vh", width: "100%" }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        overflow="hidden" // Prevent scrolling
      >
        <XBox
          sx={{
            width: "616px",
            height: "616px",
            borderRadius: "50%",
            filter: "blur(500px)",
            right: -200,
            bottom: -200,
            backgroundColor: "#E14067",
            position: "absolute",
          }}
        />
        <XBox
          sx={{
            width: "616px",
            height: "616px",
            borderRadius: "50%",
            filter: "blur(500px)",
            left: -200,
            top: -200,
            backgroundColor: "#0080EA",
            position: "absolute",
          }}
        />

        <XBox py={3} mb={20} width="100%">
          <Grid container justifyContent="center" sx={{ height: "100%" }}>
            <Grid item xs={12} lg={10}>
              <Card
                sx={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  borderRadius: "lg",
                  padding: "40px",
                }}
              >
                <CustomStepper completedSteps={completedSteps} />

                <Formik
                  initialValues={preFilledInitialValues}
                  validationSchema={currentValidation}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                    <Form id={formId} autoComplete="off" style={{ width: "100%" }}>
                      <XBox p={2}>
                        <XBox>
                          {getStepContent(
                            activeStep,
                            {
                              values,
                              touched,
                              formField,
                              errors,
                            },
                            setFieldValue,
                            setAvatar
                          )}
                          <XBox
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="flex-end"
                            gap={2}
                          >
                            {activeStep === 1 ? (
                              <XBox />
                            ) : (
                              <XButton
                                sx={{
                                  backgroundImage: "linear-gradient(to right, #E14086, #0080EA)",
                                  WebkitBackgroundClip: "text",
                                  WebkitTextFillColor: "transparent",
                                  boxShadow: "none",
                                }}
                                onClick={handleBackClick}
                              >
                                Back
                              </XButton>
                            )}
                            <XButton
                              disabled={isSubmitting}
                              type="submit"
                              color="button"
                              variant="gradient"
                            >
                              {isLastStep ? "Done" : "Next"}
                            </XButton>

                            {/* {activeStep === 1 && (
                              <XButton
                                variant="gradient"
                                color="button"
                                onClick={() => {
                                  handleNextClick();
                                  setActiveStep((prev) => prev + 1);
                                }}
                              >
                                Continue
                              </XButton>
                            )} */}
                          </XBox>
                        </XBox>
                      </XBox>
                    </Form>
                  )}
                </Formik>
              </Card>
            </Grid>
          </Grid>
        </XBox>
      </XBox>
    </>
  );
}

export default NewUser;

const useStyles = makeStyles((theme) => ({
  customStepper: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    width: "100%",
  },
  line: {
    height: 4,
    width: "100%",
    backgroundColor: "#F7F7F8",
  },
  filledLine: {
    backgroundColor: "#0080EA",
  },
}));

const CustomStepper = ({ completedSteps }) => {
  const classes = useStyles();

  return (
    <div className={classes.customStepper}>
      {completedSteps.map((step, index) => (
        <div key={index} className={`${classes.line} ${step ? classes.filledLine : ""}`}></div>
      ))}
    </div>
  );
};

CustomStepper.propTypes = {
  completedSteps: PropTypes.array,
};
