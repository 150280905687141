import { useAuth0 } from "@auth0/auth0-react"; 
import axiosInstance, { addAuthInterceptor }  from "api/AxiosInstance";

// Add the axiosInstance interceptor to automatically log the user out if the API returns a 401
const useAxiosInstance = () => {
  const { logout } = useAuth0();

  // Add interceptor to handle 401 errors
  addAuthInterceptor(logout);

  return axiosInstance;
};

export default useAxiosInstance;
