import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import defaultBgImage from "assets/images/data_visualisation_1.png";
import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";
import { Tooltip, Menu, MenuItem, Card, styled, Icon } from "@mui/material";
import XTypography from "components/XTypography";
import XBox from "components/XBox";
import XInput from "components/XInput";
import XAvatar from "components/XAvatar";
import { getTimeDifference } from "shared/Functions/Date";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useTheme } from "@mui/material/styles";
import { useXplainableController } from "context";

import CollectionIcon from "assets/images/icons/collections/collections-icon.svg";
import DarkDotsVerticalIcon from "assets/images/icons/dark-dots-vertical-icon.svg";
import LightDotsVerticalIcon from "assets/images/icons/light-dots-vertical-icon.svg";
import ArrowRightIcon from "assets/images/icons/collections/arrow-right-icon.svg";

const CollectionCard = ({
  collection,
  dropdown,
  handleSelectCollection,
  onClick,
  handleInputChange,
  handleUpdate,
  editableDescription,
  editableTitle,
  editMode,
}) => {
  const {
    collection_id,
    created_by,
    model_id,
    model_name,
    name: title,
    description,
    created,
    scenario_count: count,
    contributors,
    statuses,
  } = collection;
  const theme = useTheme();
  const [controller] = useXplainableController();

  const { viewState, activeSubscription } = useApiKey();

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      handleUpdate(e, model_id, collection_id);
      e.preventDefault(); // Prevent form submission
    }
  };

  const showContributors = 5;
  let renderContributors;
  if (contributors) {
    renderContributors = contributors.slice(0, showContributors).map((member, key) => {
      const memberKey = `member-${key}`;
      return (
        <Tooltip title={member.given_name + " " + member.family_name} key={memberKey}>
          <XAvatar
            key={memberKey}
            src={typeof member.image === "string" ? member.image : ""}
            alt="contributor profile"
            size="xs"
            sx={({ borders: { borderWidth }, palette: { white } }) => ({
              border: `${borderWidth[2]} solid ${white.main}`,
              cursor: "pointer",
              position: "relative",

              "&:not(:first-of-type)": {
                ml: -1.25,
              },

              "&:hover, &:focus": {
                zIndex: "10",
              },
            })}
          />
        </Tooltip>
      );
    });
  }

  const renderCollectionDetails = () => {
    const details = (
      <XBox>
        <XTypography
          variant="button"
          textTransform="capitalize"
          fontWeight="medium"
          sx={{
            display: "flex",
            alignItems: "center",
            color: activeSubscription ? "#BE22F5" : "secondary",
            gap: 1,
          }}
        >
          View Collection
          <XBox component="img" src={ArrowRightIcon} />
        </XTypography>
      </XBox>
    );

    if (activeSubscription) {
      return (
        <Link
          to={`/collections/${model_id}/${collection_id}`}
          state={{
            collection_id,
            created_by,
            model_id,
            model_name,
            title,
            description,
            created,
            count,
            contributors,
            statuses,
          }}
        >
          {details}
        </Link>
      );
    }
    return details;
  };

  return (
    <Tooltip title={activeSubscription ? "" : "Renew your subscription to view"}>
      <Card
        sx={{
          overflow: "none",
          zIndex: 0,
          width: { xs: "100%", sm: "310px" },
          justifyContent: { xs: "center", lg: "flex-start" },
          backgroundColor: theme.palette.white,
          transition: "background-color 300ms, transform 300ms, box-shadow 300ms",
          cursor: "pointer",
          boxShadow: activeSubscription ? "0px 0px 10px rgba(0, 0, 0, 0.25)" : "none",
          "&:hover": {
            transform: activeSubscription ? "translateY(-2px)" : "translateY(0px)",
            boxShadow: activeSubscription
              ? `0px 0px 20px ${rgba(colors.xppurple.main, 3)}`
              : undefined,
          },
        }}
        m={3}
        onClick={() => {
          if (activeSubscription) {
            handleSelectCollection(collection);
          }
        }}
      >
        <XBox p={2} overflow="visible" display="flex" flexDirection="column">
          <XBox display="flex" alignItems="center" justifyContent="space-between" mb={2}>
            <XBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={1}
              px="12px"
              py={1}
              bgColor={controller.darkMode ? "#1D1B1B" : "#F7F7F8"}
              sx={{ borderRadius: "24px" }}
            >
              <XBox component="img" src={CollectionIcon} />
              <XTypography sx={{ color: "#BE22F5" }} fontSize="12px">
                Collection
              </XTypography>
            </XBox>
            <XBox display="flex">
              <XTypography variant="button" color="secondary" fontWeight="light">
                {getTimeDifference(created, true)}
              </XTypography>
            </XBox>
          </XBox>
          <XBox>
            {editMode && activeSubscription ? (
              <XInput
                name="title"
                value={editableTitle}
                onChange={handleInputChange}
                onKeyPress={handleEnterPress}
                autoFocus
              />
            ) : (
              <XTypography
                variant="button"
                fontSize="18px"
                color={activeSubscription ? "" : "secondary"}
              >
                {title}
              </XTypography>
            )}
          </XBox>
          <XTypography sx={{ color: "#BE22F5" }} variant="button" mb={1}>
            {count + " items"}
          </XTypography>
          {editMode && activeSubscription ? (
            <XInput
              name="description"
              value={editableDescription}
              onChange={handleInputChange}
              onKeyPress={handleEnterPress}
            />
          ) : (
            <XTypography
              fontWeight="light"
              variant="button"
              color={activeSubscription ? "secondary" : "light"}
            >
              {description}
            </XTypography>
          )}

          <XBox mt={1}>
            <XTypography variant="button" color={activeSubscription ? "" : "secondary"}>
              Contributors
            </XTypography>
            {contributors && Array.isArray(contributors) && contributors.length > 0 ? (
              <XBox>
                <XBox display="flex">
                  {renderContributors}
                  {contributors.length <= showContributors ? null : (
                    <XTypography variant="overline">
                      {" "}
                      + {contributors.length - showContributors} more{" "}
                    </XTypography>
                  )}
                </XBox>
              </XBox>
            ) : null}
          </XBox>
          <XBox display="flex" justifyContent="space-between" alignItems="center" mt={3}>
            {viewState === "creator" && dropdown && (
              <>
                <XBox
                  src={controller.darkMode ? LightDotsVerticalIcon : DarkDotsVerticalIcon}
                  component="img"
                  onClick={onClick}
                />
                {dropdown.menu}
              </>
            )}
            {count > 0 ? renderCollectionDetails() : <></>}
          </XBox>
        </XBox>
      </Card>
    </Tooltip>
  );
};

CollectionCard.propTypes = {
  dropdown: PropTypes.object,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  bgImage: PropTypes.string,
  collection: PropTypes.object,
  icon: PropTypes.object,
  handleInputChange: PropTypes.func,
  handleUpdate: PropTypes.func,
  editMode: PropTypes.bool,
  editableDescription: PropTypes.string,
  editableTitle: PropTypes.string,
  handleSelectCollection: PropTypes.func,
};

CollectionCard.defaultProps = {
  bgImage: defaultBgImage,
};

export default CollectionCard;
