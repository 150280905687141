import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const usePreprocessorVersionQuery = (activeWorkspace, preprocessor_id, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.PREPROCESSOR_VERSION, activeWorkspace, preprocessor_id],
    () => apiHandlers.preprocessors.getPreprocessorVersion(activeWorkspace, preprocessor_id),
    {
      enabled: !!preprocessor_id,
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};

export const usePreprocessorSampleQuery = (activeWorkspace, preprocessor_id, version_id, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.PREPROCESSOR_SAMPLE, activeWorkspace, preprocessor_id, version_id],
    () => apiHandlers.preprocessors.getPreprocessorSample(activeWorkspace, preprocessor_id, version_id),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
