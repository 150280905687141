import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import LoadingSpinner from "shared/Animations/LoadingAnimation";

import useAxiosInstance from "api/useAxiosInstance";
import { useUserNotificationQuery } from "api/query";
import { useUserMutation } from "api/mutations";

function PlatformSettings() {
  const axios = useAxiosInstance();

  const { logout } = useAuth0();

  const { data, isLoading } = useUserNotificationQuery(logout);
  const { patchUserNotificationMutation } = useUserMutation();

  const [requests, setRequests] = useState(false);
  const [invitations, setInvitations] = useState(false);
  const [comments, setComments] = useState(false);
  const [newVersions, setNewVersions] = useState(false);
  const [newModels, setNewModels] = useState(false);
  const [newLaunches, setNewLaunches] = useState(false);
  const [productUpdate, setProductUpdate] = useState(false);
  const [newsletter, setNewsletter] = useState(false);

  useEffect(() => {
    if (!data) return;

    setRequests(data.data.requests);
    setInvitations(data.data.invitations);
    setComments(data.data.comments);
    setNewVersions(data.data.new_versions);
    setNewModels(data.data.new_models);
  }, [data]);

  //TODO: Update the endpoint so that it accepts one value at a time
  const handleToggle = async (setter, preference, currentValue) => {
    setter(!currentValue);

    patchUserNotificationMutation(
      [
        {
          [preference]: !currentValue,
        },
      ],
      {
        onError: () => {
          console.error(`Error updating ${preference} preference:`, error);
        },
      }
    );
  };

  return (
    <Card sx={{ p: 2 }}>
      <XBox pt={2} px={2}>
        <XTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          platform settings
        </XTypography>
      </XBox>
      <XBox pt={1.5} pb={2} px={2} lineHeight={1.25}>
        <XTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
          account
        </XTypography>

        {isLoading ? (
          <XBox height="240px" width="100%">
            <LoadingSpinner size={30} />
          </XBox>
        ) : (
          <>
            <XBox display="flex" py={1} mb={0.25}>
              <XBox mt={0.25}>
                <Switch
                  checked={requests}
                  onChange={() => handleToggle(setRequests, "requests", requests)}
                />
              </XBox>
              <XBox width="80%" ml={2}>
                <XTypography variant="button" fontWeight="regular" color="text">
                  Email me for new requests
                </XTypography>
              </XBox>
            </XBox>

            <XBox display="flex" py={1} mb={0.25}>
              <XBox mt={0.25}>
                <Switch
                  checked={invitations}
                  onChange={() => handleToggle(setInvitations, "invitations", invitations)}
                />
              </XBox>
              <XBox width="80%" ml={2}>
                <XTypography variant="button" fontWeight="regular" color="text">
                  Email me for new invitations
                </XTypography>
              </XBox>
            </XBox>

            <XBox display="flex" py={1} mb={0.25}>
              <XBox mt={0.25}>
                <Switch
                  checked={comments}
                  onChange={() => handleToggle(setComments, "comments", comments)}
                />
              </XBox>
              <XBox width="80%" ml={2}>
                <XTypography variant="button" fontWeight="regular" color="text">
                  Email me for new comments
                </XTypography>
              </XBox>
            </XBox>

            <XBox display="flex" py={1} mb={0.25}>
              <XBox mt={0.25}>
                <Switch
                  checked={newVersions}
                  onChange={() => handleToggle(setNewVersions, "new_versions", newVersions)}
                />
              </XBox>
              <XBox width="80%" ml={2}>
                <XTypography variant="button" fontWeight="regular" color="text">
                  Notify me of new versions
                </XTypography>
              </XBox>
            </XBox>

            <XBox display="flex" py={1} mb={0.25}>
              <XBox mt={0.25}>
                <Switch
                  checked={newModels}
                  onChange={() => handleToggle(setNewModels, "new_models", newModels)}
                />
              </XBox>
              <XBox width="80%" ml={2}>
                <XTypography variant="button" fontWeight="regular" color="text">
                  Notify me of new models
                </XTypography>
              </XBox>
            </XBox>
          </>
        )}

        <XBox mt={3}>
          <XTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
            application
          </XTypography>
        </XBox>
        <XBox display="flex" py={1} mb={0.25}>
          <XBox mt={0.25}>
            <Switch checked={newLaunches} disabled onChange={() => setNewLaunches(!newLaunches)} />
          </XBox>
          <XBox width="80%" ml={2}>
            <XTypography variant="button" fontWeight="regular" color="text">
              New launches and projects
            </XTypography>
          </XBox>
        </XBox>
        <XBox display="flex" py={1} mb={0.25}>
          <XBox mt={0.25}>
            <Switch
              checked={productUpdate}
              disabled
              onChange={() => setProductUpdate(!productUpdate)}
            />
          </XBox>
          <XBox width="80%" ml={2}>
            <XTypography variant="button" fontWeight="regular" color="text">
              Monthly product updates
            </XTypography>
          </XBox>
        </XBox>
        <XBox display="flex" py={1} mb={0.25}>
          <XBox mt={0.25}>
            <Switch checked={newsletter} disabled onChange={() => setNewsletter(!newsletter)} />
          </XBox>
          <XBox width="80%" ml={2}>
            <XTypography variant="button" fontWeight="regular" color="text">
              Subscribe to newsletter
            </XTypography>
          </XBox>
        </XBox>
      </XBox>
    </Card>
  );
}

export default PlatformSettings;
