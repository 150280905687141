import React from "react";
import PropTypes from "prop-types";

//mui material components
import Icon from "@mui/material/Icon";
import { Menu, MenuItem } from "@mui/material";
import colors from "assets/theme-dark/base/colors";

//xplainable Dashboard components
import XBox from "components/XBox";
import XAvatar from "components/XAvatar";
import XTypography from "components/XTypography";
import XInput from "components/XInput";
import XBadge from "components/XBadge";
import Card from "@mui/material/Card";

//Helper Functions
import { getTimeDifference } from "shared/Functions/Date";
import { Replies } from "../Replies";
import { useComment } from "shared/Comments/hooks";
import { wrapMentionsInSpan } from "utils";

import UnPinnedIcon from "assets/images/unpinned-icon.svg";
import pinnedIcon from "assets/images/pinned-icon.png";

export const ChatbotComment = ({
  comment,
  commentKey = null,
  isReply = false,
  currentUser,
  type,
  activeWorkspace,
  id,
  apiKey,
  fetchedComments,
  setFetchedComments,
  tabMap,
  tabValue,
  setTabValue,
  setSelectedVersion,
  setFetchedChatBotsComments,
  fetchedChatBotsComments,
}) => {
  const {
    comment_id,
    user,
    comment: text,
    like_count: like,
    created,
    last_edited,
    version_id,
    version_number,
    component,
    isPinned,
  } = comment;

  const {
    inputRef,
    isEditing,
    editedText,
    setEditedText,
    getInitials,
    getKeyByValue,
    handleKeyPress,
    editedCommentTime,
  } = useComment({
    type,
    id,
    apiKey,
    text,
    fetchedComments,
    setFetchedComments,
    activeWorkspace,
    comment_id,
  });

  const { image, username: name, family_name, given_name, position } = user;

  const handlePinnedClick = () => {
    const updatedComments = fetchedChatBotsComments.map((comment) => {
      if (comment.comment_id === comment_id) {
        return {
          ...comment,
          isPinned: !comment.isPinned, // Змініть значення isPinned на протилежне
        };
      }
      return comment;
    });
    // Оновіть стан зміненими коментарями
    setFetchedChatBotsComments(updatedComments);
  };

  return (
    <React.Fragment
    // key={(parent_id === null ? null : parent_id.toString()) + "-" + comment_id.toString()}
    >
      <XBox
        width={"100%"}
        key={name}
        display="flex"
        mt={commentKey === 0 ? 0 : isReply ? 1 : 2.5}
        p={isReply ? 0.25 : 0}
      >
        <XBox>
          {image ? (
            <XAvatar src={image} alt={user} size="xs" />
          ) : (
            <XAvatar
              bgColor="secondary"
              variant="normal"
              color="white"
              alt={user}
              size={isReply ? "xs" : "sm"}
            >
              <XBox>
                <XTypography color="white" fontWeight="bold" variant={isReply ? "caption" : "h6"}>
                  {getInitials(given_name, family_name)}
                </XTypography>
              </XBox>
            </XAvatar>
          )}
        </XBox>
        <XBox flexGrow={1} ml={1.5} mr={1.5}>
          <Card
            sx={{
              borderRadius: currentUser === name ? "0px 12px 12px 12px" : "0px 12px 12px 12px",
              position: "relative",
              zIndex: 1,
              overflow: "visible",
            }}
          >
            <XBox p={1}>
              <XBox display="flex" width="100%">
                <XBox display="flex" width="100%">
                  <XBox display="flex" flexDirection="column" width="100%">
                    <XBox
                      display="flex"
                      gap="4px"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <XBox display="flex" gap="4px">
                        <XTypography variant="button" textTransform="capitalize">
                          {given_name + " " + family_name}
                        </XTypography>
                        {isReply || name === "Chatbot" || type !== "models" ? (
                          <></>
                        ) : (
                          <>
                            <XBadge
                              sx={{ cursor: "pointer" }}
                              variant="contained"
                              color="xppink"
                              badgeContent={"version " + version_number}
                              onClick={() =>
                                setSelectedVersion({
                                  value: version_id,
                                  label: "Version " + version_number,
                                })
                              }
                            />
                            <XBadge
                              sx={{ cursor: "pointer" }}
                              variant="contained"
                              color="xpblue"
                              badgeContent={component}
                              onClick={() => setTabValue(getKeyByValue(tabMap, component))}
                            />
                          </>
                        )}
                      </XBox>
                      <XBox
                        sx={{ cursor: "pointer" }}
                        onClick={handlePinnedClick}
                        component="img"
                        src={isPinned ? pinnedIcon : UnPinnedIcon}
                      />
                    </XBox>
                    <XTypography
                      variant="caption"
                      textTransform="capitalize"
                      fontSize="14px"
                      sx={{ color: "#7C7C7C" }}
                    >
                      {position}
                    </XTypography>
                  </XBox>
                </XBox>
              </XBox>
              <XBox mt={1} mb={1} lineHeight={isReply ? 0.5 : 0.75} pr={2}>
                {isEditing ? (
                  <XInput
                    ref={inputRef}
                    value={editedText}
                    onChange={(e) => setEditedText(e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e)}
                    autoFocus
                  />
                ) : (
                  <XTypography
                    variant="button"
                    fontWeight="light"
                    dangerouslySetInnerHTML={{ __html: wrapMentionsInSpan(text) }}
                  />
                )}
              </XBox>
            </XBox>
            <XBox position="absolute" bottom={"0px"} right={"12px"}>
              <XTypography variant="caption" sx={{ color: "#AFAFAF" }}>
                {(editedCommentTime || last_edited !== created ? "Edited " : "") +
                  getTimeDifference(editedCommentTime || last_edited, true)}
              </XTypography>
            </XBox>
          </Card>
        </XBox>
      </XBox>
    </React.Fragment>
  );
};

ChatbotComment.propTypes = {
  comment: PropTypes.object,
  commentKey: PropTypes.number,
  isReply: PropTypes.bool,
  currentUser: PropTypes.string,
  type: PropTypes.string,
  activeWorkspace: PropTypes.object,
  id: PropTypes.string,
  apiKey: PropTypes.string,
  setFetchedComments: PropTypes.func,
  fetchedComments: PropTypes.array,
  tabValue: PropTypes.number,
  tabMap: PropTypes.object,
  setTabValue: PropTypes.func,
  setSelectedVersion: PropTypes.func,
  setFetchedChatBotsComments: PropTypes.func,
  fetchedChatBotsComments: PropTypes.array,
};
