import { useState, useContext, useMemo, useRef, useEffect } from "react";
import { useXplainableController } from "context";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import DeploymentContext from "../deployment-context";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Slide from "@mui/material/Slide";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import { AppBar, Tabs, Tab } from "@mui/material";

// xplainable Dashboard components
import XBox from "components/XBox";

// xplainable dashboard example components
import ProgressLineChart from "shared/Charts/ProgressLineChart";
import XInput from "components/XInput";
import { Comments } from "shared/Comments";
import XTypography from "components/XTypography";
import XButton from "components/XButton";
import DefaultItem from "shared/Items/DefaultItem";
import rgba from "assets/theme/functions/rgba";
import colors from "assets/theme/base/colors";

//Import BrushChart and Waterfall chart from scenario analysis
import { BrushChart } from "shared/models/ScenarioAnalysis/components/BrushChart";
import { IoMdCopy } from "react-icons/io";
import { useKeyQuery } from "api/query/useKeyQuery";
import { usePayloadQuery } from "api/query/usePayloadQuery";
import { usePredictionQuery } from "api/query";
import { usePredictionMutation } from "api/mutations/usePredictionMutation";
import { useAuth0 } from "@auth0/auth0-react";
import { XImg } from "components/XImg";

import XBadge from "components/XBadge";
import WaterfallChart from "shared/models/ScenarioAnalysis/components/WaterfallChart";
import EventIcon from "shared/Icons/EventIcons";

import SuccessChecklistIcon from "assets/images/icons/deployments/success-checklist-icon.svg";
import ErrorChecklistIcon from "assets/images/icons/deployments/error-checklist-icon.svg";
import DarkCopyIcon from "assets/images/icons/deployments/dark-copy-icon.svg";
import LightCopyIcon from "assets/images/icons/deployments/light-copy-icon.svg";

import { ReactComponent as DarkEyeIcon } from "assets/images/icons/deployments/dark-eye-icon.svg";

function SingleDeploymentOverview({ setTabValue, tabValue }) {
  const { apiKey, activeWorkspace } = useApiKey();
  const { logout } = useAuth0();

  const { model_id, toggle, deployment_id, model_name, collapsedWidth } =
    useContext(DeploymentContext);
  const [controller, dispatch] = useXplainableController();
  const { darkMode } = controller;

  const selectionWaterfallRef = useRef([0, 0]);

  const [payload, setPayload] = useState("");
  const [deployKey, setDeployKey] = useState(false);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [payloadCopy, setPayloadCopy] = useState(false);
  const [responseCopied, setResponseCopied] = useState(false);
  const [responseCopy, setResponseCopy] = useState(false);
  const [showKey, setShowKey] = useState(false);
  const [deployKeyData, setDeployKeyData] = useState(null);
  const [fieldValues, setFieldValues] = useState({});
  const [base_value, setBaseValue] = useState(0);
  const [response, setResponse] = useState({});
  const [appbarValue, setAppbarValue] = useState(0);
  const [responseTime, setResponseTime] = useState(null);

  useEffect(() => {
    selectionWaterfallRef.current = [Object.values(fieldValues)?.length, 0];
  }, [fieldValues]);

  //Prediction Mutation
  const mutation = usePredictionMutation(
    deployKeyData,
    payload,
    setResponse,
    setBaseValue,
    setFieldValues,
    reformatData
  );

  // Fetch the deploy key, payload and prediction
  const { data: keyData, isLoading: isKeyLoading } = useKeyQuery(
    activeWorkspace,
    deployment_id,
    apiKey,
    setDeployKey,
    logout
  );
  const {
    data: payloadData,
    isLoading: isPayloadLoading,
    refetch: refetchPayload,
  } = usePayloadQuery(activeWorkspace, deployment_id, apiKey, setPayload, logout);
  // const { data: prediction, isLoading: isPredictionLoading, isError, error } = mutation;

  //Set the Appbar selection
  const handleSetTabValue = (event, newValue) => setAppbarValue(newValue);

  // This function handles copying the URL to the clipboard
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText("https://inference.xplainable.io/v1/predict");
      setCopied(true);

      // Reset the 'copied' state after 2 seconds
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy URL to clipboard", err);
    }
  };

  function handleChange(e) {
    // Get the value from the event target (the input)
    let inputData = e.target.value;

    // Update the deployKeyData state
    setDeployKeyData(inputData);
  }

  const handleCopyPayload = async () => {
    try {
      if (payload) {
        await navigator.clipboard.writeText(payload);
        setPayloadCopy(true);

        // Reset the 'copied' state after 2 seconds
        setTimeout(() => setPayloadCopy(false), 2000);
      }
    } catch (err) {
      console.error("Failed to copy payload to clipboard", err);
    }
  };

  const handleCopyResponse = async () => {
    try {
      if (response) {
        await navigator.clipboard.writeText("[" + JSON.stringify(response, null, 2) + "]");
        setResponseCopy(true);

        // Reset the 'copied' state after 2 seconds
        setTimeout(() => setResponseCopy(false), 2000);
      }
    } catch (err) {
      console.error("Failed to copy payload to clipboard", err);
    }
  };

  const handlePrediction = async () => {
    const startTime = Date.now(); // Step 1: Capture the current time before making the request

    try {
      const response = await fetch(`https://inference.xplainable.io/v1/predict`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          api_key: deployKeyData,
        },
        body: payload,
      });

      const endTime = Date.now();
      const elapsedTime = endTime - startTime;
      setResponseTime(elapsedTime + "ms");

      if (!response.ok) {
        throw new Error("Failed to fetch prediction response");
      }

      // Get the response body
      const fetchedPrediction = await response.json();

      //Set base value
      setResponse(fetchedPrediction[0]);
      setBaseValue(fetchedPrediction[0].breakdown.base_value);

      // Reformat the data for waterfall chart
      const reformattedData = reformatData(JSON.parse(payload), fetchedPrediction[0]);
      console.log("The reformatted data is", reformattedData);

      // Update the field values
      setFieldValues(reformattedData);
    } catch (error) {
      console.error("Error fetching deployments:", error);
    }
  };

  return (
    <Grid container zIndex={1}>
      <Grid item xs={12} zIndex={1} xl={collapsedWidth ? 12 : 8} pr={{ xs: 0, lg: 1 }}>
        <XBox
          mr={1}
          sx={{
            width: collapsedWidth ? "100%" : "100%",
            transition: "width 500ms",
            zIndex: 1,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} mt={3}>
              <Card sx={{ p: 2 }}>
                <XBox display="flex" flexDirection="column" gap={2}>
                  <XTypography variant="h5" textTransform="capitalize" fontSize="18px">
                    Deployment Overview
                  </XTypography>
                  <XBox>
                    <XBox display="flex" gap={2}>
                      <XBox display="flex" position="relative" width="100%">
                        <XBox
                          width="100%"
                          bgColor={darkMode ? rgba(colors.light.main, 0.05) : colors.light.main}
                          borderRadius="md"
                          display="flex"
                          alignItems="center"
                          px={2}
                        >
                          <XTypography variant="overline" fontSize="16px">
                            https://inference.xplainable.io/v1/predict
                          </XTypography>
                        </XBox>
                        <XBox position="absolute" right="0" top="8px">
                          <EventIcon
                            onClick={handleCopy}
                            tooltipText="Copy URL to clipboard"
                            icon={
                              <XBox component="img" src={darkMode ? LightCopyIcon : DarkCopyIcon} />
                            }
                            copied={copied}
                          />
                        </XBox>
                      </XBox>
                      <XButton variant="gradient" color="button">
                        POST
                      </XButton>
                    </XBox>
                  </XBox>
                </XBox>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Card sx={{ p: 2 }}>
                <XBox lineHeight={1} mb={2}>
                  <XTypography variant="h6" fontSize="18px">
                    Testing Checklist
                  </XTypography>
                </XBox>
                <XBox display="flex" flexDirection="column" gap={3}>
                  <XBox display="flex" alignItems="center" gap={1}>
                    <XBox component="img" src={SuccessChecklistIcon} />
                    <XTypography variant="button" fontWeight="light">
                      Model Is Deployed
                    </XTypography>
                    <Tooltip
                      title="The model is active_deployment to the inference server"
                      placement="top"
                    >
                      <XButton variant="outlined" color="secondary" size="xxs" circular iconOnly>
                        <Icon>question_mark</Icon>
                      </XButton>
                    </Tooltip>
                  </XBox>
                  <XBox display="flex" alignItems="center" gap={1}>
                    <XBox
                      component="img"
                      src={toggle ? SuccessChecklistIcon : ErrorChecklistIcon}
                    />
                    <XTypography variant="button" fontWeight="light">
                      {toggle ? "Deployment is active" : "Deployment is inactive"}
                    </XTypography>
                    <Tooltip title="The deployment has been activated" placement="top">
                      <XButton variant="outlined" color="secondary" size="xxs" circular iconOnly>
                        <Icon>question_mark</Icon>
                      </XButton>
                    </Tooltip>
                  </XBox>
                  <XBox display="flex" alignItems="center" gap={1}>
                    <XBox
                      component="img"
                      src={deployKey ? SuccessChecklistIcon : ErrorChecklistIcon}
                    />
                    <XTypography variant="button" fontWeight="light">
                      {deployKey ? "Deploy key created" : "Create a deploy key"}
                    </XTypography>
                    <Tooltip
                      title="Create a deployment key and pass as a token in the request"
                      placement="top"
                    >
                      <XButton variant="outlined" color="secondary" size="xxs" circular iconOnly>
                        <Icon>question_mark</Icon>
                      </XButton>
                    </Tooltip>
                  </XBox>
                </XBox>
              </Card>
              <Card sx={{ p: 2, mt: 3 }}>
                <XTypography variant="h6" textTransform="capitalize" fontSize="18px" mb={2}>
                  Example Payload
                </XTypography>
                <XBox position="relative" width="100%">
                  <XBox
                    display="flex"
                    position="absolute"
                    top={0}
                    right={10}
                    alignItems="center"
                    zIndex={1}
                    p={1}
                  >
                    <EventIcon
                      onClick={handleCopyPayload}
                      tooltipText="Copy Payload to Clipboard"
                      icon={<XBox component="img" src={darkMode ? LightCopyIcon : DarkCopyIcon} />}
                      copied={payloadCopy}
                    />
                  </XBox>
                  <XInput
                    value={payload}
                    multiline
                    rows={15}
                    sx={{
                      "&::-webkit-scrollbar": {
                        width: 0, // Hide scrollbar for Webkit browsers
                      },
                      "&::-webkit-scrollbar-track": {
                        boxShadow: "none", // Remove scrollbar background
                      },
                      "&::-webkit-scrollbar-thumb": {
                        background: "none", // Remove scrollbar thumb
                      },
                      msOverflowStyle: "none", // Hide scrollbar for IE and Edge
                      scrollbarWidth: "none", // Hide scrollbar for Firefox
                    }}
                  />
                </XBox>
                <XBox>
                  <XBox lineHeight={1} mt={3}>
                    <XTypography variant="h6" fontSize="18px">
                      Deployment Key
                    </XTypography>
                  </XBox>
                  <XBox display="flex" mt={1} position="relative">
                    <XBox width="100%">
                      <XInput
                        type={showKey ? "text" : "password"}
                        placeholder={"Paste a deployment key here..."}
                        value={deployKeyData}
                        onChange={handleChange}
                        sx={{ pr: 4 }}
                      />
                    </XBox>
                    <XBox position="absolute" right="0">
                      <Tooltip title={showKey ? "Hide Deployment Key" : "Show Deployment Key"}>
                        <IconButton
                          size="medium"
                          color={"dark"}
                          onClick={
                            showKey ? () => setShowKey(!showKey) : () => setShowKey(!showKey)
                          }
                        >
                          {showKey ? (
                            <XImg>
                              <DarkEyeIcon />
                            </XImg>
                          ) : (
                            <XImg>
                              <DarkEyeIcon />
                            </XImg>
                          )}
                        </IconButton>
                      </Tooltip>
                    </XBox>
                  </XBox>
                  <XBox display="flex" mt={2}>
                    <XBox width="100%">
                      <Tooltip title="Generate a new example payload">
                        <XButton
                          sx={{
                            backgroundImage: "linear-gradient(to right, #E14086, #0080EA)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                            boxShadow: "none",
                            width: "100%",
                          }}
                          onClick={() => refetchPayload()}
                        >
                          Regenerate
                        </XButton>
                      </Tooltip>
                    </XBox>
                    <XBox width="100%">
                      <XButton
                        color="button"
                        variant="gradient"
                        onClick={handlePrediction}
                        sx={{ width: "100%" }}
                      >
                        Predict
                      </XButton>
                    </XBox>
                  </XBox>
                </XBox>
              </Card>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Card sx={{ p: 2 }}>
                <XBox lineHeight={1}>
                  <XTypography variant="h6" fontSize="18px">
                    Prediction Response
                  </XTypography>
                  <XBox>
                    <DefaultItem
                      color="xpblue"
                      icon="batch_prediction"
                      title="Prediction"
                      description={response.pred || 0}
                      tooltipText="The prediction of the model."
                      p={1}
                      borderRadius="lg"
                    />
                  </XBox>
                  <XBox>
                    <DefaultItem
                      color="xpblue"
                      icon="numbers"
                      title="Score"
                      description={response.score?.toFixed(2) || 0}
                      tooltipText="The score associated to the prediction value."
                      p={1}
                      borderRadius="lg"
                    />
                  </XBox>
                  <XBox>
                    <DefaultItem
                      color="xpblue"
                      icon="pie_chart"
                      title="Partition"
                      description={response.partition || "__dataset__"}
                      tooltipText="The partition passed in the example payload."
                      p={1}
                      borderRadius="lg"
                    />
                  </XBox>
                  <XBox mt={6}>
                    <AppBar position="static">
                      <Tabs
                        value={appbarValue}
                        onChange={handleSetTabValue}
                        sx={{
                          backgroundColor: `${darkMode ? "#1D1B1B" : "#F7F7F8"} !important`,

                          transition: "all 500ms ease",
                          color: "#AFAFAF",

                          "& .Mui-selected": {
                            fontWeight: "600",
                            color: `${darkMode ? "white" : "black"} !important`,
                            backgroundColor: `${darkMode ? "#262525" : "white"} !important`,
                          },
                          "& .MuiTabs-indicator": {
                            display: "none",
                          },
                        }}
                      >
                        <Tab label="Response" sx={{ minWidth: 0 }} />
                        <Tab label="Plot" sx={{ minWidth: 0 }} />
                      </Tabs>
                    </AppBar>
                    {appbarValue === 0 && (
                      <XBox mt={1} position="relative" width="100%">
                        <XBox
                          display="flex"
                          position="absolute"
                          top={0}
                          right={10}
                          alignItems="center"
                          zIndex={1}
                          p={1}
                        >
                          <EventIcon
                            onClick={handleCopyResponse}
                            tooltipText="Copy Response to Clipboard"
                            icon={
                              <XBox component="img" src={darkMode ? LightCopyIcon : DarkCopyIcon} />
                            }
                            copied={responseCopy}
                          />
                        </XBox>
                        <XInput
                          value={
                            Object.keys(response).length === 0
                              ? ""
                              : "[" + JSON.stringify(response, null, 2) + "]"
                          }
                          multiline
                          rows={22}
                        />
                        <XBox
                          position="absolute"
                          bottom={0}
                          right={10}
                          alignItems="center"
                          zIndex={1}
                          p={1}
                        >
                          {responseTime && (
                            <Tooltip
                              title="The displayed response time includes both the network latency and processing overhead within the browser."
                              placement="top"
                            >
                              <XBadge
                                badgeContent={responseTime}
                                variant="contained"
                                color="success"
                                size="md"
                              />
                            </Tooltip>
                          )}
                        </XBox>
                      </XBox>
                    )}
                    {appbarValue === 1 && (
                      <XBox>
                        <BrushChart
                          data={Object.values(fieldValues)}
                          selectionRef={selectionWaterfallRef}
                          isSorted={true}
                          collapsedWidth={collapsedWidth}
                          height={400}
                        >
                          {(selection) => (
                            <WaterfallChart
                              isCollapsed={collapsedWidth}
                              id={"deployment-waterfall"}
                              featureData={fieldValues}
                              margin={{
                                top: 10,
                                right: 20,
                                bottom: 20,
                                left: 20,
                              }}
                              height={415}
                              baseValue={response.breakdown?.base_value || 0}
                              sort={true}
                              selection={selection}
                              isSorted={true}
                            />
                          )}
                        </BrushChart>
                      </XBox>
                    )}
                  </XBox>
                </XBox>
              </Card>
            </Grid>
          </Grid>
        </XBox>
      </Grid>
      <Grid item xs={collapsedWidth ? 0 : 12} xl={collapsedWidth ? 0 : 4} mt={3}>
        <Slide direction="left" in={!collapsedWidth} timeout={500}>
          <XBox
            sx={{
              width: collapsedWidth ? 0 : "100%",
              overflow: "hidden",
              transition: "width 500ms",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              height: "100%",
            }}
          >
            <Grid container alignContent="flex-start" spacing={3}>
              {/* <Grid item xs={12} md={6} xl={12}>
                <ProgressLineChart
                  icon="group"
                  shadow={false}
                  title={model_name + " - API Calls"}
                  color={"xpblue"}
                  height={"160px"}
                  event="inference_server_predict"
                  deployment_id={deployment_id}
                  stack={true}
                />
              </Grid> */}
              <Grid item xs={12}>
                <Comments
                  tabValue={tabValue}
                  setTabValue={setTabValue}
                  modelId={model_id}
                  versionNumber={null}
                  deploymentId={deployment_id}
                  type={"deployments"}
                />
              </Grid>
            </Grid>
          </XBox>
        </Slide>
      </Grid>
    </Grid>
  );
}

export default SingleDeploymentOverview;

//Add PropTypes here
SingleDeploymentOverview.propTypes = {
  setTabValue: PropTypes.func,
  tabValue: PropTypes.number,
};

function reformatData(payload, response) {
  // Extract the payload and breakdown data
  const payloadData = payload[0];
  const breakdown = response.breakdown;

  // Initialize the output object and starting value
  let output = {};
  let startValue = 0.0;

  for (const key in breakdown) {
    // Check if the key exists in the payload
    if (payloadData.hasOwnProperty(key)) {
      // Determine the classification based on value (positive or negative)
      const classification = breakdown[key] >= 0 ? "positive" : "negative";

      // Calculate the end value based on start and breakdown value
      const endValue = startValue + breakdown[key];

      // Construct the output object for the key
      output[key] = {
        keyName: key,
        field: String(payloadData[key]),
        value: breakdown[key],
        checked: false,
        start: startValue,
        end: endValue,
        class: classification,
      };

      // Update the start value for the next iteration
      startValue = endValue;
    }
  }

  return output;
}
