import { Menu, MenuItem} from "@mui/material";
import React, { useState } from "react";
import { PropTypes } from "prop-types";

import { ReactComponent as DotsIcon } from "assets/images/icons/datasets/dots-vertical.svg";
import { ReactComponent as RepeatIcon } from "assets/images/icons/datasets/repeat-icon.svg";
import { ReactComponent as DeleteIcon } from "assets/images/icons/datasets/delete-icon.svg";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import { usePreviewQuery } from "api/query";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useAuth0 } from "@auth0/auth0-react";
import { PreviewTable } from "../PreviewTable";
import { useDatasetMutation } from "api/mutations";
import { queryClient } from "api/queryClient";
import { QUERY_CONSTANTS } from "constants";
import { useToast } from "hooks";
import { useXplainableController } from "context";
import { XImg } from "components/XImg";
import colors from "assets/theme/base/colors";

export const UploadedFile = ({ file, onReplace, width, onSelect }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { activeWorkspace } = useApiKey();
  const { logout } = useAuth0();

  const { data: previewData, isLoading } = usePreviewQuery(
    activeWorkspace,
    file.dataset_id,
    logout
  );
  const { deleteDatasetMutation } = useDatasetMutation();
  const { showSuccessToast, showErrorToast } = useToast();

  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteFile = () => {
    if (!file) return;

    deleteDatasetMutation([activeWorkspace, file.dataset_id], {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_CONSTANTS.DATASET);
        showSuccessToast("Dataset successfully deleted");
      },
      onError: (err) => {
        showErrorToast("Error");
      },
    });
  };

  return (
    <XBox
      sx={{
        border: "1px solid #EAEAEA",
        borderRadius: "16px",
        height: "224px",
        width: width ? width : "341px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        cursor: "pointer",
        // backgroundColor: colors.white.main,
      }}
      onClick={
        onSelect
          ? (e) => {
              console.log(e.target.tagName);
              if (e.target.tagName !== "svg" && e.target.tagName !== "LI") {
                onSelect(file);
              }
            }
          : () => {}
      }
    >
      <XBox
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
        }}
      >
        {previewData?.data.length > 0 && !isLoading ? (
          <PreviewTable data={previewData?.data} />
        ) : (
          <XBox
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <LoadingSpinner animationType="pulse" size={50} />
          </XBox>
        )}
      </XBox>

      <XBox
        sx={{
          display: "flex",
          position: "relative",
          justifyContent: "space-between",
          position: "relative",
          padding: "8px 16px",
          height: "76px",
          textAlign: "start",
          fontWeight: 700,
          borderBottomLeftRadius: "16px",
          borderBottomRightRadius: "16px",
          backgroundColor: darkMode ? "#0000" : "#FFF",
        }}
      >
        <XBox
          sx={{
            height: "30px",
            // backgroundColor: darkMode ? "#0000" : "#FFF",
            width: "100%",
            opacity: 0.7,
            position: "absolute",
            top: "-20px",
            left: 0,
            zIndex: 10,
          }}
        ></XBox>
        <XBox>
          <XTypography color={darkMode ? "white" : "main"} fontSize="16px" fontWeight="bold">
            {file?.dataset_name?.length > 24
              ? file?.dataset_name?.slice(0, 24) + "..."
              : file?.dataset_name}
          </XTypography>
          <XBox
            mt={1}
            sx={{
              paddingX: "8px",
              backgroundColor: "#F0F8FF",
              width: "fit-content",
              borderRadius: "100px",
            }}
          >
            <XTypography
              sx={{
                fontSize: "12px",
                color: "#0067BD",
              }}
            >
              Static
            </XTypography>
          </XBox>
        </XBox>

        <XBox
          sx={{ cursor: "pointer" }}
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <DotsIcon />
        </XBox>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            onClick={() => onReplace(file)}
            style={{ color: darkMode ? "white" : "black" }}
            sx={{ display: "flex", gap: "10px" }}
          >
            <XImg>
              <RepeatIcon />
            </XImg>
            Replace data
          </MenuItem>
          <MenuItem
            style={{ color: "red" }}
            sx={{ display: "flex", gap: "10px" }}
            onClick={handleDeleteFile}
          >
            <DeleteIcon />
            Delete
          </MenuItem>
        </Menu>
      </XBox>
    </XBox>
  );
};

UploadedFile.propTypes = {
  file: PropTypes.any,
  onReplace: PropTypes.func,
  width: PropTypes.string,
  onSelect: PropTypes.func,
};
