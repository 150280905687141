import { Card } from "@mui/material";
import XBox from "components/XBox";
import React from "react";
import { PropTypes } from 'prop-types';
import { useXplainableController } from "context";
import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";

export const PreviewTable = ({ data }) => {
  const [controller] = useXplainableController();
  const { darkMode } = controller;
  return (

    <Card>
      <XBox sx={{ overflowX: "auto", mt:4}}>
      <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            tableLayout: "fixed",
            borderTop: darkMode ? "3px solid #fff" : `3px solid ${colors.xpblue.main}`,
            color: darkMode ? "#fff" : colors.xpblue.main, // Correct text color syntax
          }}
        >
          <thead>
            <tr>
              {Object.keys(data[0]).map((header) => (
                <th
                  key={header}
                  style={{
                    fontSize: "12px",
                    padding: "4px",
                    border: "1px solid #EAEAEA",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    width: "100px",
                    backgroundColor: `${rgba(colors.xpblue.main,0.2)}`
                  }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.slice(0, 5).map((row, index) => (
              <tr key={index}>
                {Object.keys(row).map((key) => (
                  <td
                    key={key}
                    style={{
                      fontSize: "12px",
                      padding: "4px",
                      border: "1px solid #EAEAEA",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      maxWidth: "100px",
                      color: darkMode ? "white" : "black"
                    }}
                  >
                    {row[key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </XBox>
    </Card>
  );
};

PreviewTable.propTypes = {
    data: PropTypes.any
}