import { useState, useEffect } from "react";

// xplainable Dashboard components
import XBox from "components/XBox";
import { Card } from "@mui/material";

// xplainable dashboard example components
import DataHealth from "./components/health";

import ScrollReveal from "scrollreveal";
import PreprocessingPipeline from "shared/models/Pipeline";
import LinkPreprocessor from "shared/models/LinkPreprocessor";

function Preprocessing() {
  const [preprocessorData, setPreprocessorData] = useState(null);

  useEffect(() => {
    ScrollReveal().reveal(".scrollreveal", {
      delay: 0,
      distance: "100px",
      duration: 700,
      easing: "cubic-bezier(0.5, 0, 0, 1)",
      origin: "bottom",
      interval: 100,
    });
  }, []);

  useEffect(() => {
    if (preprocessorData) {
      ScrollReveal().reveal(".scrollreveal2", {
        delay: 0,
        distance: "100px",
        duration: 700,
        easing: "cubic-bezier(0.5, 0, 0, 1)",
        origin: "bottom",
        interval: 100,
      });
    }
  }, [preprocessorData]);

  return (
    <>
      <XBox py={2}>
        <XBox className="scrollreveal">
          <Card sx={{ p: 2, position: "relative", zIndex: 1, overflow: "visible" }}>
            <LinkPreprocessor updatePreprocessorData={setPreprocessorData} />
          </Card>
        </XBox>
        {preprocessorData && (
          <XBox className="scrollreveal2" mt={2}>
            <Card sx={{ p: 2, position: "relative", zIndex: 0, overflow: "visible" }}>
              <PreprocessingPipeline preprocessorData={preprocessorData} />
            </Card>
          </XBox>
        )}
        <XBox className="scrollreveal" mt={2}>
          <Card sx={{ p: 2, position: "relative", zIndex: 1, overflow: "visible" }}>
            <DataHealth />
          </Card>
        </XBox>

        {/* <Versioning /> */}
      </XBox>
    </>
  );
}

export default Preprocessing;
