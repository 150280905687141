// import React, { useState, useEffect } from "react";
// import XButton from "components/XButton";
// import PropTypes from "prop-types";
// import XTypography from "components/XTypography";
// import XSelect from "components/XSelect";
// import XBox from "components/XBox";
// import Icon from "@mui/material/Icon";
// import { Card, Grid } from "@mui/material";
// import { useApiKey } from "components/Authorisation/ApiKeyContext";
// const hostUrl = process.env.REACT_APP_HOST_URL;

// const DeploymentModal = ({ onCreateDeployment }) => {
//   const [open, setOpen] = useState(false);
//   const { apiKey, activeWorkspace } = useApiKey();
//   const [models, setModels] = useState([]);
//   const [selectedModel, setSelectedModel] = useState(null);
//   const [versions, setVersions] = useState([]);
//   const [selectedVersion, setSelectedVersion] = useState(null);
//   const [partitions, setPartitions] = useState([]);
//   const [selectedPartition, setSelectedPartition] = useState(null);

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const handleModelChange = (event) => {
//     const modelId = event.value;
//     setSelectedPartition(null);
//     setSelectedModel(event);
//     const foundModel = models.find((model) => model.model_id === modelId);
//     setVersions(foundModel ? foundModel.versions : []);
//   };

//   const handleVersionChange = (event) => {
//     setSelectedVersion(event);
//   };

//   const handlePartitionChange = (event) => {
//     setSelectedPartition(event);
//   };

//   const fetchProjects = async () => {
//     try {
//       const response = await fetch(
//         `${hostUrl}/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models`,
//         {
//           headers: {
//             api_key: apiKey,
//           },
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Failed to fetch projects");
//       }

//       const fetchedProjects = await response.json();

//       //Return the available deployments
//       const availableDeployments = fetchedProjects.filter((x) => x.deployed !== true);

//       console.log(fetchedProjects, "fetched");
//       //Set the available deployements
//       setModels(availableDeployments);
//     } catch (error) {
//       console.error("Error fetching projects:", error);
//     }

//     // setLoading(false)
//   };

//   // Fetch projects from the /models endpoint
//   useEffect(() => {
//     if (apiKey) {
//       fetchProjects();
//     }
//   }, []);

//   const getPartitions = async (model_id, version_id) => {
//     try {
//       //Get the Partition ID
//       const response = await fetch(
//         `${hostUrl}/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/versions/${version_id}`,
//         {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             api_key: apiKey,
//           },
//         }
//       );

//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }

//       const data = await response.json();
//       // console.log("the found partitions are: ",data)

//       //Set the partition IDs
//       const partitions = data.partitions.map((item) => ({
//         partition_id: item.partition_id,
//         partition_value: item.partition,
//       }));
//       setPartitions(partitions);
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };

//   // Fetch projects from the /models endpoint
//   useEffect(() => {
//     if (selectedVersion) {
//       getPartitions(selectedModel.value, selectedVersion.value);
//     }
//   }, [selectedVersion]);

//   return (
//     <div
//       style={{ position: "relative", display: "inline-block" }}
//       onMouseOver={handleOpen}
//       onMouseLeave={handleClose}
//     >
//       <XButton size="medium" id="new-deployment-button" style={{ zIndex: 1301 }} color="xpblue">
//         <Icon>add</Icon>
//         <XTypography variant="button" color="white" ml={0.5}>
//           Create New Deployment
//         </XTypography>
//       </XButton>
//       {open && (
//         <Card
//           sx={{
//             position: "absolute", // Make the box appear above other elements
//             width: "300px",
//             p: 2,
//             right: "0px",
//             borderRadius: "10px",
//             boxShadow: 3, // Some shadow for styling
//             zIndex: 10, // Make sure it appears above other elements
//           }}
//           onMouseLeave={handleClose}
//         >
//           <XBox p={2} sx={{ position: "relative", overflow: "visible", zIndex: 100 }}>
//             <XBox sx={{ position: "relative", overflow: "visible", zIndex: 100 }}>
//               <XTypography variant="h6" align="center">
//                 New Deployment
//               </XTypography>
//             </XBox>
//             <Grid container>
//               <Grid item xs={12} md={12}>
//                 <XTypography variant="overline">Model</XTypography>
//                 <XSelect
//                   value={selectedModel}
//                   onChange={handleModelChange}
//                   fullWidth
//                   placeholder="Select Model"
//                   options={models.map((model) => ({
//                     value: model.model_id,
//                     label: model.model_name,
//                   }))}
//                 />
//               </Grid>

//               <Grid item xs={12} md={12}>
//                 <XTypography variant="overline">Version</XTypography>
//                 <XSelect
//                   value={selectedVersion}
//                   onChange={handleVersionChange}
//                   fullWidth
//                   placeholder="Select Version"
//                   options={versions.map((version) => ({
//                     value: version.version_id,
//                     label: version.version_number,
//                   }))}
//                 />
//               </Grid>

//               <Grid item xs={12} md={12}>
//                 <XTypography variant="overline">Location</XTypography>
//                 <XSelect
//                   value={selectedPartition}
//                   onChange={handlePartitionChange}
//                   fullWidth
//                   isDisabled={true}
//                   placeholder="Sydney"
//                   // options={partitions.map((partition) => ({
//                   // value: partition.partition_id,
//                   // label: partition.partition_value,
//                   // }))}
//                 />
//               </Grid>
//               <Grid item xs={12} mt={1}>
//                 <XButton
//                   fullWidth
//                   variant="contained"
//                   color="primary"
//                   onClick={async () => {
//                     const foundModel = models.find(
//                       (model) => model.model_id === selectedModel.value
//                     );
//                     if (foundModel && selectedVersion) {
//                       const newDeployment = {
//                         model_id: foundModel.model_id,
//                         model_name: foundModel.model_name,
//                         model_description: foundModel.model_description,
//                         model_type: foundModel.model_type,
//                         target_name: foundModel.target_name,
//                         created: foundModel.created,
//                         created_by: foundModel.created_by,
//                         archived: foundModel.archived,
//                         active: foundModel.active,
//                         active_version: foundModel.active_version,
//                         contributors: foundModel.contributors,
//                         versions: foundModel.versions,
//                         selected_version: selectedVersion.value,
//                         // selected_partition: selectedPartition.value,
//                       };
//                       onCreateDeployment(newDeployment);
//                       handleClose();
//                     }
//                   }}
//                 >
//                   Create
//                 </XButton>
//               </Grid>
//             </Grid>
//           </XBox>
//         </Card>
//       )}
//     </div>
//   );
// };

// export default DeploymentModal;

// DeploymentModal.propTypes = {
//   onCreateDeployment: PropTypes.func,
// };
