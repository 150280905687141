import { useReportMutation } from "api/mutations/useReportMutation";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useXplainableController } from "context";
import { useApp, useModel } from "hooks";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Paths } from "settings";

export const useCreateReport = () => {
  const {
    access,
    modelState,
    selectedVersion,
    handleVersionChange,
    partitions,
    selectedPartition,
    handlePartitionChange,
  } = useModel();
  const { activeWorkspace, apiKey, viewState } = useApiKey();
  const {
    lists,
    activeStep,
    setActiveStep,
    reportId,
    reportList,
    setReportList,
    commentsValues,
    selectedModel,
    reportInfo,
  } = useApp();
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const report_id = searchParams.get("report_id");

  const { createReportVersionMutation } = useReportMutation();

  const [controller] = useXplainableController();
  const { globalImage, globalColor } = controller;

  const [listsFromRequest, setListsFromRequest] = useState(null);
  const [reportVersion, setReportVersion] = useState({ value: 1, label: "Version: 1" });

  const steps = ["Build", "Create"];

  const { versions } = modelState;

  useEffect(() => {
    if (report_id) {
      setActiveStep(1);
    }
  }, [report_id]);

  const handleNextButton = () => {
    if (activeStep === 0) {
      const elements = Object.values(lists)
        .filter((list) => list.length > 0)
        .map((field) => {
          const element = {
            component: field[0]?.type,
            componentData: field[0]?.type === "comments" ? commentsValues[field[0]?.id] : "",
            numberOfColumns: field[0]?.numberOfColumns,
          };

          return element;
        });

      const reportData = {
        data: [
          {
            elements,
            info: reportInfo,
          },
        ],
      };

      createReportVersionMutation([activeWorkspace, apiKey, reportId, JSON.stringify(reportData)], {
        onSuccess: (data) => {
          navigate(
            `${Paths.reports.index}/${selectedModel?.value || selectedModel}?report_id=${reportId}`
          );

          const reportVersionId = data.data.report_version_id;
          const newVersionNumber = reportList
            ? reportList.length + 1
            : listsFromRequest
            ? listsFromRequest.length + 1
            : 1;

          const newTest = reportList
            ? [...reportList]
            : listsFromRequest
            ? [...listsFromRequest]
            : [];

          newTest.push({
            report_version_id: reportVersionId,
            data: reportData.data,
            version_number: newVersionNumber,
          });

          setActiveStep(activeStep + 1);
          setReportVersion({ value: newVersionNumber, label: `Version: ${newVersionNumber}` });

          if (listsFromRequest) {
            setListsFromRequest(null);
          }

          setReportList(newTest);
        },
        onError: (error) => {
          console.log(error);
        },
      });
    }
  };

  const handlePreviousButton = () => {
    setActiveStep(activeStep - 1);
  };

  const handleReportVersionChange = (event) => {
    setReportVersion(event);
  };
  return {
    access,
    activeStep,
    lists,
    selectedVersion,
    handleVersionChange,
    partitions,
    selectedPartition,
    handlePartitionChange,
    globalImage,
    globalColor,
    viewState,
    listsFromRequest,
    setListsFromRequest,
    reportVersion,
    steps,
    versions,
    handleNextButton,
    handlePreviousButton,
    handleReportVersionChange,
  };
};
