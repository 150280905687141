import React, { useCallback, useState, useMemo, useEffect, useRef } from "react";
import { useApiKey } from "components/Authorisation/ApiKeyContext";

// Update user components information
import { Formik, Form } from "formik";
import FormField from "shared/Forms/FormField";
import * as Yup from "yup";
import { MdContentCopy, MdCheck } from "react-icons/md";

import { ErrorMessage } from "formik";
import colors from "assets/theme/base/colors";

import { Grid, Card, Tooltip, Icon, Modal, TextareaAutosize, DialogActions } from "@mui/material";
import PropTypes from "prop-types";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XProgress from "components/XProgress";
import ReuseableTimeline from "shared/Timeline/ReuseableTimeline";
import UsecaseSelect from "../UsecaseSelect";
import DataTable from "shared/Tables/DataTable";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import XButton from "components/XButton";
import XInput from "components/XInput";
import WarningIcon from "assets/images/warning-icon.svg";
import TableCellComponent from "./TableCellComponent";

import LightPlusIcon from "assets/images/icons/deployments/light-plus-icon.svg";

import { ReactComponent as DarkNotebookIcon } from "assets/images/icons/api-keys/dark-notebook-icon.svg";
import { ReactComponent as DarkDeploymentIcon } from "assets/images/icons/api-keys/dark-deployment-icon.svg";
import { ReactComponent as DarkOtherIcon } from "assets/images/icons/api-keys/dark-other-icon.svg";

import { useApiKeyQuery } from "api/query";
import { useApiKeyMutation } from "api/mutations";
import { useAuth0 } from "@auth0/auth0-react";
import { useXplainableController } from "context";

//Validation handlers
const initialValues = {
  description: "",
  days_until_expiry: "",
};

const validationSchema = Yup.object().shape({
  description: Yup.string()
    .matches(/.*[a-zA-Z].*/, "Description must include at least one letter")
    .required("Please enter a description"),
  days_until_expiry: Yup.number()
    .min(1, "Days until expiry must be at least 1")
    .max(90, "Days until expiry cannot exceed 90")
    .required("Days until expiry is required"),
});

const fields = {
  description: {
    label: "Description",
    name: "description",
    placeholder: "eg. Testing and exploration",
  },
  days_until_expiry: {
    label: "Days Until Expiry",
    name: "days_until_expiry",
    placeholder: "7",
  },
};

function Apis({ name, handleAdd, handleRemove }) {
  const { apiKey, activeWorkspace } = useApiKey();
  const { logout } = useAuth0();
  const [controller] = useXplainableController();

  const [copySuccess, setCopySuccess] = useState("Copy to Clipboard");
  const textAreaRef = useRef(null);

  const [tokenKey, setTokenKey] = useState("");
  const [error, setError] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [tableRows, setTableRows] = useState(name);
  const [selectedUsecase, setSelectedUsecase] = useState("Notebook");
  const [isOpen, setIsOpen] = useState(false);
  const [confirmInput, setConfirmInput] = useState("");
  const [isConfirmEnabled, setIsConfirmEnabled] = useState(false);
  const [copied, setCopied] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRevokeModalOpen, setIsRevokeModalOpen] = useState(false);
  const [step, setStep] = useState(0);

  const { data } = useApiKeyQuery(activeWorkspace, logout);
  const { createKeyMutation, revokeAllKeysMutation } = useApiKeyMutation();

  const handleUsecaseSelect = (selected) => {
    setSelectedUsecase(selected);
  };

  const createKey = async (keyData) => {
    // console.log("The key data is", keyData);
    createKeyMutation([activeWorkspace, JSON.stringify(keyData)], {
      onSuccess: (data) => {
        const item = {
          id: data.data,
          desc: keyData.description,
          created: getCurrentDate(),
          completion: 100,
          use: selectedUsecase,
        };

        const newState = [...tableRows];
        newState.push(item);
        setTableRows(newState);
        handleAdd(item);
        setTokenKey(data.data);

        return data;
      },
    });
  };

  useEffect(() => {
    if (!data) return;
    // console.log("The data for the api keys are", data.data);
    const adaptedData = data.data.map((item) => {
      const createdDate = new Date(item.created);
      const expiresDate = new Date(item.expires);
      const today = new Date();
      const completion = ((today - expiresDate) / (createdDate - expiresDate)) * 100;

      return {
        id: item.key_id,
        desc: { item: item.type, name: item.description },
        created: `${createdDate.getDate()} ${createdDate.toLocaleString("default", {
          month: "long",
        })}, ${createdDate.getFullYear()}`,
        expired: `${createdDate.getDate()} ${createdDate.toLocaleString("default", {
          month: "long",
        })}, ${createdDate.getFullYear()}`,
        completion: Math.min(Math.max(completion, 0), 100),
        use: item.type, // Replace with the correct use case value
      };
    });

    const sortedData = adaptedData.slice().sort(compareDates);
    setTableRows(sortedData || []);
    setIsLoading(false);
  }, [data]);

  const onAdd = useCallback(
    (description, days_until_expiry) => {
      const keyData = {
        description: description,
        days_until_expiry: Number(days_until_expiry),
        key_type: selectedUsecase,
      };

      createKey(keyData).then((newKey) => {
        setTokenKey(newKey, selectedUsecase);
      });
    },
    [handleAdd, tableRows, selectedUsecase]
  );

  const onRemove = useCallback(
    (index) => {
      const newState = [...tableRows];

      newState.splice(index, 1);
      setTableRows(newState);
      handleRemove(index);
    },
    [handleRemove, tableRows]
  );

  const revokeAllKeys = async () => {
    revokeAllKeysMutation({
      onSuccess: (data) => {
        console.log(data);
        return data;
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  // Adjust the revokeKeys function to handle opening the new modal
  const revokeKeys = () => {
    // Open the modal for confirmation instead of using SweetAlert
    setIsRevokeModalOpen(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: "",
        width: "5%",
        id: "function",
        Cell: (props) => (
          <TableCellComponent {...props} rowIndex={props.row.index} onRemove={onRemove} />
        ),
      },
      {
        Header: "Description",
        accessor: "desc",
        Cell: ({ value }) =>
          value ? <Description type={value.item} name={value.name} /> : "No Description", // If value is falsy, show "No Description"
      },
      {
        Header: "Created",
        accessor: "created",
        Cell: ({ value }) => (value ? value : "No Creation Date"), // If value is falsy, show "No Creation Date"
      },
      {
        Header: "Remaining",
        accessor: "completion",
        Cell: ({ value }) => (value ? <Completion value={value} /> : "No Remaining"), // If value is falsy, show "No Remaining"
      },
    ],
    [name, onRemove]
  );

  const handleClose = () => {
    setIsModalOpen(false);
    setStep(0);
  };

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setCopySuccess("Copied!");
  }

  function handleCloseCopyModal() {
    setIsOpen(false);
    setCopySuccess("Copy to Clipboard");
    setTokenKey(null);
    formikProps.resetForm();
    setExpanded(false);
  }

  // Check if the input value matches "confirm"
  useEffect(() => {
    setIsConfirmEnabled(confirmInput.toLowerCase() === "revoke-all-keys");
  }, [confirmInput]);

  // Function to handle the copy action
  const handleCopy = () => {
    navigator.clipboard.writeText("revoke-all-keys").then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 3000); // Reset icon state after 3 seconds
    });
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="request-title"
        aria-describedby="request-description"
      >
        <XBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -60%)",
            width: 600,
            background: controller.darkMode ? colors.background.dark : "white",
            borderRadius: "16px",
            padding: 2,
          }}
        >
          <XBox display="flex" justifyContent="space-between" alignItems="center">
            <XTypography variant="button" fontSize="16px">
              {step === 0 ? "Create API Key" : "API Key"}
            </XTypography>
            <Icon
              sx={({ typography: { size, fontWeightBold }, palette: { dark, white } }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                color: controller.darkMode ? white.main : dark.main,
                stroke: controller.darkMode ? white.main : dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
              })}
              onClick={handleClose}
            >
              close
            </Icon>
          </XBox>
          <Formik initialValues={initialValues} validationSchema={validationSchema}>
            {(formikProps) => (
              <Form id="api-key-form" autoComplete="off">
                {step === 0 ? (
                  <Grid container>
                    <Grid item xs={12} mt={2} sx={{ pr: { xs: 0, md: 2 } }}>
                      <FormField
                        as="input"
                        type="text"
                        id={fields.description.name}
                        label={fields.description.label}
                        name={fields.description.name}
                        placeholder={fields.description.placeholder}
                        error={formikProps.touched.description && formikProps.errors.description}
                        success={
                          formikProps.values.description.length > 0 &&
                          !formikProps.errors.description
                        }
                        textColor={colors.black.main}
                      />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <FormField
                        as="input"
                        type="text"
                        id={fields.days_until_expiry.name}
                        label={fields.days_until_expiry.label}
                        name={fields.days_until_expiry.name}
                        placeholder={fields.days_until_expiry.placeholder}
                        error={
                          formikProps.touched.days_until_expiry &&
                          formikProps.errors.days_until_expiry
                        }
                        success={
                          formikProps.values.days_until_expiry.length > 0 &&
                          !formikProps.errors.days_until_expiry
                        }
                        textColor={colors.black.main}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} mb={3}>
                      <XTypography variant="overline" fontWeight="bold">
                        What is this key for?
                      </XTypography>
                      <UsecaseSelect onSelection={handleUsecaseSelect} />
                    </Grid>
                    <Grid item xs={12} mt={1}>
                      <XButton
                        sx={{ alignSelf: "flex-end" }}
                        variant="gradient"
                        color="button"
                        size="medium"
                        onClick={() => {
                          formikProps.submitForm(); // submit the form
                          if (formikProps.isValid) {
                            // if the form is valid
                            setStep((prev) => prev + 1);
                            if (formikProps.isValid) {
                              onAdd(
                                formikProps.values.description,
                                formikProps.values.days_until_expiry
                              );
                            } else {
                              console.log("Form is not valid");
                            }
                          }
                        }}
                      >
                        Create API Key
                      </XButton>
                    </Grid>
                  </Grid>
                ) : (
                  <XBox>
                    <XTypography color="secondary" variant="button" fontWeight="light" mb={2}>
                      {"Keep this key safe. You won't be able to see it again."}
                    </XTypography>
                    <TextareaAutosize
                      ref={textAreaRef}
                      minRows={1}
                      style={{
                        width: "100%",
                        backgroundColor: colors.light.main,
                        color: "dark",
                        fontSize: "1.2rem",
                        border: "none",
                        borderRadius: "12px",
                        padding: "0.8rem",
                        marginTop: "10px",
                      }}
                      value={tokenKey}
                    />
                    <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <XButton
                        variant="dark"
                        onClick={handleCloseCopyModal}
                        sx={{ mx: 1, backgroundColor: colors.light.main }}
                      >
                        Close
                      </XButton>
                      <XButton
                        variant="gradient"
                        color="button"
                        onClick={(e) => {
                          copyToClipboard(e);
                        }}
                        sx={{ mx: 1 }}
                      >
                        {copySuccess}
                      </XButton>
                    </DialogActions>
                  </XBox>
                )}
              </Form>
            )}
          </Formik>
        </XBox>
      </Modal>
      <Modal
        open={isRevokeModalOpen}
        onClose={() => setIsRevokeModalOpen(false)}
        aria-labelledby="request-title"
        aria-describedby="request-description"
      >
        <XBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -60%)",
            width: 600,
            background: controller.darkMode ? colors.background.dark : colors.background.default,
            borderRadius: "16px",
            padding: 3,
          }}
        >
          <XBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <XBox />
            <Icon
              sx={({ typography: { size, fontWeightBold }, palette: { dark, white } }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                color: controller.darkMode ? white.main : dark.main,
                stroke: controller.darkMode ? white.main : dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
              })}
              onClick={() => setIsRevokeModalOpen(false)}
            >
              close
            </Icon>
          </XBox>
          <XBox
            display="flex"
            flexDirection="column"
            gap={3}
            justifyContent="center"
            textAlign="center"
          >
            <XBox width="100%" justifyContent="center" alignItems="center">
              <XBox src={WarningIcon} component="img" width={120} />
            </XBox>
            <XTypography fontSize="16px" fontWeight="medium">
              This will revoke access to all api keys for this workspace. This action cannot be
              undone.
            </XTypography>
            <XBox
              sx={{
                backgroundColor: "rgba(colors.secondary.main, 0.1)",
                padding: 0.5,
                borderColor: "grey.300", // Use the appropriate gray from your theme here
                border: 1, // Specifies the border width and style
                borderStyle: "solid", // This makes the border solid
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between", // Corrected justifyContent value
              }}
            >
              <XBox>
                <XTypography fontSize="16px" fontWeight="light" color="secondary">
                  {`Type "revoke-all-keys" into the input field to proceed`}
                </XTypography>
              </XBox>
              <Tooltip title="Copy to clipboard" placement="top">
                <XBox
                  sx={{
                    p: 1.5,
                    borderColor: "grey.300",
                    border: 1,
                    borderStyle: "solid",
                    borderRadius: "8px",
                    display: "flex", // Ensure content is aligned horizontally
                    alignItems: "center", // Center items vertically
                    justifyContent: "space-between", // Space between the text and icon
                  }}
                >
                  {copied ? (
                    <MdCheck size="24" />
                  ) : (
                    <MdContentCopy size="24" onClick={handleCopy} style={{ cursor: "pointer" }} />
                  )}
                </XBox>
              </Tooltip>
            </XBox>
            <XInput value={confirmInput} onChange={(e) => setConfirmInput(e.target.value)} />
            <XBox display="flex" justifyContent="space-between" width="100%" gap={2}>
              <XButton
                sx={{ width: "calc(80% - 8px)", mr: 2 }} // Adjust width to account for margin, assuming a box-sizing of border-box
                variant="gradient"
                color="error"
                onClick={() => {
                  if (isConfirmEnabled) {
                    revokeAllKeys(); // Call your function to revoke all keys
                    setIsRevokeModalOpen(false); // Close the modal
                  }
                }}
                disabled={!isConfirmEnabled}
              >
                Revoke all keys
              </XButton>
              <XButton
                sx={{ width: "20%" }}
                variant="gradient"
                color="light"
                onClick={() => setIsRevokeModalOpen(false)} // Assuming this closes the modal
              >
                Cancel
              </XButton>
            </XBox>
          </XBox>
        </XBox>
      </Modal>

      <XBox ml={1} display="flex" justifyContent="flex-end" mb={2}>
        <XBox display="flex" gap={2}>
          <XButton
            sx={{ color: "#EA2C4C", boxShadow: "none", background: "transparent" }}
            size="medium"
            onClick={() => revokeKeys()}
          >
            Revoke All Keys
          </XButton>
          <XButton
            onClick={() => setIsModalOpen(true)}
            variant="gradient"
            color="button"
            sx={{ gap: "8px", display: "flex" }}
          >
            {/* <XBox component="img" src={LightPlusIcon} /> */}
            Create API Key
          </XButton>
        </XBox>
      </XBox>
      <Grid spacing={3} container>
        <Grid item xs={12} lg={8}>
          <Card>
            {isLoading ? (
              <XBox height={"600px"}>
                <LoadingSpinner size={40} />
              </XBox>
            ) : error ? (
              <XBox height={"600px"}>
                <ErrorMessage message="Failed to fetch API keys. Please try again later." />
              </XBox>
            ) : (
              <DataTable
                handleRowClick={null}
                table={{ rows: tableRows, columns: columns }}
                entriesPerPage={{
                  defaultValue: 10,
                  entries: [5, 7, 10, 15, 20, 25],
                }}
                canSearch
              />
            )}
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <XBox>
            <ReuseableTimeline
              title={"API Key Timeline"}
              tableRows={tableRows}
              team_id={activeWorkspace?.team_id}
              event={[
                "created_api_key",
                "revoked_api_key",
                "deleted_api_key",
                "revoked_all_user_api_keys",
              ]}
            />
          </XBox>
        </Grid>
      </Grid>
    </>
  );
}

export default React.memo(Apis);

function Description({ type, name }) {
  // Map type to the corresponding icon component
  const iconMap = {
    Notebook: DarkNotebookIcon,
    Deployment: DarkDeploymentIcon,
    Other: DarkOtherIcon,
  };

  // Default to DarkNotebookIcon if type is not found in the map
  const IconComponent = iconMap[type] || DarkNotebookIcon;

  return (
    <XBox display="flex" alignItems="center">
      <IconComponent style={{ stroke: "#7C7C7C", width: "30px", height: "30px" }} />
      <XBox ml={2}>
        <XTypography variant="button" fontWeight="medium" color="text">
          {name}
        </XTypography>
      </XBox>
    </XBox>
  );
}

function Completion({ value }) {
  let color;
  if (value <= 30) {
    color = "error";
  } else if (value > 30 && value <= 70) {
    color = "info";
  } else {
    color = "success";
  }
  return (
    <XBox display="flex" alignItems="center">
      <XBox width="8rem" ml={1} label={true}>
        <XProgress value={value.toFixed(2)} color={color} label={true} />
      </XBox>
    </XBox>
  );
}

function getCurrentDate() {
  const today = new Date();
  const day = today.getDate();
  const month = today.toLocaleString("default", { month: "long" });
  const year = today.getFullYear();
  return `${day} ${month}, ${year}`;
}

function parseDateString(dateString) {
  const dateParts = dateString.split(" ");
  const day = parseInt(dateParts[0], 10);
  const month = new Date(Date.parse(`${dateParts[1]} 1, 2021`)).getMonth();
  const year = parseInt(dateParts[2], 10);
  return new Date(year, month, day);
}

function compareDates(a, b) {
  const dateA = parseDateString(a.created);
  const dateB = parseDateString(b.created);
  return dateB - dateA;
}

Completion.propTypes = {
  value: PropTypes.number,
  color: PropTypes.string,
};

Description.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
};

Apis.propTypes = {
  value: PropTypes.number,
  row: PropTypes.number,
  name: PropTypes.string.isRequired,
  handleAdd: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
};
