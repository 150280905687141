import React, { useCallback, useState, useMemo, useEffect, useRef } from "react";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { Formik, Form } from "formik";
import Swal from "sweetalert2";

import { Collapse } from "@mui/material";
import { AddressCell, Completion } from "shared/Tables/DataTable/StyledCell";
import { Grid, Card, Tooltip, Icon } from "@mui/material";

import PropTypes from "prop-types";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XAvatar from "components/XAvatar";

import TableCellComponent from "./components/TableCellComponent";
import DataTable from "shared/Tables/DataTable";

// Update user components information
import { useOrganisationsQuery } from "api/query";
import { useOrganisationMutation } from "api/mutations";
import { useAuth0 } from "@auth0/auth0-react";

const hostUrl = process.env.REACT_APP_HOST_URL;

function Organisations({ handleRowClick, tableRows, setTableRows }) {
  const { apiKey, workspaces } = useApiKey();
  const { logout } = useAuth0();

  const { data } = useOrganisationsQuery(logout);
  const { createOrganisationMutation, deleteOrganisationMutation } = useOrganisationMutation();

  useEffect(() => {
    if (!data) return;
    setTableRows(data.data);
  }, [data]);

  const handleSubmit = (values, actions) => {
    const item = {
      organisation_name: values.orgName,
      country: values.country,
      state: values.state,
      logo_link: values.logo,
      postcode: values.postCode,
      address: values.address,
      days_until_expiry: 365,
    };

    createOrganisationMutation([JSON.stringify(item)], {
      onSuccess: () => {
        const newState = [...tableRows];

        // Add the items required for the table render
        item.teams = 1;
        item.completion = 100;
        item.status = "active";

        // Add the organisation id to the item
        newState.push(item);
        setTableRows(newState);

        // Reset the form
        actions.resetForm();
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const onRemove = useCallback(
    (index) => {
      //Get the organisation id from the index
      const organisation_id = tableRows[index].organisation_id;

      deleteOrganisationMutation([organisation_id], {
        onSuccess: () => {
          const newState = [...tableRows];
          newState.splice(index, 1);
          setTableRows(newState);
        },
        onError: (err) => {
          console.log(err);
        },
      });
    },
    [tableRows]
  );

  const showAlert = (index) => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-success",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "This will remove the organisation!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
          onRemove(index);
          handleRowClick({});
        }
      });
  };

  const columns = useMemo(
    () => [
      // {
      //   Header: "",
      //   id: "function",
      //   width: "5%",
      //   Cell: (props) => {
      //     const organisation = workspaces.find(
      //       (item) => item.organisation_id === props.row.original.organisation_id
      //     );
      //     return (
      //       <TableCellComponent
      //         {...props}
      //         rowIndex={props.row.index}
      //         onRemove={onRemove}
      //         disabled={!organisation?.is_admin}
      //       />
      //     );
      //   },
      // },
      {
        Header: "Organisation Name",
        // accessor: "team_name",
        id: "organisation_name",
        accessor: (data) => ({
          logo_link: data.logo_link,
          organisation_name: data.organisation_name,
        }),
        Cell: ({ value }) =>
          value ? <OrgCell icon={value.logo_link} name={value.organisation_name} /> : null,
      },
      {
        Header: "Address",
        id: "address",
        accessor: (data) => ({
          address: data.address,
          state: data.state,
          country: data.country,
          postcode: data.postcode,
        }),
        Cell: ({ value }) => <AddressCell addressDetails={value} />,
      },
      {
        Header: "Teams",
        accessor: "teams",
        Cell: ({ value }) => value || "",
      },
      {
        Header: "Expiry Days",
        accessor: "expiryDays",
        Cell: ({ value }) => value || "",
      },
      {
        Header: "Remaining",
        accessor: "completion",
        Cell: ({ value }) => (value ? <Completion value={value} /> : null),
      },
      // {
      //     Header: "Status",
      //     accessor: "status",
      //     Cell: ({ value }) => value === "active" ? active : archived,
      // }
    ],
    [onRemove]
  );

  return (
    <Grid spacing={3} container>
      <Grid item xs={12}>
        <Card>
          <DataTable
            handleRowClick={handleRowClick}
            table={{ rows: tableRows, columns: columns }}
            entriesPerPage={{
              defaultValue: 5,
              entries: [5, 7, 10, 15, 20, 25],
            }}
            canSearch
          />
        </Card>
      </Grid>
    </Grid>
  );
}

Organisations.propTypes = {
  handleRowClick: PropTypes.func,
  value: PropTypes.number,
  row: PropTypes.number,
  name: PropTypes.string,
  tableRows: PropTypes.array,
  setTableRows: PropTypes.func,
};

function OrgCell({ icon, name }) {
  return (
    <XBox display="flex" alignItems="center" pr={2}>
      <XAvatar src={icon} alt={"Logo"} variant="full" size="sm" />
      <XBox display="flex" flexDirection="column" ml={2}>
        <XTypography variant="button" fontWeight="medium">
          {name}
        </XTypography>
      </XBox>
    </XBox>
  );
}

OrgCell.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export const OrganisationTable = React.memo(Organisations);
export { OrgCell };
