import React, { useState, useEffect } from "react";
import { useModel } from "hooks";
import { useApiKey } from "components/Authorisation/ApiKeyContext";

import { Grid, Icon, Tooltip } from "@mui/material";
import XBox from "components/XBox";
import XSelect from "components/XSelect";
import XTypography from "components/XTypography";
import XButton from "components/XButton";

import MiniStatisticsCard from "shared/Cards/MiniStatisticsCard";
import BoxPlot from "./components/Boxplot";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import DefaultItem from "shared/Items/DefaultItem";

import { FaFont, FaUnlink } from "react-icons/fa";
import { BsDice5 } from "react-icons/bs";
import { AiOutlineNumber } from "react-icons/ai";
import { LiaBalanceScaleSolid } from "react-icons/lia";

import {
  Functions,
  ShowChart,
  BarChart,
  ScatterPlot,
  Error,
  TrendingUp,
} from "@mui/icons-material";
import { useHealthQuery } from "api/query";
import { useAuth0 } from "@auth0/auth0-react";

const DataHealth = () => {
  const { apiKey, activeWorkspace } = useApiKey();
  const { model_id, selectedVersion, selectedPartition } = useModel();
  const { logout } = useAuth0();

  const [healthData, setHealthData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState(null);

  const { 
    data, 
    isLoading 
  } = useHealthQuery(activeWorkspace, apiKey, model_id, selectedVersion, logout);

  useEffect(() => {
    if (data) {
      setHealthData(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (
      healthData &&
      selectedPartition &&
      healthData[selectedPartition.label] &&
      healthData[selectedPartition.label].length > 0
    ) {
      setSelectedData(healthData[selectedPartition.label]);
      setSelectedFeature(healthData[selectedPartition.label][0].feature);
    }
  }, [healthData, selectedPartition]);

  useEffect(() => {
    if (selectedData && selectedFeature) {
      const selected = selectedData.find((item) => item.feature === selectedFeature);
      if (selected) {
        setFilteredData(selected);
      } else {
        setFilteredData([]); // Or whatever you want to set when no match is found
      }
    }
  }, [selectedFeature]);

  const handleSelectFeature = (event) => {
    const selected = selectedData.find((item) => item.feature === event.value);
    console.log("The event value is ", event.value);
    setSelectedFeature(event.value);
  };

  const excludedKeys = ["type", "median", "std", "25%", "50%", "75%", "min", "max"];
  const metadata = {
    mean: {
      icon: <Functions />,
      tooltipText: "Mean represents the average value.",
    },
    kurtosis: {
      icon: <ShowChart />,
      tooltipText: "Kurtosis measures the tail-heaviness of the distribution.",
    },
    skewness: {
      icon: <BarChart />,
      tooltipText: "Skewness measures the asymmetry of the distribution.",
    },
    variance: {
      icon: <ScatterPlot />,
      tooltipText: "Variance indicates the spread of the dataset.",
    },
    missing_pct: {
      icon: <Error />,
      tooltipText: "Percentage of missing data in the dataset.",
    },
    mode: {
      icon: <TrendingUp />,
      tooltipText: "Mode is the most frequently occurring value.",
    },
    nunique: {
      icon: <AiOutlineNumber />,
      tooltipText: "Number of unique values in the dataset.",
    },
    mixed_case: {
      icon: <FaFont />,
      tooltipText: "Indicates presence of both uppercase and lowercase values.",
    },
    mixed_type: {
      icon: <BsDice5 />,
      tooltipText: "Presence of multiple data types in the dataset.",
    },
    cardinality: {
      icon: <FaUnlink />,
      tooltipText: "Indicates the number of distinct values.",
    },
    category_imbalance: {
      icon: <LiaBalanceScaleSolid />,
      tooltipText: "Shows imbalance across different categories in the dataset.",
    },
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <XTypography variant="h5" fontSize="18px">
          Data Health
        </XTypography>
        <XTypography variant="button" color="secondary" fontWeight="light">
          Data health of post-processed data used for training
        </XTypography>
      </Grid>
      {isLoading ?
        (
        <XBox 
          height="440px" 
          width="100%"
          justifyContent= 'center' // Centers horizontally
          alignItems= 'center' // Centers vertically
          display= 'flex' // Displays children as flex items
        >
          <LoadingSpinner size={50} />
        </XBox>
        )
        :
        <>
        <Grid item xs={12}>
        <XBox>
          <XTypography fontWeight="light" variant="button">
            Feature
          </XTypography>
          <XBox display="flex">
            <XSelect
              value={{ value: selectedFeature, label: selectedFeature }}
              onChange={handleSelectFeature}
              fullWidth
              placeholder="Select Version"
              options={selectedData.map((data) => ({
                value: data.feature,
                label: data.feature,
              }))}
            />
          </XBox>
        </XBox>
      </Grid>
      <Grid item xs={12}>
        <XTypography fontWeight="light" variant="button">
          Description
        </XTypography>
        <XBox display="flex" alignItems="center">
          <XTypography fontWeight="light" variant="button" color="secondary">
            {filteredData?.description || "No description is set"}
          </XTypography>
        </XBox>
      </Grid>
      <XBox mt={1} p={3}>
        <XTypography fontWeight="light" variant="button">
          Key Metrics
        </XTypography>
        <Grid container spacing={2}>
          {filteredData && filteredData.health && (
            Object.entries(filteredData.health)
              .filter(([key, value]) => !excludedKeys.includes(key))
              .map(([key, value]) => (
                <Grid item xs={12} sm={6} md={4} key={key}>
                  <DefaultItem
                    color="xpblue"
                    icon={metadata[key].icon}
                    title={key}
                    description={
                      typeof value === "number"
                        ? Number(
                            value.toFixed(
                              Math.min((value.toString().split(".")[1] || "").length, 4)
                            )
                          )
                        : value
                    }
                    tooltipText={metadata[key].tooltipText}
                    borderRadius="lg"
                  />
                </Grid>
              ))
          )}
        </Grid>
      </XBox>
      <Grid item xs={12}>
        {filteredData && filteredData.health && filteredData.health.type === "numeric" && (
          <>
            <XTypography color="secondary" variant="h6">
              Distribution
            </XTypography>
            <BoxPlot data={filteredData.health} />
          </>
        )}
      </Grid>
      </>
    }

    </Grid>
  );
};

export default DataHealth;
