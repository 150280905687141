import { useEffect, useRef, useState } from "react";

import { useCommentsMutation } from "api/mutations";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { handleTokenExpired } from "utils";

import { debouncedSearch } from "utils";

import breakpoints from "assets/theme/base/breakpoints";
import { useAuth0 } from "@auth0/auth0-react";
import { useCommentsQuery, useCommentsUsersQuery } from "api/query";

export const useComments = ({
  modelId,
  deploymentId,
  collectionId,
  versionId,
  type,
  tabValue,
  version,
}) => {
  const { apiKey, user, activeWorkspace } = useApiKey();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreComments, setHasMoreComments] = useState(true);

  const itemsPerPage = 10;

  const { logout } = useAuth0();
  const {
    data,
    isLoading,
    refetch: commentsRefetch,
  } = useCommentsQuery({
    activeWorkspace,
    modelId,
    deploymentId,
    collectionId,
    type,
    pageNumber: currentPage,
    itemsPerPage,
    logout,
  });

  const { refetch } = useCommentsUsersQuery({ activeWorkspace, logout });
  const { addCommentMutation } = useCommentsMutation();

  const inputRef = useRef(null);

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [currentUser, setCurrentUser] = useState(user ? user.username : "Username not set");

  const [fetchedComments, setFetchedComments] = useState([]);
  const [filteredComments, setFilteredComments] = useState([]);
  const [selectionValue, setSelectionValue] = useState(0);
  const [commentValue, setCommentValue] = useState("");

  const [inputValue, setInputValue] = useState("");

  // const fetchMoreComments = async () => {
  //   try {
  //     const fetchPage = currentPage + 1; // Increment the page directly

  //     await commentsRefetch({
  //       activeWorkspace,
  //       modelId,
  //       deploymentId,
  //       collectionId,
  //       type,
  //       pageNumber: fetchPage,
  //       itemsPerPage,
  //       logout,
  //     });

  //     //This should log when they're fetched
  //     console.log("The component is fetching more comments");

  //     setCurrentPage(fetchPage);
  //   } catch (error) {
  //     console.error("Error fetching more timeline items:", error);
  //   }
  // };

  const fetchMoreComments = async () => {

    // Check if all necessary parameters are available
    if (activeWorkspace?.organisation_id) {
      try { 
        const fetchPage = currentPage + 1; // Increment the page directly
  
        await commentsRefetch({
          activeWorkspace,
          modelId,
          deploymentId,
          collectionId,
          type,
          pageNumber: fetchPage,
          itemsPerPage,
          logout,
        });
  
        // Log when they're fetched
        console.log("The component is fetching more comments");
  
        setCurrentPage(fetchPage);
      } catch (error) {
        console.error("Error fetching more timeline items:", error);
      }
    } else {
      // Log or handle the case where parameters are missing
      console.error("Missing parameters for fetching more comments");
    }
  };
  

  const fetchUsers = (query, callback) => {
    if (!query) {
      refetch().then(({ data }) => {
        const mentionData =
          data?.data?.map((user) => ({
            id: user.user_id,
            display: `${user.given_name} ${user.family_name}`,
          })) || [];

        callback([...mentionData, { id: "__all__", display: "all" }]);
        return;
      });
    } else {
      debouncedSearch(activeWorkspace, query, apiKey, (data) => {
        const mentionData =
          data?.map((user) => ({
            id: user.user_id,
            display: `${user.given_name} ${user.family_name}`,
          })) || [];

        callback([...mentionData, { id: "__all__", display: "all" }]);
      });
    }
  };

  useEffect(() => {
    if (data) {
      setFetchedComments((prevItems) => [...prevItems, ...data.data]);

      if (data.data.length < 10) {
        setHasMoreComments(false);
      }
    }
  }, [isLoading, data]);

  const idMapping = {
    models: modelId,
    deployments: deploymentId,
    collections: collectionId,
  };

  const getIdByType = (type) => {
    return idMapping[type] || modelId;
  };

  const id = getIdByType(type);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
           The event listener that's calling the handleTabsOrientation function when resizing the window.
          */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    setSelectionValue(newValue);

    if (newValue === 0) {
      setFilteredComments(fetchedComments);
    } else {
      const filtered = fetchedComments.filter((comment) => comment.version_id === versionId);
      console.log("The filtered array is", filtered);

      setFilteredComments(filtered);
    }
  };

  //Create a new tabMap object that maps tab values to their keys
  const tabMap = Object.entries({
    Profile: 0,
    Scenario: 1,
    Performance: 2,
    Preprocessing: 3,
    Predict: 4,
    Config: 5,
  }).reduce((acc, [key, value]) => {
    acc[value] = key;
    return acc;
  }, {});

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddComment = () => {
    if (!commentValue.trim()) return;
    const now = new Date();

    const regex = /#\(([^)]+)\)/g;
    const matches = commentValue.match(regex);

    const tagged_users = matches ? matches.map((match) => match.slice(2, -1)) : [];

    const resultString = commentValue.replace(/#\([^)]+\)/g, "");

    const data = JSON.stringify({
      comment: resultString,
      version_id: versionId,
      parent_id: null,
      tagged_users: tagged_users,
      component: tabMap[tabValue],
    });

    const newComment = {
      comment_id: "",
      model_id: modelId,
      version_id: versionId,
      version_number: version,
      parent_id: null,
      created: now,
      last_edited: now,
      comment: resultString,
      component: tabMap[tabValue],
      like_count: 0,
      user: {
        user_id: user.user_id,
        username: user.username,
        given_name: user.given_name,
        family_name: user.family_name,
        position: user.position,
        image: user.image,
      },
      replies: [],
    };

    setFetchedComments((prevComments) => [...prevComments, newComment]);
    setCommentValue("");

    addCommentMutation([activeWorkspace, type, id, apiKey, data], {
      onSuccess: ({ data }) => {
        newComment.comment_id = data.comment_id;

        setFetchedComments((prevComments) => [
          ...prevComments.filter((comment) => comment !== newComment),
          newComment,
        ]);
        setCommentValue("");
      },
      onError: (error) => {
        console.log(error);
        setFetchedComments((prevComments) => [
          ...prevComments.filter((comment) => comment !== newComment),
        ]);
        handleTokenExpired();
      },
    });
  };

  return {
    inputRef,
    currentUser,
    selectionValue,
    handleInputChange,
    handleAddComment,
    handleSetTabValue,
    tabsOrientation,
    data,
    isLoading,
    user,
    inputValue,
    fetchedComments,
    activeWorkspace,
    id,
    apiKey,
    setFetchedComments,
    tabMap,
    commentValue,
    setCommentValue,
    fetchUsers,
    hasMoreComments,
    fetchMoreComments,
  };
};
