import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Slider,
} from "@mui/material";
import { usePipeLineQuery } from "api/query";
import { useAuth0 } from "@auth0/auth0-react";
import { css } from "@emotion/react";

import XTypography from "components/XTypography";
import XBox from "components/XBox";

import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";

import LoadingSpinner from "shared/Animations/LoadingAnimation";
import PipelineDetails from "./components/PipelineDetails";
import ComingSoon from "shared/Cards/ComingSoon";

import _ from "lodash";
import { useXplainableController } from "context";

const flashAnimation = css`
  @keyframes flash {
    0% {
      background-color: transparent;
    }
    50% {
      background-color: pink;
    }
    100% {
      background-color: transparent;
    }
  }
`;

export const TableCellCustom = React.forwardRef((props, ref) => (
  <TableCell
    {...props}
    ref={ref}
    sx={{
      // maxWidth: props.headerWidth,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      backgroundColor: props.bgColor,
      color: props.color,
      fontSize: "0.8rem", // change this value as per your requirement
      py: 1,
      ...props.sx,
    }}
  />
));

export const CustomTooltipContent = ({ title, customProp }) => (
  <div>
    <div>{title}</div>
    <div>Custom content: {customProp}</div>
  </div>
);

export const TableCellWithTooltip = ({ title, children }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipOpen = (event) => {
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  return (
    <Tooltip
      open={tooltipOpen}
      title={title}
      sx={{
        zIndex: 5000,
        "& .MuiTooltip-tooltip": {
          zIndex: 5000,
        },
        "& .MuiTooltip-arrow": {
          // Add this zIndex
          zIndex: 5000,
        },
      }}
      onMouseEnter={handleTooltipOpen}
      onMouseLeave={handleTooltipClose}
    >
      {children}
    </Tooltip>
  );
};

const PreprocessingPipeline = ({ preprocessorData, showSteps = true }) => {
  const { activeWorkspace } = useApiKey();
  const { logout } = useAuth0();
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const [step, setStep] = useState(0);
  const [pipelineData, setPipelineData] = useState(null);
  const [mainDelta, setMainDelta] = useState(null);
  const headerWidth = "20px"; // Adjust this value as needed
  const [isComponentMounted, setIsComponentMounted] = useState(true);

  const abortController = new AbortController();
  const signal = abortController.signal;

  const { data, isLoading } = usePipeLineQuery(
    activeWorkspace,
    preprocessorData?.id,
    preprocessorData?.version,
    signal,
    logout
  );

  useEffect(() => {
    if (!data) return;
    setPipelineData(data.data);
    setMainDelta(data.data.deltas[step]);
  }, [data]);

  useEffect(() => {
    return () => {
      setIsComponentMounted(false); // unmounting
    };
  }, []);

  useEffect(() => {
    if (!data) return;

    if (step === 0) {
      setMainDelta(data.data.deltas[step]);
      return;
    }

    const deltasToMerge = data.data.deltas.slice(0, step);

    const newData = _.mergeWith([], ...deltasToMerge);

    setMainDelta(newData);
  }, [step]);

  const isDroppedOrChangedColumn = (rowIndex, columnIndex, deltas, currentStep) => {
    if (deltas.length === 0 || deltas[0].length === 0) return { dropped: false, changed: false };
    const keys = Object.keys(deltas[0][0]);
    for (let i = 1; i < deltas.length; i++) {
      if (
        (!deltas[i][rowIndex][keys[columnIndex]] &&
          deltas[i - 1][rowIndex][keys[columnIndex]] &&
          currentStep >= i) ||
        (!deltas[i][rowIndex][keys[columnIndex]] &&
          !deltas[i - 1][rowIndex][keys[columnIndex]] &&
          currentStep >= i) ||
        (deltas[i][rowIndex][keys[columnIndex]] &&
          !deltas[i - 1][rowIndex][keys[columnIndex]] &&
          currentStep >= i)
      ) {
        return { dropped: true, step: i };
      }

      if (
        deltas[i][rowIndex][keys[columnIndex]] !== deltas[i - 1][rowIndex][keys[columnIndex]] &&
        currentStep >= i
      ) {
        return { changed: true, step: i };
      }
    }

    return { dropped: false, changed: false };
  };

  return (
    <XBox>
      <Grid container>
        <Grid item xs={12}>
          <XTypography variant="h5" py={1} fontSize="18px">
            Pipeline
          </XTypography>
          <XTypography variant="button" color="secondary" fontWeight="light">
            Move the slider to view the preprocessing stages
          </XTypography>
        </Grid>
        <Grid item xs={12}>
          {pipelineData && (
            <Card sx={{ boxShadow: "none" }}>
              <XBox display="flex" alignItems="center">
                <XBox p={2} width="100%">
                  <Slider
                    color={"primary"}
                    step={1}
                    min={0}
                    max={pipelineData.deltas.length - 1}
                    value={step}
                    onChange={(e, sliderValue) => {
                      setStep(sliderValue);
                    }}
                  />
                </XBox>
                <XTypography
                  fontSize="14px"
                  variant="body1"
                  fontWeight="regular"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  Stage {step}
                </XTypography>
              </XBox>
            </Card>
          )}
        </Grid>
        <Grid item xs={12}>
          {isLoading ? (
            <XBox height={"500px"}>
              <LoadingSpinner size={50} />
            </XBox>
          ) : mainDelta ? (
            <XBox mt={3} overflowX="auto">
              <TableContainer
                sx={{ borderRadius: "16px", border: "1px solid #EAEAEA", padding: "8px" }}
              >
                <Table>
                  {mainDelta && mainDelta.length > 0
                    ? Object.keys(mainDelta[0]).map((header) => (
                        <TableCellCustom
                          key={header}
                          headerWidth={headerWidth}
                          bgColor="transparent"
                          sx={{ borderBottom: "1px solid transparent" }}
                        >
                          <XTypography fontWeight="bold" fontSize="14px" sx={{ color: "#AFAFAF" }}>
                            {header}
                          </XTypography>
                        </TableCellCustom>
                      ))
                    : null}

                  <TableBody>
                    {mainDelta && mainDelta.length > 0
                      ? mainDelta.map((row, rowIndex) => (
                          <TableRow key={rowIndex}>
                            {Object.keys(row).map((key, columnIndex) => {
                              const value = row[key];
                              const droppedOrChangedInfo = isDroppedOrChangedColumn(
                                rowIndex,
                                columnIndex,
                                pipelineData.deltas,
                                step
                              );

                              const originalValue =
                                droppedOrChangedInfo.dropped || droppedOrChangedInfo.changed
                                  ? pipelineData.deltas[droppedOrChangedInfo.step - 1][rowIndex][
                                      key
                                    ]
                                  : null;

                              return droppedOrChangedInfo.dropped ||
                                droppedOrChangedInfo.changed ? (
                                <TableCellWithTooltip
                                  title={`Original: ${originalValue}, Updated at step: ${droppedOrChangedInfo.step}`}
                                >
                                  <TableCellCustom
                                    key={columnIndex}
                                    headerWidth={headerWidth}
                                    sx={{
                                      backgroundColor: () => {
                                        if (droppedOrChangedInfo.changed)
                                          return rgba(colors.xpblue.main, 0.1);

                                        if (droppedOrChangedInfo.dropped)
                                          return rgba(colors.xppink.main, 0.1);
                                      },

                                      borderBottom: () => {
                                        if (droppedOrChangedInfo.changed)
                                          return `1px solid ${rgba(colors.xpblue.main, 0.1)}`;

                                        if (droppedOrChangedInfo.dropped)
                                          return `1px solid ${rgba(colors.xppink.main, 0.1)}`;
                                      },

                                      color: darkMode ? "#fff" : "#000000DE",

                                      animation:
                                        droppedOrChangedInfo.updated &&
                                        step === droppedOrChangedInfo.step
                                          ? `${flashAnimation} 1s`
                                          : "",
                                    }}
                                  >
                                    {value !== null ? value.toString() : "null"}
                                  </TableCellCustom>
                                </TableCellWithTooltip>
                              ) : (
                                <TableCellCustom
                                  key={columnIndex}
                                  headerWidth={headerWidth}
                                  sx={{
                                    borderBottom: "1px solid transparent",
                                    color: darkMode ? "#fff" : "#000000DE",
                                  }}
                                >
                                  {value !== null ? value.toString() : "null"}
                                </TableCellCustom>
                              );
                            })}
                          </TableRow>
                        ))
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </XBox>
          ) : (
            <ComingSoon
              title=""
              caption="Add a preprocessing pipeline to view pipeline stages"
              height={"500px"}
              shadow={false}
            />
          )}
        </Grid>
      </Grid>

      {pipelineData && showSteps && (
        <PipelineDetails stages={pipelineData.stages} step={step} setStep={setStep} />
      )}
    </XBox>
  );
};

export default PreprocessingPipeline;

PreprocessingPipeline.propTypes = {
  preprocessorData: PropTypes.object,
  showSteps: PropTypes.bool,
};

TableCellCustom.propTypes = {
  sx: PropTypes.any,
  headerWidth: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  tooltipInfo: PropTypes.object,
  tooltipOpen: PropTypes.func,
};

TableCellWithTooltip.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

CustomTooltipContent.propTypes = {
  title: PropTypes.string.isRequired,
  customProp: PropTypes.string.isRequired,
};
