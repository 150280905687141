import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useApiKey } from "../ApiKeyContext";
import { useLocation, Navigate } from "react-router-dom";

import PropTypes from "prop-types";

import colors from "assets/theme/base/colors";
import XBox from "components/XBox";
import LoadingSpinner from "shared/Animations/LoadingAnimation";

import { Paths } from "settings";
import { LOCAL_STORAGE_KEYS } from "constants";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth0();
  const { apiKeyChecked, apiKey } = useApiKey();

  const location = useLocation();

  if (location.pathname !== "/" && apiKeyChecked && !isAuthenticated) {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.ROUTE,
      window.location.pathname + window.location.search
    );
  }

  if (apiKeyChecked && !isAuthenticated) {
    console.log("Navigating to /login due to not authenticated");
    return <Navigate to={Paths.login} replace />;
  }

  if (apiKeyChecked && !apiKey && isAuthenticated && location.pathname !== "/new-user") {
    console.log("Navigating to /new-user due to no apiKey");
    return <Navigate to={Paths.newUser} replace />;
  }

  // Check if the user is trying to access '/new-user' manually
  if (apiKeyChecked && location.pathname === "/new-user" && apiKey) {
    // If the user is authenticated and trying to access '/new-user', don't navigate them away
    return <Navigate to={Paths.index} replace />;
  }

  if (!apiKeyChecked) {
    return (
      <XBox
        height="100vh"
        sx={{ position: "relative", zIndex: 9000, background: colors.background.default }}
      >
        <LoadingSpinner size={100} animationType="pulse" />
      </XBox>
    );
  }

  return children;
};

export default ProtectedRoute;
ProtectedRoute.propTypes = {
  children: PropTypes.any,
};
