import React, { useEffect, useState } from "react";

import Optimisation from "layouts/models/model/components/performance/components/Optimisation";

import { useBinaryPerformanceQuery } from "api/query";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useModel } from "hooks";
import { useAuth0 } from "@auth0/auth0-react";

export const ThresholdOptimisation = () => {
  const { activeWorkspace } = useApiKey();
  const { selectedPartition, selectedVersion, model_id } = useModel();
  const { logout } = useAuth0();

  const [scoreData, setScoreData] = useState([]);
  const [performanceData, setPerformanceData] = useState([]);

  const { data } = useBinaryPerformanceQuery(
    activeWorkspace,
    model_id,
    selectedVersion?.value,
    selectedPartition?.value,
    logout
  );

  useEffect(() => {
    if (!data) return;

    setPerformanceData(data.data[0]);
  }, [data]);

  useEffect(() => {
    const filterData = () => {
      return performanceData.evaluation?.train;
    };

    const newData = filterData();

    // console.log("The filtered data is", newData)
    if (newData) {
      //Extract the score values
      const scoreValues = newData.scores.map((obj) => {
        const { fp, fn, tp, tn } = obj;
        return { fp, fn, tp, tn };
      });
      setScoreData(scoreValues);
    }
  }, [performanceData]);

  useEffect(() => {
    if (!data) return;

    setPerformanceData(data.data[0]);
  }, [data]);
  return <Optimisation scoreData={scoreData} />;
};
