import React, { useCallback, useState, useMemo, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useXplainableController } from "context";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useDeployKeysMutation } from "api/mutations";
import { MdContentCopy, MdCheck } from "react-icons/md";
import { useDeploymentKeysQuery } from "api/query";

// Update user components information
import { Formik, Form } from "formik";
import FormField from "shared/Forms/FormField";
import * as Yup from "yup";

import Swal from "sweetalert2";

import WarningIcon from "assets/images/warning-icon.svg";

// Import Mui components
import {
  Grid,
  Card,
  Tooltip,
  Icon,
  Collapse,
  Modal,
  DialogActions,
  TextareaAutosize,
} from "@mui/material";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XButton from "components/XButton";
import XProgress from "components/XProgress";

// User Defined components
import TokenModal from "shared/Modals/TokenModal";
import DataTable from "shared/Tables/DataTable";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import ReuseableTimeline from "shared/Timeline/ReuseableTimeline";
import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";

import LightPlusIcon from "assets/images/icons/deployments/light-plus-icon.svg";
import TrashIcon from "assets/images/icons/deployments/trash-icon.svg";
import XInput from "components/XInput";
import { queryClient } from "api/queryClient";
import { QUERY_CONSTANTS } from "constants";

const hostUrl = process.env.REACT_APP_HOST_URL;

function Apis({ name, handleAdd, handleRemove, deploymentId }) {
  const [controller] = useXplainableController();
  const { apiKey, activeWorkspace } = useApiKey();

  const { data, refetch } = useDeploymentKeysQuery(activeWorkspace, deploymentId);
  const { createDeployKeyMutation, deleteDeployKeyMutation, revokeAllDeployKeysMutation } =
    useDeployKeysMutation();

  const [isLoading, setIsLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [tableRows, setTableRows] = useState(name);
  const [deploymentKey, setTokenKey] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [copySuccess, setCopySuccess] = useState("Copy to Clipboard");
  const [confirmInput, setConfirmInput] = useState("");
  const [isConfirmEnabled, setIsConfirmEnabled] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isRevokeModalOpen, setIsRevokeModalOpen] = useState(false);

  const textAreaRef = useRef(null);

  useEffect(() => {
    if (!data) return;

    const adaptedData = data.data.map((item) => {
      const createdDate = new Date(item.created);
      const expiresDate = new Date(item.expires);
      const today = new Date();
      const completion = ((today - expiresDate) / (createdDate - expiresDate)) * 100;

      return {
        id: item.key_id,
        desc: item.description,
        created: `${createdDate.getDate()} ${createdDate.toLocaleString("default", {
          month: "long",
        })}, ${createdDate.getFullYear()}`,
        completion: Math.min(Math.max(completion, 0), 100),
      };
    });

    const sortedData = adaptedData.slice().sort(compareDates);

    setTableRows(sortedData);
    setIsLoading(false);
  }, [data]);

  useEffect(() => {
    setIsConfirmEnabled(confirmInput.toLowerCase() === "revoke-all-keys");
  }, [confirmInput]);

  const handleCopy = () => {
    navigator.clipboard.writeText("revoke-all-keys").then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 3000); // Reset icon state after 3 seconds
    });
  };

  const onAdd = useCallback(
    (description, days_until_expiry) => {
      const keyData = {
        description: description,
        days_until_expiry: Number(days_until_expiry),
      };

      createDeployKeyMutation([activeWorkspace, deploymentId, apiKey, JSON.stringify(keyData)], {
        onSuccess: (data) => {
          setTokenKey(data.data.deploy_key);
          refetch();
        },
        onError: () => {
          console.log(error, "error");
        },
      });
    },
    [handleAdd, tableRows]
  );

  const onRemove = useCallback(
    (index) => {
      const newState = [...tableRows];
      const row = newState[index]; // Get the row data for the index
      const keyId = row.id; // Extract the key_id from the row data
      console.log("The row data is", row);

      deleteDeployKeyMutation([activeWorkspace, deploymentId, apiKey, keyId], {
        onSuccess: (data) => {
          console.log("Key deletion successful:", data.data);
        },
        onError: (error) => {
          console.error("Error deleting the API key:", error);
        },
      });

      newState.splice(index, 1); // Remove the row from the table
      setTableRows(newState);
      handleRemove(index);
    },
    [handleRemove, tableRows]
  );

  const showAlert = (index) => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-error",
        cancelButton: "button",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "This will revoke this API Key!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, revoke it!",
      })
      .then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "The API Key has been revoked!", "success");
          //TODO: Hit the backend and delete it from the database
          onRemove(index);
        }
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Description",
        accessor: "desc",
        width: "40%",
        Cell: ({ value }) => value,
      },
      {
        Header: "Created",
        accessor: "created",
        Cell: ({ value }) => value,
      },
      {
        Header: "Remaining",
        accessor: "completion",
        Cell: ({ value }) => <Completion value={value} />,
      },
      {
        Header: " ",
        id: "actions",
        Cell: ({ row: { index } }) => (
          <XBox display="flex" alignItems="center">
            <XBox>
              <XTypography
                variant="body1"
                color="secondary"
                sx={{ cursor: "pointer", lineHeight: 0 }}
              >
                <Tooltip title="Revoke API Key" placement="top">
                  <XBox component="img" src={TrashIcon} onClick={() => showAlert(index)} />
                </Tooltip>
              </XTypography>
            </XBox>
          </XBox>
        ),
      },
    ],
    [name, onRemove]
  );

  const revokeAllKeys = async () => {
    revokeAllDeployKeysMutation([activeWorkspace, deploymentId]);
  };

  const initialValues = {
    description: "",
    days_until_expiry: "",
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string()
      .matches(/.*[a-zA-Z].*/, "Description must include at least one letter")
      .required("Please enter a description"),
    days_until_expiry: Yup.number()
      .min(1, "Days until expiry must be at least 1")
      .max(90, "Days until expiry cannot exceed 90")
      .required("Days until expiry is required"),
  });

  const fields = {
    description: {
      label: "Description",
      name: "description",
      placeholder: "eg. Testing and exploration",
    },
    days_until_expiry: {
      label: "Days Until Expiry",
      name: "days_until_expiry",
      placeholder: "7",
    },
  };

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setCopySuccess("Copied!");
  }

  const handleCloseCopyModal = (formikProps) => {
    setCopySuccess("Copy to Clipboard");
    setTokenKey(null);
    formikProps.resetForm();
    setIsModalOpen(false);
    setStep(0);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setStep(0);
  };

  return (
    <>
      <XBox>
        <Modal
          open={isModalOpen}
          onClose={handleClose}
          aria-labelledby="request-title"
          aria-describedby="request-description"
        >
          <XBox
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -60%)",
              width: 600,
              background: controller.darkMode ? colors.background.dark : colors.background.default,
              borderRadius: "16px",
              padding: 2,
            }}
          >
            <XBox display="flex" justifyContent="space-between" alignItems="center">
              <XTypography variant="button" fontSize="16px">
                {step === 0 ? "New Deployment Key" : "API Key"}
              </XTypography>
              <Icon
                sx={({ typography: { size, fontWeightBold }, palette: { dark, white } }) => ({
                  fontSize: `${size.md} !important`,
                  fontWeight: `${fontWeightBold} !important`,
                  color: controller.darkMode ? white.main : dark.main,
                  stroke: controller.darkMode ? white.main : dark.main,
                  strokeWidth: "2px",
                  cursor: "pointer",
                })}
                onClick={handleClose}
              >
                close
              </Icon>
            </XBox>
            <Formik initialValues={initialValues} validationSchema={validationSchema}>
              {(formikProps) => (
                <Form id="orgForm" autoComplete="off">
                  {step === 0 ? (
                    <Grid container mt={2}>
                      <Grid item xs={12}>
                        <FormField
                          as="input"
                          type="text"
                          id={fields.description.name}
                          label={fields.description.label}
                          name={fields.description.name}
                          placeholder={fields.description.placeholder}
                          error={formikProps.touched.description && formikProps.errors.description}
                          success={
                            formikProps.values.description.length > 0 &&
                            !formikProps.errors.description
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormField
                          as="input"
                          type="text"
                          id={fields.days_until_expiry.name}
                          label={fields.days_until_expiry.label}
                          name={fields.days_until_expiry.name}
                          placeholder={fields.days_until_expiry.placeholder}
                          error={
                            formikProps.touched.days_until_expiry &&
                            formikProps.errors.days_until_expiry
                          }
                          success={
                            formikProps.values.days_until_expiry.length > 0 &&
                            !formikProps.errors.days_until_expiry
                          }
                        />
                      </Grid>
                      <Grid item xs={5} mt={2}>
                        <XButton
                          variant="gradient"
                          color="button"
                          size="medium"
                          fullWidth
                          onClick={() => {
                            formikProps.submitForm(); // submit the form
                            if (formikProps.isValid) {
                              onAdd(
                                formikProps.values.description,
                                formikProps.values.days_until_expiry
                              );
                              setStep(1);
                            }
                          }}
                        >
                          Create Deployment Key
                        </XButton>
                      </Grid>
                    </Grid>
                  ) : (
                    <XBox>
                      <XTypography color="secondary" variant="button" fontWeight="light" mb={2}>
                        Keep this key safe. You won’t be able to see it again.
                      </XTypography>

                      <TextareaAutosize
                        ref={textAreaRef}
                        minRows={1}
                        style={{
                          width: "100%",
                          backgroundColor: colors.light.main,
                          color: "dark",
                          fontSize: "1.2rem",
                          border: "none",
                          borderRadius: "12px",
                          padding: "0.8rem",
                        }}
                        value={deploymentKey}
                      />
                      <DialogActions sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <XButton
                          variant="dark"
                          onClick={() => handleCloseCopyModal(formikProps)}
                          sx={{ mx: 1, backgroundColor: colors.light.main }}
                        >
                          Close
                        </XButton>
                        <XButton
                          variant="gradient"
                          color="button"
                          onClick={(e) => {
                            copyToClipboard(e);
                          }}
                          sx={{ mx: 1 }}
                        >
                          {copySuccess}
                        </XButton>
                      </DialogActions>
                    </XBox>
                  )}
                </Form>
              )}
            </Formik>
          </XBox>
        </Modal>

        <Modal
          open={isRevokeModalOpen}
          onClose={() => setIsRevokeModalOpen(false)}
          aria-labelledby="request-title"
          aria-describedby="request-description"
        >
          <XBox
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -60%)",
              width: 600,
              background: controller.darkMode ? colors.background.dark : colors.background.default,
              borderRadius: "16px",
              padding: 3,
            }}
          >
            <XBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
              <XBox />
              <Icon
                sx={({ typography: { size, fontWeightBold }, palette: { dark, white } }) => ({
                  fontSize: `${size.md} !important`,
                  fontWeight: `${fontWeightBold} !important`,
                  color: controller.darkMode ? white.main : dark.main,
                  stroke: controller.darkMode ? white.main : dark.main,
                  strokeWidth: "2px",
                  cursor: "pointer",
                })}
                onClick={() => setIsRevokeModalOpen(false)}
              >
                close
              </Icon>
            </XBox>
            <XBox
              display="flex"
              flexDirection="column"
              gap={3}
              justifyContent="center"
              textAlign="center"
            >
              <XBox width="100%" justifyContent="center" alignItems="center">
                <XBox src={WarningIcon} component="img" width={120} />
              </XBox>
              <XTypography fontSize="16px" fontWeight="medium">
                This will revoke access to all api keys for this workspace. This action cannot be
                undone.
              </XTypography>
              <XBox
                sx={{
                  backgroundColor: "rgba(colors.secondary.main, 0.1)",
                  padding: 0.5,
                  borderColor: "grey.300", // Use the appropriate gray from your theme here
                  border: 1, // Specifies the border width and style
                  borderStyle: "solid", // This makes the border solid
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between", // Corrected justifyContent value
                }}
              >
                <XBox>
                  <XTypography fontSize="16px" fontWeight="light" color="secondary">
                    {`Type "revoke-all-keys" into the input field to proceed`}
                  </XTypography>
                </XBox>
                <Tooltip title="Copy to clipboard" placement="top">
                  <XBox
                    sx={{
                      p: 1.5,
                      borderColor: "grey.300",
                      border: 1,
                      borderStyle: "solid",
                      borderRadius: "8px",
                      display: "flex", // Ensure content is aligned horizontally
                      alignItems: "center", // Center items vertically
                      justifyContent: "space-between", // Space between the text and icon
                    }}
                  >
                    {copied ? (
                      <MdCheck size="24" />
                    ) : (
                      <MdContentCopy size="24" onClick={handleCopy} style={{ cursor: "pointer" }} />
                    )}
                  </XBox>
                </Tooltip>
              </XBox>
              <XInput value={confirmInput} onChange={(e) => setConfirmInput(e.target.value)} />
              <XBox display="flex" justifyContent="space-between" width="100%" gap={2}>
                <XButton
                  sx={{ width: "calc(80% - 8px)", mr: 2 }} // Adjust width to account for margin, assuming a box-sizing of border-box
                  variant="gradient"
                  color="error"
                  onClick={() => {
                    if (isConfirmEnabled) {
                      revokeAllKeys(); // Call your function to revoke all keys
                      setIsRevokeModalOpen(false); // Close the modal
                    }
                  }}
                  disabled={!isConfirmEnabled}
                >
                  Revoke all keys
                </XButton>
                <XButton
                  sx={{ width: "20%" }}
                  variant="gradient"
                  color="light"
                  onClick={() => setIsModalOpen(false)} // Assuming this closes the modal
                >
                  Cancel
                </XButton>
              </XBox>
            </XBox>
          </XBox>
        </Modal>

        <XTypography variant="button" fontSize="18px" mb={2}>
          All Deployment Keys
        </XTypography>
        <XBox ml={1} display="flex" justifyContent="flex-end" mb={2}>
          <XBox display="flex" gap={2}>
            <XButton
              sx={{ color: "#EA2C4C", boxShadow: "none", background: "transparent" }}
              size="medium"
              onClick={() => setIsRevokeModalOpen(true)}
            >
              Revoke All Keys
            </XButton>
            <XButton
              onClick={() => setIsModalOpen(true)}
              variant="gradient"
              color="button"
              sx={{ gap: "8px", display: "flex" }}
            >
              {/* <XBox component="img" src={LightPlusIcon} /> */}
              New API Key
            </XButton>
          </XBox>
        </XBox>
      </XBox>
      <Grid spacing={3} container>
        <Grid item xs={12} lg={7}>
          <Card>
            {isLoading ? (
              <XBox height={"600px"}>
                <LoadingSpinner size={40} />
              </XBox>
            ) : (
              <DataTable
                table={{ rows: tableRows, columns: columns }}
                entriesPerPage={{
                  defaultValue: 10,
                  entries: [5, 7, 10, 15, 20, 25],
                }}
                canSearch
              />
            )}
          </Card>
        </Grid>
        <Grid item xs={12} lg={5}>
          <XBox>
            <ReuseableTimeline
              title={"Deploy Key Timeline"}
              tableRows={tableRows}
              team_id={activeWorkspace?.team_id}
              event={[
                "created_deploy_key",
                "revoked_deploy_key",
                "deleted_deploy_key",
                "revoked_deploy_keys",
              ]}
            />
          </XBox>
        </Grid>
      </Grid>
    </>
  );
}

export default React.memo(Apis);

function Completion({ value }) {
  let color;
  if (value <= 30) {
    color = "error";
  } else if (value > 30 && value <= 70) {
    color = "info";
  } else {
    color = "success";
  }
  return (
    <XBox display="flex" alignItems="center">
      <XTypography variant="caption" color="text">
        {value}%
      </XTypography>
      <XBox width="8rem" ml={1}>
        <XProgress value={value} color={color} label={false} />
      </XBox>
    </XBox>
  );
}

function getCurrentDate() {
  const today = new Date();
  const day = today.getDate();
  const month = today.toLocaleString("default", { month: "long" });
  const year = today.getFullYear();
  return `${day} ${month}, ${year}`;
}

function parseDateString(dateString) {
  const dateParts = dateString.split(" ");
  const day = parseInt(dateParts[0], 10);
  const month = new Date(Date.parse(`${dateParts[1]} 1, 2021`)).getMonth();
  const year = parseInt(dateParts[2], 10);
  return new Date(year, month, day);
}

function compareDates(a, b) {
  const dateA = parseDateString(a.created);
  const dateB = parseDateString(b.created);
  return dateB - dateA;
}

const shakeAnimation = {
  animation: `$shake 500ms cubic-bezier(.36,.07,.19,.97) both`,
  "@keyframes shake": {
    "10%, 90%": { transform: "translate3d(-1px, 0, 0)" },
    "20%, 80%": { transform: "translate3d(2px, 0, 0)" },
    "30%, 50%, 70%": { transform: "translate3d(-4px, 0, 0)" },
    "40%, 60%": { transform: "translate3d(4px, 0, 0)" },
  },
};

Completion.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

Apis.propTypes = {
  deploymentId: PropTypes.number,
  value: PropTypes.number,
  row: PropTypes.number,
  name: PropTypes.string.isRequired,
  handleAdd: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
};
