import React, { useState } from "react";
import PropTypes from "prop-types";

//mui material components
import Icon from "@mui/material/Icon";
import { Menu, MenuItem } from "@mui/material";

//xplainable Dashboard components
import XBox from "components/XBox";
import XAvatar from "components/XAvatar";
import XTypography from "components/XTypography";
import XInput from "components/XInput";
import XBadge from "components/XBadge";
import Card from "@mui/material/Card";

//Helper Functions
import { getTimeDifference } from "shared/Functions/Date";
import colors from "assets/theme/base/colors";
import { useReply } from "shared/Comments/hooks";
import { wrapMentionsInSpan } from "utils";

import UnLikedIcon from "assets/images/icons/comment/un-liked-icon.svg";
import LikedIcon from "assets/images/icons/comment/liked-icon.svg";

export const Reply = ({
  replyKey,
  isReply = true,
  currentUser,
  reply,
  type,
  id,
  fetchedComments,
  setFetchedComments,
  handleLike,
}) => {
  const {
    user: { image, username: name, family_name, given_name },
    comment_id,
    parent_id,
    comment: text,
    like_count: like,
    created,
    last_edited,
    version_id,
    version_number,
    user_has_liked,
    component,
    position,
  } = reply;

  const {
    user,
    isEditingReply,
    likeCount,
    menuAnchorEl,
    setMenuAnchorEl,
    darkMode,
    getInitials,
    editedText,
    setEditedText,
    handleMenuClose,
    handleEditComment,
    handleKeyPress,
    handleDeleteComment,
  } = useReply({ reply, id, fetchedComments, setFetchedComments, type });

  return (
    <React.Fragment>
      <XBox
        width={"100%"}
        display="flex"
        mt={replyKey === 0 ? 0 : isReply ? 1 : 2.5}
        p={isReply ? 0.25 : 0}
        flexDirection={currentUser === name ? "row-reverse" : "row"}
      >
        <XBox>
          {image ? (
            <XAvatar src={image} alt={user} size={isReply ? "xs" : "sm"} />
          ) : (
            <XAvatar
              bgColor="secondary"
              variant="normal"
              color="white"
              alt={user}
              size={isReply ? "xs" : "sm"}
            >
              <XBox>
                <XTypography color="white" fontWeight="bold" variant={isReply ? "caption" : "h6"}>
                  {getInitials(given_name, family_name)}
                </XTypography>
              </XBox>
            </XAvatar>
          )}
        </XBox>
        <XBox
          flexGrow={1}
          ml={currentUser === name ? 0 : isReply ? 1 : 1.5}
          mr={currentUser === name ? 1.5 : 0}
        >
          <Card
            sx={{
              borderRadius: currentUser === name ? "0px 12px 12px 12px" : "0px 12px 12px 12px",
              position: "relative",
              zIndex: 1,
              overflow: "visible",
            }}
          >
            <XBox p={1}>
              <XBox display="flex">
                <XBox display="flex" marginRight="auto">
                  <XBox display="flex" flexDirection="column">
                    <XTypography variant="button" textTransform="capitalize">
                      {given_name + " " + family_name}
                    </XTypography>
                    <XTypography
                      variant="caption"
                      textTransform="capitalize"
                      fontSize="14px"
                      sx={{ color: "#7C7C7C" }}
                    >
                      {position}
                    </XTypography>
                  </XBox>
                  <XBox ml={1}>
                    {isReply || type !== "models" ? (
                      <></>
                    ) : (
                      <>
                        <XBadge
                          sx={{ cursor: "pointer" }}
                          color="xppink"
                          badgeContent={"version " + version_number}
                          onClick={() =>
                            setSelectedVersion({
                              value: version_id,
                              label: "Version " + version_number,
                            })
                          }
                        />
                        <XBadge
                          sx={{ cursor: "pointer" }}
                          color="xpblue"
                          badgeContent={component}
                          onClick={() => setTabValue(getKeyByValue(tabMap, component))}
                        />
                      </>
                    )}
                  </XBox>
                </XBox>
                {currentUser === name && (
                  <>
                    <Icon
                      fontSize="default"
                      sx={{ cursor: "pointer" }}
                      onClick={(event) => {
                        event.persist();
                        setMenuAnchorEl(event.currentTarget);
                      }}
                    >
                      more_vert
                    </Icon>

                    <Menu
                      anchorEl={menuAnchorEl}
                      open={Boolean(menuAnchorEl)}
                      onClose={handleMenuClose}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      transformOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleEditComment();
                          handleMenuClose();
                        }}
                      >
                        <Icon>edit</Icon>
                        <XTypography variant="caption" ml={1}>
                          Edit
                        </XTypography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleDeleteComment();
                          handleMenuClose();
                        }}
                      >
                        <Icon>delete</Icon>
                        <XTypography variant="caption" ml={1}>
                          Delete
                        </XTypography>
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </XBox>
              <XBox mt={1} mb={1} lineHeight={isReply ? 0.5 : 0.75} pr={2}>
                {isEditingReply ? (
                  <XInput
                    value={editedText}
                    onChange={(e) => setEditedText(e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e)}
                    autoFocus
                  />
                ) : (
                  <XTypography
                    variant="button"
                    fontWeight="light"
                    dangerouslySetInnerHTML={{ __html: wrapMentionsInSpan(text) }}
                  />
                )}
              </XBox>
            </XBox>
            <XBox position="absolute" bottom={"0px"} right={"12px"}>
              <XTypography variant="caption" sx={{ color: "#AFAFAF" }}>
                {(last_edited !== created ? "Edited " : "") + getTimeDifference(last_edited, true)}
              </XTypography>
            </XBox>
          </Card>

          <XBox mt={1} ml={2} display="flex" flexWrap="wrap" alignItems="center">
            <XBox
              component="img"
              onClick={() => {
                user_has_liked ? handleLike(comment_id, "unlike") : handleLike(comment_id, "like");
              }}
              src={user_has_liked ? LikedIcon : UnLikedIcon}
            />
            <XTypography variant="button" mx={1} fontWeight={"regular"} sx={{ color: "#AFAFAF" }}>
              {+(likeCount[replyKey] || like) + (like === 1 ? " Like" : " Likes")}
            </XTypography>
          </XBox>
        </XBox>
      </XBox>
    </React.Fragment>
  );
};

Reply.propTypes = {
  replyKey: PropTypes.number,
  isReply: PropTypes.bool,
  currentUser: PropTypes.string,
  type: PropTypes.string,
  reply: PropTypes.object,
  id: PropTypes.string,
  setFetchedComments: PropTypes.func,
  fetchedComments: PropTypes.array,
  handleLike: PropTypes.func,
};
