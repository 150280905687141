import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useDeploymentKeysQuery = (activeWorkspace, deploymentId, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.DEPLOYMENT_KEYS, activeWorkspace, deploymentId],
    () => apiHandlers.deployKeys.getDeployKeys(activeWorkspace, deploymentId),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
