// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";

// xplainable dashboard contexts
import { useXplainableController } from "context";
import XBadge from "components/XBadge";
import XAvatar from "components/XAvatar";
import { getTimeDifference } from "shared/Functions/Date";

import colors from "assets/theme/base/colors";

function PreprocessorInfoCard({
  bgColor,
  title,
  version,
  author,
  date,
  imgUrl,
  role,
  description,
  icon,
  direction,
}) {
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  return (
    <Card sx={{ boxShadow: "none", border: "1px solid #EAEAEA", p: 2 }}>
      <XBox>
        <XBox>
          <Grid container>
            {/* <Grid item>
              <XBox
                variant="gradient"
                bgColor={bgColor === "white" ? icon.color : "white"}
                color={bgColor === "white" ? "dark" : "white"}
                width="3rem"
                height="3rem"
                borderRadius="section"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {typeof icon.component === "string" ? (
                  <Icon fontSize="small" color="inherit">
                    {icon.component}
                  </Icon>
                ) : (
                  <XBox fontSize="1.125rem" display="grid" placeItems="center" color="inherit">
                    {icon.component}
                  </XBox>
                )}
              </XBox>
            </Grid> */}
            <Grid item>
              <XBox mb={1} display="flex" flexDirection="column" gap={1}>
                <XTypography variant="button" fontSize="18px" fontWeight="medium">
                  {title}
                </XTypography>
                <XTypography variant="h5" fontWeight="bold" fontSize="14px" color="xpblue">
                  {"Version " + version}
                </XTypography>
              </XBox>
            </Grid>
          </Grid>

          <Grid>
            <XBox
              display="flex"
              sx={{
                overflow: "hidden",
                overflowY: "scroll",
                minHeight: "80px",
                scrollbarWidth: "none",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                msOverflowStyle: "none",
              }}
            >
              <XTypography variant="button" fontWeight="light" color="secondary">
                {description}
              </XTypography>
            </XBox>
          </Grid>
          <Grid>
            <XBox display="flex" alignItems="center">
              <XBox display="flex" textAlign="left" gap={1} alignItems="center">
                <XAvatar variant="rounded" size="xs" src={imgUrl} />
                <XBox display="flex" flexDirection="column">
                  <XTypography variant="button" fontWeight="light">
                    {author}
                  </XTypography>
                  <XTypography variant="caption" sx={{ color: "#AFAFAF" }} fontWeight="medium">
                    {role}
                  </XTypography>
                </XBox>
              </XBox>
            </XBox>
          </Grid>
          <Grid display="flex" justifyContent="end">
            <XTypography variant="caption" color="secondary" lineHeight={1.4}>
              {date ? getTimeDifference(date, true) : ""}
            </XTypography>
          </Grid>
        </XBox>
      </XBox>
    </Card>
  );
}

// Setting default values for the props of DetailedStaticsCard
PreprocessorInfoCard.defaultProps = {
  bgColor: "white",
  percentage: {
    color: "success",
    version: 0,
    text: "",
  },
  direction: "right",
};

// Typechecking props for the DetailedStaticsCard
PreprocessorInfoCard.propTypes = {
  bgColor: PropTypes.oneOf([
    "transparent",
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  author: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  imgUrl: PropTypes.string,
  role: PropTypes.string,
  version: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.shape({
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    component: PropTypes.node.isRequired,
  }),
  direction: PropTypes.oneOf(["right", "left"]),
};

export default PreprocessorInfoCard;
