import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const usePayloadQuery = (activeWorkspace, deployment_id, api_key, setPayload, logout) => {
  return useQuery(
    [QUERY_CONSTANTS.PAYLOAD, activeWorkspace, deployment_id, api_key],
    () => apiHandlers.deployments.getPayload(activeWorkspace, deployment_id, api_key),
    {
      enabled: true, //Fetch data immediately when the component mounts
      onSuccess: (fetchedPayload) => {
        const payloadString = JSON.stringify(fetchedPayload.data[0], null, 2);
        setPayload("[" + payloadString + "]");
      },
      onError: (error) => {
        console.error("Error fetching deployments:", error);
        handleTokenExpired(error, logout);
      },
    }
  );
};
