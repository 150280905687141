import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useApiKey } from "components/Authorisation/ApiKeyContext";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import { Stack } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import colors from "assets/theme/base/colors";
import { Tooltip } from "@mui/material";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XAvatar from "components/XAvatar";

import rgba from "assets/theme/functions/rgba";
import findVersionNumberByVersionId from "helpers/Versioning";

import { handleTokenExpired } from "utils";
import { useAuth0 } from "@auth0/auth0-react";
import { useCardsMutation } from "api/mutations";
import { useXplainableController } from "context";

import DraftModelsIcon from "assets/images/icons/draft-models-icon.svg";
import PublishedModelsIcon from "assets/images/icons/published-models-icon.svg";
import DarkDotsVerticalIcon from "assets/images/icons/dark-dots-vertical-icon.svg";
import LightDotsVerticalIcon from "assets/images/icons/light-dots-vertical-icon.svg";
import { ReactComponent as ArrowIcon } from "assets/images/icons/arrow-right.svg";

import ActiveDeploymentIcon from "assets/images/icons/deployments/active-deployment-icon.svg";
import UnActiveDeploymentIcon from "assets/images/icons/deployments/un-active-deployment-icon.svg";
import { getTimeDifference } from "shared/Functions/Date";

// Custom styles for ComplexModelCard
function ReuseableCard({ type, dropdown, onClick, project, updateActiveCount, color, arrowIcon }) {
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (!project) return;

    setToggle(type === "model" ? project.active : project.active_deployment);
  }, [project, type]);

  const { logout } = useAuth0();
  const { editCardMutation } = useCardsMutation();

  const [controller] = useXplainableController();
  const theme = useTheme();
  const { apiKey, viewState, activeWorkspace, activeSubscription } = useApiKey();
  const {
    model_id,
    model_name,
    model_description,
    model_type,
    target_name,
    created,
    archived,
    active,
    user,
    active_version,
    deployed,
    deployment_id,
    active_deployment,
    contributors,
    versions,
    number_of_versions,
  } = project;

  const cardActionStyles = ({ palette: { white }, typography: { size } }) => ({
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",

    "& .material-icons-round": {
      ml: 0.5,
      fontSize: size.md,
      transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
    },

    "&:hover .material-icons-round": {
      transform: "translateX(5px)",
    },
  });

  const showContributors = 5;
  const renderContributors = project.contributors
    .slice(0, showContributors)
    .map((contributor, key) => {
      const contributorKey = `contributor-${key}`;

      return (
        <XBox display="flex" key={contributorKey} gap={1} alignItems="center">
          <Tooltip title={contributor.given_name + " " + contributor.family_name}>
            <XAvatar
              key={contributorKey}
              src={typeof contributor.image === "string" ? contributor.image : ""}
              alt="contributor profile"
              size="xs"
              sx={({ borders: { borderWidth }, palette: { white } }) => ({
                border: `${borderWidth[2]} solid ${white.main}`,
                cursor: "pointer",
                position: "relative",

                "&:not(:first-of-type)": {
                  ml: -1.25,
                },

                "&:hover, &:focus": {
                  zIndex: "10",
                },
              })}
            />
          </Tooltip>
          {project.contributors.slice(0, showContributors).length < 2 && (
            <XTypography fontSize="14px">
              {project.contributors[0].given_name + " " + project.contributors[0].family_name}
            </XTypography>
          )}
        </XBox>
      );
    });

  const renderModelDetails = () => {
    const details = (
      <XBox display="flex" gap="10px" alignItems="center">
        <XTypography
          variant="button"
          textTransform="capitalize"
          fontWeight="medium"
          color={activeSubscription ? color : "light"}
          sx={cardActionStyles}
        >
          {type === "model" ? "Model Insights" : "Deployment Insights"}
        </XTypography>
        <XBox>
        <ArrowIcon stroke={activeSubscription ? type === "model" ? colors.xppink.main : colors.xpblue.main : colors.light.main} />
        </XBox>
      </XBox>
    );

    if (activeSubscription) {
      return (
        <Link
          to={type === "model" ? `/models/${project.model_id}` : `/deployments/${project.deployment_id}`}
          state={{
            model_id,
            model_name,
            model_description,
            model_type,
            target_name,
            created,
            archived,
            active,
            user,
            active_version,
            deployed,
            deployment_id,
            active_deployment,
            contributors,
            versions,
            number_of_versions,
          }}
        >
          {details}
        </Link>
      );
    }
    return details;
  };

  const handleToggleChange = async () => {
    const action = toggle ? "deactivate" : "activate";
    const endpointType = type === "model" ? "models" : "deployments";
    const id = type === "model" ? model_id : deployment_id;

    editCardMutation([activeWorkspace, endpointType, id, action, apiKey], {
      onSuccess: () => {
        setToggle(!toggle);
        updateActiveCount(id, !toggle);
      },

      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    });
  };

  const badgeBackgroundColor = () => {
    if (toggle) {
      return colors[color].main;
    } else {
      return controller.darkMode ? "#1D1B1B" : "#F7F7F8";
    }
  };

  return (
    <Tooltip title={activeSubscription ? "" : "Renew your subscription to view"}>
      <Card
        sx={{
          overflow: "none",
          zIndex: 0,
          width: { xs: "100%", sm: type === "model" ? "340px" : "535px" },
          justifyContent: { xs: "center", lg: "flex-start" },
          backgroundColor: theme.palette.white,
          transition: "background-color 300ms, transform 300ms, box-shadow 300ms",
          boxShadow: activeSubscription ? "0px 0px 10px rgba(0, 0, 0, 0.25)" : "none",
          "&:hover": {
            transform: `${activeSubscription ? "translateY(-2px)" : "translateY(0px)"}`,
            boxShadow: activeSubscription
              ? type === "model"
                ? `0px 0px 20px ${rgba(colors.xppink.main, 3)}`
                : `0px 0px 20px ${rgba(colors.xpblue.main, 3)}`
              : "none",
          },
        }}
        m={3}
      >
        <XBox p={3} overflow="visible">
          <XBox display="flex" alignItems="end" justifyContent="space-between">
            <XBox
              display="flex"
              alignItems="center"
              gap={1}
              px="12px"
              py={1}
              bgColor={badgeBackgroundColor()}
              sx={{ borderRadius: "24px" }}
            >
              <XBox
                component="img"
                src={
                  type === "model" && toggle
                    ? PublishedModelsIcon
                    : type === "model" && !toggle
                    ? DraftModelsIcon
                    : type !== "model" && toggle
                    ? ActiveDeploymentIcon
                    : UnActiveDeploymentIcon
                }
              />
              <XTypography sx={{ color: toggle ? "#ffffff" : "#AFAFAF" }} fontSize="12px">
                {type === "model" ? (toggle ? "Published" : "Draft") : toggle ? "Active" : "Draft"}
              </XTypography>
            </XBox>
            {viewState === "creator" && (
              <XBox display="flex">
                <XBox mt={0.25}>
                  <Switch
                    checked={toggle}
                    onChange={() => {
                      handleToggleChange();
                    }}
                    sx={{
                      width: "50px",
                      height: "24px",
                      "& .MuiSwitch-switchBase": {
                        padding: 0,
                        "&.Mui-checked": {
                          transform: "translate(28px,-10px)",
                        },
                      },
                      "& .MuiSwitch-thumb": {
                        width: 20,
                        height: 20,
                      },

                      "& .Mui-checked + .MuiSwitch-track": {
                        backgroundColor: `${colors[color].main} !important`,
                      },
                    }}
                    disabled={!activeSubscription}
                  />
                </XBox>
              </XBox>
            )}
          </XBox>

          <XBox my={2} lineHeight={1}>
            <XTypography
              variant="h6"
              color={activeSubscription? "" : "secondary"}
              textTransform="capitalize"
              fontWeight="medium"
              mb={1}
              fontSize="18px"
              sx={{
                whiteSpace: "nowrap",
                overflowY: "auto",
                scrollbarWidth: "none",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                msOverflowStyle: "none",
              }}
            >
              {project.model_name}
            </XTypography>
            <XTypography
              variant="h6"
              textTransform="capitalize"
              fontWeight="medium"
              mb={1}
              fontSize="14px"
              color={activeSubscription? color : "secondary"}
              sx={{
                overflowY: "auto",
                scrollbarWidth: "none",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                msOverflowStyle: "none",
              }}
            >
              {`Version ${findVersionNumberByVersionId(project.versions, project.active_version)}`}
            </XTypography>
            <Stack position="relative">
              <XBox
                sx={{
                  maxHeight: "60px",
                  minHeight: "60px",
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  msOverflowStyle: "none",
                  scrollbarWidth: "none",
                }}
              >
                <XTypography 
                  variant="button" 
                  fontWeight="regular" 
                  color={activeSubscription? "secondary" : "light"} >
                  {project.model_description}
                </XTypography>
              </XBox>
            </Stack>
          </XBox>
          <XBox>
            <XTypography 
              variant="button"
              color={activeSubscription? "": "secondary"}
              >Contributors</XTypography>
            {project.contributors.length > -1 ? (
              <XBox display="flex" justifyContent="space-between">
                <XBox display="flex">
                  {renderContributors}
                  {project.contributors.length <= showContributors ? null : (
                    <XTypography variant="overline">
                      {" "}
                      + {project.contributors.length - showContributors} more{" "}
                    </XTypography>
                  )}
                </XBox>
                <XTypography variant="button" fontWeight="light" sx={{ color: "#AFAFAF" }}>
                  {created ? getTimeDifference(created, true) : null}
                </XTypography>
              </XBox>
            ) : null}
          </XBox>

          <XBox display="flex" justifyContent="space-between" alignItems="center" mt={3}>
            <XBox
              display="flex"
              flexDirection="row"
              lineHeight={0}
              overflow="visible"
              position="relative"
            >
              {viewState === "creator" && dropdown && (
                <>
                  <XBox
                    src={controller.darkMode ? LightDotsVerticalIcon : DarkDotsVerticalIcon}
                    component="img"
                    onClick={onClick}
                  />
                  {dropdown.menu}
                </>
              )}
            </XBox>
            <XBox display="flex" lineHeight={0} justifyContent="flex-end">
              {renderModelDetails()}
            </XBox>
          </XBox>
        </XBox>
      </Card>
    </Tooltip>
  );
}

// Setting default values for the props of ComplexModelCard
ReuseableCard.defaultProps = {
  color: "dark",
  members: [],
  dropdown: false,
  status: "active",
};

// Typechecking props for the ProfileInfoCard
ReuseableCard.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
    }),
  ]),
  project: PropTypes.object,
  updateActiveCount: PropTypes.func,
  id: PropTypes.number,
  versions: PropTypes.array,
  deployed: PropTypes.bool,
  active_deployment: PropTypes.bool,

  deployment_id: PropTypes.number,
  modelType: PropTypes.string,
  active: PropTypes.bool,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
    "xppink",
    "xpblue",
  ]),
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.node,
  status: PropTypes.string,
  version: PropTypes.number,
  members: PropTypes.array,
  arrowIcon: PropTypes.string,
  activeSubscription: PropTypes.bool
};

export default ReuseableCard;
