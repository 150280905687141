// useMixPanelQuery.js
import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const useModelsQuery = (activeWorkspace, api_key, logout) => {
  return useQuery(
    [activeWorkspace?.organisation_id && activeWorkspace?.team_id && QUERY_CONSTANTS.MODELS],
    () => apiHandlers.models.getModels(activeWorkspace, api_key),
    {
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
