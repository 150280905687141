import { Switch } from "@mui/material";
import XBox from "components/XBox";
import { useXplainableController } from "context";
import XTypography from "components/XTypography";
import React, { useContext, useState } from "react";
import DeploymentContext from "../../../deployment-context";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useOutputMutation } from "api/mutations";

import colors from "assets/theme/base/colors";

export const Output = () => {
  const { activeWorkspace } = useApiKey();
  const { deployment_id } = useContext(DeploymentContext);
  const { enableExplainMutation, disableExplainMutation } = useOutputMutation();
  const [controller] = useXplainableController();

  const [checked, setChecked] = useState(false);
  const handleChange = () => {
    if (checked) {
      disableExplainMutation([activeWorkspace, deployment_id], {
        onSuccess: (data) => {
          setChecked(!checked);
          return;
        },
        onError: (err) => {
          console.log(err);
        },
      });
      return;
    }

    enableExplainMutation([activeWorkspace, deployment_id], {
      onSuccess: (data) => {
        setChecked(!checked);
        return;
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };
  return (
    <XBox 
      display="flex" 
      justifyContent="center" 
      width="100%" 
      bgColor={controller.darkMode ? colors.background.dark : colors.background.default} 
      borderRadius="10px"
      sx={{ maxWidth: "400px" }}>
      <XBox minWidth={"100%"} display="flex" flexDirection="column" minHeight={"150px"} p={2}>
        <XBox mb={3}>
          <XTypography variant="h5" fontSize="18px">
            Server Output
          </XTypography>
        </XBox>
        <XBox width="100%" >
          <XBox display="flex" alignItems="center" justifyContent="space-between" py={1}>
            <XTypography variant="h6" color="secondary">Return breakdown</XTypography>
            <Switch checked={checked} onChange={handleChange} />
          </XBox>
          <XBox display="flex" alignItems="center" justifyContent="space-between" py={1}>
            <XTypography variant="h6" color="secondary">Apply preprocessor</XTypography>
            <Switch />
          </XBox>
        </XBox>
      </XBox>
    </XBox>
  );
};
