import { AppBar, Tab, Tabs } from "@mui/material";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import XBox from "components/XBox";
import { useModel } from "hooks";
import PerformanceChart from "shared/models/Metrics/Components/PerformanceChart";
import React, { useEffect, useState } from "react";
import LoadingSpinner from "shared/Animations/LoadingAnimation";

const hostUrl = process.env.REACT_APP_HOST_URL;

export const Curves = () => {
  const { model_id, selectedVersion, selectedPartition } = useModel();
  const { apiKey, activeWorkspace } = useApiKey();

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");

  const [tabValue, setTabValue] = useState(0);
  const [rocData, setRocData] = useState([]);
  const [precisionRecallData, setPrecisionRecallData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDataset, setSelectedDataset] = useState({
    value: "train",
    label: "Train",
  });
  const [threshold, setThreshold] = useState(0.5);

  const [performanceData, setPerformanceData] = useState([]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const getPerformanceData = async (model_id, version_id, partition_id) => {
    try {
      //Get the Partition ID
      const response = await fetch(
        `${hostUrl}/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/versions/${version_id}/partitions/${partition_id}/evaluation`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            api_key: apiKey,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      //Set the Performance data
      setPerformanceData(data[0]);
      setIsLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  console.log(rocData, precisionRecallData, "here");

  useEffect(() => {
    if (selectedVersion && selectedPartition) {
      getPerformanceData(model_id, selectedVersion.value, selectedPartition.value);
    }
  }, [model_id, selectedVersion, selectedPartition]);

  useEffect(() => {
    const filterData = () => {
      if (selectedDataset.value === "validation") {
        return performanceData.evaluation?.validation;
      } else if (selectedDataset.value === "train") {
        return performanceData.evaluation?.train;
      }
    };

    const newData = filterData();

    // console.log("The filtered data is", newData)

    if (newData) {
      setFilteredData(newData);
    }
  }, [selectedDataset, performanceData]);

  useEffect(() => {
    console.log(filteredData);
    if (filteredData && filteredData.roc && filteredData.precision_recall_curve) {
      setPrecisionRecallData(filteredData.precision_recall_curve);
      setRocData(filteredData.roc);
    }
  }, [threshold, filteredData]);

  return (
    <>
      {isLoading ? (
        <XBox height={"500px"}>
          <LoadingSpinner size={50} />
        </XBox>
      ) : (
        <XBox>
          <AppBar position="static">
            <Tabs
              orientation={tabsOrientation}
              value={tabValue}
              onChange={handleSetTabValue}
              sx={{ backgroundColor: "transparent" }}
            >
              <Tab
                label="PR Curve"
                icon={<i className="ni ni-app" style={{ marginTop: "6px", marginRight: "8px" }} />}
              />
              <Tab
                label="ROC Curve"
                icon={
                  <i className="ni ni-email-83" style={{ marginTop: "6px", marginRight: "8px" }} />
                }
              />
            </Tabs>
          </AppBar>
          <PerformanceChart
            data={tabValue === 0 ? precisionRecallData : rocData}
            type={tabValue === 0 ? "PR" : "ROC"}
          />
        </XBox>
      )}
    </>
  );
};
