import React, { useRef, useState, useEffect } from "react";

import { Card, Divider, Icon, Switch } from "@mui/material";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import XAvatar from "components/XAvatar";
import XBox from "components/XBox";
import XButton from "components/XButton";
import XEditor from "components/XEditor";
import XInput from "components/XInput";
import XTypography from "components/XTypography";

import { debounce } from "lodash";
import LinkAnimation from "shared/Animations/LinkAnimation";
import HoverMenu from "shared/Commentary/components/HoverMenu";
import { getTimeDifference } from "shared/Functions/Date";
import { useModel } from "hooks";

const hostUrl = process.env.REACT_APP_HOST_URL;

export const Commentary = () => {
  const { model_id, selectedVersion } = useModel();
  const { apiKey, viewState, activeWorkspace } = useApiKey();

  const [quillType, setQuillType] = useState("bubble");
  const [readOnly, setReadOnly] = useState(true);
  const [editedHeader, setEditedHeader] = useState("");
  const [editedCaption, setEditedCaption] = useState("");
  const [author, setAuthor] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [editedDate, setEditedDate] = useState(null);
  const [published, setPublished] = useState(false);
  const [editorValue, setEditorValue] = useState("");
  const [buttonText, setButtonText] = useState("Edit");
  const [isLoading, setIsLoading] = useState(false);
  const [typing, setTyping] = useState(false);

  useEffect(() => {
    const fetchCommentary = async () => {
      try {
        const response = await fetch(
          `${hostUrl}/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/versions/${selectedVersion.value}/commentary/Profile`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              api_key: apiKey,
            },
          }
        );
        const data = await response.json();
        //console.log("The commentary data is", data)
        setImgUrl(data.last_edited_by.image);
        setPublished(data.published);
        setEditedDate(data.last_edited);
        setAuthor(data.last_edited_by.given_name + " " + data.last_edited_by.family_name);
        setEditedHeader(data.heading);
        setEditedCaption(data.subheading);
        setEditorValue(data.text);
      } catch (error) {
        console.error("Error fetching commentary:", error);
      }
    };

    fetchCommentary();
  }, [model_id, selectedVersion]);

  const typeWriter = (markdownText) => {
    const htmlText = markdownToHtml(markdownText);

    if (!htmlText || typeof htmlText !== "string") {
      console.error("Invalid input for typeWriter:", htmlText);
      return;
    }

    let index = 0;
    setTyping(true);

    const interval = setInterval(() => {
      setEditorValue((prevHtml) => prevHtml + htmlText[index]);
      index++;
      if (index === htmlText.length) {
        clearInterval(interval);
        setTyping(false);
      }
    }, 0.03);
  };

  const autoGenerateReport = async (target_description, project_objective, temperature) => {
    // Set loading status
    setIsLoading(true);

    const reportData = {
      target_description: target_description,
      project_objective: project_objective,
      temperature: temperature,
    };

    try {
      const response = await fetch(
        `${hostUrl}/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/versions/${selectedVersion.value}/generate-report`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            api_key: apiKey,
          },
          body: JSON.stringify(reportData),
        }
      );

      const data = await response.json();

      // Type out data response
      if (data) {
        setEditorValue("");
        updateQuill();

        setEditedHeader(data.heading);
        setEditedCaption(data.tagline);

        typeWriter(data.body);
      }
    } catch (error) {
      console.error("Error auto generating report:", error);
      setIsLoading(false);
    } finally {
      // Stop loading status
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      await fetch(
        `${hostUrl}/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/versions/${selectedVersionvalue}/commentary/Profile`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            api_key: apiKey,
          },
          body: JSON.stringify({
            text: editorValue,
            heading: editedHeader,
            subheading: editedCaption,
          }),
        }
      );
    } catch (error) {
      console.error("Error updating commentary:", error);
    }
  };

  const handlePublishChange = async () => {
    const action = published ? "unpublish" : "publish";
    try {
      await fetch(
        `${hostUrl}/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/versions/${selectedVersion.value}/commentary/Profile/${action}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            api_key: apiKey,
          },
        }
      );
    } catch (error) {
      console.error("Error updating commentary:", error);
    }
  };

  //Handle the select of the dropdown
  async function updateQuill() {
    setReadOnly(!readOnly);
    setQuillType(quillType === "bubble" ? "snow" : "bubble");
    setButtonText(buttonText === "Save" ? "Edit" : "Save");
    if (buttonText === "Save") {
      await handleSubmit();
    }
  }

  // Create a ref to store the debounced function
  const debouncedSetEditorValue = useRef(debounce((value) => setEditorValue(value), 300)).current;

  return (
    <XBox mt={{ xs: 10, lg: 3 }}>
      <XBox p={2}>
        <XBox display="flex" justifyContent="space-between" alignItems="center">
          <XBox width={"70%"}>
            {buttonText === "Save" ? (
              <XInput
                inputProps={{
                  style: { height: "40px", fontSize: 24, fontWeight: "bold", width: "100%" },
                }}
                value={editedHeader}
                onChange={(e) => setEditedHeader(e.target.value)}
                autoFocus
              />
            ) : (
              <XTypography variant="h4" fontWeight="bold" mr={1}>
                {editedHeader}
              </XTypography>
            )}
            {buttonText === "Save" ? (
              <XInput
                inputProps={{ style: { fontSize: 14, fontWeight: "bold", width: "100%" } }}
                value={editedCaption}
                onChange={(e) => {
                  setEditedCaption(e.target.value);
                }}
                autoFocus
              />
            ) : (
              <XTypography variant="subtitle2" color="secondary" mr={1}>
                {editedCaption}
              </XTypography>
            )}
          </XBox>
          <XBox display="flex" alignItems="center">
            <XBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <XTypography variant="h6" lineHeight={1}>
                {author}
              </XTypography>
              <XTypography variant="caption" color="secondary" lineHeight={1.4}>
                {editedDate ? getTimeDifference(editedDate, true) : ""}
              </XTypography>
            </XBox>
            <XBox ml={1}>
              <XAvatar variant="rounded" src={imgUrl} />
            </XBox>
          </XBox>
        </XBox>
        {viewState === "creator" && (
          <XBox display="flex" justifyContent="space-between" alignItems="center" mt={2}>
            <XBox>
              <XButton
                onClick={() => {
                  updateQuill();
                }}
                p={0.3}
              >
                <Icon color="secondary">edit</Icon>
                <XTypography variant="button" color="secondary" ml={1}>
                  {buttonText}
                </XTypography>
              </XButton>
              <XTypography variant="button" mx={2}>
                {"Publish"}
              </XTypography>
              <Switch
                checked={published}
                onChange={() => {
                  setPublished(!published);
                  handlePublishChange();
                }}
              />
            </XBox>
            <XBox>
              <HoverMenu autoGenerateReport={autoGenerateReport} />
            </XBox>
          </XBox>
        )}
        <Divider />
        <XBox mt={4} sx={{ zIndex: 10 }}>
          {isLoading ? (
            <LinkAnimation />
          ) : (
            <XEditor
              readOnly={readOnly}
              theme={"bubble"}
              onChange={(content, delta, source, editor) => debouncedSetEditorValue(content)}
              value={editorValue}
            />
          )}
        </XBox>
      </XBox>
    </XBox>
  );
};
