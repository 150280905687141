export const QUERY_CONSTANTS = {
  COMMENTS_MODELS: "commentsModels",
  COMMENTS_DEPLOYMENTS: "commentsDeployments",
  COMMENTS_COLLECTIONS: "commentsCollections",
  COMMENTS_USER: "commentsUsers",
  ALL_USERS: "allUsers",
  FILTERED_USER: "filteredUser",
  XPANEL_DATA: "xPanelData",
  USER: "user",
  MODEL: "model",
  MODELS: "models",
  CREATE_USER: "createUser",
  DELETE_USER: "deleteUser",
  DELETE_INVITATION: "deleteInvitation",
  DEPLOYMENTS: "deployments",
  PREDICTIONS: "predictions",
  DEPLOY_KEY: "deployKey",
  PAYLOAD: "payload",
  PREPROCESSORS: "preprocessors",
  PROFILE_DATA: "profileData",
  SCENARIO: "scenario",
  REPORTS: "reports",
  REPORT_VERSION: "reportVersion",
  HEALTH: "health",
  SUBSCRIPTION: "subscription",
  SUBSCRIPTION_SEATS: "subscriptionSeats",
  COMMENTARY: "commentary",
  BINARY_PERFORMANCE: "binaryPerformance",
  PIPE_LINE: "pipeLine",
  REGRESSION_PERFORMANCE: "regressionPerformance",
  API_KEYS: "apiKeys",
  BATCH_OPTIMISATION: "batchOptimisation",
  DATASET: "dataset",
  PREVIEW: "preview",
  OPTIMISER_VERSIONS: "optimiserVersions",
  OPTIMISER_VERSION: "optimiserVersion",
  RUNS: "run",
  COLLECTION: "collection",
  COLLECTIONS: "collection",
  PARTITIONS: "partitions",
  ALL_COLLECTIONS: "allCollections",
  ORGANISATION_METADATA: "organisationMetadata",
  USER_NOTIFICATION: "userNotification",
  ALL_TEAM_USERS: "allTeamUsers",
  INVITATIONS: "invitations",
  REQUESTS: "requests",
  DEPLOY_KEYS: "deployKeys",
  TEAMS: "teams",
  INVITE_USER: "inviteUser",
  SEARCH_USER: "searchUser",
  FIREWALL: "firewall",
  DEPLOYMENT_KEYS: "deploymentKeys",
  CALENDAR: "calendar",
  RECENT: "recent",
  ALL_PREPROCESSORS: "allPreprocessors",
  PREPROCESSOR_VERSION: "preprocessorVersion",
  RUN: "run",
  LOGIN: "login",
  RUN_BATCHES: "runBatches",
  DOWNLOAD_BATCH: "downloadBatch",
  LIST_MODELS:"listModels",
  LIMITS:"limits",
  LLM_KEYS:"llm_keys"
};
