import { useMutation } from "@tanstack/react-query";
import { apiHandlers } from "../apiHandlers";

export const useTrainMutation = () => {
  const { mutateAsync: summarizeMutation } = useMutation((params) =>
    apiHandlers.train.summarize(...params)
  );
  const { mutateAsync: summarizeUrlMutation } = useMutation((params) =>
    apiHandlers.train.summarizeUrl(...params)
  );

  const { mutateAsync: goalMutation } = useMutation((params) => apiHandlers.train.goal(...params));
  const { mutateAsync: autoTrainMutation } = useMutation((params) =>
    apiHandlers.train.autoTrain(...params)
  );
  const { mutateAsync: trainMutation } = useMutation((params) =>
    apiHandlers.train.train(...params)
  );
  const { mutateAsync: applyStepMutation } = useMutation((params) =>
    apiHandlers.train.applyStep(...params)
  );

  const { mutateAsync: downloadDataprepMutation } = useMutation((params) =>
    apiHandlers.train.downloadDataprep(...params)
  );
  const { mutateAsync: dropDataprepMutation } = useMutation((params) =>
    apiHandlers.train.dropDataprep(...params)
  );
  const { mutateAsync: featureEngineeringMutation } = useMutation((params) =>
    apiHandlers.train.featureEngineering(...params)
  );
  const { mutateAsync: dataPrepMutation } = useMutation((params) =>
    apiHandlers.train.dataPrep(...params)
  );
  const { mutateAsync: insightsMutation } = useMutation((params) =>
    apiHandlers.train.insights(...params)
  );
  const { mutateAsync: visualizeMutation } = useMutation((params) =>
    apiHandlers.train.visualize(...params)
  );
  const { mutateAsync: visualizeEditMutation } = useMutation((params) =>
    apiHandlers.train.visualizeEdit(...params)
  );
  const { mutateAsync: visualizeRepairMutation } = useMutation((params) =>
    apiHandlers.train.visualizeRepair(...params)
  );
  const { mutateAsync: visualizeExplainMutation } = useMutation((params) =>
    apiHandlers.train.visualizeExplain(...params)
  );
  const { mutateAsync: visualizeEvaluateMutation } = useMutation((params) =>
    apiHandlers.train.visualizeEvaluate(...params)
  );
  const { mutateAsync: visualizeRecommendMutation } = useMutation((params) =>
    apiHandlers.train.visualizeRecommend(...params)
  );
  const { mutateAsync: textGenerateMutation } = useMutation((params) =>
    apiHandlers.train.textGenerate(...params)
  );
  const { mutateAsync: infographerMutation } = useMutation((params) =>
    apiHandlers.train.infographer(...params)
  );
  const { mutateAsync: llmKeyMutation } = useMutation((params) =>
    apiHandlers.train.setApiKey(...params)
  );

  return {
    summarizeMutation,
    goalMutation,
    autoTrainMutation,
    applyStepMutation,
    trainMutation,
    insightsMutation,
    visualizeMutation,
    visualizeEditMutation,
    visualizeRepairMutation,
    visualizeExplainMutation,
    visualizeEvaluateMutation,
    visualizeRecommendMutation,
    textGenerateMutation,
    summarizeUrlMutation,
    infographerMutation,
    featureEngineeringMutation,
    dataPrepMutation,
    downloadDataprepMutation,
    dropDataprepMutation,
    llmKeyMutation
  };
};
