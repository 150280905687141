import React, { useState, useEffect, useContext } from "react";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { Divider, Icon, Switch, Grid, Modal } from "@mui/material";
import { useXplainableController } from "context";

import XBox from "components/XBox";
import XInput from "components/XInput";
import XTypography from "components/XTypography";
import XButton from "components/XButton";

import DeploymentContext from "../../../deployment-context";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import LightPlusIcon from "assets/images/icons/deployments/light-plus-icon.svg";
import SuccessChecklistIcon from "assets/images/icons/deployments/success-checklist-icon.svg";
import ErrorChecklistIcon from "assets/images/icons/deployments/error-checklist-icon.svg";
import colors from "assets/theme/base/colors";
import { useNetworkMutation } from "api/mutations";
import { useFirewallQuery } from "api/query";
import { useAuth0 } from "@auth0/auth0-react";

const hostUrl = process.env.REACT_APP_HOST_URL;

const Networking = () => {
  const [controller] = useXplainableController();

  const { logout } = useAuth0();
  const { apiKey, activeWorkspace } = useApiKey();
  const { deployment_id } = useContext(DeploymentContext);
  const { createIpAddressMutation, handleToggleChangeMutation, removeIpAddress } =
    useNetworkMutation();
  const { data, isLoading } = useFirewallQuery(activeWorkspace, deployment_id, logout);

  const [active, setActive] = useState(false);
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");
  const [trustedSources, setTrustedSources] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAddClick = async () => {
    setIsModalOpen(true);
  };

  const handleAddTrustedSourceClick = async () => {
    const data = JSON.stringify({
      ip_address: address,
      description: description,
    });

    createIpAddressMutation([activeWorkspace, deployment_id, data], {
      onSuccess: (data) => {
        const newSource = {
          ip_id: data.data.ip_id,
          ip_address: address,
          description: description,
        };

        setTrustedSources([...trustedSources, newSource]);
        setAddress("");
        setDescription("");
        setIsModalOpen(false);
      },
      onError: (error) => {
        console.error("Error:", error);
      },
    });
  };

  const handleRemoveClick = async (sourceToRemove, deploymentId) => {
    setTrustedSources(trustedSources.filter((source) => source !== sourceToRemove));

    removeIpAddress([activeWorkspace, deploymentId, sourceToRemove]);
  };

  const handleToggleChange = async () => {
    const action = active ? "deactivate-deployment-ip-blocking" : "activate-deployment-ip-blocking";

    handleToggleChangeMutation([activeWorkspace, deployment_id, action], {
      onSuccess: (data) => {
        if (data.data.message) {
          setActive(!active);
          return;
        }
      },
      onError: (error) => {
        console.error(`Error updating the ${deployment_id} status.`);
      },
    });
  };

  // Call getFirewalls on component mount
  useEffect(() => {
    if (!data) return;

    setActive(data.data.ip_blocking);
    setTrustedSources(data.data.allowed_ips);
  }, [data]);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <XBox display="flex" justifyContent="center" mt={2}>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="request-title"
        aria-describedby="request-description"
      >
        <XBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -60%)",
            width: 600,
            background: controller.darkMode ? colors.background.dark : colors.background.default,
            borderRadius: "16px",
            padding: 3,
          }}
        >
          <XBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <XTypography fontSize="16px" fontWeight="bold">
              Add Trusted Source
            </XTypography>
            <Icon
              sx={({ typography: { size, fontWeightBold }, palette: { dark, white } }) => ({
                fontSize: `${size.md} !important`,
                fontWeight: `${fontWeightBold} !important`,
                color: controller.darkMode ? white.main : dark.main,
                stroke: controller.darkMode ? white.main : dark.main,
                strokeWidth: "2px",
                cursor: "pointer",
              })}
              onClick={handleClose}
            >
              close
            </Icon>
          </XBox>
          <XBox display="flex" flexDirection="column" gap={2} mb={2}>
            <XBox>
              <XBox>
                <XTypography variant="h6">IP Address</XTypography>
                <XInput
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  placeholder="e.g. 0.0.0.0"
                  startAdornment={
                    <Icon fontSize="small" color="secondary" style={{ marginRight: "6px" }}>
                      address
                    </Icon>
                  }
                />
              </XBox>
            </XBox>
            <XBox>
              <XBox>
                <XTypography variant="h6">Description</XTypography>
                <XInput
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Add a description of what the address if for..."
                  startAdornment={
                    <Icon fontSize="small" color="secondary" style={{ marginRight: "6px" }}>
                      description
                    </Icon>
                  }
                />
              </XBox>
            </XBox>
          </XBox>
          <XButton
            color="button"
            variant="gradient"
            onClick={handleAddTrustedSourceClick}
            justifyContent="flex-end"
          >
            Add Trusted Source
          </XButton>
        </XBox>
      </Modal>
      <XBox p={2} width="100%" display="flex" flexDirection="column" minHeight={"600px"}>
        <XBox mb={3}>
          <XTypography fontSize="18px" variant="h5">
            Trusted Source
          </XTypography>
        </XBox>
        <XBox display="flex" justifyContent="flex-end">
          <XBox display="flex" gap={1}>
            <XButton fullWidth color="button" variant="gradient" onClick={handleAddClick}>
              {/* <XBox component="img" src={LightPlusIcon} /> */}
              Add Trusted Source
            </XButton>

            {/* <XBox display="flex" alignItems="center">
              <XTypography variant="h6" fontSize="12px" mr={2} sx={{ whiteSpace: "nowrap" }}>
                Apply Rules
              </XTypography>
              <Switch
                checked={active}
                onChange={() => {
                  handleToggleChange();
                }}
              />
            </XBox> */}
          </XBox>
        </XBox>

        <XBox mt={6}>
          <XBox display="flex" width="100%" justifyContent="space-between" alignItems="center">
            <XBox display="flex" alignItems="center">
              <XTypography variant="h6" color="secondary" mr={2}>
                Apply Rules
              </XTypography>
              <Switch
                checked={active}
                onChange={() => {
                  handleToggleChange();
                }}
              />
            </XBox>
          </XBox>
          {isLoading ? (
            <LoadingSpinner size={60} />
          ) : (
            trustedSources.map((source, index) => (
              <XBox key={index}>
                <Divider />
                <XBox display="flex" width="100%" justifyContent="space-between">
                  <XBox display="flex">
                    <XBox
                      component="img"
                      src={active ? SuccessChecklistIcon : ErrorChecklistIcon}
                    />
                    <XBox ml={2}>
                      <XTypography fontWeight="medium" variant="h6" lineHeight={0.8}>
                        {source.ip_address}
                      </XTypography>
                      <XTypography color="secondary" variant="caption" lineHeight={0.8}>
                        {source.description}
                      </XTypography>
                    </XBox>
                  </XBox>
                  <XBox>
                    <XTypography
                      color="secondary"
                      fontWeight="medium"
                      variant="h6"
                      onClick={() => handleRemoveClick(source, deployment_id)}
                      sx={{ cursor: "pointer", color: "#EA2C4C" }}
                    >
                      Remove
                    </XTypography>
                  </XBox>
                </XBox>
              </XBox>
            ))
          )}
        </XBox>
      </XBox>
    </XBox>
  );
};

export default Networking;
