import { Box } from "@mui/material";
import { animateGradient } from "assets/theme/base/animations";
import { useXplainableController } from "context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React from "react";
import ReuseableHeader from "shared/Headers/ReuseableHeader";
import { UploadDataSet, UploadModal, UploadedFile } from "./components";
import XBox from "components/XBox";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import { useDataSets } from "hooks";

export const DataSets = () => {
  const [controller] = useXplainableController();
  const { globalImage, globalColor } = controller;
  const {
    dataSets,
    isLoading,
    isModalOpen,
    fileToReplace,
    handleOpenModal,
    setIsModalOpen,
    handleOpenReplaceModal,
  } = useDataSets();

  return (
    <DashboardLayout
      sx={{
        backgroundImage: () =>
          globalImage
            ? `linear-gradient(to bottom right, rgba(225,64,103,0.9), rgba(0,128,234,0.9)), url(${globalImage})`
            : `linear-gradient(to bottom right, ${globalColor}, ${globalColor})`,
        backgroundSize: "100% 100%, cover",
        backgroundPosition: "0% 50%, 80% 50%",
        animation: `${animateGradient} 15s ease infinite`,
      }}
    >
      <ReuseableHeader
        title="Datasets"
        description={"One-click shareable reports with intuitive drag-and-drop functionality"}
        icon={"ni ni-atom"}
      />

      {isLoading ? (
        <XBox
          sx={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <LoadingSpinner animationType="pulse" size={50} />
        </XBox>
      ) : (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "24px" }}>
          <UploadDataSet openModal={handleOpenModal} />

          <UploadModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            fileToReplace={fileToReplace}
          />

          {dataSets?.data.length > 0 &&
            dataSets?.data?.map((file) => (
              <UploadedFile file={file} key={file.dataset_id} onReplace={handleOpenReplaceModal} />
            ))}
        </Box>
      )}
    </DashboardLayout>
  );
};
