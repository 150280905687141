import { useQuery } from "@tanstack/react-query";
import { apiHandlers } from "api/apiHandlers";
import { QUERY_CONSTANTS } from "constants";
import { handleTokenExpired } from "utils";

export const usePipeLineQuery = (
  activeWorkspace,
  preprocessor_id,
  preprocessor_version,
  signal,
  logout
) => {
  return useQuery(
    [QUERY_CONSTANTS.PIPE_LINE, activeWorkspace, preprocessor_id, preprocessor_version],
    () =>
      apiHandlers.pipeLine.getData(activeWorkspace, preprocessor_id, preprocessor_version, signal),
    {
      enabled: !!preprocessor_version && !!preprocessor_id,
      onError: (err) => {
        handleTokenExpired(err, logout);
      },
    }
  );
};
