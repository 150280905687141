import React, { useState, useEffect, useRef, useContext } from "react";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import PropTypes from "prop-types";
import { useModel } from "hooks";

// @mui material components
import CloseIcon from "@mui/icons-material/Close";
import { Card, Icon, Grid, Pagination, IconButton } from "@mui/material";

// User created components
import XTypography from "components/XTypography";
import XButton from "components/XButton";
import XInput from "components/XInput";
import XBox from "components/XBox";

//User defined components
import PricingModal from "shared/Modals/PricingModal";
import PlaceholderCard from "shared/Cards/PlaceholderCard";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import CollectionCard from "shared/Cards/CollectionCard";
import { useCollectionsQuery } from "api/query";
import { useCollectionMutation } from "api/mutations";

function Collections({ handleSelectCollection, inputValue }) {
  const { viewState, apiKey, activeWorkspace } = useApiKey();
  const { model_id } = useModel();
  const itemsPerPage = 5;

  const [page, setPage] = useState(1);
  const [addingCollection, setAddingCollection] = useState(false);
  const [showPricingModal, setShowPricingModal] = useState(false);
  const [collections, setCollections] = useState([]);

  const { data } = useCollectionsQuery(activeWorkspace, model_id);

  const onCreateCollection = (newCollection) => {
    setCollections((prev) => [...prev, newCollection]);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const noOfPages = Math.ceil(
    collections
      ? collections.filter((item) => {
          return (
            item?.name.toLowerCase().includes(inputValue.toLowerCase()) ||
            item?.description.toLowerCase().includes(inputValue.toLowerCase())
          );
        }).length / itemsPerPage
      : 0
  );

  useEffect(() => {
    if (!data) return;

    data.data.sort((a, b) => {
      return new Date(b.created) - new Date(a.created);
    });

    console.log(data, " data");
    //Set the Performance data
    setCollections(data.data);
  }, [data]);

  return (
    <>
      <Grid container spacing={2} sx={{ width: "100%" }}>
        <Grid item xs={12} sm={6} md={4} display="flex" sx={{ justifyContent: "center" }}>
          {viewState === "creator" &&
            page === 1 &&
            (addingCollection ? (
              <NewCollection
                onCreateCollection={onCreateCollection}
                setAddingCollection={setAddingCollection}
                apiKey={apiKey}
                activeWorkspace={activeWorkspace}
                modelId={model_id}
                setShowPricingModal={setShowPricingModal}
              />
            ) : (
              <PlaceholderCard
                title={{ variant: "h6", text: "New Collection" }}
                outlined
                onClick={() => {
                  setAddingCollection(!addingCollection);
                }}
                width={{ xs: "100%", sm: "310px" }}
                height="233px"
              />
            ))}
        </Grid>
        {collections ? (
          collections
            .filter((item) => {
              return (
                item?.name.toLowerCase().includes(inputValue.toLowerCase()) ||
                item?.description.toLowerCase().includes(inputValue.toLowerCase())
              );
            })
            .slice((page - 1) * itemsPerPage, page * itemsPerPage)
            .map((item, key) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={key}
                display="flex"
                sx={{ justifyContent: "center" }}
              >
                <CollectionCard
                  collection={item}
                  icon={{ color: "white", component: "library_books" }}
                  handleSelectCollection={handleSelectCollection}
                />
              </Grid>
            ))
        ) : (
          <Grid item xs={12} sm={6} md={4}>
            <XBox>
              <LoadingSpinner size={30} />
            </XBox>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
        <Pagination
          count={noOfPages}
          page={page}
          onChange={handleChange}
          defaultPage={1}
          showFirstButton
          showLastButton
        />
      </Grid>
      {showPricingModal && (
        <PricingModal open={showPricingModal} onClose={() => setShowPricingModal(false)} />
      )}
    </>
  );
}

export default Collections;

const NewCollection = ({
  onCreateCollection,
  setAddingCollection,
  apiKey,
  activeWorkspace,
  modelId,
  setShowPricingModal,
}) => {
  const { createCollection } = useCollectionMutation();

  const titleRef = useRef(null);
  const descRef = useRef(null);
  let formattedDate = new Date().toISOString().replace("Z", "") + "000";

  const addNewCollection = () => {
    const newCollection = {
      name: titleRef.current.value,
      description: descRef.current.value,
      created: formattedDate,
      scenario_count: 0,
    };
    createCollection([activeWorkspace, modelId, JSON.stringify(newCollection)], {
      onSuccess: (data) => {
        newCollection["collection_id"] = data.data.collection_id;
        onCreateCollection(newCollection);
        setAddingCollection(false);
        console.log("success");
      },
      onError: (error) => {
        if (error.response.status === 429) {
          setShowPricingModal(true);
          setAddingCollection(false);
          throw new Error("Forbidden"); // To stop further execution in the next then blocks.
        }
        console.log(error);
      },
    });
  };

  return (
    <Card
      sx={{
        position: "relative",
        zIndex: 1,
        overflow: "visible",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        height: "233px",
        width: "100%",
        py: 2,
        px: 5,
      }}
    >
      <IconButton
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
        }}
        onClick={() => {
          setAddingCollection(false);
        }}
      >
        <CloseIcon />
      </IconButton>
      <XTypography variant="h6">Collection Title</XTypography>
      <XInput placeholder={"This is a title"} inputRef={titleRef} />
      <XTypography variant="h6">Collection Description</XTypography>
      <XInput placeholder={"This is a description"} inputRef={descRef} />
      <XButton fullWidth variant="gradient" color="button" size="medium" onClick={addNewCollection}>
        Add Collection
      </XButton>
    </Card>
  );
};

// Typechecking props for the Feature Chart
Collections.propTypes = {
  handleSelectCollection: PropTypes.func,
  inputValue: PropTypes.string,
};

// Typechecking props for the Feature Chart
NewCollection.propTypes = {
  onCreateCollection: PropTypes.func,
  setAddingCollection: PropTypes.func,
  apiKey: PropTypes.string,
  modelId: PropTypes.number,
  activeWorkspace: PropTypes.object,
  setShowPricingModal: PropTypes.func,
};
