import React, { createContext, useContext, useState, useEffect } from "react";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useNotificationsMutation } from "api/mutations/useNotificationsMutation";
import Pusher from "pusher-js";
import PropTypes from "prop-types";

const pusherKey = process.env.REACT_APP_PUSHER_KEY;
const hostCluster = process.env.REACT_APP_PUSHER_CLUSTER;

const PusherContext = createContext();

export const usePusher = () => {
  return useContext(PusherContext);
};

export const PusherProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const { apiKey, activeWorkspace } = useApiKey();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [currentNotification, setCurrentNotification] = useState(null);
  const { getNotificationsMutation } = useNotificationsMutation();

  const handleGetAllNotifications = () => {
    getNotificationsMutation(apiKey, {
      onSuccess: (response) => {

        setNotifications(response.data);
      },
      onError: (error) => {
        console.error("Failed to get all notifications:", error);
      },
    });
  };

  useEffect(() => {
    if (!apiKey) {
      return;
    }

    handleGetAllNotifications(apiKey);
  }, [apiKey]);

  useEffect(() => {
    if (!activeWorkspace || !activeWorkspace.user_id) {
      return;
    }

    const pusher = new Pusher(pusherKey, {
      cluster: hostCluster,
      encrypted: true,
    });

    const channel = pusher.subscribe(activeWorkspace.user_id);
    channel.bind("notification", function (newData) {
      setCurrentNotification(newData);
      setSnackbarOpen(true);
      setNotifications((prevNotifications) => [...prevNotifications, newData]);
    });

    return () => {
      channel.unbind("notification");
      pusher.unsubscribe(activeWorkspace.user_id);
    };
  }, [activeWorkspace]);

  return (
    <PusherContext.Provider value={{ notifications, setNotifications }}>
      {children}
    </PusherContext.Provider>
  );
};

PusherProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
