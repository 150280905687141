import React, { useCallback, useState, useEffect } from "react";

import PropTypes from "prop-types";

import { Grid, Card, Tooltip, Icon } from "@mui/material";
import { useToast } from "hooks";

import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XBadge from "components/XBadge";
import XAvatar from "components/XAvatar";
import XSnackbar from "components/XSnackbar";

import DataTable from "shared/Tables/DataTable";

// xplainable dashboard base styles
import breakpoints from "assets/theme/base/breakpoints";

//Helper Functions
import { getTimeDifference } from "shared/Functions/Date";
import { useApiKey } from "components/Authorisation/ApiKeyContext";

import { ReactComponent as SuccessStatusIcon } from "assets/images/icons/admin/success-status.svg";
import { ReactComponent as RejectStatusIcon } from "assets/images/icons/admin/reject-status.svg";

import TableCellComponent from "./components/TableCellComponent";

function UserTable({
  tableRows,
  setTableRows,
  organisationId,
  entriesPerPage = 5,
  isAdmin,
  setIsAdmin,
  messageContent,
  show,
  toggleSnackbar,
}) {
  const { workspaces } = useApiKey();
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const { showSuccessToast } = useToast();

  useEffect(() => {
    if (organisationId && workspaces) {
      const organisation = workspaces.find(
        (item) => item?.organisation_id === organisationId.organisation_id
      );

      setIsAdmin(organisation?.is_admin);
    }
  }, [organisationId, workspaces]);

  // Debounced search  function
  const onRemove = useCallback(
    (index) => {
      const newState = [...tableRows];

      newState.splice(index, 1);
      setTableRows(newState);
    },
    [tableRows]
  );

  const columns = [
    {
      Header: "",
      width: "5%",
      id: "function",
      accessor: (data) => ({
        user_id: data.email + data.user_id,
        org_admin: data.org_admin,
        role_name: data.role_name,
        status: data.status,
        invite_id: data.invite_id,
      }),
      Cell: (props) => (
        <TableCellComponent
          {...props}
          rowIndex={props.row.index}
          user_id={props.value.user_id}
          org_admin={props.value.org_admin}
          role_name={props.value.role_name}
          status={props.value.status}
          invite_id={props.value.invite_id}
          onRemove={onRemove}
          disabled={!isAdmin}
        />
      ),
    },
    {
      Header: "User",
      id: "user",
      accessor: (data) => ({
        profile_image_url: data.profile_image_url,
        given_name: data.given_name,
        family_name: data.family_name,
        position: data.position,
      }),
      Cell: ({ value }) => (
        <Author
          image={value.profile_image_url}
          given_name={value.given_name}
          family_name={value.family_name}
          position={value.position}
        />
      ),
    },
    {
      Header: "Team Name",
      accessor: "team_name",
      Cell: ({ value }) => (
        <XBox width={"100%"} display="flex" flexDirection="column">
          {value.map((currentValue, index) => (
            <XBox key={index}>
              <XTypography
                variant="button"
                fontWeight="regular"
                sx={{ lineHeight: 0, color: "#67748e" }}
              >
                {currentValue}
              </XTypography>
            </XBox>
          ))}
        </XBox>
      ),
    },
    {
      Header: "Username",
      accessor: "username",
      Cell: ({ value }) => value,
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ value }) => value,
    },

    {
      Header: "Role",
      accessor: "role_name",
      Cell: ({ value }) => (
        <XBox width={"100%"} display="flex" justifyContent="center" alignItems="center">
          {renderBadge(value)}
        </XBox>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => (
        <Tooltip title={value === "active" ? "" : "Request Pending"}>
          {value === "active" ? <SuccessStatusIcon /> : <RejectStatusIcon />}
        </Tooltip>
      ),
    },
    {
      Header: "Joined",
      accessor: "created",
      Cell: ({ value }) => (value === null ? "" : getTimeDifference(value, true)),
    },
  ];

  //Use effect to vertically sort the dropdown on page break
  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  // we need this so the table updates after the timeout expires

  return (
    <Grid spacing={3} container>
      <Grid item xs={12}>
        <Card>
          <DataTable
            handleRowClick={null}
            table={{ rows: tableRows, columns: columns }}
            entriesPerPage={{
              defaultValue: entriesPerPage,
              entries: [5, 7, 10, 15, 20, 25],
            }}
            canSearch
          />
        </Card>
      </Grid>

      <XSnackbar
        color="success"
        icon="check"
        title="The user has been invited!"
        content={messageContent}
        // dateTime="Right now!"
        open={show}
        close={toggleSnackbar}
      />
    </Grid>
  );
}

export default React.memo(UserTable);

function Author({ image, given_name, family_name, position }) {
  return (
    <XBox display="flex" alignItems="center" px={1} py={0.5}>
      <XBox mr={2}>
        <XAvatar src={image} alt={name} size="sm" />
      </XBox>
      <XBox display="flex" flexDirection="column">
        <XTypography variant="button" fontWeight="medium">
          {(given_name || family_name) === null ? "" : given_name + " " + family_name}
        </XTypography>
        <XTypography variant="caption" color="secondary">
          {position ? position : ""}
        </XTypography>
      </XBox>
    </XBox>
  );
}

function Function({ job, org }) {
  return (
    <XBox display="flex" flexDirection="column">
      <XTypography variant="caption" fontWeight="medium" color="text">
        {job}
      </XTypography>
      <XTypography variant="caption" color="secondary">
        {org}
      </XTypography>
    </XBox>
  );
}

// Render the badge content
const renderBadge = (badgeContent) => {
  let color;
  switch (badgeContent) {
    case "admin":
      color = "success";
      break;
    case "creator":
      color = "xppink";
      break;
    case "viewer":
      color = "xpblue";
      break;
    default:
      return null; // Or provide a default badge if needed
  }

  return <XBadge color={color} variant="contained" size="xs" badgeContent={badgeContent} />;
};

Author.propTypes = {
  given_name: PropTypes.string.isRequired,
  family_name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
};

Function.propTypes = {
  job: PropTypes.string.isRequired,
  org: PropTypes.string.isRequired,
};

UserTable.propTypes = {
  data: PropTypes.object,
  teamData: PropTypes.object,
  organisationId: PropTypes.string,
  handleRowClick: PropTypes.func,
  value: PropTypes.object,
  row: PropTypes.object,
  entriesPerPage: PropTypes.number,
  isAdmin: PropTypes.bool,
  setIsAdmin: PropTypes.func,
  tableRows: PropTypes.array,
  setTableRows: PropTypes.func,
  messageContent: PropTypes.string,
  show: PropTypes.bool,
  toggleSnackbar: PropTypes.func,
};
