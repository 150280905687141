import React, { useState, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  Icon,
} from "@mui/material";
import { styled } from "@mui/system";
import PropTypes from "prop-types";
import XButton from "components/XButton";
import XTypography from "components/XTypography";
import XBox from "components/XBox";
import colors from "assets/theme/base/colors";

const StyledDialog = styled(Dialog)`
  .MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const TokenModal = ({
  isOpen,
  setIsOpen,
  onUpdate,
  tokenKey,
  setTokenKey,
  formikProps,
  setExpanded,
}) => {
  const [copySuccess, setCopySuccess] = useState("Copy to Clipboard");
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setCopySuccess("Copied!");
  }

  function handleClose() {
    setIsOpen(false);
    setCopySuccess("Copy to Clipboard");
    setTokenKey(null);
    formikProps.resetForm();
    setExpanded(false);
  }

  return (
    <>
      <XButton
        sx={{ alignSelf: "flex-end" }}
        variant="gradient"
        color="button"
        size="medium"
        onClick={() => {
          formikProps.submitForm(); // submit the form
          if (formikProps.isValid) {
            // if the form is valid
            setIsOpen(true); // open the modal
            onUpdate(); // run the onUpdate function
          }
        }}
      >
        Add Collection
      </XButton>
      <StyledDialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <XBox position="relative" p={2}>
          <XBox
            display="flex"
            mb={1}
            sx={{ flexDirection: "column", justifyContent: "center", alignItems: "center" }}
          >
            <XTypography variant="h5">API Key</XTypography>
            <XTypography variant="h6" color="xppink" fontWeight="light">
              {"Keep this key safe. You won't be able to see it again."}
            </XTypography>
            <Icon
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                cursor: "pointer",
              }}
              fontSize="2rem"
              onClick={handleClose}
            >
              close
            </Icon>
          </XBox>
          <TextareaAutosize
            ref={textAreaRef}
            minRows={3}
            style={{
              width: "100%",
              backgroundColor: colors.light.main,
              color: "dark",
              fontSize: "1.2rem",
              border: "none",
              borderRadius: "12px",
              padding: "0.8rem",
            }}
            value={tokenKey}
          />
          <DialogActions>
            <XButton
              color="error"
              variant="light"
              onClick={handleClose}
              sx={{ flexGrow: 1, mx: 1 }}
            >
              Close
            </XButton>
            <XButton
              variant="light"
              color={copySuccess === "Copied!" ? "success" : "xpblue"}
              onClick={(e) => {
                copyToClipboard(e);
              }}
              sx={{ flexGrow: 1, mx: 1 }}
            >
              {copySuccess}
            </XButton>
          </DialogActions>
        </XBox>
      </StyledDialog>
    </>
  );
};

export default TokenModal;

// Typechecking for proptypes
TokenModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onUpdate: PropTypes.func,
  tokenKey: PropTypes.string,
  setTokenKey: PropTypes.func,
  formikProps: PropTypes.object,
  setExpanded: PropTypes.func,
};
