import { forwardRef, isValidElement } from "react";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XButton from "components/XButton";
import { defaultItemIconBox, defaultItemIcon } from "shared/Items/DefaultItem/styles";
import { Tooltip } from "@mui/material";

const DefaultItem = forwardRef(
  ({ color, icon, title, description, tooltipText, small = false, ...rest }, ref) => (
    <XBox {...rest} ref={ref} display="flex" alignItems="center">
      <XBox display="flex" alignItems="center" flexGrow={1}>
        <XBox lineHeight={1}>
          <XBox display="flex" gap={1}>
            <XTypography
              variant="body1"
              fontSize={small ? "12px" : "16px"}
              display="block"
              sx={{ color: "#7C7C7C" }}
            >
              {title}
            </XTypography>
            {tooltipText && (
              <XBox mr={2}>
                <Tooltip title={tooltipText} placement="top">
                  <XButton variant="outlined" color="secondary" size="xxs" circular iconOnly>
                    <Icon>question_mark</Icon>
                  </XButton>
                </Tooltip>
              </XBox>
            )}
          </XBox>

          <XTypography variant={small ? "body2" : "h5"} fontWeight="medium">
            {description}
          </XTypography>
        </XBox>
      </XBox>
    </XBox>
  )
);

DefaultItem.defaultProps = {
  color: "info",
};

DefaultItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "xpblue",
    "xppurple",
    "xppink",
  ]),
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tooltipText: PropTypes.string,
  small: PropTypes.bool,
};

export default DefaultItem;
