import { useEffect, useRef, useState } from "react";

import { useCommentsMutation } from "api/mutations";
import { useXplainableController } from "context";
import { handleTokenExpired } from "utils";

export const useComment = ({
  type,
  id,
  apiKey,
  text,
  fetchedComments,
  setFetchedComments,
  activeWorkspace,
  comment_id,
}) => {
  const inputRef = useRef(null);

  const [controller] = useXplainableController();

  const { deleteCommentMutation, editCommentMutation, addLikeMutation, removeLikeMutation } =
    useCommentsMutation();

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(text);
  const [isReplyOpen, setIsReplyOpen] = useState(false);
  const [editedCommentTime, setEditedCommentTime] = useState(null);

  const [likeCount, setLikeCount] = useState(0);

  const { darkMode } = controller;

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const getInitials = (given_name, family_name) => {
    return given_name.charAt(0).toUpperCase() + family_name.charAt(0).toUpperCase();
  };

  const getKeyByValue = (object, value) => {
    return parseInt(Object.keys(object).find((key) => object[key] === value));
  };

  const handleDeleteComment = () => {
    deleteCommentMutation([activeWorkspace, type, id, comment_id, apiKey], {
      onSuccess: () => {
        const updatedCommentList = fetchedComments.filter(
          (comment) => comment.comment_id !== comment_id
        );
        setFetchedComments(updatedCommentList);
      },
      onError: (error) => {
        console.log(error);
        handleTokenExpired();
      },
    });
  };

  const handleEditComment = () => {
    setIsEditing(true);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const data = JSON.stringify({
        comment: editedText,
      });

      editCommentMutation([activeWorkspace, type, id, comment_id, apiKey, data], {
        onSuccess: () => {
          const updatedCommentList = fetchedComments.map((comment) => {
            if (comment.comment_id === comment_id) {
              comment.comment = editedText;
            }
            return comment;
          });
          setFetchedComments(updatedCommentList);
          setIsEditing(false);

          setEditedCommentTime(new Date().toISOString());
        },
        onError: (error) => {
          console.log(error);
          handleTokenExpired();
        },
      });
    }
  };

  const escFunction = (event) => {
    if (event.key === "Escape" && isEditing) {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const handleLike = (commentId, interactionType) => {
    const updateComments = (comments) => {
      for (let i = 0; i < comments.length; i++) {
        if (comments[i].comment_id === commentId) {
          if (interactionType === "like") {
            comments[i].like_count += 1;
            comments[i].user_has_liked = true;
          } else if (interactionType === "unlike") {
            comments[i].like_count -= 1;
            comments[i].user_has_liked = false;
          }
          return true; // Found and updated the comment
        }
        if (comments[i].replies && comments[i].replies.length > 0) {
          if (updateComments(comments[i].replies)) {
            return true; // Found and updated the comment in a nested reply
          }
        }
      }
      return false; // Comment not found
    };

    const updatedComments = [...fetchedComments];
    if (updateComments(updatedComments)) {
      setFetchedComments(updatedComments);
    }

    if (interactionType === "like") {
      addLikeMutation([activeWorkspace, type, id, comment_id, apiKey, interactionType], {
        onSuccess: (data) => {
          console.log(data);
        },
        onError: (error) => {
          console.log(error);
          handleTokenExpired();
        },
      });
    } else {
      removeLikeMutation([activeWorkspace, type, id, comment_id, apiKey, interactionType], {
        onSuccess: (data) => {
          console.log(data);
        },
        onError: (error) => {
          console.log(error);
          handleTokenExpired();
        },
      });
    }
  };

  const toggleReplies = () => {
    setIsReplyOpen(!isReplyOpen);
  };
  return {
    inputRef,
    menuAnchorEl,
    setMenuAnchorEl,
    isEditing,
    setIsEditing,
    editedText,
    setEditedText,
    likeCount,
    darkMode,
    handleMenuClose,
    getInitials,
    getKeyByValue,
    handleDeleteComment,
    handleEditComment,
    handleKeyPress,
    handleLike,
    toggleReplies,
    isReplyOpen,
    editedCommentTime,
    setEditedCommentTime,
  };
};
