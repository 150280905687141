import { apiPathWithApiHost } from "utils";

export const apiPaths = {
  comments: {
    getComments: {
      models: (activeWorkspace, modelId, pageNumber, itemsPerPage) =>
        `/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${modelId}/comments?page_number=${pageNumber}&items_per_page=${itemsPerPage}`,
      deployments: (activeWorkspace, deploymentId, pageNumber, itemsPerPage) =>
        `/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/deployments/${deploymentId}/comments?page_number=${pageNumber}&items_per_page=${itemsPerPage}`,
      collections: (activeWorkspace, collectionId, pageNumber, itemsPerPage) =>
        `/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/collections/${collectionId}/comments?page_number=${pageNumber}&items_per_page=${itemsPerPage}`,
    },

    getUsers: (activeWorkspace) =>
      `/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/users`,

    getFilteredUsers: (activeWorkspace, input) =>
      `/organisations/${activeWorkspace?.organisation_id}/search-user?search_string=${input}`,

    addComment: (activeWorkspace, type, id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/${type}/${id}/create-comment`
      ),
    deleteComment: (activeWorkspace, type, id, comment_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/${type}/${id}/comments/${comment_id}`
      ),

    editComment: (activeWorkspace, type, id, comment_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/${type}/${id}/comments/${comment_id}/edit`
      ),

    addLike: (activeWorkspace, type, id, comment_id, interactionType) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/${type}/${id}/comments/${comment_id}/${interactionType}`
      ),

    removeLike: (activeWorkspace, type, id, comment_id, interactionType) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/${type}/${id}/comments/${comment_id}/${interactionType}`
      ),
  },
  cards: {
    toggleCard: (activeWorkspace, endpointType, id, action) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/${endpointType}/${id}/${action}`
      ),
  },
  xPanel: {
    getXPanel: () => apiPathWithApiHost(`/v1/xpanel`),
  },
  user: {
    getUser: () => apiPathWithApiHost(`/v1/user`),
    createUser: () => apiPathWithApiHost(`/v1/create-user`),
    deleteUser: () => apiPathWithApiHost(`/v1/user`),
    userNotification: () => apiPathWithApiHost(`/v1/user/notification-preferences`),
  },
  users: {
    getUsers: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/organisations/${activeWorkspace?.organisation_id}/users`),
    getTeamUsers: (organisationId) =>
      apiPathWithApiHost(`/v1/organisations/${organisationId}/users`),
    searchUser: (organisationId, input) =>
      apiPathWithApiHost(`/v1/organisations/${organisationId}/search-user?search_string=${input}`),
  },
  models: {
    getModels: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models`
      ),
    getModel: (activeWorkspace, model_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}`
      ),
    deleteModel: (activeWorkspace, model_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}`
      ),
    archiveModel: (activeWorkspace, model_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/archive`
      ),
    moveModel: (activeWorkspace, model_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/move`
      ),
    deleteModelVersion: (activeWorkspace, model_id, selectedVersion) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/versions/${selectedVersion.value}`
      ),
    handleActiveVersion: (activeWorkspace, model_id, selectedVersion) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/versions/${selectedVersion.value}/set-as-active-version`
      ),
    createModel: (activeWorkspace, modelData) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/create-model`,
        modelData
      ),
    addVersion: (activeWorkspace, model_id, versionData) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/add-version`,
        versionData
      ),
  },
  deployments: {
    getDeployments: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models`
      ),
    deleteDeployments: (activeWorkspace, deployment_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/deployments/${deployment_id}`
      ),
    handleDeployDeployment: (activeWorkspace, model_id, version_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/versions/${version_id}/deploy`
      ),
    handleArchiveDeployment: (activeWorkspace, model_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/archive`
      ),
    getKey: (activeWorkspace, deployment_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/deployments/${deployment_id}/deploy-keys`
      ),
    getPayload: (activeWorkspace, deployment_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/deployments/${deployment_id}/payload`
      ),
    getPrediction: () => `https://inference.xplainable.io/v1/predict`,
  },
  toggle: {
    toggleDeployment: (activeWorkspace, deployment_id, action) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/deployments/${deployment_id}/${action}`
      ),
    toggleModel: (activeWorkspace, model_id, action) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/${action}`
      ),
  },
  invitations: {
    inviteUser: () => apiPathWithApiHost("/v1/invite"),
    getInvitations: () => apiPathWithApiHost(`/v1/invitations`),
    getRequests: () => apiPathWithApiHost(`/v1/requests`),
    deleteInvitation: (invitationId) => apiPathWithApiHost(`/v1/invitations/${invitationId}`),
    handleInvitations: (type, requestId, action) =>
      apiPathWithApiHost(`/v1/${type}/${requestId}/${action}`),
  },
  notifications: {
    getNotifications: () => apiPathWithApiHost(`/v1/notifications`),
    markAsRead: (notificationId) => apiPathWithApiHost(`/v1/notifications/${notificationId}`),
    markAllAsRead: () => apiPathWithApiHost(`/v1/notifications`),
  },
  preprocessors: {
    getAllPreprocessors: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/preprocessors`
      ),
    getPreprocessorVersion: (activeWorkspace, preprocessor_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/preprocessors/${preprocessor_id}/versions`
      ),
    getPreprocessors: (activeWorkspace, model_id, version_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/versions/${version_id}/preprocessor`
      ),
    getPreprocessorSample: (activeWorkspace, model_id, version_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/versions/${version_id}/sample`
      ),
    checkSignature: (activeWorkspace, preprocessor_id, preprocessor_version) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/preprocessors/${preprocessor_id}/versions/${preprocessor_version}/check-signature`
      ),
    linkPreprocessor: (activeWorkspace, model_id, version_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/versions/${version_id}/link-preprocessor`
      ),
  },
  profileData: {
    getProfileData: (activeWorkspace, model_id, version) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/versions/${version}/profile`
      ),
  },
  scenario: {
    addScenario: (activeWorkspace, model_id, collection_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/collections/${collection_id}/add-scenario`
      ),

    getScenario: (activeWorkspace, model_id, collection_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/collections/${collection_id}/scenarios`
      ),
    deleteScenario: (activeWorkspace, modelId, collectionId, scenarioId) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${modelId}/collections/${collectionId}/scenarios/${scenarioId}`
      ),
  },
  collections: {
    getAllCollections: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/collections`
      ),
    getCollections: (activeWorkspace, model_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/collections`
      ),
    getCollection: (activeWorkspace, model_id, collection_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/collections/${collection_id}/scenarios`
      ),
    createCollection: (activeWorkspace, modelId) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${modelId}/create-collection`
      ),
    deleteCollection: (activeWorkspace, model_id, collection_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/collections/${collection_id}`
      ),
    updateTitle: (activeWorkspace, model_id, collection_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/collections/${collection_id}/name`
      ),
    updateDescription: (activeWorkspace, model_id, collection_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/collections/${collection_id}/description`
      ),
    deleteModelCollections: (activeWorkspace, model_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/delete-collections`
      ),
    updateScenarioNotes: (activeWorkspace, model_id, collection_id, scenario_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/collections/${collection_id}/scenarios/${scenario_id}/notes`
      ),
  },
  reports: {
    getReports: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/reports`
      ),
    getReportsVersion: (activeWorkspace, reportId) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/reports/${reportId}`
      ),
    createReport: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/create-report`
      ),
    createReportVersion: (activeWorkspace, reportId) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/reports/${reportId}/create-version`
      ),
    deleteReport: (activeWorkspace, reportId) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/reports/${reportId}`
      ),
    copyReport: (activeWorkspace, reportId) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/reports/${reportId}/copy`
      ),
  },
  health: {
    getHealthData: (activeWorkspace, modelId, selectedVersion) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${modelId}/versions/${selectedVersion.value}/feature-info`
      ),
  },
  predict: {
    postPredict: () => apiPathWithApiHost("/v1/predict"),
  },
  subscription: {
    getSubscription: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/organisations/${activeWorkspace?.organisation_id}/get-subscription`),
    getSubscriptionSeats: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/organisations/seats/${activeWorkspace?.organisation_id}`),
    updateSubscriptionSeats: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/subscriptions/addon/${activeWorkspace?.organisation_id}`),
    createSubscription: (activeWorkspace) => 
      apiPathWithApiHost(`/v1/organisations/${activeWorkspace?.organisation_id}/create-subscription`),
    cancelSubscription: (activeWorkspace) => 
      apiPathWithApiHost(`/v1/organisations/${activeWorkspace?.organisation_id}/cancel-subscription`),
    resumeSubscription: (activeWorkspace) => 
      apiPathWithApiHost(`/v1/organisations/${activeWorkspace?.organisation_id}/resume-subscription`),
  },
  commentary: {
    getCommentary: (activeWorkspace, id, version) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${id}/versions/${version.value}/commentary/Profile`
      ),

    autoGenerateReport: (activeWorkspace, id, version) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${id}/versions/${version.value}/generate-report`
      ),

    changeCommentary: (activeWorkspace, id, version) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${id}/versions/${version.value}/commentary/Profile`
      ),

    handlePublishCommentary: (activeWorkspace, id, version, action) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${id}/versions/${version.value}/commentary/Profile/${action}`
      ),
  },
  binaryPerformance: {
    getData: (activeWorkspace, model_id, version_id, partition_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/versions/${version_id}/partitions/${partition_id}/evaluation`
      ),
  },
  pipeLine: {
    getData: (activeWorkspace, preprocessor_id, preprocessor_version) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/preprocessors/${preprocessor_id}/versions/${preprocessor_version}/pipeline`
      ),
  },
  regressionPerformance: {
    getData: (activeWorkspace, model_id, version_id, partition_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/versions/${version_id}/partitions/${partition_id}/evaluation`
      ),
  },
  organisation: {
    getData: () => apiPathWithApiHost(`/v1/organisations`),
    createOrganisation: () => apiPathWithApiHost(`/v1/create-organisation`),
    deleteOrganisation: (organisation_id) =>
      apiPathWithApiHost(`/v1/organisations/${organisation_id}`),
    getOrganisationMetadata: () => apiPathWithApiHost(`/v1/organisations/metadata`),
    manageOrganisation: () => apiPathWithApiHost(`/v1/subscriptions/customer-portal`),
  },
  teams: {
    getTeams: (organisationId) => apiPathWithApiHost(`/v1/organisations/${organisationId}/teams`),
    createTeam: (organisationData) =>
      apiPathWithApiHost(`/v1/organisations/${organisationData?.organisation_id}/create-team`),
    deleteTeam: (organisationData, team_id) =>
      apiPathWithApiHost(`/v1/organisations/${organisationData?.organisation_id}/teams/${team_id}`),
  },
  apiKeys: {
    getData: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/keys`
      ),

    createKey: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/create-bound-api-key`
      ),

    revokeAllKeys: () => apiPathWithApiHost("/v1/revoke-all-keys"),
  },
  batchOptimisation: {
    getData: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers`
      ),
    getOptimisersVersions: (activeWorkspace, optimiser_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions`
      ),
    getOptimiserVersion: (activeWorkspace, optimiser_id, version_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}`
      ),
    createOptimiserVersion: (activeWorkspace, optimiser_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/create-version`
      ),
    deleteOptimiser: (activeWorkspace, optimiser_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}`
      ),
    createOptimiser: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/create-optimiser`
      ),
    deleteOptimiserVersion: (activeWorkspace, optimiser_id, version_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}`
      ),
    updateOptimiserVersion: (activeWorkspace, optimiser_id, version_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}`
      ),
    resetOptimiserVersion: (activeWorkspace, optimiser_id, version_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}/reset`
      ),
    createBatches: (activeWorkspace, optimiser_id, version_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}/create-batches`
      ),
    getBatch: (activeWorkspace, optimiser_id, version_id, batch_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}/batches/${batch_id}/frontend`
      ),
    downloadBatch: (activeWorkspace, optimiser_id, version_id, batch_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}/batches/${batch_id}/download`
      ),
    getRunBatches: (activeWorkspace, optimiser_id, version_id, run_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}/runs/${run_id}/batches`
      ),
  },
  dataset: {
    getData: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/datasets`
      ),
    createDataset: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/upload-dataset`
      ),
    getPreview: (activeWorkspace, dataset_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/datasets/${dataset_id}/preview`
      ),
    deleteDataset: (activeWorkspace, dataset_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/datasets/${dataset_id}`
      ),
    updateDataSet: (activeWorkspace, dataset_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/datasets/${dataset_id}`
      ),
    getItemData: (activeWorkspace, dataset_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/datasets/${dataset_id}`
      ),
    downloadDataset: (activeWorkspace, dataset_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/datasets/${dataset_id}/download`
      ),
  },
  runs: {
    createRun: (activeWorkspace, optimiser_id, version_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}/create-run`
      ),
    getData: (activeWorkspace, optimiser_id, version_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}/runs`
      ),
    getRunData: (activeWorkspace, optimiser_id, version_id, run_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}/runs/${run_id}/frontend`
      ),
    getBatchData: (activeWorkspace, optimiser_id, version_id, batch_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/optimisers/${optimiser_id}/versions/${version_id}/batches/${batch_id}/frontend`
      ),
  },
  output: {
    enableExplain: (activeWorkspace, deployment_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/deployments/${deployment_id}/enable-explain`
      ),

    disableExplain: (activeWorkspace, deployment_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/deployments/${deployment_id}/disable-explain`
      ),
  },
  partitions: {
    getPartitions: (activeWorkspace, model_id, version_id) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/versions/${version_id}`
      ),
  },
  preferences: {
    updatePreferences: () => apiPathWithApiHost(`/v1/user/preferences`),
  },
  request: {
    sendRequest: () => "https://formbold.com/s/3Gq10",
  },
  deployKeys: {
    getDeployKeys: (activeWorkspace, deploymentId) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/deployments/${deploymentId}/deploy-keys`
      ),
    createDeployKey: (activeWorkspace, deploymentId) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/deployments/${deploymentId}/create-deploy-key`
      ),
    deleteDeployKey: (activeWorkspace, deploymentId, keyId) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/deployments/${deploymentId}/deploy-keys/${keyId}`
      ),
    revokeAllDeployKeys: (activeWorkspace, deploymentId) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/deployments/${deploymentId}/revoke-all-user-deploy-keys`
      ),
  },
  network: {
    createIpAddress: (activeWorkspace, deploymentId) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/deployments/${deploymentId}/add-allowed-ip`
      ),
    handleToggleChange: (activeWorkspace, deployment_id, action) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/deployments/${deployment_id}/${action}`
      ),
    getFirewalls: (activeWorkspace, deploymentId) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/deployments/${deploymentId}/get-firewall-descriptions`
      ),
    removeIpAddress: (activeWorkspace, deploymentId, sourceToRemove) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/deployments/${deploymentId}/ips/${sourceToRemove.ip_id}`
      ),
  },
  calendar: {
    getCalendarData: () => apiPathWithApiHost("/v1/login-events"),
  },
  recent: {
    getRecentData: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/recent`
      ),
  },
  accountSettings: {
    updateProfileImage: () => apiPathWithApiHost(`/v1/user/profile-image`),
    updateDetails: () => apiPathWithApiHost(`/v1/user/update-details`),
  },
  configuration: {
    saveConfiguration: (activeWorkspace, model_id, selectedVersion) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/models/${model_id}/versions/${selectedVersion.value}/feature-info`
      ),
  },
  login: {
    login: () => apiPathWithApiHost(`/v1/login`),
    limits: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/organisations/limits/${activeWorkspace?.organisation_id}`),
  },
  train: {
    dataPrep: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/dataprep/recommend`),
    applyStep: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/dataprep/apply`
      ),
    downloadDataprep: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/dataprep/download`
      ),
    dropDataprep: (activeWorkspace) =>
      apiPathWithApiHost(
        `/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/dataprep/drop`
      ),
    summarize: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/summarize`),
    summarizeUrl: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/summarize/url`),
    goal: (activeWorkspace) => apiPathWithApiHost(`/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/goal`),
    featureEngineering: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/feature_engineering`),
    insights: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/insights`),
    visualize: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/visualize`),
    visualizeEdit: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/visualize/edit`),
    visualizeRepair: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/visualize/repair`),
    visualizeExplain: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/visualize/explain`),
    visualizeEvaluate: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/visualize/evaluate`),
    visualizeRecommend: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/visualize/recommend`),
    textGenerate: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/text/generate`),
    infographer: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/text/infographer`),
    getModels: () =>
      apiPathWithApiHost(`/v1/models/completion`),
    autoTrain: (activeWorkspace) =>
      apiPathWithApiHost(`/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/auto_train`),
    train: (activeWorkspace) => apiPathWithApiHost(`/v1/organisations/${activeWorkspace?.organisation_id}/teams/${activeWorkspace?.team_id}/train`),
    getApiKey: (modelProvider) =>
      apiPathWithApiHost(`/v1/user/autotrain_keys/${modelProvider}`),
    setApiKey: () =>
      apiPathWithApiHost(`/v1/user/autotrain_keys`),
  },
};
