import React, { useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useApiKey } from "../ApiKeyContext";
import { Paths } from "settings";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useXplainableController } from "context";
import { RxAvatar } from "react-icons/rx";

import XTypography from "components/XTypography";
import XAvatar from "components/XAvatar";
import XBox from "components/XBox";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Dark Icon

import DarkAccountSettingIcon from "assets/images/icons/user-manager/dark-account-setting-icon.svg";
import DarkAdminIcon from "assets/images/icons/user-manager/dark-admin-icon.svg";
import DarkLogoutIcon from "assets/images/icons/user-manager/dark-logout-icon.svg";

// Light Icon
import LightAccountSettingIcon from "assets/images/icons/user-manager/light-account-setting-icon.svg";
import LightAdminIcon from "assets/images/icons/user-manager/light-admin-icon.svg";
import LightLogoutIcon from "assets/images/icons/user-manager/light-logout-icon.svg";

const UserButton = () => {
  const { logout } = useAuth0();
  const [controller] = useXplainableController();

  const { user, setLoggingOut, resetState, ...rest } = useApiKey();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleAccountSetting = () => {
    handleClose();
    navigate("/", { state: { tabValue: 1 } });
  };

  const handleManageUsers = () => {
    handleClose();
    navigate("/admin");
  };

  //Handle the logout functionality
  const handleLogout = useCallback(() => {
    console.log("handleLogout triggered");

    handleClose();

    // Clear the specific items in local storage
    // localStorage.removeItem("apiKey");
    // localStorage.removeItem("workspaces");

    // Use Auth0 handler to logout
    logout({
      logoutParams: { returnTo: `${window.location.origin}${Paths.login}` },

      onComplete: () => {
        console.log("Auth0 logout completed");
      },
    });
  }, [handleClose]);

  return (
    <XBox>
      {user?.image ?
        <XAvatar
        src={user?.image}
        alt="profile picture"
        size="sm"
        variant="full"
        onClick={handleClick}
        aria-controls="logout-menu"
        aria-haspopup="true"
      />
      :
      <RxAvatar 
        style={{ color: controller?.darkMode || controller?.darkSidenav ? "white": 'black', fontSize: '35px', marginTop: "8px" }}  
        onClick={handleClick}
      />
      }
      <Menu
        id="logout-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem sx={{ marginBottom: "16px" }}>
          <XBox display="flex" flexDirection="column">
            <XTypography variant="button">{user?.username}</XTypography>
            <XTypography variant="caption" style={{ color: "#7C7C7C", fontSize: "14px" }}>
              {user?.position}
            </XTypography>
          </XBox>
        </MenuItem>
        <MenuItem onClick={handleAccountSetting} sx={{ padding: "10px 16px" }}>
          <XBox display="flex" gap="10px" alignItems="center">
            <XBox
              src={controller?.darkMode ? LightAccountSettingIcon : DarkAccountSettingIcon}
              component="img"
            />
            <XTypography variant="caption" style={{ fontSize: "14px" }}>
              Account Settings
            </XTypography>
          </XBox>
        </MenuItem>
        <MenuItem onClick={handleManageUsers} sx={{ padding: "10px 16px" }}>
          <XBox display="flex" gap="10px" alignItems="center">
            <XBox src={controller?.darkMode ? LightAdminIcon : DarkAdminIcon} component="img" />
            <XTypography variant="caption" style={{ fontSize: "14px" }}>
              Admin
            </XTypography>
          </XBox>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout} sx={{ padding: "10px 16px" }}>
          <XBox display="flex" gap="10px" alignItems="center">
            <XBox src={controller?.darkMode ? LightLogoutIcon : DarkLogoutIcon} component="img" />
            <XTypography variant="caption" style={{ fontSize: "14px" }}>
              Log out
            </XTypography>
          </XBox>
        </MenuItem>
      </Menu>
    </XBox>
  );
};

export default UserButton;
