import React, { useEffect, useRef, useState, useContext } from "react";
import ModelContext from "context/ModelContext";
import PropTypes from "prop-types";
import * as d3 from "d3";

import XTypography from "components/XTypography";
import XBox from "components/XBox";

import { useModel } from "hooks";

const ConfusionChart = ({ confusionData, margin }) => {
  const { collapsedWidth } = useModel();

  const dataRef = useRef();
  const formatDataRef = useRef();
  const simulationRef = useRef();
  const oldScaled = useRef();

  const ref = useRef();
  const svgContainer = useRef(null);
  const widthRef = useRef(550);

  const [width, setWidth] = useState(550);
  const [height, setHeight] = useState(340);

  const [isInitial, setIsInitial] = useState(true);

  // This function calculates width and height of the container
  const getSvgContainerSize = () => {
    const newWidth = svgContainer.current.clientWidth;

    if (isInitial) {
      widthRef.current = newWidth;
    }
    setWidth(newWidth);
  };

  function ticked() {
    const svg = d3.select(ref.current);

    svg
      .selectAll(".bubble")
      .attr("cx", function (d) {
        return d.x;
      })
      .attr("cy", function (d) {
        return d.y;
      });
  }

  function updateBubbles() {
    const svg = d3.select(ref.current);

    const fn = dataRef.current.fn;
    const fp = dataRef.current.fp;
    const tn = dataRef.current.tn;
    const tp = dataRef.current.tp;

    const maxScaledValue = 300;
    // Calculate the scaling factor
    const total = fn + fp + tn + tp;
    const scalingFactor = maxScaledValue / total;
    // Scale the values
    const new_scaled_fn = Math.round(fn * scalingFactor);
    const new_scaled_fp = Math.round(fp * scalingFactor);
    const new_scaled_tn = Math.round(tn * scalingFactor);
    const new_scaled_tp = Math.round(tp * scalingFactor);

    const textValues = [
      [dataRef.current.tn, dataRef.current.fn],
      [dataRef.current.fp, dataRef.current.tp],
    ];

    if (new_scaled_tn > oldScaled.current.scaled_tn) {
      const selectedBubbles = formatDataRef.current
        .filter((d) => d.bucket === "fp")
        .slice(0, new_scaled_tn - oldScaled.current.scaled_tn);

      const targetX = 10;
      const targetBucket = "tn";

      selectedBubbles.forEach((bubble) => {
        bubble.bucket = targetBucket;
        bubble.x += targetX;
      });

      oldScaled.current.scaled_tn = new_scaled_tn;
      oldScaled.current.scaled_fp = new_scaled_fp;
    }

    if (new_scaled_fp > oldScaled.current.scaled_fp) {
      const selectedBubbles = formatDataRef.current
        .filter((d) => d.bucket === "tn")
        .slice(0, new_scaled_fp - oldScaled.current.scaled_fp);

      const targetX = 10;
      const targetBucket = "fp";

      selectedBubbles.forEach((bubble) => {
        bubble.bucket = targetBucket;
        bubble.x += targetX;
      });

      oldScaled.current.scaled_fp = new_scaled_fp;
      oldScaled.current.scaled_tn = new_scaled_tn;
    }

    if (new_scaled_tp > oldScaled.current.scaled_tp) {
      const selectedBubbles = formatDataRef.current
        .filter((d) => d.bucket === "fn")
        .slice(0, new_scaled_tp - oldScaled.current.scaled_tp);

      const targetX = 10;
      const targetBucket = "tp";

      selectedBubbles.forEach((bubble) => {
        bubble.bucket = targetBucket;
        bubble.x += targetX;
      });

      oldScaled.current.scaled_tp = new_scaled_tp;
      oldScaled.current.scaled_fn = new_scaled_fn;
    }

    if (new_scaled_fn > oldScaled.current.scaled_fn) {
      const selectedBubbles = formatDataRef.current
        .filter((d) => d.bucket === "tp")
        .slice(0, new_scaled_fn - oldScaled.current.scaled_fn);

      const targetX = 10;
      const targetBucket = "fn";

      selectedBubbles.forEach((bubble) => {
        bubble.bucket = targetBucket;
        bubble.x += targetX;
      });

      oldScaled.current.scaled_tp = new_scaled_tp;
      oldScaled.current.scaled_fn = new_scaled_fn;
    }

    svg.selectAll(".text-value").remove();

    const groups = svg
      .selectAll("groups")
      .data(textValues)
      .enter()
      .append("g")
      .attr("transform", (d, i) => "translate(" + i * (width - 50) + ",0)")
      .style("font-size", "20px")
      .attr("fill", "#94a3b8")
      .attr("stroke", "white")
      .attr("class", "text-value")
      .attr("stroke-width", "0.5px")
      .lower();

    // const texts = groups
    //   .selectAll("texts")
    //   .data((d) => d)
    //   .enter()
    //   .append("text")
    //   .attr("y", (d, i) => 30 + i * 380)
    //   .attr("dy", (d, i) => (i == 1 ? "-1em" : "1em"))
    //   .text((d) => d);

    const duration = 1000;
    simulationRef.current.nodes(formatDataRef.current).on("tick", ticked);
    simulationRef.current.alpha(1).alphaTarget(0.03).restart();
    simulationRef.current.force("x").initialize(formatDataRef.current);
    simulationRef.current.force("y").initialize(formatDataRef.current);
    simulationRef.current.force("collide").initialize(formatDataRef.current);
  }

  useEffect(() => {
    const container = svgContainer.current;

    const handleResize = (entries) => {
      for (let entry of entries) {
        const newWidth = entry.contentRect.width;
        setWidth(newWidth);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    // Observe the container element
    if (container) {
      resizeObserver.observe(container);
    }

    return () => {
      // Disconnect the observer when the component unmounts
      resizeObserver.disconnect();
    };
  }, [svgContainer]);

  useEffect(() => {
    dataRef.current = confusionData;

    if (widthRef.current !== width && !isInitial && Object.keys(confusionData).length !== 0) {
      const svg = d3.select(ref.current);

      svg.attr("width", width).attr("height", height);
      widthRef.current = width;

      buildPlot(confusionData);
    }

    if (!isInitial && Object.keys(confusionData).length !== 0) {
      updateBubbles();
    }

    if (isInitial && Object.keys(confusionData).length !== 0) {
      const svg = d3.select(ref.current);

      svg.attr("width", width).attr("height", height);

      setIsInitial(false);
      buildPlot(confusionData);
    }
  }, [confusionData, width]);

  const buildPlot = (data) => {
    const svg = d3.select(ref.current);

    const colorScale = d3.scaleLinear().domain([0, 1]).range(["#E14067", "#0080EA"]);

    svg.selectAll("*").remove();

    // svg
    //   .append("line")
    //   .attr("x1", width / 2)
    //   .attr("y1", 0)
    //   .attr("x2", width / 2)
    //   .attr("y2", height - margin.top - margin.bottom - 20)
    //   .style("stroke-width", 2)
    //   .style("stroke", "#A9A9A9")
    //   .style("opacity", 0.2)
    //   .style("fill", "none")
    //   .style("stroke-dasharray", "5,5");

    //Add horizontal segment line
    // svg
    //   .append("line")
    //   .attr("x1", 0)
    //   .attr("y1", (height - margin.top - margin.bottom) / 2)
    //   .attr("x2", width - 20)
    //   .attr("y2", (height - margin.top - margin.bottom) / 2)
    //   .style("stroke-width", 2)
    //   .style("stroke", "#A9A9A9")
    //   .style("opacity", 0.2)
    //   .style("fill", "none")
    //   .style("stroke-dasharray", "5,5");

    const squareSize = Math.min(width, height) / 2;

    const partGroups = svg
      .selectAll(".part")
      .data(Object.entries(data))
      .enter()
      .append("g")
      .attr("class", "part")
      .attr("transform", (d, i) => {
        const x = (i % 2) * (width / 2);
        const y = Math.floor(i / 2) * (height / 2);
        return `translate(${x}, ${y})`;
      });

    const fn = confusionData.fn;
    const fp = confusionData.fp;
    const tn = confusionData.tn;
    const tp = confusionData.tp;

    const maxScaledValue = 300;
    // Calculate the scaling factor
    const total = fn + fp + tn + tp;
    const scalingFactor = maxScaledValue / total;
    // Scale the values
    const scaled_fn = Math.round(fn * scalingFactor);
    const scaled_fp = Math.round(fp * scalingFactor);
    const scaled_tn = Math.round(tn * scalingFactor);
    const scaled_tp = Math.round(tp * scalingFactor);

    oldScaled.current = { scaled_fn, scaled_fp, scaled_tn, scaled_tp };

    formatDataRef.current = [];
    const buckets = ["fn", "fp", "tn", "tp"];

    buckets.forEach(function (bucket) {
      var scaledValue = 0;
      if (bucket === "fn") {
        scaledValue = scaled_fn;
      } else if (bucket === "fp") {
        scaledValue = scaled_fp;
      } else if (bucket === "tn") {
        scaledValue = scaled_tn;
      } else if (bucket === "tp") {
        scaledValue = scaled_tp;
      }

      for (var i = 0; i < scaledValue; i++) {
        formatDataRef.current.push({ bucket: bucket, value: confusionData[bucket] });
      }
    });

    const centerX = width / 2;
    const centerY = height / 2;

    formatDataRef.current.forEach((d) => {
      if (d.bucket === "tn") {
        d.x = centerX;
        d.y = centerY;
      } else if (d.bucket === "fp") {
        d.x = centerX;
        d.y = centerY;
      } else if (d.bucket === "fn") {
        d.x = centerX;
        d.y = centerY;
      } else {
        d.x = centerX;
        d.y = centerY;
      }
    });

    simulationRef.current = d3
      .forceSimulation()
      .force(
        "x",
        d3
          .forceX(function (d) {
            if (d.bucket == "tn") {
              return (width * 1) / 4;
            } else if (d.bucket == "fp") {
              return (width * 3) / 4;
            } else if (d.bucket == "fn") {
              return (width * 1) / 4;
            } else {
              return (width * 3) / 4;
            }
          })
          .strength(0.05)
      )
      .force(
        "y",
        d3
          .forceY(function (d) {
            if (d.bucket == "tn") {
              return (height * 1) / 4;
            } else if (d.bucket == "fp") {
              return (height * 1) / 4;
            } else if (d.bucket == "fn") {
              return (height * 3) / 4;
            } else {
              return (height * 3) / 4;
            }
          })
          .strength(0.05)
      )
      .alphaDecay(0.03)
      .force("collide", d3.forceCollide().radius(5).strength(0.1));

    var bubbles = svg
      .selectAll("circle.bubble")
      .data(formatDataRef.current)
      .enter()
      .append("circle")
      .attr("class", "bubble")
      .attr("id", (d) => `circle-${d}`)
      .attr("r", 5)
      .style("fill", (d) => {
        return colorScale(d.value / d3.sum(Object.values(data)));
      })
      .style("opacity", 0.8)
      .style("z-index", "-1")
      .raise()
      .on("mouseover", function () {
        d3.select(this).style("stroke", "black");
      })
      .on("mouseout", function () {
        d3.select(this).style("stroke", "none");
      });

    svg.selectAll("text").remove();

    // const textValues = [
    //   [confusionData.tn, confusionData.fn],
    //   [confusionData.fp, confusionData.tp],
    // ];
    // const textLabels = [
    //   [`TRUE NEGATIVE`, `FALSE NEGATIVE`],
    //   [`FALSE POSITIVE`, `TRUE POSITIVE`],
    // ];

    // const groups = svg
    //   .selectAll("groups")
    //   .data(textValues)
    //   .enter()
    //   .append("g")
    //   .attr("transform", (d, i) => "translate(" + i * (width - 50) + ",0)")
    //   .style("font-size", "20px")
    //   .attr("fill", "#94a3b8")
    //   .attr("stroke", "white")
    //   .attr("stroke-width", "0.5px")
    //   .attr("class", "text-value")
    //   .lower();

    // const texts = groups
    //   .selectAll("texts")
    //   .data((d) => d)
    //   .enter()
    //   .append("text")
    //   .attr("y", (d, i) => 30 + i * 380)
    //   .attr("dy", (d, i) => (i == 1 ? "-1em" : "1em"))
    //   .text((d) => d);

    // const group2 = svg
    //   .selectAll("groups")
    //   .data(textLabels)
    //   .enter()
    //   .append("g")
    //   .attr("transform", (d, i) => "translate(" + i * (width - 140) + ",0)")
    //   .style("font-size", "16px")
    //   .attr("fill", "#94a3b8")
    //   .attr("stroke", "white")
    //   .attr("stroke-width", "0.5px")
    //   .lower();

    // const textlabel = group2
    //   .selectAll("texts")
    //   .data((d) => d)
    //   .enter()
    //   .append("text")
    //   .attr("y", (d, i) => 30 + i * 380)
    //   .text((d) => d);

    simulationRef.current.nodes(formatDataRef.current).on("tick", ticked);
  };

  return (
    <XBox height={"458px"}>
      <XBox display="flex" justifyContent="space-between">
        <XBox>
          <XTypography variant="h6" fontSize="18px">
            True Negative
          </XTypography>
          <XTypography color="xpblue" fontWeight="medium" fontSize="24px">
            {confusionData.tn}
          </XTypography>
        </XBox>
        <XBox>
          <XTypography variant="h6" sx={{ textAlign: "right" }} fontSize="18px">
            False Positive
          </XTypography>
          <XTypography
            sx={{ textAlign: "right" }}
            color="xpblue"
            fontWeight="medium"
            fontSize="24px"
          >
            {confusionData.fp}
          </XTypography>
        </XBox>
      </XBox>
      <XBox ref={svgContainer} height={"520px"} mt={-7}>
        <svg width={"100%"} pb={2} />
        <XBox mt={-14}>
          <svg ref={ref} />
        </XBox>
      </XBox>
      <XBox display="flex" justifyContent="space-between" mt={-23}>
        <XBox>
          <XTypography color="xpblue" fontWeight="medium" fontSize="24px">
            {confusionData.fn}
          </XTypography>
          <XTypography variant="h6" fontSize="18px">
            False Negative
          </XTypography>
        </XBox>
        <XBox>
          <XTypography
            sx={{ textAlign: "right" }}
            color="xpblue"
            fontWeight="medium"
            fontSize="24px"
          >
            {confusionData.tp}
          </XTypography>
          <XTypography variant="h6" sx={{ textAlign: "right" }} fontSize="18px">
            True Positive
          </XTypography>
        </XBox>
      </XBox>
    </XBox>
  );
};

const MetricElement = ({ title, value }) => {
  return (
    <XBox
      display="flex-col"
      p={1}
      alignItems="center"
      justifyContent="center"
      sx={{
        textAlign: "center",
        borderRadius: "10px",
        borderColor: "light",
        borderWidth: 1,
        borderStyle: "solid",
      }}
    >
      <XTypography variant="h6" opacity={0.8}>
        {title}
      </XTypography>
      <XTypography variant="h4">{value}</XTypography>
    </XBox>
  );
};

export { ConfusionChart, MetricElement };

// Typechecking props for the Feature Chart
ConfusionChart.propTypes = {
  confusionData: PropTypes.object,
  threshold: PropTypes.number,
  margin: PropTypes.object,
};

// Typechecking props for the Feature Chart
MetricElement.propTypes = {
  title: PropTypes.string,
  value: PropTypes.any,
};
