import React, { useState, useEffect, useRef } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// xplainable Dashboard components
import XBox from "components/XBox";
import XTypography from "components/XTypography";

// ProgressLineChart configurations
import configs from "./config";
import XDatePicker from "components/XDatePicker";
import { Tooltip, AppBar, Tabs, Tab } from "@mui/material";
import breakpoints from "assets/theme/base/breakpoints";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useXPanelQuery } from "api/query";
import { useXplainableController } from "context";

import LoadingSpinner from "shared/Animations/LoadingAnimation";
import rgba from "assets/theme/functions/rgba";
import colors from "assets/theme/base/colors";
import { useAuth0 } from "@auth0/auth0-react";
import XSelect from "components/XSelect";

const getInitialDateRange = () => {
  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);

  // format the date as "YYYY-MM-DD"
  const formatDate = (date) =>
    `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(
      date.getDate()
    ).padStart(2, "0")}`;

  return [formatDate(sevenDaysAgo), formatDate(today)];
};

function ProgressLineChart({
  color,
  icon,
  title,
  height,
  event,
  shadow = true,
  stack = false,
  ...queryProps
}) {
  const { logout } = useAuth0();
  const { apiKey, activeSubscription } = useApiKey();

  const containerRef = useRef();

  const [controller, dispatch] = useXplainableController();
  const { darkMode } = controller;
  const [dateRange, setDateRange] = useState(getInitialDateRange());
  const [fromDate, setFromDate] = useState(getInitialDateRange()[0]);
  const [toDate, setToDate] = useState(getInitialDateRange()[1]);
  const [uniqueViews, setUniqueViews] = useState(false);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(1);

  const [tableData, setTableData] = useState(null);
  const animationDuration = useRef(1000);

  let total = null;
  let lineData = null;
  let lineOptions = null;

  if (!activeSubscription) {
    color = "secondary"
  }
  const emptyData = {
    labels: [0, 0, 0, 0, 0, 0, 0],
    data: [0, 0, 0, 0, 0, 0, 0],
  };

  //Function to toggle unique views
  const handleUniqueViews = (obj) => {
    if (obj.value === "all") {
      setUniqueViews(false);
      return;
    }

    setUniqueViews(true);
  };

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const getUnitByTabValue = (value) => {
    switch (value) {
      case 0:
        return "hour";
      case 1:
        return "day";
      case 2:
        return "month";
      default:
        return "day"; // Default to hour, though you can change this as you wish
    }
  };

  const query = useXPanelQuery(
    {
      from_date: fromDate,
      to_date: toDate,
      unit: getUnitByTabValue(tabValue),
      type: uniqueViews ? "unique" : "general",
      event: event,
      ...queryProps,
    },
    apiKey,
    logout
  );
  const { data, isLoading, isError } = query;

  useEffect(() => {
    if (data) {
      setTableData(data);
      animationDuration.current = 1000;
    }
  }, [data]);

  if (isError) {
    return (
      <Card sx={{ boxShadow: shadow ? undefined : "none", height:"380px" }}>
        <XBox display="flex" justifyContent="center" alignItems="center" height="200px" p={3}>
          <XTypography variant="h6">
            {"We're having trouble returning your data. Please try again later."}
          </XTypography>
        </XBox>
      </Card>
    );
  }

  //Get the total count to add to plot metrics
  const counts = tableData && tableData.data.data.values[event];
  total = counts && Object.values(counts).reduce((acc, curr) => acc + curr, 0);

  //Transform the data
  const transformedData = tableData && transformData(tableData.data, event);

  //Put into correct format for chart
  const chartConfig = configs(
    color,
    transformedData?.labels || emptyData.labels,
    title,
    transformedData?.data || emptyData.data,
    animationDuration.current
  );

  //Set the line data and options
  lineData = chartConfig.data;
  lineOptions = chartConfig.options;

  if (animationDuration.current) {
    animationDuration.current = 0;
  }

  return (
    <Card sx={{ boxShadow: shadow ? undefined : "none", height:"380px" }} ref={containerRef}>
      <XBox
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="column"
        pt={2}
        px={stack ? 1 : 2}
      >
        <XBox lineHeight={1} display="flex" justifyContent="space-between" width="100%">
          <XTypography
            variant="button"
            fontWeight="medium"
            textTransform="capitalize"
            fontSize="18px"
          >
            {title}
          </XTypography>

          <XDatePicker
            value={dateRange}
            options={{
              mode: "range",
              dateFormat: "Y-m-d",
            }}
            style={{ backgroundColor: "red" }}
            onChange={(dates) => {
              setDateRange(dates);
              if (dates && dates.length === 2) {
                const formatDate = (date) => {
                  const d = new Date(date);
                  return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, "0")}-${String(
                    d.getDate()
                  ).padStart(2, "0")}`;
                };
                setFromDate(formatDate(dates[0]));
                setToDate(formatDate(dates[1]));
              }
            }}
          />
        </XBox>
        <XBox display="flex" sx={{ alignSelf: "start" }}>
          <XTypography variant="h5" fontWeight="bold" fontSize="24px" color={color}>
            {isLoading ? 0 : total}
          </XTypography>
        </XBox>
        <XBox display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <XBox>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{
                  "& .Mui-selected": {
                    color: `${colors[color].main} !important`,
                  },
                  "& .MuiTabs-indicator": {
                    backgroundColor: colors[color].main,
                  },
                }}
              >
                <Tooltip title="Show hourly count">
                  <Tab
                    label="Hour"
                    sx={{
                      minWidth: "auto",
                      padding: 0,
                      marginRight: 0,
                      fontSize: "16px",
                    }}
                  />
                </Tooltip>
                <Tooltip title="Show daily count">
                  <Tab
                    label="Day"
                    sx={{
                      minWidth: "auto",
                      width: "fit-content",
                      padding: 0,
                      marginRight: 0,
                      fontSize: "16px",
                    }}
                  />
                </Tooltip>
                <Tooltip title="Show monthly count">
                  <Tab
                    label="Month"
                    sx={{ minWidth: "auto", width: "fit-content", padding: 0, fontSize: "16px" }}
                  />
                </Tooltip>
              </Tabs>
            </AppBar>
          </XBox>
          <XBox display="flex" alignItems="center">
            <XSelect
              defaultValue={{ value: "all", label: "All Views" }}
              options={[
                { value: "unique", label: "Unique Views" },
                { value: "all", label: "All Views" },
              ]}
              size="small"
              onChange={handleUniqueViews}
              sx={{
                backgroundColor: "red",
              }}
            />
          </XBox>
        </XBox>
      </XBox>
      <XBox mt={2}>
        {lineData !== null && lineOptions !== null && (
          <Line data={lineData} style={{ height: "200px", width: "100%" }} options={lineOptions} />
        )}
      </XBox>
    </Card>
  );
}

// Setting default values for the props of ProgressLineChart
ProgressLineChart.defaultProps = {
  color: "info",
  count: 0,
  height: "6.25rem",
};

// Typechecking props for the ProgressLineChart
ProgressLineChart.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "xppink",
    "xpblue",
    "xpgreen",
    "xppurple",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  progress: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array),
  event: PropTypes.string,
  shadow: PropTypes.bool,
  stack: PropTypes.bool,
};

export default React.memo(ProgressLineChart);

export function transformData(inputData, event) {
  // console.log("The input data is", inputData);

  if (!inputData || !inputData.data || !inputData.data.values || !inputData.data.values[event]) {
    console.error("Invalid inputData format");
    return {
      labels: [],
      data: [],
    };
  }

  // Extracting series from the keys of the event object
  const series = Object.keys(inputData.data.values[event]);
  const values = inputData.data.values[event];
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Check if all the dates are from the same day
  const sameDay = series.every(
    (date) => new Date(date).toDateString() === new Date(series[0]).toDateString()
  );

  // Check if all the dates are from the same month
  const sameMonth = series.every(
    (date) => new Date(date).getMonth() === new Date(series[0]).getMonth()
  );

  let labels;

  if (sameDay) {
    // Day-hour format
    labels = series.map((date) => {
      const d = new Date(date);
      return `${d.getDate()}-${d.getHours()}`;
    });
  } else if (sameMonth) {
    // Month-day format
    labels = series.map((date) => {
      const d = new Date(date);
      return `${monthNames[d.getMonth()]}-${d.getDate()}`;
    });
  } else {
    // Month format
    labels = series.map((date) => monthNames[new Date(date).getMonth()]);
  }

  const data = series.map((date) => values[date]);

  return {
    labels: labels,
    data: data,
  };
}
