import React, { useState, useContext, useEffect } from "react";
import { useApiKey } from "components/Authorisation/ApiKeyContext";

// @mui material components
import Grid from "@mui/material/Grid";
import { Fade, Card, Stepper, Step, StepLabel } from "@mui/material";

// xplainable Dashboard components
import XBox from "components/XBox";
import XButton from "components/XButton";
import XDropzone from "shared/Dropzone";
import PredictionResult from "./components/PredictionResult";
import LinkPreprocessor from "../../../../../shared/models/LinkPreprocessor";

import ScrollReveal from "scrollreveal";
import { useModel, useToast } from "hooks";
import { usePredictMutation } from "api/mutations";

const steps = ["Upload Dataset", "Predict"];

function Predict() {
  const { apiKey } = useApiKey();
  const { model_id, selectedVersion, selectedPartition } = useModel();
  const { showErrorToast } = useToast();
  const { postPredictMutation } = usePredictMutation();

  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [returnedData, setReturnedData] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [isActiveButton, setIsActiveButton] = useState(true);
  const [stepperStep, setStepperStep] = useState(0);
  const [preprocessorData, setPreprocessorData] = useState(null);

  const handleDrop = (acceptedFiles) => {
    setAcceptedFiles(acceptedFiles);
  };

  const onSubmit = async (acceptedFiles, id, version_id, partition_id) => {
    setActiveStep(activeStep + 1);

    const formData = new FormData();

    // Check if there's at least one file in the acceptedFiles array
    if (acceptedFiles.length > 0) {
      // Append the first file from the acceptedFiles array
      formData.append("file", acceptedFiles[0]);
      formData.append("model_id", id);
      formData.append("version_id", version_id.value);
      formData.append("delimiter", ",");

      postPredictMutation([apiKey, formData], {
        onSuccess: (data) => {
          setReturnedData(data?.data?.predictions);
        },
        onError: (err) => {
          setIsActiveButton(false);
          setActiveStep(activeStep - 1);

          showErrorToast(
            <div>
              <p style={{ fontWeight: "bold" }}>The response error is </p>
              <p>{err?.response?.data?.detail}</p>
            </div>
          );
        },
      });
    } else {
      console.error("No files were uploaded.");
    }
  };

  const handleNextButton = () => {
    if (activeStep === 1) {
      onSubmit(acceptedFiles, model_id, selectedVersion, selectedPartition);
      return;
    }

    setActiveStep(activeStep + 1);
    setStepperStep(stepperStep + 1);
  };

  const handleBackButton = () => {
    setActiveStep(activeStep - 1);
    setStepperStep(stepperStep - 1);

    setIsActiveButton(true);
  };

  useEffect(() => {
    ScrollReveal().reveal(".scrollreveal", {
      delay: 0,
      distance: "100px",
      duration: 700,
      easing: "cubic-bezier(0.5, 0, 0, 1)",
      origin: "bottom",
      interval: 100,
    });
  }, []);

  return (
    <>
      <Card sx={{ p: 4, mt: 2 }} className="scrollreveal">
        <LinkPreprocessor updatePreprocessorData={setPreprocessorData} isPredict={true} />
      </Card>
      <Card sx={{ p: 4, mt: 2 }} className="scrollreveal">
        <Stepper
          activeStep={stepperStep}
          sx={{ marginBottom: "20px", marginTop: "10px" }}
          alternativeLabel
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Grid container width={"100%"}>
          {activeStep === 0 || activeStep === 1 ? (
            <Fade in={showResults || !showResults}>
              <Grid container width={"100%"}>
                <Grid item xs={12}>
                  <XBox sx={{ minHeight: "300px" }}>
                    <XBox height={"100%"}>
                      <XDropzone
                        options={{
                          addRemoveLinks: true,
                          maxFiles: 1,
                        }}
                        onDrop={handleDrop}
                        activeStep={activeStep}
                        isImage={false}
                      />
                    </XBox>
                  </XBox>
                </Grid>
              </Grid>
            </Fade>
          ) : activeStep === 2 && returnedData.length > 0 ? (
            <PredictionResult returnedData={returnedData} />
          ) : (
            <></>
          )}
          <XBox display="flex" width="100%" gap="0 10px" mt={2} sx={{ justifyContent: "flex-end" }}>
            {activeStep !== 0 && (
              <XButton
                color="black"
                variant="white"
                disabled={activeStep === 0}
                onClick={handleBackButton}
              >
                Back
              </XButton>
            )}
            <XButton
              color="button"
              variant="gradient"
              onClick={handleNextButton}
              disabled={activeStep === 2 || acceptedFiles.length === 0 || !isActiveButton}
            >
              {activeStep === 0 ? "Dataset Metadata" : "Predict"}
            </XButton>
          </XBox>
        </Grid>
      </Card>
    </>
  );
}

export default Predict;
