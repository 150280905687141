import React, { useState, useEffect, useContext, useMemo } from "react";

import PropTypes from "prop-types";

import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { Card, Grid } from "@mui/material";
import { useModel } from "hooks";
import { ConfusionChart } from "./components/ConfusionChart";
import { useBinaryPerformanceQuery } from "api/query";
import { useAuth0 } from "@auth0/auth0-react";

import ProbabilityChart from "./components/ProbabilityChart";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import XSelect from "components/XSelect";
import Optimisation from "../../../layouts/models/model/components/performance/components/Optimisation";
import Parameters from "../../../layouts/models/model/components/performance/components/shared/Parameters";
import breakpoints from "assets/theme/base/breakpoints";
import Metrics from "../Metrics";

import ScrollReveal from "scrollreveal";

const BinaryPerformance = () => {
  const { model_id, selectedVersion, selectedPartition } = useModel();
  const { activeWorkspace } = useApiKey();
  const { logout } = useAuth0();

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [selectedDataset, setSelectedDataset] = useState({ value: "train", label: "Train" });
  const [performanceData, setPerformanceData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [metricsData, setMetricsData] = useState([]);
  const [scoreData, setScoreData] = useState([]);
  const [precisionRecallData, setPrecisionRecallData] = useState([]);
  const [rocData, setRocData] = useState([]);
  const [confusionData, setConfusionData] = useState({});
  const [threshold, setThreshold] = useState(50);
  const [probabilityData, setProbabilityData] = useState([]);
  const [probabilityMap, setProbabilityMap] = useState({});

  const { data, isLoading } = useBinaryPerformanceQuery(
    activeWorkspace,
    model_id,
    selectedVersion?.value,
    selectedPartition?.value,
    logout
  );

  useEffect(() => {
    if (!data) return;

    setPerformanceData(data.data[0]);
  }, [data]);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  useEffect(() => {
    const filterData = () => {
      if (selectedDataset.value === "validation") {
        return performanceData.evaluation?.validation;
      }

      if (selectedDataset.value === "train") {
        return performanceData.evaluation?.train;
      }
    };

    const newData = filterData();

    // console.log("The filtered data is", newData)
    if (newData) {
      setFilteredData(newData);

      //Extract the score values
      const scoreValues = newData.scores.map((obj) => {
        const { fp, fn, tp, tn } = obj;
        return { fp, fn, tp, tn };
      });
      setScoreData(scoreValues);
    }
  }, [selectedDataset, performanceData]);

  useEffect(() => {
    if (
      filteredData &&
      filteredData.scores &&
      filteredData.auc_pr &&
      filteredData.mcc &&
      filteredData.log_loss &&
      filteredData.roc_auc &&
      filteredData.roc &&
      filteredData.precision_recall_curve
    ) {
      //Build the scores dataset
      const scores = filteredData.scores[Math.round(threshold)]; //[Number(Math.round((threshold) * 100))];

      // console.log("The filtered Data is", filteredData)

      const metrics = [
        { title: "F1", value: scores.f1.toFixed(3) },
        { title: "Accuracy", value: scores.accuracy.toFixed(3) },
        { title: "Precision", value: scores.precision.toFixed(3) },
        { title: "Recall", value: scores.recall.toFixed(3) },
        { title: "AUC PR", value: filteredData.auc_pr.toFixed(3) },
        { title: "MCC", value: filteredData.mcc.toFixed(3) },
        { title: "Log Loss", value: filteredData.log_loss.toFixed(3) },
        { title: "ROC AUC", value: filteredData.roc_auc.toFixed(3) },
      ];

      const confusionData = Object.fromEntries(
        Object.entries(scores).filter(([k, v]) => ["fn", "fp", "tn", "tp"].includes(k))
      );

      setPrecisionRecallData(filteredData.precision_recall_curve);
      setRocData(filteredData.roc);
      setMetricsData(metrics);
      setConfusionData(confusionData);
    }
  }, [threshold, filteredData]);

  //Handle Filter status
  const handleDatasetChange = (dataset) => {
    setSelectedDataset(dataset);
  };

  useEffect(() => {
    ScrollReveal().reveal(".scrollreveal", {
      delay: 0,
      distance: "100px",
      duration: 700,
      easing: "cubic-bezier(0.5, 0, 0, 1)",
      origin: "bottom",
      interval: 100,
    });
  }, []);

  return (
    <XBox py={2}>
      <Grid container className="scrollreveal">
        <Grid item xs={12}>
          <Card
            sx={{
              p: 2,
              position: "relative",
              zIndex: 100,
              overflow: "visible",
              mb: 3,
            }}
          >
            <XBox display="flex" justifyContent="space-between" gap={3}>
              <XBox>
                <XTypography variant="h5" fontSize="24px">
                  Threshold Analysis
                </XTypography>
                <XTypography variant="h6" color="secondary" fontWeight="light">
                  Analyse how the threshold changes key performance metrics
                </XTypography>
              </XBox>
              <XBox maxWidth="280px">
                <XTypography variant="overline" fontSize="14px">
                  Selected Dataset
                </XTypography>
                <XSelect
                  placeholder="Filter on Dataset"
                  options={[
                    { value: "validation", label: "Validation" },
                    { value: "train", label: "Train" },
                  ]}
                  selected={selectedDataset}
                  onChange={handleDatasetChange}
                />
              </XBox>
            </XBox>
          </Card>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6} mt={1}>
              <Card sx={{ p: 2, height: "460px" }} id="probability-chart">
                <ProbabilityChart
                  id={"probability--plot"}
                  data={probabilityData}
                  dataset={selectedDataset.value}
                  fpm={probabilityMap}
                  margin={{
                    top: 20,
                    right: 40,
                    bottom: 20,
                    left: 40,
                  }}
                  setThreshold={setThreshold}
                />
              </Card>
            </Grid>
            <Grid item xs={12} lg={6} mt={1}>
              <Card sx={{ p: 2, height: "460px", position: "relative" }}>
                <ConfusionChart
                  id={"confusion--plot"}
                  confusionData={confusionData}
                  threshold={threshold}
                  margin={{
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                  }}
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={3} className="scrollreveal">
        <Metrics
          metricsData={metricsData}
          rocData={rocData}
          precisionRecallData={precisionRecallData}
          isLoading={isLoading}
        />
      </Grid>
      <Grid container mt={3} className="scrollreveal">
        <Optimisation scoreData={scoreData} />
      </Grid>
      <Grid container mt={3} className="scrollreveal">
        <Parameters />
      </Grid>
    </XBox>
  );
};

export default BinaryPerformance;

BinaryPerformance.propTypes = {
  threshold: PropTypes.number,
  setThreshold: PropTypes.func,
  confusionData: PropTypes.object,
};
