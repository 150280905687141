import React, { useState, useEffect, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useApiKey } from "components/Authorisation/ApiKeyContext";

// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import { AuthRouteWrapper } from "components/Authorisation/authRouteWrapper";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// xplainable Dashboard components
import XBox from "components/XBox";

// xplainable dashboard example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// xplainable themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// xplainable dashboard routes
import routes from "routes";
import ProtectedRoute from "components/Authorisation/ProtectedRoute";

// xplainable dashboard contexts
import { useXplainableController, setMiniSidenav } from "context";

// Images
import brand from "assets/images/xplainable-logo.svg";
import brandDark from "assets/images/xplainable-logo.svg";

// Css imports
import "assets/css/animations.css";
import "assets/css/nucleo-icons.css";
import "assets/css/nucleo-svg.css";
import "assets/css/tt-lakes-neue.css";
import "assets/css/plus-jakarta.css";

// import { setAccessToken, setApiKeyConfig, setApiTokenConfig } from "api";
import { setAccessToken, setApiKeyConfig } from "api/AxiosInstance";
import { setQueryAccessToken, setQueryApiKeyConfig } from "api/httpClient";

import { Paths } from "settings";
import { useApp } from "hooks";
import { LOCAL_STORAGE_KEYS } from "constants";

import { ReactComponent as ChevronRight } from "assets/images/dark-chevron-left.svg";
import { ReactComponent as ChevronLeft } from "assets/images/dark-chevron-right.svg";

import "assets/css/style.css";
import { useQuery } from "@tanstack/react-query";

const hostUrl = process.env.REACT_APP_HOST_URL;

export default function App() {
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0();
  const {
    setApiKey,
    setApiKeyChecked,
    setWorkspaces,
    setActiveWorkspace,
    setPreferences,
    setViewState,
    setUser,
    apiKey,
    setCheckEmail,
  } = useApiKey();
  const { activeStep } = useApp();

  const [controller, dispatch] = useXplainableController();
  const { miniSidenav, direction, layout, sidenavColor, darkSidenav, darkMode } =
    controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const route = localStorage.getItem(LOCAL_STORAGE_KEYS.ROUTE);
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  const fetchApiKeyTest = async () => {
    const token = await getAccessTokenSilently();
    const apiUrl = `${hostUrl}/v1/login`;
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });

    return response;
  };

  const { data: loginData, error } = useQuery(["login", isAuthenticated], fetchApiKeyTest);

  if (route && isAuthenticated) {
    navigate(route);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.ROUTE);
  }

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  useEffect(() => {
    if (!error) return;

    if (error.message === "Please verify your email before logging in.") {
      setCheckEmail(true);
    }
    setApiKey(null);
    setApiKeyChecked(true);
    // console.error("Error fetching API key:", error);
    // showErrorToast(error.message);
  }, [error]);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  if (apiKey !== null) {
    setApiKeyConfig(apiKey);
    setQueryApiKeyConfig(apiKey);
  }

  useEffect(() => {
    if (!loginData) return;

    let isMounted = true;

    const fetchApiKey = async () => {
      try {
        if (loginData.status === 401) {
          setApiKeyChecked(true);

          //Check whether API key has expired and return and logout
          //Use Auth0 handler to logout
          logout({
            logoutParams: { returnTo: `${window.location.origin}${Paths.login}` },

            onComplete: () => {
              console.log("Auth0 logout completed");
            },
          });

          return;
        }

        if (loginData.status === 404) {
          //Set if the key is checked for routing to the first login page
          setApiKeyChecked(true);

          return;
        }

        if (!loginData.ok) {
          setApiKeyChecked(true);
          setApiKey(false);
          throw new Error("Failed to fetch API key");
        }

        // Extract the data from the response
        const data = await loginData.json();

        console.log("the login data,", data)

        if (isMounted) {
          console.log("The preferences are", data.preferences);
          //Map the naming convention from the API to the local storage
          const updatedPreferences = {
            miniSidenav: data.preferences.miniSidenav,
            darkSidenav: data.preferences.side_nav_dark,
            sidenavColor: data.preferences.side_nav_colour,
            transparentNavbar: data.preferences.transparentNavbar,
            fixedNavbar: data.preferences.nav_bar_fixed,
            layout: data.preferences.layout,
            darkMode: data.preferences.dark_mode,
            globalColor: data.preferences.global_colour,
            globalImage: data.preferences.global_image,
            user_id: data.preferences.user_id,
            active_organisation_id: data.preferences.active_organisation_id,
            active_team_id: data.preferences.active_team_id,
            models_view_table: data.preferences.models_view_table,
            deployments_view_table: data.preferences.deployments_view_table,
            collections_view_table: data.preferences.collections_view_table,
            reports_view_table: data.preferences.reports_view_table,
            batches_view_table: data.preferences.batches_view_table,
          };

          // Define a function to get the item from localStorage or fallback to provided default
          const getPreferenceOrDefault = (key, defaultValue) => {
            // Get and parse the 'preferences' object from localStorage
            const storedPreferences = JSON.parse(localStorage.getItem("preferences") || "{}");

            // Return the value for the given key if it exists, or the default value otherwise
            return storedPreferences[key] !== undefined ? storedPreferences[key] : defaultValue;
          };

          //Get the current state from the local storage or use the default value
          const userId = getPreferenceOrDefault("user_id", data.preferences.user_id);
          const organisationId = getPreferenceOrDefault(
            "active_organisation_id",
            data.preferences.active_organisation_id
          );

          const teamId = getPreferenceOrDefault("active_team_id", data.preferences.active_team_id);

          // Only proceed with setting state if the component is still mounted
          setApiKeyConfig(data.api_key);
          setApiKey(data.api_key);
          setQueryApiKeyConfig(data.api_key);
          setApiKeyChecked(true);
          setUser(data.user);
          setWorkspaces(data.workspaces);
          setActiveWorkspace({
            user_id: userId,
            organisation_id: organisationId,
            team_id: teamId,
          });

          // console.log("The api key is", data.api_key)
          //This should normally be data.preferences if it doesnt exist then use the local storage
          setPreferences(updatedPreferences);
          setViewState("creator");
        }
      } catch (error) {
        if (error.message === "Please verify your email before logging in.") {
          setCheckEmail(true);
        }
        setApiKey(null);
        setApiKeyChecked(true);
        return;
      }
    };

    fetchApiKey();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [loginData]);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={
              route.protected ? (
                <ProtectedRoute>{route.component}</ProtectedRoute>
              ) : (
                <AuthRouteWrapper>{route.component}</AuthRouteWrapper>
              )
            }
            key={route.key}
          />
        );
      }

      return null;
    });

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && isAuthenticated && pathname !== Paths.newUser && (
          <>
            <XBox
              sx={(theme) => {
                return {
                  position: "fixed",
                  top: "350px%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "5px",
                  left: miniSidenav ? "110px" : "260px",
                  transform: "translate(20px)",
                  boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.08)",
                  borderRadius: "50%",
                  zIndex: "1200",
                  background: darkSidenav ? "" : "white",
                  transition: "all cubic-bezier(0.4, 0, 0.6, 1) 225ms",
                  cursor: "pointer",
                };
              }}
              onClick={handleMiniSidenav}
            >
              {pathname !== "/new-user" && !miniSidenav && (
                <ChevronRight stroke={darkSidenav ? "white" : "#0D0C0C"} />
              )}
              {pathname !== "/new-user" && miniSidenav && (
                <ChevronLeft stroke={darkSidenav ? "white" : "#0D0C0C"} />
              )}
            </XBox>

            <Sidenav
              color={sidenavColor}
              brand={darkSidenav || darkMode ? brand : brandDark}
              brandName="xplainable"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {/* {configsButton} */}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          {/* Add static routes not visbile to user */}
          {/* <Route path="*" element={<div>This is a 404 error</div>} /> */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && isAuthenticated && pathname !== Paths.newUser && (
        <>
          {activeStep !== 1 && (
            <>
              <XBox
                sx={(theme) => {
                  return {
                    position: "fixed",
                    top: "350px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px",
                    left: miniSidenav ? "110px" : "260px",
                    transform: "translate(20px)",
                    boxShadow: "0 4px 10px 0 rgba(0, 0, 0, 0.08)",
                    borderRadius: "50%",
                    zIndex: "1300",
                    background: darkSidenav ? theme.palette.background.dark : "white",
                    transition: "all cubic-bezier(0.4, 0, 0.6, 1) 225ms",
                    cursor: "pointer",
                  };
                }}
                onClick={handleMiniSidenav}
              >
                {pathname !== "/new-user" && !miniSidenav && (
                  <ChevronRight stroke={darkSidenav ? "white" : "#0D0C0C"} />
                )}
                {pathname !== "/new-user" && miniSidenav && (
                  <ChevronLeft stroke={darkSidenav ? "white" : "#0D0C0C"} />
                )}
              </XBox>

              {pathname !== "/new-user" && (
                <Sidenav
                  color={sidenavColor}
                  brand={darkSidenav || darkMode ? brand : brandDark}
                  brandName="xplainable"
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
              )}

              <Configurator />
              {/* {configsButton} */}
            </>
          )}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        {/* Add static routes not visbile to user */}
        {/* <Route path="*" element={<div>This is a 404 error</div>} /> */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
}
