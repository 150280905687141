import React, { useState } from "react";
import NotificationItem from "shared/Items/NotificationItem";
import XBox from "components/XBox";
import PropTypes from "prop-types";
import XButton from "components/XButton";
import Icon from "@mui/material/Icon";
import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";
import Tooltip from "@mui/material/Tooltip";

import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { Collapse } from "@mui/material";
import { useInviteMutation } from "api/mutations/useInvitationsMutation";
import { getTimeDifference } from "shared/Functions/Date";
import { useToast } from "hooks";

import DenyIcon from "assets/images/icons/deny-icon.svg";
import ConfirmIcon from "assets/images/icons/confirm-icon.svg";

const hostUrl = process.env.REACT_APP_HOST_URL;

const InvitationNotification = ({ notification, handleCloseMenu, handleMarkAsRead }) => {
  const { apiKey, setRequests, invites } = useApiKey();
  const { showSuccessToast, showErrorToast } = useToast();
  const { handleInvitationMutation } = useInviteMutation();

  const [isNotificationVisible, setIsNotificationVisible] = useState(true);

  //Accept/Decline the request
  const handleRequest = async (requestId, type, action, team_name) => {
    handleInvitationMutation([type, requestId, action, apiKey], {
      onSuccess: () => {
        setRequests((prevRequests) =>
          prevRequests.filter((request) => request.request_id !== requestId)
        );

        handleMarkAsRead(notification._id);
        setIsNotificationVisible(false);

        if (action === "accept") {
          showSuccessToast(`You’ve been added to ${team_name}, wait while we refresh the page`);
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
          return;
        }

        showErrorToast("Request has been declined");
      },
      onError: () => {
        console.error("Error:", error);
      },
    });
  };

  return (
    <Collapse in={isNotificationVisible}>
      <NotificationItem
        key={notification._id}
        image={
          <XBox sx={{ position: "relative" }}>
            <img src={notification.data.profile_image_url} sx={{ width: "100%", height: "auto" }} />
            <XBox sx={{ position: "absolute", bottom: "0px", right: "-10px" }}>
              <XBox
                bgColor={rgba(colors.xpblue.main, 0.8)}
                color="white"
                p={0.5}
                borderRadius="8px"
                sx={{
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Icon>{"send"}</Icon>
              </XBox>
            </XBox>
          </XBox>
        }
        title={[`${notification.data.inviter_name}`, `${notification.description}`]}
        date={getTimeDifference(notification.insert_timestamp, true)}
        confirm={
          <XBox ml={1.5} display="flex" justifyContent="center" alignItems="center">
            <Tooltip title="Accept Invite Request" placement="bottom">
              <XBox
                src={ConfirmIcon}
                component="img"
                onClick={() =>
                  handleRequest(
                    notification.data.invite_id,
                    "invitations",
                    "accept",
                    notification.data.team_name
                  )
                }
              />
            </Tooltip>
          </XBox>
        }
        deny={
          <XBox mx={0.5} display="flex" justifyContent="center" alignItems="center">
            <Tooltip title="Decline Invite Request" placement="bottom">
              <XBox
                src={DenyIcon}
                component="img"
                onClick={() =>
                  handleRequest(
                    notification.data.invite_id,
                    "invitations",
                    "decline",
                    notification.data.team_name
                  )
                }
              />
            </Tooltip>
          </XBox>
        }
      />
    </Collapse>
  );
};

export default InvitationNotification;

InvitationNotification.propTypes = {
  notification: PropTypes.object.isRequired,
  handleCloseMenu: PropTypes.func.isRequired,
  handleMarkAsRead: PropTypes.func.isRequired,
};
