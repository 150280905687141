import React, { useEffect, useState } from "react";

import { Card, Grid } from "@mui/material";
import XBox from "components/XBox";
import XTypography from "components/XTypography";
import RegressionChart from "layouts/models/model/components/performance/components/RegressionPerformance/components/RegressionChart2";

import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useModel } from "hooks";
import { useRegressionPerformanceQuery } from "api/query";
import { useAuth0 } from "@auth0/auth0-react";

export const ReportRegressionChart = () => {
  const { logout } = useAuth0();

  const { activeWorkspace } = useApiKey();
  const { selectedPartition, trainingMetadata, regressionBins, selectedVersion, model_id } =
    useModel();

  const { data } = useRegressionPerformanceQuery(
    activeWorkspace,
    model_id,
    selectedVersion?.value,
    selectedPartition?.value,
    logout
  );

  const [filteredData, setFilteredData] = useState([]);

  const selectedDataset = { value: "train", label: "Train" };
  const sliderValue = 26;

  const filterAndSetData = (data) => {
    if (!data) return;

    setFilteredData(data);
  };

  // Fetch the performance data from the model endpoint
  useEffect(() => {
    if (!data) return;

    // A local function to handle the logic
    if (selectedDataset.value === "validation") {
      filterAndSetData(data.data[0].evaluation?.validation);
    } else if (selectedDataset.value === "train") {
      filterAndSetData(data.data[0].evaluation?.train);
    }
  }, [data, model_id, selectedVersion, selectedPartition, selectedDataset]); // Make sure you add selectedDataset here.

  return (
    <Grid item lg={12}>
      <XBox pt={2} px={2} lineHeight={1}>
        <XTypography variant="h6" color="secondary">
          Predicted vs Actual (Residuals)
        </XTypography>
      </XBox>
      {filteredData.charts && (
        <RegressionChart
          id={"regression--error--plot"}
          data={filteredData.charts}
          pixels={sliderValue}
          title={"Regression Error Plot"}
          xLabel={"Actual x"}
          yLabel={"Predicted x"}
          xName={"error-x"}
          yName={"error-y"}
        />
      )}
    </Grid>
  );
};
