import React, { useEffect, useState } from "react";
import { Card, Icon, Modal } from "@mui/material";
import { useAutoTrain, useToast } from "hooks";
import { v4 as uuidv4 } from "uuid";
import { useTrainMutation } from "api/mutations";
import { useApiKey } from "components/Authorisation/ApiKeyContext";
import { useXplainableController } from "context";
import { ChatMessage, TextMessage } from "./components";

import XBox from "components/XBox";
import XTypography from "components/XTypography";
import LoadingSpinner from "shared/Animations/LoadingAnimation";
import PropTypes from "prop-types";
import colors from "assets/theme/base/colors";

import { ReactComponent as EmptyChatIcon } from "assets/images/icons/train/empty-chat-icon.svg";
import { ReactComponent as ChatImageErrorIcon } from "assets/images/icons/train/chat-image-error-icon.svg";

export function Base64Image({ base64 }) {
  const imageUrl = `data:image/png;base64,${base64}`;
  return <img src={imageUrl} alt="Raster Plot" width="100%" />;
}

export const ChatAutoTrain = ({ isAnalystExpanded, isAnalyst, modelSettings }) => {
  const [controller] = useXplainableController();
  const { darkMode } = controller;

  const { showSuccessToast, showErrorToast } = useToast();
  const { insightsMutation } = useTrainMutation();
  const { activeWorkspace } = useApiKey();
  const {
    chatHistory,
    setChatHistory,
    activeGoal,
    goalData,
    summarizeData,
    isChatSuggestionVisible,
    setActiveGoal,
  } = useAutoTrain();

  const [isVisualisationLoading, setIsVisualisationLoading] = useState(false);
  const [visualisationModalData, setVisualisationModalData] = useState(null);
  const [isRetry, setIsRetry] = useState(false);

  const handleCloseVisualisationModal = () => {
    setVisualisationModalData(null);
  };

  useEffect(() => {
    if (!activeGoal) return;
    setChatHistory((prevHistory) => [
      ...prevHistory,
      { id: uuidv4(), sender: "user", text: activeGoal, type: "answer" },
    ]);

    const fetchInsights = async () => {
      try {
        setIsVisualisationLoading(true);
        const activeGoalToUse = activeGoal;
        const activeGoalData = goalData.find((item) => item.question === activeGoalToUse);

        const stringifySummarize = JSON.stringify({
          summary: summarizeData.summary,
          goal: activeGoalData || {
            question: activeGoal,
            visualization: activeGoal,
            rationale: "",
          },
          textgen_config: modelSettings
        });

        const { data: insightsData } = await insightsMutation([
          activeWorkspace,
          stringifySummarize,
        ]);

        if (!insightsData.status) {
          setChatHistory((prevHistory) => [
            ...prevHistory,
            {
              id: uuidv4(),
              sender: "ai",
              type: "error",
            },
          ]);
          showErrorToast(insightsData?.type === "plot" ? "There was an error returning the plot" : "There was an error returning the result");
          setIsVisualisationLoading(false);
          return;
        }

        showSuccessToast(insightsData?.type === "plot" ? "Succesfully returned a plot" : "Successfully queried the dataset");

        if (insightsData.type === "plot") {
          setChatHistory((prevHistory) => [
            ...prevHistory,
            {
              id: uuidv4(),
              sender: "ai",
              data: [insightsData?.result?.[0]],
              goal: activeGoalData || {
                question: activeGoal,
                visualization: activeGoal,
                rationale: "",
              },
              type: "answer",
            },
          ]);
        } else if (insightsData.type === "query") {
          setChatHistory((prevHistory) => [
            ...prevHistory,
            {
              id: uuidv4(),
              sender: "ai",
              text: insightsData?.result,
              type: "query",
              code: insightsData?.code
            },
          ]);
        }

        setIsVisualisationLoading(false);
      } catch (error) {
        setChatHistory((prevHistory) => [
          ...prevHistory,
          {
            id: uuidv4(),
            sender: "ai",
            type: "error",
          },
        ]);
        showErrorToast(error?.response?.data?.message || "Error", 5000);
        setIsVisualisationLoading(false);
      }
    };

    fetchInsights();
  }, [activeGoal, isRetry]);

  useEffect(() => {
    return () => setActiveGoal("");
  }, []);

  return (
    <XBox
      display="flex"
      gap={3}
      flexDirection="column"
      justifyContent="flex-start"
      sx={{
        height: isAnalyst
          ? "100%"
          : isChatSuggestionVisible && goalData.length > 0
          ? "calc(100% - 320px)"
          : "calc(100% - 100px)",
        overflow: "auto",
        "scroll-snap-type": "y mandatory",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Modal
        open={visualisationModalData}
        onClose={handleCloseVisualisationModal}
        aria-labelledby="request-title"
        aria-describedby="request-description"
      >
        <XBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: darkMode ? colors.background.dark : colors.background.default,
            borderRadius: "16px",
            padding: 3,
          }}
        >
          <XBox display="flex" flexDirection="column" gap="16px">
            <XBox display="flex" justifyContent="space-between" alignItems="center" gap={4}>
              <XTypography fontSize="16px" fontWeight="bold">
                {visualisationModalData?.goal?.question}
              </XTypography>
              <Icon
                sx={({ typography: { size, fontWeightBold }, palette: { dark, white } }) => ({
                  fontSize: `${size.md} !important`,
                  fontWeight: `${fontWeightBold} !important`,
                  color: darkMode ? white.main : dark.main,
                  stroke: darkMode ? white.main : dark.main,
                  strokeWidth: "2px",
                  cursor: "pointer",
                })}
                onClick={handleCloseVisualisationModal}
              >
                close
              </Icon>
            </XBox>

            {visualisationModalData?.data[visualisationModalData.activeChat - 1]?.raster ? (
              <Base64Image
                base64={visualisationModalData.data[visualisationModalData.activeChat - 1]?.raster}
              />
            ) : (
              <ChatImageErrorIcon
                style={{ alignSelf: "center", width: "600px", height: "600px" }}
              />
            )}
          </XBox>
        </XBox>
      </Modal>

      {chatHistory.length === 0 && (
        <XBox
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <EmptyChatIcon />
          <XBox display="flex" flexDirection="column" gap={1} alignItems="center">
            <XTypography fontWeight="bold" fontSize="18px">
              Chat to explore data
            </XTypography>
            <XTypography sx={{ color: "#7C7C7C", fontSize: "16px" }}>Start a new chat</XTypography>
          </XBox>
        </XBox>
      )}
      {chatHistory.map((item) => {
        if (item.type === "query") {
          return (
            <TextMessage item={item} />
          );
        }
        return (
          <ChatMessage
            key={item.id}
            item={item}
            setVisualisationModalData={setVisualisationModalData}
            visualisationModalData={visualisationModalData}
            setChatHistory={setChatHistory}
            summarizeData={summarizeData}
            setIsRetry={setIsRetry}
            isAnalystExpanded={isAnalystExpanded}
            isAnalyst={isAnalyst}
            modelSettings={modelSettings}
          />
        );
      })}

      {isVisualisationLoading && <LoadingSpinner size={50} />}
    </XBox>
  );
};

ChatAutoTrain.propTypes = {
  isAnalystExpanded: PropTypes.bool,
  isAnalyst: PropTypes.bool,
  modelSettings: PropTypes.object
};

Base64Image.propTypes = {
  base64: PropTypes.string,
};
