import XBox from "components/XBox";
import React from "react";
import PropTypes from "prop-types";

import { useXplainableController } from "context";

export const XImg = ({ children, lightColor = "white", darkColor = "#0D0C0C", ...props }) => {
  const [controller] = useXplainableController();
  return (
    <XBox
      sx={{ svg: { stroke: controller.darkMode ? lightColor : darkColor, cursor: "pointer" } }}
      display="flex"
      {...props}
    >
      {children}
    </XBox>
  );
};

XImg.propTypes = {
  children: PropTypes.element,
  lightColor: PropTypes.string,
  darkColor: PropTypes.string,
};
