import React, { useState } from "react";
import PropTypes from "prop-types";
import XButton from "components/XButton";
import XInput from "components/XInput";
import XTypography from "components/XTypography";
import XBox from "components/XBox";
import XBadge from "components/XBadge";
import Icon from "@mui/material/Icon";

import { Slider, Card, Modal } from "@mui/material";
import { useXplainableController } from "context";

const HoverMenu = ({ autoGenerateReport }) => {
  const [controller] = useXplainableController();
  const { darkMode } = controller;
  const [targetDescription, setTargetDescription] = useState("");
  const [projectObjective, setProjectObjective] = useState("");
  const [sliderValue, setSliderValue] = useState(0.7);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpen = (e) => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = () => {
    handleClose();
    autoGenerateReport(targetDescription, projectObjective, sliderValue); // Pass the state values to the function
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="request-title"
        aria-describedby="request-description"
      >
        <Card
          sx={{
            position: "absolute",
            width: "500px",
            p: 4,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -60%)",
            borderRadius: "10px",
            boxShadow: 3,
            zIndex: 100,
          }}
        >
          <XBox mb={2}>
            <XTypography variant="h6" fontSize="12px">
              Target description
            </XTypography>
            <XInput
              multiline
              rows={4}
              size="medium"
              placeholder={"Describe the target in detail..."}
              value={targetDescription}
              onChange={(e) => setTargetDescription(e.target.value)}
            />
          </XBox>
          <XBox my={2}>
            <XTypography variant="h6" fontSize="12px">
              Project Objective
            </XTypography>
            <XInput
              multiline
              rows={4}
              size="medium"
              placeholder={"Describe what the intent of the project is..."}
              value={projectObjective}
              onChange={(e) => setProjectObjective(e.target.value)}
            />
          </XBox>
          <XBox my={1} width={"100%"} px={2}>
            <XBox display="flex" justify-content="space-between">
              <XTypography variant="h6" fontSize="12px">
                Temperature
              </XTypography>
              <XBadge color="xpblue" variant="contained" badgeContent={sliderValue} />
            </XBox>
            <Slider
              color={"primary"}
              step={0.01}
              min={0}
              max={1}
              value={sliderValue}
              onChange={(sliderValue) => {
                setSliderValue(sliderValue.target.value);
              }}
            />
          </XBox>
          <XBox sx={{ display: "flex", justifyContent: "flex-end" }}>
            <XButton onClick={handleSubmit} variant="gradient" color="button">
              Create Report
            </XButton>

          </XBox>
        </Card>
      </Modal>

      <div style={{ position: "relative", display: "inline-block" }} onClick={handleOpen}>
        <XButton
          color={darkMode ? "dark" : "white"}
          size="medium"
          id="menubutton1"
          style={{ zIndex: 1 }}
        >
          <Icon color={darkMode ? "white" : "secondary"}>auto_fix_high</Icon>
          <XTypography variant="button" color={darkMode ? "white" : "secondary"} ml={0.5}>
            XReport
          </XTypography>
        </XButton>
      </div>
    </>
  );
};

export default HoverMenu;

HoverMenu.propTypes = {
  autoGenerateReport: PropTypes.func,
};
